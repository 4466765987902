import './upload.css';
import React, { useState, useRef, useEffect, useContext } from 'react';
import { BASE_BACK } from '../../util/Urls';
import { ValidLogo } from '../../util/Functions';
import * as TXTS from '../../util/Texts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faRepeat, faPlus } from '@fortawesome/free-solid-svg-icons';
import { AlertasContext } from '../../contexts/AlertasContext';
import * as SiteS from '../../services/Site.service';
import LogoIDR from '../LogoIDR';

function UploadSlide({

    url = false,
    refe,
    onChange = false,
    showLogo = true,

}) {

    const [imgReady, setImgReady]           = useState(false);
    const [selectedFile, setSelectedFile]   = useState(null);
    const fileInputRef                      = useRef();
    const [slide, setSlide]                 = useState('');
    const [hasSlide, setHasSlide]           = useState(false);
    const [oldFile, setOldFile]             = useState('');

    const {

        setAlertShow,
        setAlertBtnOk,
        setAlertType,
        setAlertMsg

    } = useContext(AlertasContext);

    const handleSlide = (event) => {

        const validar = ValidLogo(event, false)
        if(validar !== ''){

            setAlertShow(true);
            setAlertMsg(validar);
            setAlertType('error');
            setAlertBtnOk(false);

            if (fileInputRef) fileInputRef.current.value = null;
            setSelectedFile(null);
            return false;
        }

        setSelectedFile(event.target.files[0]);
    }

    useEffect(() => {
        const handleUpLoad = async () => {
            
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('old_file', oldFile);
            setImgReady(false);

            try{

                const result = await SiteS.ChangeSlide(formData);

                if(result.data){
                    if (result.status === 200) {

                        const slide = result.data.slide ? `${BASE_BACK}${result.data.slide}` : '/img/semimagem.png';
                    
                        const img = new Image();
                        
                        img.onload = () => {
                            
                            setSlide(slide);
                            setHasSlide(true);

                            setTimeout(() => {
                                setImgReady(true);
                            }, 300);

                            if (typeof onChange === "function") {
                                onChange(result.data.slide ?? false);
                            }
                        }

                        img.onerror = () => {

                            setSlide('/img/semimagem.png');
                            setHasSlide(false);

                            if (typeof onChange === "function") {
                                onChange(false);
                            }

                        }

                        img.src = slide;

                    }else{

                        setImgReady(true);
                        setAlertShow(true);
                        setAlertMsg(result.data.msg);
                        setAlertType('error');
                        setAlertBtnOk(false);
                    
                    }
                }
                else{

                    setImgReady(true);
                    setAlertShow(true);
                    setAlertMsg(TXTS.IMG_LOAD_ERROR);
                    setAlertType('error');
                    setAlertBtnOk(false);

                }

            }catch{

                setImgReady(true);
                setAlertShow(true);
                setAlertMsg(TXTS.ERRO_LOGIN_3);
                setAlertType('error');
                setAlertBtnOk(false);

            }
            setSelectedFile(null);
        }

        if(selectedFile !== null)
        handleUpLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFile, setAlertBtnOk, setAlertMsg, setAlertShow, setAlertType, setHasSlide, setSlide]);

    useEffect(() => {

        if(!url){
            setImgReady(true);
            setHasSlide(false);
            setOldFile('');
            return;
        }

        const urlOk = `${BASE_BACK}${url}`;

        const img = new Image();
                        
        img.onload = () => {
            
            setSlide(urlOk);
            setTimeout(() => {
                setImgReady(true);
                setHasSlide(true);
            }, 300);
        }

        img.onerror = () => {

            setSlide('/img/semimagem.png');
            setTimeout(() => {
                setImgReady(true);
                setHasSlide(false);
            }, 300);

        }

        img.src = urlOk;
        setOldFile(url);
        
    }, [url]);

    return(

        <div className='slideUpload'>
            
            {imgReady ?
                <>
                    
                    <input type='file' id={`slide-${refe}`} name={`slide-${refe}`} onChange={handleSlide} ref={fileInputRef}/>

                    <label htmlFor={`slide-${refe}`} className='slide'>
                        {hasSlide &&
                            <img draggable={false} src={slide} alt='Slide'/>
                        }
                        {!hasSlide &&
                            <img draggable={false} src='/img/semimagem.png' alt='Sem Slide'/>
                        }

                        {showLogo &&
                        <LogoIDR 
                            hoverSlides={true}
                            uploadSlides={true}
                        />
                        }
                    </label>

                    <div className='slideChange'>
                            
                        <FontAwesomeIcon icon={hasSlide ? faRepeat : faPlus}/>
                        {hasSlide ? TXTS.SLIDE_CHANGE : TXTS.SLIDE_ADD}
                
                    </div>                        
                </>
                :
                <><FontAwesomeIcon icon={faSpinner} spin color="lightblue"/></>
            }
        
        </div>
       
    )
}

export default UploadSlide;