import React, { useState, useContext, useEffect } from "react";
import { AlertasContext } from "../../../contexts/AlertasContext";
import { LoaderContext } from "../../../contexts/LoaderContext";
import { SiteContext } from "../../../contexts/SiteContext";
import { UserContext } from "../../../contexts/UserContext";
import * as UserService from '../../../services/User.service';
import * as TXTS from '../../../util/Texts';

import Input from "../Input";



function ChangePass() {
    
    const {
        
        changePassIputs,
        handleInputChange,
        setAlertShow,
        setAlertBtnClose,
        setAlertBtnOk,
        setAlertBtnCancel,
        setAlertBtnOkTxt,
        setAlertType,
        setAlertMsg,
        setAlertOnOk,
        setAlertChangePass,
        changePass

    } = useContext(AlertasContext);

    const {
        setIsLoading,
        firstLoad

    } = useContext(LoaderContext);

    const { setFirstAccess } = useContext(UserContext);

    const { setErrorCon } = useContext(SiteContext);

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPass, setShowPass]               = useState(false);
    const [showPassCheck, setShowPassCheck]     = useState(false);

    const toogleShowPass = (which) => {

        if(which === 'oldPassPassword'){
            setShowOldPassword(showOldPassword ? false : true);
        }

        if(which === 'newPassword'){
            setShowPass(showPass ? false : true);
        }

        if(which === 'checkNewPassword'){
            setShowPassCheck(showPassCheck ? false : true);
        }
        
    }

    const handleChangePass = async (changePassIputs) => {
        
        const oldPassPassword = changePassIputs.oldPassPassword.trim();
        const newPassword = changePassIputs.newPassword.trim();
        const checkNewPassword = changePassIputs.newPassword.trim();        
        
        setIsLoading('show');
        
        const userData = {
            oldPassPassword: oldPassPassword.trim(),
            newPassword: newPassword.trim(),
            checkNewPassword: checkNewPassword.trim()
        };

        try{
            const result = await UserService.ChangePass(userData);

            if(result.data){
                if(result.status === 200){
                    setFirstAccess(false);
                    setAlertMsg(TXTS.SENHA_ALT_SUCESSO);
                    setAlertType('success');
                    setAlertChangePass(false);
                    setAlertBtnOk(false);
                    setAlertBtnCancel(false);
                    setAlertBtnClose(true);
                }
                else{
                    
                    setAlertMsg(result.data.msg);
                    setAlertType('error');
                
                }
            }else{
                
                setAlertMsg(TXTS.ERRO_LOGIN_2);
                setAlertType('error');

            }
            
        }catch{
            
            setAlertMsg(TXTS.ERRO_LOGIN_3);
            setAlertType('error');
            setErrorCon(true);

        }
        setIsLoading('hide');
    }

    useEffect(() => {
        
        if(changePass){
            setAlertShow(true);
            setAlertOnOk(() => handleChangePass);
            setAlertMsg(firstLoad ? TXTS.MUDAR_SENHA : TXTS.PEDIR_MUDAR_SENHA);
            setAlertType(firstLoad ? 'warning' :  'primary');
            setAlertBtnClose(firstLoad ? false : true);
            setAlertBtnOkTxt(firstLoad ? 'ENVIAR' : 'OK');
            setAlertBtnCancel(!firstLoad);
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changePass]);
    
  
    return (
        <>
        <div className='col col-12 col-lg-6 mt-3 mt-md-0'>
            <Input 
                name='oldPassPassword'
                labelLeft='faLock'
                labelRight={showOldPassword ? 'faEyeSlash' : 'faEye'}
                labelRightOnClick={()=>{toogleShowPass('oldPassPassword')}}
                labelRightTitle={showOldPassword ? 'Ocultar Senha Atual' : 'Mostrar Senha Atual'}
                placeholder='Senha Atual:'
                type={showOldPassword ? 'text' : 'password'}
                required={true}
                value={changePassIputs.oldPassPassword}
                onChange={handleInputChange}
            />
        </div>
        <div className='col col-12 col-lg-6 mt-3 mt-md-0'>
            <Input 
                name='newPassword'
                labelLeft='faLock'
                labelRight={showPass ? 'faEyeSlash' : 'faEye'}
                labelRightOnClick={()=>{toogleShowPass('newPassword')}}
                labelRightTitle={showPass ? 'Ocultar Nova Senha' : 'Mostrar Nova Senha'}
                placeholder='Nova Senha:'
                type={showPass ? 'text' : 'password'}
                required={true}
                value={changePassIputs.newPassword}
                onChange={handleInputChange}
            />
            </div>
            <div className='col col-12 col-lg-6 mt-3 mt-md-0'>
            <Input 
                name='checkNewPassword'
                labelLeft='faLock'
                labelRight={showPassCheck ? 'faEyeSlash' : 'faEye'}
                labelRightOnClick={()=>{toogleShowPass('checkNewPassword')}}
                labelRightTitle={showPassCheck ? 'Ocultar Nova Senha' : 'Mostrar Nova Senha'}
                placeholder='Confirmar Senha:'
                type={showPassCheck ? 'text' : 'password'}
                required={true}
                value={changePassIputs.checkNewPassword}
                onChange={handleInputChange}
            />
        </div>
        </>
    );
  }
  
export default ChangePass;