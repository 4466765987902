/* eslint no-eval: 0 */
import './index.css';
import React, { useEffect, useContext, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { animacao } from '../../util/AnimatedDiv'
import Input from '../../components/Forms/Input';
import { LoaderContext } from '../../contexts/LoaderContext';
import { SiteContext } from '../../contexts/SiteContext';
import { UserContext } from '../../contexts/UserContext';
import * as FR from '../../services/Frota.service';
import * as DRE from '../../services/Dres.service';
import * as IN from '../../services/Indicadores.service';
import * as TXTS from '../../util/Texts';
import SingleChartCircle from '../../components/SingleChartCircle';
import { GlobalFiltersContext } from '../../contexts/GlobalFiltersContext';
import * as F from '../../util/Functions';
import { LOGO_AUDIT } from '../../util/Urls';

function HomeUser() {

  const navigate                                      = useNavigate();
  const { setIsLoading, cLoading }                    = useContext(LoaderContext);

  const {
    HandleScroll,
    setDocTitle,
    canLoad
  } = useContext(SiteContext);

  const { isLogged }                                  = useContext(UserContext);
  const [dre, setDre]                                 = useState([]);
  const [indicadores, setIndicadores]                 = useState([]);
  const [indicadoresShow, setIndicadoresShow]         = useState([]);
  const [recLoc, setRecLoc]                           = useState('0,00');
  const [vendaVeiculo, setVendaVeiculo]               = useState('0,00');
  const [vendaVeicNome, setVendaVeicNome]             = useState(' - ');
  const [lucLiqPer, setLucLiqPer]                     = useState('0,00');
  const [changeMes, setChangeMes]                     = useState(true);
  const [carregando, setCarregando]                   = useState(false);
  const [frotaLoaded, setFrotaLoaded]                 = useState(false);
  const [globalReady, setGlobalReady]                 = useState(false);

  const [retornoFrota, setRetornoFrota] = useState(
    {
      frota: 0,
      frota_operacional: 0,
      valorDre: '0,00',
      ticketMedio: '0,00',
      valorNF: '0,00',
      mesAno: '',
      diasMes: 0,
      receitaDiariaMes: '0,00',
      ocupacao: 0
    }
  );

  const clearFrota = () => {
    setRetornoFrota(
      {
        frota: 0,
        frota_operacional: 0,
        valorDre: '0,00',
        ticketMedio: '0,00',
        valorNF: '0,00',
        mesAno: '',
        diasMes: 0,
        receitaDiariaMes: '0,00',
        ocupacao: 0
      }
    );
  }

  const { 

    globalFiltersPeriodoDash, setGlobalFiltersPeriodoDash,
    minMaxMes, setMinMaxMes,
    handleMesAnoIniDash,
    globalFiltersDreDash,
    locadoraPadrao,
    setActiveTab

  } = useContext(GlobalFiltersContext);

  const filtersDre = useMemo(() => {
  
    return {...globalFiltersDreDash, ...globalFiltersPeriodoDash};
    
  }, [globalFiltersDreDash, globalFiltersPeriodoDash]);

  const handleGetDresDash = async () => {
        
    if(!changeMes || carregando){
      return;
    }

    setChangeMes(false);
    setCarregando(true);
    setIsLoading('show');
    
    try{
    
        const result =  await DRE.GetDresDash(filtersDre);

        if(result.data){

            if (result.status === 200) {
              
              setDre(result.data.campos); 
              setRecLoc(result.data.recLoc);
              setLucLiqPer(result.data.lucLiqPer);
              setVendaVeiculo(result.data.vendaVeic);
              setVendaVeicNome(result.data.vendaVeicNome);

              setGlobalFiltersPeriodoDash(globalFiltersPeriodoDash => ({ ...globalFiltersPeriodoDash, 
                mes_ano_ini: result.data.mesAnoIniOk.slice(0, -3),
                ultimos_meses: 0
              }));

              setGlobalReady(true);

              setMinMaxMes(minMaxMes => ({ ...minMaxMes, 
                min_mes: result.data.minMes.slice(0, -3),
                max_mes: result.data.maxMes.slice(0, -3)
              }));

              handleGetIndicadores(result.data.mesAnoIniOk.slice(0, -3));
              if(frotaLoaded){
                handleGetFrota();
              }

              setTimeout(() => {
                setChangeMes(true);
                setCarregando(false);
              }, 1000);

            }else{
              setTimeout(() => {
                setChangeMes(true);
                setCarregando(false);
              }, 1000);
              setDre([]);
              setIndicadores([]);
              setRecLoc('0,00');
              setLucLiqPer('0,00');
            }

        }

    }catch{
      setTimeout(() => {
        setChangeMes(true);
        setCarregando(false);
      }, 1000);
      setDre([]);
      setIndicadores([]);
      setRecLoc('0,00');
      setLucLiqPer('0,00');
    }

    setIsLoading('hide');
  }

  const handleGetIndicadores = async (mes) => {
        
    const data = {
        mes_ano_ini: mes,
        ultimos_meses: 0,
        companyId: globalFiltersPeriodoDash.companyId
    }

    setIsLoading('show');
    
    try{
    
        const result =  await IN.GetIndicadoresDash(data);

        if(result.data){

            if (result.status === 200) {
              
              setIndicadores(result.data.indicadores);
              
            }else{
              setIndicadores([]);
            }

        }

    }catch{
        
    }

    setIsLoading('hide');
  }

  const handleGetFrota = async () => {

    const data = {
      mes_ano_ini: globalFiltersPeriodoDash.mes_ano_ini,
      locPadraoId: locadoraPadrao,
      dash: 1
    }
    
    setIsLoading('show');
    
    try{
    
        const result =  await FR.GetFrota(data);

        if(result.data){

          if (result.status === 200) {
            
            if(result.data.frota.length === 1){

              const frota = result.data.frota[0];

              setRetornoFrota(
                {
                  frota: frota.frota,
                  frota_operacional: frota.frota_operacional,
                  valorDre: frota.valorDre,
                  ticketMedio: frota.ticketMedio,
                  valorNF: frota.valorNF,
                  mesAno: frota.mesAno,
                  diasMes: frota.diasMes,
                  receitaDiariaMes: frota.receitaDiariaMes,
                  ocupacao: frota.ocupacao
                }
              );

            }else{

              clearFrota();

            }

            setFrotaLoaded(true);
            
          }else{
            clearFrota();
            setFrotaLoaded(false);
          }

      }

    }catch{
        
    }

    setIsLoading('hide');
  }

  const goBalancos = () => {
    setActiveTab(1);
    navigate('/balancos');
  }

  const goFrota = () => {
    setActiveTab(3);
    navigate('/balancos');
  }
  
  useEffect(() => {
    
    if(!F.isEmpty(indicadores)){
      
      const indicaresok = [];

      Object.values(indicadores).forEach( (item) => {

        // if(item.nome.trim() === 'Ebit'){
        //   indicaresok.push({
        //     nome: item.nome,
        //     porcentagem: ebitValor,
        //     fs: 'fs-4'
        //   });
        // }

        // if(item.nome.trim() === 'Ebitda Locações'){
        //   indicaresok.push({
        //     nome: item.nome,
        //     porcentagem: ebitdaValor,
        //     fs: 'fs-4'
        //   });
        // }

        try {
            
          const valor =  eval(item.indValores); 
   
          const fator = item.changeSign === 1 ? '-100' : '100';

          let porcentagem = (eval(valor)*(fator)).toFixed(2);
           
          porcentagem = isFinite(porcentagem) ? porcentagem : 0;
          
          indicaresok.push({
            nome: item.nome,
            porcentagem: `${porcentagem.replace('.', ',')}%`,
            fs: 'fs-4'
          });

        } catch {

          indicaresok.push({
            nome: item.nome,
            porcentagem: `0%`
          });
        }

      });

      setIndicadoresShow(indicaresok);
      
    }else{
      setIndicadoresShow([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[indicadores]);

  useEffect(() => {
    
    if(isLogged && canLoad === ''){

      handleGetDresDash();

    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFiltersPeriodoDash, isLogged, canLoad]);

  useEffect(() => {

    if(globalReady){
      handleGetFrota();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalReady]);

  useEffect(() => {
    
    setDocTitle(TXTS.DASHBOARD_TITLE);

    if(isLogged)

    setTimeout(() => {
      setIsLoading('hide');
    }, 500);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading, isLogged]);

    return (
      <motion.section 
        className="conteudo"

        variants={animacao}
        initial='entrada'
        animate='animacao'
        exit='saida'
        
        onScroll={HandleScroll}
      >
        <div className='container-lg'>
          <h2 className='pageTitle'>{TXTS.DASHBOARD_TITLE}</h2>
        </div>

        <div className='container container-lg'>

          <div className='row mt-4 mb-0 mt-md-0 mb-md-3 mx-0'>
            <h2 className='pageTitle sub col col-12 col-md-3 col-xxl-2 mt-4 mt-md-0'>
              Referente:
            </h2>
            <div className='col col-12 col-md-3 col-xxl-2 mt-2 mt-md-0'>
              <Input 
                style={{marginTop:0}}
                name='mes_ano_ini'
                type='month'
                min={minMaxMes.min_mes}
                max={minMaxMes.max_mes}
                value={globalFiltersPeriodoDash.mes_ano_ini}
                onChange={handleMesAnoIniDash}
                useLoading={true}
              />
            </div>
            <i>{TXTS.AVISO_REFERENTE}</i>
          </div>

          <hr></hr>

          <div className='row row-cols-1 row-cols-md-3 gy-3 gx-3 justify-content-start mb-3 mx-auto topDash d-flex align-items-stretch'>
            <div className='col d-flex'>
              <div className={`card text-center border-white border-1 ${cLoading ? 'Cloading' : ''} w-100`}>
                  <div className="card-header bg-light px-1">Receita de Locação</div>
                  <div className='card-body fs-3 d-flex align-items-center justify-content-center'>{recLoc}</div>
                </div>
            </div>
            
            <div className='col d-flex'>
                <div className={`card text-center border-white border-1 ${cLoading ? 'Cloading' : ''} w-100`}>
                  <div className="card-header bg-light px-1">{vendaVeicNome}</div>
                  <div className='card-body fs-3 d-flex align-items-center justify-content-center'>{vendaVeiculo}</div>
                </div>
            </div>

            <div className='col d-flex'>
              <div className={`card text-center border-white border-1 ${cLoading ? 'Cloading' : ''} w-100`}>
                  <div className="card-header bg-light px-1">Resultado Líquido do Período</div>
                  <div className='card-body fs-3 d-flex align-items-center justify-content-center'>{lucLiqPer}</div>
                </div>
            </div>
          </div>

          <hr></hr>

          <div className='row row-cols-1 row-cols-md-3 gy-3 gx-3 justify-content-start mb-3'>

            <div className='col'>
              
              <div className='card'>
                <div className='card-header bg-cards-title text-center fs-6 fw-bold text-light pointer' title='Ver Frota' onClick={goFrota}>FROTA</div>
                  <div className='card-body row align-items-top'>
                    {/* <div className='col col-12 col-xl-6 my-2'>
                      
                      <div className={`card text-center border-1 ${cLoading ? 'Cloading' : ''}`}>
                        <div className="card-header bg-light px-1">Frota</div>
                        <div className='card-body fs-3 px-0'>{retornoFrota.frota}</div>
                      </div>
                      
                    </div> */}

                    <div className='col col-12 my-2'>
                      
                      <div className={`card text-center border-1 ${cLoading ? 'Cloading' : ''}`}>
                        <div className="card-header bg-light px-1">Frota Operacional</div>
                        <div className='card-body fs-3 px-0'>{retornoFrota.frota_operacional}</div>
                      </div>
                      
                    </div>


                    <div className='col col-12 my-2'>
                      
                      <div className={`card text-center border-1 ${cLoading ? 'Cloading' : ''}`}>
                        <div className="card-header bg-light px-1">Valor Frota NF:</div>
                        <div className='card-body fs-3 px-0'>{retornoFrota.valorNF}</div>
                      </div>
                      
                    </div>

                    <div className='col col-12 my-2'>
                      
                      <div className={`card text-center border-1 ${cLoading ? 'Cloading' : ''}`}>
                        <div className="card-header bg-light px-1">Ticket Médio</div>
                        <div className='card-body fs-5 px-0'>{retornoFrota.ticketMedio}</div>
                        <div className="card-header bg-light px-1">Ocupação</div>
                        <div className='card-body fs-3'>
                          <SingleChartCircle 
                            percent={retornoFrota.ocupacao}
                            showOver={false}
                            strokeColor='#ccc'
                            progressColor='rgb(0,0,0)'
                          /> 
                        </div>
                      </div>
                      
                    </div>

                    {/* <div className='col col-12 col-xl-6 my-2'>
                      
                      <div className={`card text-center border-1 ${cLoading ? 'Cloading' : ''}`}
                      style={{borderColor: bgLocadoraPadrao, color: bgLocadoraPadrao}}
                      >
                        <div className="card-header bg-light px-1">Ticket Médio {nomeLocadoraPadrao}</div>
                        <div className='card-body fs-5 px-0'>{retornoFrotaLocPadrao.ticketMedio}</div>
                        <div className="card-header bg-light px-1">Ocupação</div>
                        <div className='card-body fs-3'>
                          <SingleChartCircle 
                            percent={retornoFrotaLocPadrao.ocupacao}
                            showOver={false}
                            strokeColor='#ccc'
                            progressColor={bgLocadoraPadrao}
                          /> 
                        </div>
                        <div className="card-footer bg-light px-1">{retornoFrotaLocPadrao.mesAno}</div>
                      </div>
                      
                    </div> */}

                  </div>              
              </div>

            </div>

            <div className='col'>

              <div className='card'>
                <div className='card-header bg-cards-title text-center fs-6 fw-bold text-light pointer' title='Ver os Indicadores' onClick={() => {navigate('/indicadores')}}>INDICADORES</div>
                  <div className='card-body row align-items-top'>
                  {indicadoresShow.length === 0 &&

                    <div className="alert alert-warning" role="alert">
                      Nenhum resultado com os filtros aplicados.
                    </div>
                  
                  }
                  {indicadoresShow.map( (item, key) => {
                    return(
                      <div className='col col-12 col-xl-6 my-2' key={key}>
                      
                        <div className={`card text-center border-1 ${cLoading ? 'Cloading' : ''}`}> 
                          <div className="card-header fs-6 bg-light px-1">{item.nome}</div>
                          <div className={`card-body px-0 ${item.fs}`}>{item.porcentagem}</div>
                        </div>
                      
                      </div>
                    )
                  })}

                   
                  </div>              
              </div>

            </div>

            <div className='col'>

            <div className='card'>
                <div className='card-header bg-cards-title text-center fs-6 fw-bold text-light pointer' title='Ver Balanços e DRES' onClick={goBalancos}>EXTRATO DO DRE</div>
                  <div className="card-body lh-1" style={{hyphens: 'auto'}}>
                  
                  {dre.length === 0 &&

                    <div className="alert alert-warning" role="alert">
                      Nenhum resultado com os filtros aplicados.
                    </div>

                  }
                  {dre.map( (item, key) => {
                    return(
                      <div className={`row align-items-center my-0 mx-0 py-2 px-0 overflow-hidden ${cLoading ? 'Cloading' : ''}`} key={key} style={{borderBottom: '1px solid #ccc'}}>
                        <div className='col-5 col-md-7 my-1 fs-5 mx-0 py-0 px-0 text-start'>
                          <b>{item.titulo}</b>
                        </div>
                        <div className='col-7 col-md-5 my-1 fs-5 mx-0 py-0 px-0 text-end'>
                          {item.value}
                        </div>
                      </div>
                    )
                  })}

                   
                  </div>              
              </div>

            </div>

          </div>

          <hr></hr>
          
        
        </div>

        <div className='logoBotton'>
            <img src={LOGO_AUDIT} alt="AUDIT" className='auditLayout'/> 
        </div> 
        
      </motion.section>
    );
}

export default HomeUser;