import { instance } from "./Default.service";

export const GetDres = async (data) => {

    return await instance.post('getDres', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const AddDre = async (data) => {

    return await instance.post('addDre', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const EditDre = async (data) => {

    return await instance.post('editDre', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const DeleteDre = async (id, companyId = 0) => {

    return await instance.get(`deleteDre/${id}/${companyId}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const GetFields = async (id = 0, companyId = 0) => {

    return await instance.get(`campos/dres/${id}/${companyId}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const UpdateFields = async (data) => {

    return await instance.post('updateFields/dres', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const GetDresDash = async (data) => {

    return await instance.post('getDresDash', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}