import React, { useContext } from "react";
import { Route, Routes, useLocation, Navigate, Outlet } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { GetLocalLogged, GetUserType } from '../util/Functions';
import { UserContext } from "../contexts/UserContext";
//PAGES
//HOME
import Home from "../pages/Home";
import HomeUser from "../pages/HomeUser";
import HomeAdmin from '../pages/HomeAdmin';


//COMPARATIVOS
import Comparativos from '../pages/Comparativos';


//BALANCOS E DRE
import Balancos from "../pages/Balancos";
import BalancosAdd from "../pages/Balancos/add";
import DreAdd from "../pages/Balancos/addDre";
import CamposAdd from '../pages/Balancos/addCampos';

//FROTA 
import FrotaAdd from "../pages/Balancos/addFrota";


//INDICADORES
// import Indicadores from '../pages/Indicadores';
import FiqueDeOlho from '../pages/Indicadores/fiquedeolho';
import MontarIndicador from '../pages/Indicadores/montar';


//GRÁFICOS
import Graficos from '../pages/Graficos';


// //FROTA
// import Frota from "../pages/Frota";
// import LocacaoEdit from '../pages/Frota/editLoc';
// import FrotaAdd from '../pages/Frota/add';


//CONFIGURAÇÕES
import Settings from "../pages/Settings";

//USUÁRIOS
import Users from '../pages/Users';
import EditUser from '../pages/Users/edit';

//SUBUSUÁRIOS
import EditSubUser from '../pages/SubUsers/edit';

//PLANOS
import PlanoAdd from '../pages/Planos/add';

//LOCADORAS MERCADO
import AddLocadoraMercado from '../pages/LocadoraMercado/add';

//SLIDES HOME
import SlidesHomeEdit from '../pages/SlidesHomeEdit';

//PAGE NOT FOUND
import PageNotFound from "../pages/PageNotFound";

function AnimatedRoutes() {

    const isLogged = GetLocalLogged();

    const userType = GetUserType();
    
    const location = useLocation();

    const { isPromoAccess } = useContext(UserContext);
    
    return (
        <AnimatePresence>
            <Routes location={location} key={location.key}> 

                <Route path='/' element={isLogged ? (userType === 1) ? <HomeAdmin /> : <HomeUser /> : <Home />} />

                <Route path='/comparativos' element={isLogged && userType !== 1 ? <Comparativos /> : <Navigate to='/' />} />

                <Route path='/indicadores' element={isLogged ? <Outlet /> : <Navigate to='/' />}>

                    <Route index element={<FiqueDeOlho />} />

                    {/* <Route path='fiquedeolho' element={<FiqueDeOlho />} /> */}

                    <Route path='montar/:id?' element={<MontarIndicador />} />

                </Route>

                <Route path='/balancos' element={isLogged ? <Outlet /> : <Navigate to='/' />}>

                    <Route index element={<Balancos />} />

                    <Route path='bal/:id?' element={isPromoAccess ? <Balancos /> : <BalancosAdd />} />
                    
                    <Route path='dre/:id?' element={isPromoAccess ? <Balancos /> : <DreAdd />} />

                    <Route path='frota/:id?' element={isPromoAccess ? <Balancos /> : <FrotaAdd />} />

                    <Route path='campos/:id' element={!isPromoAccess && userType === 1 ? <CamposAdd /> : <Balancos />} />

                </Route>

                <Route path='/graficos' element={isLogged ? <Graficos /> : <Navigate to='/' />} />

                {/* <Route path='/frota' element={isLogged ? <Outlet /> : <Navigate to='/' />}>

                    <Route index element={<Frota />} />

                    <Route path='locacao/edit/:id' element={<LocacaoEdit />} />

                    <Route path='add/:id?' element={<FrotaAdd />} />

                </Route> */}


                <Route path='/settings' element={isLogged && !isPromoAccess ? <Outlet /> : <Navigate to='/' />}>

                    <Route index element={<Settings />} />
                    <Route path='users/edit/:id' element={<EditSubUser />} />
                    <Route path='users/add' element={<EditSubUser />} />

                </Route>

                <Route path='/users' element={isLogged ? <Outlet /> : <Navigate to='/' />}>

                    <Route index element={userType === 1 ? <Users /> : <Navigate to='/' />} />
                    <Route path='edit/:id' element={<EditUser />} />
                    <Route path='add' element={<EditUser />} />

                </Route>

                <Route path='/planos' element={isLogged ? <Outlet /> : <Navigate to='/' />}>

                    <Route index element={<Navigate to='/' />} />
                    <Route path='edit/:id' element={<PlanoAdd />} />
                    <Route path='add' element={<PlanoAdd />} />

                </Route>

                <Route path='/locadoraMercado' element={isLogged ? <Outlet /> : <Navigate to='/' />}>

                    <Route index element={<Navigate to='/' />} />
                    <Route path='edit/:id' element={<AddLocadoraMercado />} />
                    <Route path='add' element={<AddLocadoraMercado />} />

                </Route>

                <Route path='/slidesHome' element={isLogged && userType === 1 ? <SlidesHomeEdit /> : <Navigate to='/' />} />

                <Route path='*' element={<PageNotFound />} />
            </Routes>
        </AnimatePresence>
    );
}

export default AnimatedRoutes;