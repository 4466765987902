import { instance } from "./Default.service";

export const GetCidades = async (estado) => {

    return await instance.get(`functions/getCidades/${estado}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}


export const GetCidadesAll = async (data) => {

    return await instance.post('functions/getCidadesAll', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}


export const consultaCep = async (cep) => {

    return await instance.get(`functions/consultaCep/${cep}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}


export const UpdateEndereco = async (data) => {

    return await instance.post('updateEndereco', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}