import React, { useEffect, useContext } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faRankingStar, faChartLine, faDiagramProject, faRightToBracket, faFileInvoiceDollar, faGear, faUsersGear } from '@fortawesome/free-solid-svg-icons';
import * as TXTS from '../../util/Texts';
import * as F from '../../util/Functions';
import './index.css';
import { LoaderContext } from '../../contexts/LoaderContext';
import { SiteContext } from '../../contexts/SiteContext';
import { UserContext } from '../../contexts/UserContext';


function Menu() {
    
    const location                  = useLocation();
    const navigate                  = useNavigate();

    const { 

        userName, 
        userType,
        isLogged,
        setUserTk,
        handleLocalLogout,
        isPromoAccess

    } = useContext(UserContext);

    const {
        isLoading,
        setIsLoading,
        firstLoad,
        setFirstLoad,
        setLoadClassName

    } = useContext(LoaderContext);

    const {

        setOpenLogin,
        setShowFooter,
        setShowLogos,
        docTitle,
        setCanLoad,
        textColor

    } = useContext(SiteContext);
    
    const handleOpenLogin = () => {
        setOpenLogin('loginAreaOpen');
    }

    useEffect(() => {
        
        const isLogged = F.GetLocalLogged();

        if(isLogged){

            setUserTk(F.GetToken());

        }else{

            handleLocalLogout();

            if(location.pathname !== '/'){
                navigate('/');
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    
    useEffect(() => {

        if(firstLoad && isLoading === 'hide'){
            setTimeout(() => {
                setLoadClassName('');
                setFirstLoad(false);
            }, 1000);
        }
        
    }, [firstLoad, isLoading, setLoadClassName, setFirstLoad]);

    useEffect(() => {
    
        if(isLoading === 'hide'){
            document.title = `${TXTS.SITE_TITLE}${userType === 1 && userName !== '' ? ` - ${userName}` : ''} ${docTitle !== '' ? `- ${docTitle}`:  ''}`;
        }
        
    }, [userName, docTitle, isLogged, isLoading, userType]);

    useEffect(() => {

        let first = location.pathname;

        first.indexOf(1);
        
        first.toLowerCase();
        
        first = first.split("/")[1];

        setCanLoad(first);
        setIsLoading('show');    
        setShowFooter(`${location.key === 'default' ? 'show' : ''}`);
        setShowLogos('');  

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, setIsLoading, setShowFooter, setShowLogos]);
    
    return (
        <nav className={`navMenu ${isLogged ? 'logged' : ''}`} style={{'--textColor' : textColor}}>
            <ul>
                <NavLink to="/"><li><p>{TXTS.HOME_TITLE}</p><FontAwesomeIcon icon={faHouse} /></li></NavLink>
                {!isLogged &&
                    <li className='openLogin' onClick={handleOpenLogin}><p>{TXTS.ENTER_TXT}</p><FontAwesomeIcon icon={faRightToBracket} /></li>
                }
                    <>

                    {userType !== 1 &&
                        <NavLink to="/comparativos"><li><p>{TXTS.COMPARATIVOS_TITLE}</p><FontAwesomeIcon icon={faRankingStar} /></li></NavLink>
                    }

                    {userType === 1 &&
                        <NavLink to="/users"><li><p>{TXTS.USUARIOS_TITLE}</p><FontAwesomeIcon icon={faUsersGear} /></li></NavLink>
                    }
                    
                    <NavLink to="/indicadores"><li><p>{TXTS.INDICADORES_TITLE}</p><FontAwesomeIcon icon={faDiagramProject} /></li></NavLink>
                    <NavLink to="/balancos"><li><p>{TXTS.BALANCO_TITLE}</p><FontAwesomeIcon icon={faFileInvoiceDollar} /></li></NavLink>
                    <NavLink to="/graficos"><li><p>{TXTS.GRAFICOS_TITLE}</p><FontAwesomeIcon icon={faChartLine} /></li></NavLink>
                    {/* <NavLink to="/frota"><li><p>{TXTS.FROTA_TITLE}</p><FontAwesomeIcon icon={faCarRear} /></li></NavLink> */}
                    
                    {!isPromoAccess &&
                        <NavLink to="/settings"><li><p>{TXTS.SETTINGS_TITLE}</p><FontAwesomeIcon icon={faGear} /></li></NavLink>
                    }
                    
                    </>
            </ul>
        </nav>
    );
}

export default Menu;