//USER
export const LOGIN_AREA_TXT = 'Olá,';
export const USER_UPDATE_SUCESSO = 'Usuário atualizado com sucesso!';

//LOGIN
export const ERRO_LOGIN_1       = 'Usuário Inválido!';
export const ERRO_LOGIN_2       = 'Dados não encontrados!';
export const ERRO_LOGIN_3       = 'Não foi possível se conectar ao servidor, por favor tente novamente.';
export const MUDAR_SENHA        = 'Esse é seu primeiro acesso, por favor defina uma nova senha:';
export const PEDIR_MUDAR_SENHA  = 'Para alterar a senha, digite sua senha atual e a nova senha:';

//ALETERAÇÃO DE SENHA
export const SENHA_ALT_SUCESSO = 'Senha alterada com sucesso!';

//FOOTER
export const FOOTER_AUDIT       = `®${new Date().getFullYear()} IDR.Indicadores - Todos os Direitos Reservados`;
export const FOOTER_TALENTUM    = `©${new Date().getFullYear()} Talentum Tecnologia`;
export const SITE_TALENTUM      =  'https://talentumtecnologia.com.br';

//ALERT
export const ALERT_TITLE_DEFAULT = 'ATENÇÃO';
export const ALERT_TITLE_SUCCESS = 'SUCESSO';
export const ALERT_TITLE_WARNING = 'ATENÇÃO';
export const ALERT_TITLE_ERROR   = 'ERRO';

//SITE
export const SITE_TITLE = 'IDR.Indicadores'; 

//PAGES TITLES //MENU TITLES
export const HOME_TITLE = 'HOME';
export const DASHBOARD_TITLE = 'DASHBOARD';
export const DASHBOARD_TITLE_ADM = 'DASHBOARD ADMIN';
export const COMPARATIVOS_TITLE = 'COMPARATIVOS';
export const USUARIOS_TITLE = 'USUÁRIOS';
export const GRAFICOS_TITLE = 'GRÁFICOS';
export const INDICADORES_TITLE = 'INDICADORES';
export const BALANCO_TITLE = 'BALANÇOS/DRES/FROTA';
export const FROTA_TITLE = 'FROTA';
export const SETTINGS_TITLE = 'CONFIGURAÇÕES';
export const USERS_TITLE = 'USUÁRIOS';
export const EXIT_TXT = 'SAIR';
export const ENTER_TXT = 'ENTRAR';
export const NOTFOUND_TITLE = 'Página não encontrada';

//VALIDAÇÃO DE IMGS
export const IMG_FORMAT_INVALID = 'Formato de imagem não permitido';
export const IMG_SIZE_INVALID = 'Tamanho de imagem não permitido';
export const IMG_SIZE_NOSELECT = 'Nenhuma imagem selecionada';
export const IMG_LOAD_ERROR = 'Erro ao caregar a imagem, por favor tente novamente';

//LOGO TXTS
export const LOGO_ADD = 'Adicionar Logo';
export const LOGO_CHANGE = 'Trocar Logo';

//LOGO IDR TXTS
export const LOGO_IDR_ADD = 'Adicionar Logo IDR';
export const LOGO_IDR_CHANGE = 'Trocar Logo IDR';

//COOKIES
export const COOKIES_TXT = 'Este site utiliza cookies. Ao continuar navegando neste site, você concorda em utilizar cookies para melhorar sua navegação.';
export const COOKIES_BTN = 'EU CONCORDO';

//CAMPOS
export const CNPJ_CARACTERES = 'O Campo CNPJ deve ter 14 números!';
export const CPF_CARACTERES = 'O Campo CPF deve ter 11 números!';
export const CNPJ_OBRIGATORIO = 'O Campo CNPJ é obrigatório!';
export const CPF_OBRIGATORIO = 'O Campo CPF é obrigatório!';

//COMPANY
export const COMPANY_UPDATE_SUCESSO = 'Empresa atualizada com sucesso!';
export const COMPANY_CREATE_SUCESSO = 'Empresa criada com sucesso!';

//ENDERECO 
export const ENDERECO_UPDATE_SUCESSO = 'Endereço atualizado com sucesso!';
export const ENDERECO_CREATE_SUCESSO = 'Endereço criado com sucesso!';

//BALANÇOS
export const BALANCO_SINGLE_TITLE = 'Balanços:';
export const BALANCO_UPDATE_SUCESSO = 'Balanço editado com sucesso!';
export const BALANCO_CREATE_SUCESSO = 'Balanço adicionado com sucesso!';
export const BALANCO_DELETE_SUCESSO = 'Balanço removido com sucesso!';
export const BALANCO_ADD_TITLE = 'Adicionar Balanço';
export const BALANCO_EDIT_TITLE = 'Editar Balanço';
export const BALANCO_CAMPOS_EDIT = 'Editar Campos  - Balanço';

//DRES
export const DRE_SINGLE_TITLE = 'DRES:';
export const DRE_UPDATE_SUCESSO = 'DRE editado com sucesso!';
export const DRE_CREATE_SUCESSO = 'DRE adicionado com sucesso!';
export const DRE_DELETE_SUCESSO = 'DRE removido com sucesso!';
export const DRE_ADD_TITLE = 'Adicionar DRE';
export const DRE_EDIT_TITLE = 'Editar DRE';
export const DRE_CAMPOS_EDIT = 'Editar Campos - DRE';

//BALANCOS E DRES
export const BAL_DRE_ADD_EDIT_TRASH = 'Lixeira:';

//FROTA
export const FORTA_LOCACOES_TITLE = 'Adicionar/Editar Locações';
export const FROTA_ADD_TITLE = 'Adicionar Frota';
export const FROTA_EDIT_TITLE = 'Editar Frota';

//INDICADORES
export const INDICADORES_ADD_TITLE = 'Adicionar Indicador';
export const INDICADORES_EDIT_TITLE = 'Editar Indicador';
export const INDICADORES_CAMPOS_TITLE = 'Campos:';
export const INDICADORES_BALANCOS_INFO = '(Serão ultilizados os valores do último Balanço)';
export const INDICADORES_DRES_INFO = '(Serão ultilizados os valores do último DRE)';
export const INDICADORES_CALCULADORA_TITLE = 'Calculadora:';
export const INDICADORES_FORMULA = 'Fórmula:';
export const INDICADORES_FORMULA_INFO = '(Veja aqui como ficará a fórmula)';
export const INDICADORES_FORMULA_RESULT = 'Resultado:';
export const INDICADORES_FORMULA_RESULT_INFO = '(Veja o resultado em %)';
export const INDICADORES_FIQUE_DE_OLHO_TITLE = 'Fique de Olho nos Indicadores';

//USUÁRIOS
export const USERS_ADD_TITLE = 'ADICIONAR USUÁRIO';
export const USERS_EDIT_TITLE = 'EDITAR USUÁRIO';

//SUBUSUÁRIOS
export const SUBUSERS_ADD_TITLE = 'ADICIONAR SUBUSUÁRIO';
export const SUBUSERS_EDIT_TITLE = 'EDITAR SUBUSUÁRIO';

//PLANOS
export const PLANO_ADD_TITLE = 'Adicionar Plano';
export const PLANO_EDIT_TITLE = 'Editar Plano';

//LOCADORAS MERCADO
export const LOCMERCADO_ADD_TITLE = 'Adicionar Locadora Mercado';
export const LOCMERCADO_EDIT_TITLE = 'Editar Locadora Mercado';

//SLIDES HOME 
export const SLIDESHOME_EDIT_TITLE = 'Editar Slides Home';
export const SLIDE_ADD = 'Adicionar Imagem - Max: 2Mb';
export const SLIDE_CHANGE = 'Trocar Imagem - Max: 2Mb';

//AVISO REFERENTE
export const AVISO_REFERENTE = '(Mostrando seus dados de acordo com mês escolhido)';
export const AVISO_REFERENTE_DOIS = '(Mostrando seus dados e os do mercado de acordo com mês escolhido)';

export const IDR_REFERENCIA_COMPARA = 'IDR (Referência)';