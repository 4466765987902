import { useContext } from 'react';
import './index.css';
import Menu from '../Menu';
import Logos from '../Logos';
import LoginArea from '../User/LoginArea';
import { UserContext } from '../../contexts/UserContext';
import { CompanyContext } from '../../contexts/CompanyContext';

function Header() {

  const { isLogged } = useContext(UserContext);
  const { companyColor } = useContext(CompanyContext);
  
  return (
    <header className={`${isLogged ? 'logged' : ''}`} style={{backgroundColor: companyColor}}>      
      <Logos />
      <Menu />
      <LoginArea />
    </header>
  );
}

export default Header;