import { instance } from "./Default.service";

export const GetIdrLogo = async () => {

    return await instance.get('getIdrLogo')
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const slidesHome = async () => {

    return await instance.get('slidesHome')
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const ChangeSlide = async (data) => {

    return await instance.post('changeSlide', data, {
        headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => {
                return response;
            })
            .catch(error => {
                if(error.response){
                    return error.response;
                }else{
                    return null;
                }
            });
}

export const UpdateSlides = async (data) => {

    return await instance.post('updateSlides', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}