import './index.css';
import React, { useEffect, useContext, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { animacao } from '../../util/AnimatedDiv';
import { AlertasContext } from '../../contexts/AlertasContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { SiteContext } from '../../contexts/SiteContext';
import { UserContext } from '../../contexts/UserContext';
import * as TXTS from '../../util/Texts';
import * as IN from '../../services/Indicadores.service';
import Btn from '../../components/Forms/Btn';
import { LOGO_AUDIT } from '../../util/Urls';

function FiqueDeOlho() {

  const navigate = useNavigate();

  const { setIsLoading } = useContext(LoaderContext);

  const { HandleScroll, setDocTitle, setErrorCon } = useContext(SiteContext);

  const { isLogged, userType } = useContext(UserContext);

  const [indicadores, setIndicadores] = useState([]); 

  const [indcaLoading, setIndcaLoading] = useState(true);

  const {

    setAlertShow,
    setAlertBtnOk,
    setAlertBtnCancel,
    setAlertOnOk,
    setAlertType,
    setAlertStyle,
    setToastPosition,
    setAlertMsg,

  } = useContext(AlertasContext);

  const dragItem = useRef();

  const dragOverItem = useRef();

  const dragStart = (position) => {
    
    dragItem.current = position;
  
  }

  const dragEnter = (position) => {

    dragOverItem.current = position;

  }

  const drop = async () => {

    if(isNaN(dragItem.current) || isNaN(dragOverItem.current) || (dragItem.current === dragOverItem.current)){
      return false;
    }

    const copyIndicadores = [...indicadores];

    const dragItemContent = copyIndicadores[dragItem.current];
    copyIndicadores.splice(dragItem.current, 1);
    copyIndicadores.splice(dragOverItem.current, 0, dragItemContent);

    dragItem.current = null;
    dragOverItem.current = null;

    const indicadoresSend = [];

    copyIndicadores.forEach( (item) => {

      indicadoresSend.push(item.id);

    });

    setIsLoading('show');
    setIndcaLoading(true);

    try{

      const result = await IN.ChangeOrder({indicadores: indicadoresSend});

      if (result.status === 200) {

        setIndicadores(copyIndicadores); 

      }else{
        setAlertShow(true);
        setAlertBtnOk(false);
        setAlertType('warning');
        setAlertStyle(2);
        setToastPosition('top-right');
        setAlertMsg('Houve um erro, por favor tente novamente.');
      }

    }catch{
      setAlertShow(true);
      setAlertBtnOk(false);
      setAlertType('warning');
      setAlertStyle(2);
      setToastPosition('top-right');
      setAlertMsg('Houve um erro, por favor tente novamente.');
    }

    setIsLoading('hide');
    setIndcaLoading(false);   

  }

  const handleDoRemove = async (Id) => {

    setIsLoading('show');

    try{

      const result =  await IN.DeleteIndicador(Id);

      setAlertBtnCancel(false);

      if(result.data){

        if (result.status === 200) {

          setAlertShow(true);
          setAlertMsg(result.data.msg);
          setAlertType('success');
          setAlertBtnOk(false);  
          handleGetIndicadores();

        }else{

            setAlertShow(true);
            setAlertMsg(result.data.msg);
            setAlertType('error');
            setAlertBtnOk(false);
        }

      }else{
              
          setAlertShow(true);
          setAlertMsg(TXTS.ERRO_LOGIN_2);
          setAlertType('error');
          setAlertBtnOk(false);
      
      }

    }catch{

        setAlertShow(true);
        setAlertMsg(TXTS.ERRO_LOGIN_3);
        setAlertType('error');
        setAlertBtnOk(false);
        setErrorCon(true);

    }

    setIsLoading('hide');
  }

  const handleRemove = (Id, titulo) => {
      
    setAlertShow(true);
    setAlertType('warning');
    setAlertBtnCancel(true);
    setAlertOnOk(() => () => {handleDoRemove(Id)});
    setAlertMsg(`Remover o Indicador ${titulo}?`);

  }

  const handleGetIndicadores = async () => {

    setIsLoading('show');
    setIndcaLoading(true);

    try{

      const result = await IN.GetIndicadores();

      if (result.status === 200) {

          setIndicadores(result.data.indicadores);

      }else{
          navigate('/indicadores');
      }

    }catch{
        navigate('/indicadores');
    }

    setIsLoading('hide');
    setIndcaLoading(false);

  }

  useEffect(() => {

    setDocTitle(TXTS.INDICADORES_FIQUE_DE_OLHO_TITLE);

    if(isLogged)
    handleGetIndicadores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading, isLogged]);

    return (
      <motion.section 
        className="conteudo"

        variants={animacao}
        initial='entrada'
        animate='animacao'
        exit='saida'

        onScroll={HandleScroll}
      >
        
        <div className='container-lg'>
          <h2 className='pageTitle'>{TXTS.INDICADORES_FIQUE_DE_OLHO_TITLE}</h2>
        </div>

        <div className='container container-lg'>  
        
        {userType === 1 &&
          <>
          <div className="alert alert-info row justify-content-end align-items-center gx-0 mb-4">
          
            <div className='col col-12 col-md-9'>
              Arraste os indicadores para alterar a ordem que deverão ser exibidos.
            </div>

            <div className='col col-12 col-md-3 mt-4 mt-md-0'>
              <Btn 
                title='Adicionar Indicador'
                value='Adicionar Indicador'
                type='button'
                btnType='primary'
                iconRight='faFileCirclePlus'
                onClick={() => {navigate('montar')}}
                useLoading={true}
              />
            </div>
              
          </div>
          <hr></hr> 
          </>
        }

        {indicadores.length === 0 && !indcaLoading &&

          <div className="alert alert-warning" role="alert">
            Nenhum Indicador Encontrado.
          </div>

        }        

        <div className='row row-cols-1 row-cols-md-2 g-4 mt-3 mb-5 align-items-center'>
          
        {indicadores.length === 0 && indcaLoading &&

          <div className='col'>
            <div className='card Cloading'>
              <div className='card-header bg-cards-title text-center fs-5 fw-bold text-light'></div>
              <div className='card-body'>
                <p className='card-text'></p>
              </div>              
            </div>
          </div>

        }
        {indicadores.length > 0 &&
          
          <>
          {indicadores.map( (item, key) => {
              return(
                <div className='col'
                  key={key} 
                  draggable={userType === 1 ? true : false}
                  onDragStart={() => dragStart(key)}
                  onDragEnter={() => dragEnter(key)}
                  onDragEnd={drop}
                >
                  <div className={`card ${indcaLoading ? 'Cloading' : ''}`}>
                    <div className='card-header bg-cards-title text-center fw-bold text-light'>
                      {item.nome} {item.titulo !== null ? ` - (${item.titulo})` : ''}
                    </div>

                    <div className='formulaIndicador mt-3 mb-2 px-2'>
                      <div className='text-center w-100'>
                      {
                        Object.keys(Object.keys(item.formula)).map((key) => {
                          if(item.formula[key]['nome'] === '/'){
                            return(<span key={key} className='dividir col mx-auto my-2'></span>)
                          }else{
                            return(<span key={key}> {item.formula[key]['nome']} </span>)
                          }
                          
                        })
                      }
                      </div>
                      
                    </div>

                    {item.descricao &&
                      <>
                        <hr></hr>
                        <div className='card-body textoIndicador mb-3'>
                          
                          {item.descricao.split('\n').map((item, key) => {
                            return (<span key={key}>{item}<br/></span>)
                          })}
                          
                        </div>
                      </>
                    }
                    

                    {userType === 1 &&
                      <div className="card-footer">
                        <div className='row justify-content-center justify-content-md-end my-0 my-0 py-0 px-0'>
                            <div className='col-5 col-md-2 p-1 mt-1 mb-2 mx-2'>
                              <Btn 
                                title='Editar'
                                type='button'
                                btnType='primary'
                                iconRight='faPenToSquare'
                                onClick={() => {navigate(`/indicadores/montar/${item.id}`)}}
                                useLoading={true}
                              />
                            </div>

                            <div className='col-5 col-md-2 p-1 mt-1 mb-2 mx-2'>
                              <Btn 
                                title='Remover'
                                type='button'
                                btnType='danger'
                                iconRight='faTrashCan'
                                onClick={() => {handleRemove(item.id, `${item.nome} ${item.titulo !== null ? ` - (${item.titulo})` : ''}`)}}
                                useLoading={true}
                              />
                            </div>
                          </div>
                      </div>
                    }
                    
                  </div>
                </div>
              )
            })
          }
          </>
        }
        </div>      

        </div>

        <div className='logoBotton'>
            <img src={LOGO_AUDIT} alt="AUDIT" className='auditLayout'/> 
        </div>     
        
      </motion.section>
    );
}

export default FiqueDeOlho;