import { useContext } from 'react';
import './index.css';
import { FOOTER_AUDIT, FOOTER_TALENTUM, SITE_TALENTUM } from '../../util/Texts';
import { SiteContext } from '../../contexts/SiteContext';
import { CompanyContext } from '../../contexts/CompanyContext';

function Footer() {

  const { showFooter, textColor } = useContext(SiteContext);
  const { footerCompanyColor } = useContext(CompanyContext);

  return (
    <footer className={`${showFooter}`} style={{backgroundColor: footerCompanyColor, '--textColor':textColor}}>
        <p>{FOOTER_AUDIT}</p>
        <a href={SITE_TALENTUM} target="_blank" rel="noopener noreferrer">{FOOTER_TALENTUM}</a>
    </footer>
  );
}

export default Footer;