import './index.css';
import React, { useEffect, useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { animacao } from '../../util/AnimatedDiv'
import UserLogo from '../../components/User/UserLogo';
import Input from '../../components/Forms/Input';
import Btn from '../../components/Forms/Btn';
import Select from '../../components/Forms/Select';
import { LoaderContext } from '../../contexts/LoaderContext';
import { SiteContext } from '../../contexts/SiteContext';
import { AlertasContext } from '../../contexts/AlertasContext';
import { UserContext } from '../../contexts/UserContext';
import { CompanyContext } from '../../contexts/CompanyContext';
import * as ES from '../../services/Enderecos.service';
import * as TXTS from '../../util/Texts';
import * as F from '../../util/Functions';
import { LOGO_AUDIT } from '../../util/Urls';
import SubUsersList from '../../components/SubUsers/list';

function Settings() {

  
  const { setIsLoading, firstLoad } = useContext(LoaderContext);
  const {

          HandleScroll,
          setDocTitle,
          setTextColor

        } = useContext(SiteContext);

  const {

          setAlertChangePass,

        } = useContext(AlertasContext);

  const [disabledBtsUser, setDisabledBtsUser]           = useState(true);
  const [disabledBtsCompany, setDisabledBtsCompany]     = useState(true);
  const [disabledBtsEndereco, setDisabledBtsEndereco]   = useState(true);

  const {

    isLogged,
    userName,
    userEmail,
    userType,
    ufs,
    cidades,
    setCidades,
    handleUserUpdate,
    requiredUserValues,
    userPermission,
    userMaster

  } = useContext(UserContext);

  const disabledInputs = userPermission === 2 ? true : false;

  const [userValues, setUserValues] = useState({

    name: userEmail,
    email: userName,
    type: userType,

  });

  const handleUserValues = (e) => {

    setUserValues({
        ...userValues,
        [e.target.name]: e.target.value,
    });

  };
  
  useEffect(() => {

    let err = 0;

    requiredUserValues.forEach((item) => {
      if(userValues[item] === ''){
        err += 1;
      }
    });
    

    if(err === 0){
      setDisabledBtsUser(false);
    }else{
      setDisabledBtsUser(true);
    }  

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userValues]);

  const handleCancelUser = () => {
        
    setUserValues({
        name: userName,
        email: userEmail,
        type: userType,
    });

  };

  const {

    companyName,
    companyNameFantasia,
    companyType,
    companyCnpjCpf,
    companyIeRg,
    companyTpessoa,
    companyTpessoaOpt,
    handleCompanyUpdate,
    requiredCompanyValues,
    handleCnpjCpf,
    unsavedCompanyValues,
    companyEndereco,
    unsavedEndereco,
    handleEnderecoUpdate,
    companyColor,
    companyPrimeFatura,
    backColor,
    setCompanyColor,

  } = useContext(CompanyContext);

  const [companyValues, setCompanyValues] = useState({

    razaoSocial_nomeCompleto: '',
    nomeFantasia_apelido: '',
    type: '',
    cnpj_cpf: '',
    ie_rg: '',
    type_pessoa: '',
    color: '',
    primeiro_faturamento: ''

  });

  const handleCompanyValues = (e) => {
    
    setCompanyValues({
        ...companyValues,
        [e.target.name]: e.target.value,
    });

  };

  const handleCompanyColor = (e) => {
    
    const textColor = F.TextColorByBg(e.target.value);
    setTextColor(textColor);
    setCompanyColor(e.target.value);
    setCompanyValues({
      ...companyValues,
      color: e.target.value,
    });

  };

  useEffect(() => {

    let err = 0;

    requiredCompanyValues.forEach((item) => {
      if(companyValues[item] === ''){
        err += 1;
      }
    });
    
    const rLength = companyValues.type_pessoa === 1 ? 11 : 14;

    if(err === 0 && companyValues.cnpj_cpf.length === rLength){
      setDisabledBtsCompany(false);
    }else{
      setDisabledBtsCompany(true);
    }  

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyValues]);

  const handleCancelCompany = () => {
       
    setCompanyValues({

      razaoSocial_nomeCompleto: companyName,
      nomeFantasia_apelido: companyNameFantasia,
      type: companyType,
      cnpj_cpf: companyCnpjCpf,
      ie_rg: companyIeRg,
      type_pessoa: companyTpessoa,
      primeiro_faturamento: companyPrimeFatura

    });

    if(userType === 2){

      setCompanyValues(companyValues => ({ ...companyValues, color: backColor }));
      
      setCompanyColor(backColor);
      const textColor = F.TextColorByBg(backColor);
      setTextColor(textColor);
      
    }

  }; 

  useEffect(() => {

    for(const [key, value] of Object.entries(unsavedCompanyValues)){
      
      if(value !== '')
      setCompanyValues(companyValues => ({ ...companyValues, [key]: value }));
      }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[unsavedCompanyValues]);

  const [cidadeCep, setCidadeCep] = useState(0);

  const [enderecoValues, setEnderecoValues] = useState({

    cep: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    estado_id: 0,
    cidade_id: 0,

  });

  useEffect(() => {

    if(unsavedEndereco.cep){
      setEnderecoValues(enderecoValues => ({ ...enderecoValues, cep: unsavedEndereco.cep }));
    }

    if(unsavedEndereco.logradouro){
      setEnderecoValues(enderecoValues => ({ ...enderecoValues, logradouro: unsavedEndereco.logradouro }));
    }
    
    if(unsavedEndereco.bairro){
      setEnderecoValues(enderecoValues => ({ ...enderecoValues, bairro: unsavedEndereco.bairro }));
    }

    if(unsavedEndereco.estado_id){
      setEnderecoValues(enderecoValues => ({ ...enderecoValues, estado_id: unsavedEndereco.estado_id }));
    }

    if(unsavedEndereco.cidade_id){
      setCidadeCep(unsavedEndereco.cidade_id);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[unsavedEndereco]);

  const handleEndercoValues = (e) => {

    setEnderecoValues({
        ...enderecoValues,
        [e.target.name]: e.target.value,
    });

  };

  const handleSetUF = (val) => {

    if(val === 0) {
      return;
    }

    setEnderecoValues({
      ...enderecoValues,
      estado_id: val,
    });

  }  

  const handleSetCidade = (val) => {

    if(val === 0) {
      return;
    }

    setEnderecoValues({
      ...enderecoValues,
      cidade_id: val,
    });

  } 

  const handleCancelEndereco = () => {

    setEnderecoValues(companyEndereco);

    if(companyEndereco.cidade_id === 0){
      setCidades([]);
    }

    if(enderecoValues.cidade_id !== companyEndereco.cidade_id){
      
      setEnderecoValues(enderecoValues => ({ ...enderecoValues, cidade_id: 0 }));
    }
  }; 

  const handleUf = async (value) => {

    setIsLoading('show');
    setCidades([]);
    setEnderecoValues(enderecoValues => ({ ...enderecoValues, cidade_id: 0 }));

    try{

      const result =  await ES.GetCidades(value);
      

      if(result.data && result.status === 200){

        setCidades(result.data.cidades);

      }
      

    }catch{
      
    }

    setIsLoading('hide');
  };


  useEffect(() => {

    
    if(cidadeCep !== 0 && cidades.length > 0){
      setTimeout(() => {
        setEnderecoValues({
          ...enderecoValues,
          cidade_id: cidadeCep,
        });

        setCidadeCep(0);
      }, 100);
    }

    
    if(companyEndereco.estado_id !== enderecoValues.estado_id){

      setEnderecoValues(enderecoValues => ({ ...enderecoValues, cidade_id: 0 }));
      
    }else{
      if(cidades.length > 0 && companyEndereco.cidade_id !== 0){

        setEnderecoValues(enderecoValues => ({ ...enderecoValues, cidade_id: companyEndereco.cidade_id }));

      }
    }
    
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cidades]);

  const handleConsultaCep = async () => {
    const cep = enderecoValues.cep;
    
    if(cep !== '' && cep.length === 8){

      setIsLoading('show');

      try{

        const result =  await ES.consultaCep(cep);
        
        
        if(result.data && result.status === 200 && result.data.endereco !== null){
  
          setEnderecoValues({
            ...enderecoValues,
            logradouro: result.data.endereco.logradouro,
            estado_id: result.data.endereco.estado_id,
            bairro: result.data.endereco.bairro,
          });

          setCidadeCep(result.data.endereco.cidade_id);
            
        }else{

            setEnderecoValues({
              ...enderecoValues,
              logradouro: '',
              estado_id: 0,
              cidade_id: 0,
              bairro: '',
            });

            setCidades([]);
            setCidadeCep(0);

        }
        
  
      }catch{
        
      }

      setIsLoading('hide');
    }
  };

  useEffect(() => {
    if(enderecoValues.estado_id !== '' && enderecoValues.estado_id !== 0 && !firstLoad)
    handleUf(enderecoValues.estado_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enderecoValues.estado_id]);

  useEffect(() => {

    if(enderecoValues.cep === ''
      || enderecoValues.estado_id === 0
      || enderecoValues.cidade_id === 0
      || enderecoValues.logradouro === ''
      || enderecoValues.bairro === ''){
        setDisabledBtsEndereco(true);
    }else{
      setDisabledBtsEndereco(false);
    }

  }, [enderecoValues])

  useEffect(() => {
    
    setDocTitle(TXTS.SETTINGS_TITLE);

    if(isLogged){

      setUserValues({
        name: userName,
        email: userEmail,
        type: userType,
      });
  
      setCompanyValues({
        razaoSocial_nomeCompleto: companyName,
        nomeFantasia_apelido: companyNameFantasia,
        type: companyType,
        cnpj_cpf: companyCnpjCpf,
        ie_rg: companyIeRg,
        type_pessoa: companyTpessoa,
        color: companyColor,
        primeiro_faturamento: companyPrimeFatura
      });
  
      setEnderecoValues(companyEndereco);
  
      setTimeout(() => {
        setIsLoading('hide');
      }, 500);
      
    }
    
    
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading, isLogged]);

  useEffect(() => {
    setCompanyValues(companyValues => ({ ...companyValues, color: companyColor }));
    
  }, [companyColor]);

    return (
      <motion.section 
        className="conteudo"

        variants={animacao}
        initial='entrada'
        animate='animacao'
        exit='saida'
        
        onScroll={HandleScroll}
      >
        <div className='container-lg'>
          <h2 className='pageTitle'>{TXTS.SETTINGS_TITLE}</h2>
        </div>
        
        <div className='container container-lg'>
          
          <div className='col col-12 mt-4 mb-0 mt-md-0 mb-md-3'>
            <h2 className='pageTitle sub'>Usuário:</h2>
          </div>

          <form className='row justify-content-center'>
            
            <div className='col col-12 col-md-4 col-xxl-3 mt-4 mt-md-0'>
              <Input 
                  name='name'
                  labelLeft='faIdBadge'
                  placeholder='Nome:'
                  type='text'
                  required={true}
                  value={userValues.name}
                  onChange={handleUserValues}
                  useLoading={true}
              />
            </div>

            <div className='col col-12 col-md-4 col-xxl-3 mt-4 mt-md-0'>
              <Input 
                  name='email'
                  labelLeft='faEnvelope'
                  placeholder='E-mail:'
                  type='email'
                  required={true}
                  value={userValues.email}
                  onChange={handleUserValues}
                  useLoading={true}
              />
            </div>

            <div className={`col col-12 col-md-4 col-xxl-3 mt-4 mt-md-2`}>
              <Btn 
                  value='Alterar Senha'
                  iconLeft='faLock'
                  iconRight='faRepeat'
                  onClick={()=>{setAlertChangePass(true)}}
                  type='button'
                  useLoading={true}
              />
            </div>

            <div className='row justify-content-center mx-0 px-0'>

              <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                <Btn 
                    value='Cancelar'
                    type='button'
                    btnType='danger'
                    onClick={handleCancelUser}
                    useLoading={true}
                />
              </div>

              <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                <Btn 
                    value='Salvar'
                    type='submit'
                    btnType='success'
                    disabled={disabledBtsUser}
                    onClick={(e)=>{handleUserUpdate(e, userValues)}}
                    useLoading={true}
                />
              </div>  

            </div>
            
          </form>

          <hr></hr>

          <div className='col col-12 mt-4 mb-0 mt-md-0 mb-md-3'>
            <h2 className='pageTitle sub'>Empresa:</h2>
          </div>

          <form className='row justify-content-start'>

            {companyType !== 2 &&
              <div className='col-12 col-md-3 mt-4 mt-md-0 mb-4 mb-md-0'>

                {userPermission === 1 &&
                  <div className='col col-12 mb-4'>
                    <Input 
                        name='color'
                        placeholder='Cor Principal:'
                        type='color'
                        required={false}
                        value={companyValues.color}
                        onChange={handleCompanyColor}
                        useLoading={true}
                    />
                  </div>
                }

                <UserLogo estilo={'settings'}/>

              </div>
            }

            <div className={`col-12 ${userType === 2 ? 'col-md-9' : 'col-md-12'} order-md-first mx-0 px-0`}>

              <div className='row mx-0 px-0'>
                <div className='col col-12 col-md-3 mt-4 mt-md-0'>
                  <Select 
                    placeholder='Tipo Pessoa:'
                    data={companyTpessoaOpt}
                    value={companyValues.type_pessoa}
                    readOnly={disabledInputs}
                    useLoading={true}
                    onClick={(selValue) => {setCompanyValues({
                      ...companyValues,
                      type_pessoa: selValue,
                    })}}
                  />
                </div>

                <div className='col col-12 col-md-4 mt-4 mt-md-0'>
                  <Input 
                      name='cnpj_cpf'
                      placeholder={companyValues.type_pessoa === 2 ? 'CNPJ:' : 'CPF:'}
                      type='number'
                      required={true}
                      mask={companyValues.type_pessoa === 2 ? '99.999.999/9999-99' : '999.999.999-99'}
                      value={companyValues.cnpj_cpf}
                      readOnly={disabledInputs}
                      onChange={handleCompanyValues}
                      {...(userPermission === 1 && { onBlur: ()=>{handleCnpjCpf(companyValues)} })}
                      useLoading={true}
                  />
                </div>

                <div className='col col-12 col-md-4 mt-4 mt-md-0'>
                  <Input 
                      name='ie_rg'
                      placeholder={companyValues.type_pessoa === 2 ? 'Inscrição Estadual:' : 'RG:'}
                      type='number'
                      required={false}
                      mask={companyValues.type_pessoa === 2 ? '999.999.999.999' : ''}
                      value={companyValues.ie_rg}
                      readOnly={disabledInputs}
                      onChange={handleCompanyValues}
                      useLoading={true}
                  />
                </div>

                <div className='col col-12 col-md-6 mt-4'>
                  <Input 
                      name='razaoSocial_nomeCompleto'
                      placeholder={companyValues.type_pessoa === 2 ? 'Razão Social:' : 'Nome:'}
                      type='text'
                      required={true}
                      value={companyValues.razaoSocial_nomeCompleto}
                      readOnly={disabledInputs}
                      onChange={handleCompanyValues}
                      useLoading={true}
                  />
                </div>

                <div className='col col-12 col-md-6 mt-4'>
                  <Input 
                      name='nomeFantasia_apelido'
                      placeholder={companyValues.type_pessoa === 2 ? 'Nome Fantasia:' : 'Apelido:'}
                      type='text'
                      required={false}
                      value={companyValues.nomeFantasia_apelido}
                      readOnly={disabledInputs}
                      onChange={handleCompanyValues}
                      useLoading={true}
                  />
                </div>

                <div className='col col-12 col-md-4 col-xxl-4 mt-4'>
                  <Input 
                      name='primeiro_faturamento'
                      placeholder='Primeiro Faturamento:'
                      type='month'
                      value={companyValues.primeiro_faturamento}
                      readOnly={disabledInputs}
                      onChange={handleCompanyValues}
                      useLoading={true}
                    />
                </div>

              </div>

            </div>
            
            {userPermission === 1 &&

              <div className='row justify-content-center mx-0 px-0'>

                <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                  <Btn 
                      value='Cancelar'
                      type='button'
                      btnType='danger'
                      onClick={handleCancelCompany}
                      useLoading={true}
                  />
                </div>

                <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                  <Btn 
                      value='Salvar'
                      type='submit'
                      btnType='success'
                      disabled={disabledBtsCompany}
                      onClick={(e)=>{handleCompanyUpdate(e, companyValues)}}
                      useLoading={true}
                  />
                </div>

              </div> 

            } 
            
          </form>

          <hr></hr>

          <div className='col col-12 mt-4 mb-0 mt-md-0 mb-md-3'>
            <h2 className='pageTitle sub'>Endereço:</h2>
          </div>

          <form className='row justify-content-start'>

            <div className='col col-6 col-md-2 mt-4 mt-md-0'>
              <Input 
                  name='cep'
                  placeholder='CEP:'
                  type='number'
                  required={true}
                  readOnly={disabledInputs}
                  value={enderecoValues.cep}
                  mask='99.999-999'
                  onChange={handleEndercoValues}
                  {...(userPermission === 1 && { onBlur: handleConsultaCep })}
                  useLoading={true}
              />
            </div>
            
            {ufs &&
              <div className='col col-6 col-md-2 col-xl-1 mt-4 mt-md-0'>
                <Select 
                  placeholder='UF:'
                  data={ufs}
                  value={enderecoValues.estado_id}
                  readOnly={disabledInputs}
                  useLoading={true}
                  onClick={(selValue) => {handleSetUF(selValue)}}
                />
              </div>
            }    

            {cidades &&
              <div className='col col-12 col-md-3 col-xxl-2 mt-4 mt-md-0'>
                <Select 
                  placeholder='Cidade:'
                  data={cidades}
                  value={enderecoValues.cidade_id}
                  readOnly={disabledInputs}
                  useLoading={true}
                  onClick={(selValue) => {handleSetCidade(selValue)}}
                />
              </div>
            }        
            
            <div className='col col-12 col-md-3 col-xxl-3 mt-4 mt-md-0'>
              <Input 
                  name='logradouro'
                  placeholder='Logradouro:'
                  type='text'
                  required={true}
                  value={enderecoValues.logradouro}
                  readOnly={disabledInputs}
                  onChange={handleEndercoValues}
                  useLoading={true}
              />
            </div>

            <div className='col col-6 col-md-2 col-xl-1 mt-4 mt-md-0'>
              <Input 
                  name='numero'
                  placeholder='Número:'
                  type='number'
                  required={false}
                  value={enderecoValues.numero}
                  readOnly={disabledInputs}
                  onChange={handleEndercoValues}
                  useLoading={true}
              />
            </div>

            <div className='col col-6 col-md-2 col-xxl-1 mt-4 mt-xl-0'>
              <Input 
                  name='complemento'
                  placeholder='Comp:'
                  type='text'
                  required={false}
                  value={enderecoValues.complemento}
                  readOnly={disabledInputs}
                  onChange={handleEndercoValues}
                  useLoading={true}
              />
            </div>

            <div className='col col-12 col-md-3 col-xxl-2 mt-4 mt-xxl-0'>
              <Input 
                  name='bairro'
                  placeholder='Bairro:'
                  type='text'
                  required={true}
                  value={enderecoValues.bairro}
                  readOnly={disabledInputs}
                  onChange={handleEndercoValues}
                  useLoading={true}
              />
            </div>

            {userPermission === 1 &&

              <div className='row justify-content-center mx-0 px-0'>
                
                <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                  <Btn 
                      value='Cancelar'
                      type='button'
                      btnType='danger'
                      onClick={handleCancelEndereco}
                      useLoading={true}
                  />
                </div>

                <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                  <Btn 
                      value='Salvar'
                      type='submit'
                      btnType='success'
                      disabled={disabledBtsEndereco}
                      onClick={(e)=>{handleEnderecoUpdate(e, enderecoValues)}}
                      useLoading={true}
                  />
                </div>

              </div>

            }

          </form>
          
          {userMaster &&
          
            <>
              <hr></hr>
              <SubUsersList canAdd={true}/>
            </>
           
          }

        </div>
        <div className='logoBotton'>
            <img src={LOGO_AUDIT} alt="AUDIT" className='auditLayout'/> 
        </div>
      </motion.section>
    );
}

export default Settings;