import './index.css';
import React, { useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import { animacao } from '../../util/AnimatedDiv'
import { LoaderContext } from '../../contexts/LoaderContext';
import { SiteContext } from '../../contexts/SiteContext';
import SlidesHome from '../../components/SlidesHome';
import * as TXTS from '../../util/Texts';


function Home() {

  const { setIsLoading } = useContext(LoaderContext);

  const { HandleScroll, setDocTitle } = useContext(SiteContext);
  

  useEffect(() => {
    setDocTitle(TXTS.HOME_TITLE);
    setTimeout(() => {
      setIsLoading('hide');
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading]);

    return (
      <motion.section 
        className="Home conteudo"

        variants={animacao}
        initial='entrada'
        animate='animacao'
        exit='saida'

        onScroll={HandleScroll}
      >
        <SlidesHome/>
      
      </motion.section>
    );
}

export default Home;
