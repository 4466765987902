import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { animacao } from '../../util/AnimatedDiv'
import Input from '../../components/Forms/Input';
import Btn from '../../components/Forms/Btn';
import Select from '../../components/Forms/Select';
import UserLogo from '../../components/User/UserLogo';
import { LoaderContext } from '../../contexts/LoaderContext';
import { SiteContext } from '../../contexts/SiteContext';
import { AlertasContext } from '../../contexts/AlertasContext';
import { UserContext } from '../../contexts/UserContext';
import { CompanyContext } from '../../contexts/CompanyContext';
import * as ES from '../../services/Enderecos.service';
import * as LOCS from '../../services/LocadorasPadrao.service';
import * as TXTS from '../../util/Texts';
import * as CS from '../../services/Company.service';
import { LOGO_AUDIT } from '../../util/Urls';

function AddLocadoraMercado() {
  
  const { id }                            = useParams();

  const [companyId, setCompanyId]         = useState(isNaN(id) || Number(id) === 0 ? 0 : id);

  const [tempLogo, setTempLogo]           = useState(null);
  
  const navigate                          = useNavigate();

  const [firstLoad, setFirstload]         = useState(true);

  const [disabledSave, setDisabledSave]   = useState(true);

  const { setIsLoading }                  = useContext(LoaderContext);

  const { 

    isLogged,
    userType,
    ufs

  } = useContext(UserContext);

  const { 
    
    HandleScroll,
    setDocTitle,
    setErrorCon,
    canLoad
  
  } = useContext(SiteContext);

  const {

    setAlertShow,
    setAlertMsg,
    setAlertType,
    setAlertBtnOk,
    setAlertOnClose,
    setAlertStyle,
    setToastPosition
  
  } = useContext(AlertasContext);

 
  const [userLogo, setUserLogo] = useState(null);
  
  const {

    companyTpessoaOpt,
    companyColor

  } = useContext(CompanyContext);

  const [companyName, setCompanyName]                 = useState('');
  const [companyNameFantasia, setCompanyNameFantasia] = useState('');
  const [companyType, setCompanyType]                 = useState(3);
  const [companyCnpjCpf, setCompanyCnpjCpf]           = useState('');
  const [companyIeRg, setCompanyIeRg]                 = useState('');
  const [companyTpessoa, setCompanyTpessoa]           = useState(2);  
  const [companyColorL, setCompanyColorL]             = useState(companyColor);

  const [companyValues, setCompanyValues] = useState({

    razaoSocial_nomeCompleto: companyName,
    nomeFantasia_apelido: companyNameFantasia,
    type: companyType,
    cnpj_cpf: companyCnpjCpf,
    ie_rg: companyIeRg,
    type_pessoa: companyTpessoa,
    color: companyColorL

  });

  const handleCompanyValues = (e) => {
    
    setCompanyValues({
        ...companyValues,
        [e.target.name]: e.target.value,
    });

  };

  const handleCancelCompany = () => {
       
    setCompanyValues({

      razaoSocial_nomeCompleto: companyName,
      nomeFantasia_apelido: companyNameFantasia,
      type: companyType,
      cnpj_cpf: companyCnpjCpf,
      ie_rg: companyIeRg,
      type_pessoa: companyTpessoa,
      color: companyColorL

    });

  }; 

  const handleCnpjCpf = async () => {

      const length = companyValues.type_pessoa === 1 ? 11 : 14;

      const msgCaracteres = companyValues.type_pessoa === 1 ? TXTS.CPF_CARACTERES : TXTS.CNPJ_CARACTERES;

      const msgObrigatorio = companyValues.type_pessoa === 1 ? TXTS.CPF_OBRIGATORIO : TXTS.CNPJ_OBRIGATORIO;

      if(companyValues.cnpj_cpf.length !== length || companyValues.cnpj_cpf.length === 0){
          setAlertShow(true);
          setAlertBtnOk(false);
          setAlertType('warning');
          setAlertStyle(2);
          setToastPosition('top-right');
          setAlertMsg(companyValues.cnpj_cpf.length === 0 ? msgObrigatorio : msgCaracteres);
          return;
      }

      const data = {
          cnpj_cpf: companyValues.cnpj_cpf,
          type_pessoa: companyValues.type_pessoa,
          locPadrao: companyId
      }
      setIsLoading('show');

      try{

          const result =  await CS.CheckCnpjCpf(data);
          

          if(result.data && result.status === 200){

              setCompanyValues({
                  ...companyValues,
                  razaoSocial_nomeCompleto: result.data.dados.razaoSocial_nomeCompleto ?? ''
              });

              if(result.data.endereco !== null){
                  
                  setEnderecoValues({
                    ...enderecoValues,
                    cep: result.data.endereco.cep ?? '',
                    logradouro: result.data.endereco.logradouro ?? '',
                    bairro: result.data.endereco.bairro ?? '',
                    estado_id: result.data.endereco.estado_id ?? 0
                  });

                  setCidadeCep(result.data.endereco.cidade_id ?? 0);
              }
          }else{
              setAlertShow(true);
              setAlertBtnOk(false);
              setAlertType('warning');
              setAlertStyle(2);
              setToastPosition('top-right');
              setAlertMsg(result.data.msg);
          }
          
      }catch{
          
      }

      setIsLoading('hide');
  }

  const [enderecoId, setEnderecoId]   = useState(0);
  const [cidadeCep, setCidadeCep]     = useState(0);
  const [cidades, setCidades]         = useState([]);
  const [cep, setCep]                 = useState('');
  const [logradouro, setLogradouro]   = useState('');
  const [numero, setNumero]           = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro]           = useState('');
  const [estadoId, setEstadoId]       = useState(0);
  const [cidadeId, setCidadeId]       = useState(0);

  const [enderecoValues, setEnderecoValues] = useState({

    cep: cep,
    logradouro: logradouro,
    numero: numero,
    complemento: complemento,
    bairro: bairro,
    estado_id: estadoId,
    cidade_id: cidadeId

  });

  const handleEndercoValues = (e) => {

    setEnderecoValues({
        ...enderecoValues,
        [e.target.name]: e.target.value,
    });

  };

  const handleUf = async (value) => {
    
    setIsLoading('show');
    setCidades([]);
    setEnderecoValues(enderecoValues => ({ ...enderecoValues, cidade_id: 0 }));

    try{

      const result =  await ES.GetCidades(value);
      

      if(result.data && result.status === 200){

        setCidades(result.data.cidades);

      }
      

    }catch{
      
    }

    setIsLoading('hide');
  };

  const handleConsultaCep = async () => {
    const cep = enderecoValues.cep;
    
    if(cep !== '' && cep.length === 8){

      setIsLoading('show');

      try{

        const result =  await ES.consultaCep(cep);
        
        
        if(result.data && result.status === 200 && result.data.endereco !== null){
  
          setEnderecoValues({
            ...enderecoValues,
            logradouro: result.data.endereco.logradouro ?? '',
            estado_id: result.data.endereco.estado_id ?? 0,
            bairro: result.data.endereco.bairro ?? '',
          });

          setCidadeCep(result.data.endereco.cidade_id ?? 0);
            
        }else{

            setEnderecoValues({
              ...enderecoValues,
              logradouro: '',
              estado_id: 0,
              cidade_id: 0,
              bairro: '',
            });

            setCidades([]);
            setCidadeCep(0);

        }
        
  
      }catch{
        
      }

      setIsLoading('hide');
    }
  };

  const handleSetUF = (val) => {

    if(val === 0) {
      return;
    }

    setEnderecoValues({
      ...enderecoValues,
      estado_id: val,
    });

  }  

  const handleSetCidade = (val) => {

    if(val === 0) {
      return;
    }

    setEnderecoValues({
      ...enderecoValues,
      cidade_id: val,
    });

  } 

  const handleCancelEndereco = () => {

    setEnderecoValues({
      ...enderecoValues, 
      cep: cep,
      logradouro: logradouro,
      numero: numero,
      complemento: complemento,
      bairro: bairro,
      estado_id: estadoId

    });

    if(cidadeId === 0){
      setCidades([]);
    }

    if(enderecoValues.cidade_id !== cidadeId){
      
      setEnderecoValues(enderecoValues => ({ ...enderecoValues, cidadeId }));
      
    }
  }; 


  const handleUpdate = async (e) => {

    if(disabledSave){
      return;
    }

    e.preventDefault();

    setIsLoading('show');
    
    const data = {

      company: {...companyValues, id: companyId},
      endereco: {...enderecoValues, id: enderecoId},
      tempLogo: tempLogo
    }

    try{

      const result = await LOCS.UpdateLocadora(data);

      if(result.data){

        if (result.status === 200) {

          let msg = '';

          if(result.data.erros.length === 0){
            msg = result.data.msg;

          }else{
            const erros = result.data.erros;
            erros.forEach( (item, index) => {
              msg += `${item} ${index < (erros.length - 1) ? '<br>':''}`;
            });
          }

          setAlertShow(true);
          setAlertMsg(msg);
          setAlertType('success');
          setAlertBtnOk(false);

          if(result.data.newCompany){
            
            const company = result.data.newCompany;
            handleApplyCompany(company);

          }

        }else{
            setAlertShow(true);
            setAlertMsg(result.data.msg);
            setAlertType('error');
            setAlertBtnOk(false);
            
        }

      }else{
                
          setAlertShow(true);
          setAlertMsg(TXTS.ERRO_LOGIN_2);
          setAlertType('error');
          setAlertBtnOk(false);
      
      }

    }catch{

        setAlertShow(true);
        setAlertMsg(TXTS.ERRO_LOGIN_3);
        setAlertType('error');
        setAlertBtnOk(false);
        setErrorCon(true);
        setAlertOnClose(() => () => {navigate('/')});

    }

    setIsLoading('hide');

  }

  useEffect(() => {
    
    if(enderecoValues.estado_id !== '' && enderecoValues.estado_id !== 0 && !firstLoad){
      handleUf(enderecoValues.estado_id);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enderecoValues.estado_id]);


  useEffect(() => {

    if(cidadeCep !== 0 && cidades.length > 0){
      setTimeout(() => {
        setEnderecoValues({
          ...enderecoValues,
          cidade_id: cidadeCep,
        });

        setCidadeCep(0);
      }, 100);
    }

    
    if(estadoId !== enderecoValues.estado_id){

      setEnderecoValues(enderecoValues => ({ ...enderecoValues, cidade_id: 0 }));
      
    }else{
      if(cidades.length > 0 && cidadeId !== 0){

        setEnderecoValues(enderecoValues => ({ ...enderecoValues, cidade_id: cidadeId }));

      }
    }
    
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cidades]);

  const handleApplyCompany = (company) => {

    setCompanyName(company.razaoSocial_nomeCompleto ?? '');
    setCompanyNameFantasia(company.nomeFantasia_apelido ?? '');
    setCompanyType(company.type ?? 3);
    setCompanyCnpjCpf(company.cnpj_cpf ?? '');
    setCompanyIeRg(company.ie_rg ?? '');
    setCompanyTpessoa(company.type_pessoa ?? 2);
    setCompanyId(company.id);

    if(company.color){
      setCompanyColorL(company.color);
    }

    setCompanyValues({

      razaoSocial_nomeCompleto: company.razaoSocial_nomeCompleto ?? '',
      nomeFantasia_apelido: company.nomeFantasia_apelido ?? '',
      type: company.type ?? 1,
      cnpj_cpf: company.cnpj_cpf ?? '',
      ie_rg: company.ie_rg ?? '',
      type_pessoa: company.type_pessoa ?? 2,
      color: company.color ?? companyColorL

    });

    if(company.endereco){

      const endereco = company.endereco;

      handleApplyEnd(endereco);

    }

  }

  const handleApplyEnd = (endereco) => {

    setCep(endereco.cep ?? '');
    setLogradouro(endereco.logradouro ?? '');
    setNumero(endereco.numero ?? '');
    setComplemento(endereco.complemento ?? '');
    setBairro(endereco.bairro ?? '');
    setEstadoId(endereco.estado_id ?? 0);
    setCidadeId(endereco.cidade_id ?? 0);

    setEnderecoId(endereco.id);

    setEnderecoValues({

      cep: endereco.cep ?? '',
      logradouro: endereco.logradouro ?? '',
      numero: endereco.numero ?? '',
      complemento: endereco.complemento ?? '',
      bairro: endereco.bairro ?? '',
      estado_id: endereco.estado_id ?? 0,
      cidade_id: endereco.cidade_id ?? 0

    });

  }

  const handleGetLocadora = async(id) => {
        
        setIsLoading('show');

        try {
            
            const result = await LOCS.GetLocadora(id);

            if(result.data){
                
                if (result.status === 200) {
                  
                  setCidades(result.data.cidades);

                  if(result.data.company){

                    const company = result.data.company;

                    handleApplyCompany(company);

                    if(result.data.logo){
                      setUserLogo(result.data.logo);
                    }

                  }


                }else{
                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    setAlertType('error');
                    setAlertBtnOk(false);
                    setAlertOnClose(() => () => {navigate('/')});
                }
            }else{
                
                setAlertShow(true);
                setAlertMsg(TXTS.ERRO_LOGIN_2);
                setAlertType('error');
                setAlertBtnOk(false);
                setAlertOnClose(() => () => {navigate('/')});
            
            }
            
        }catch {

            setAlertShow(true);
            setAlertMsg(TXTS.ERRO_LOGIN_3);
            setAlertType('error');
            setAlertBtnOk(false);
            setErrorCon(true);
            setAlertOnClose(() => () => {navigate('/')});
        
        }
        
        setIsLoading('hide');

        setTimeout(() => {
          setFirstload(false);
        }, 500);
  };
  
  useEffect(() => {
    
    const length = companyValues.type_pessoa === 1 ? 11 : 14;

    if(
      companyValues.cnpj_cpf === ''
      || companyValues.cnpj_cpf.length !== length
      || companyValues.cnpj_cpf.length === 0
      || companyValues.razaoSocial_nomeCompleto === ''
      || enderecoValues.cep === ''
      || enderecoValues.estado_id === 0 || enderecoValues.cidade_id === 0
      || enderecoValues.logradouro === ''
      || enderecoValues.bairro === '' 
      ){

      setDisabledSave(true);

    }else{

      setDisabledSave(false);

    }

  }, [companyValues, enderecoValues]);

  useEffect(() => {
    setDocTitle(companyId === 0 ? TXTS.LOCMERCADO_ADD_TITLE : TXTS.LOCMERCADO_EDIT_TITLE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId])

  useEffect(() => {
    
    if(isLogged && canLoad === 'locadoraMercado'){

      if(userType === 1){

        if(!isNaN(id) && Number(id) !== 0){
          handleGetLocadora(id);
        }else{
          setFirstload(false);
        }

        setTimeout(() => {
          setIsLoading('hide');
        }, 500);

      }else{

        navigate(-1);

      }
      
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, canLoad, userType]);



    return (
      <motion.section 
        className="conteudo"

        variants={animacao}
        initial='entrada'
        animate='animacao'
        exit='saida'
        
        onScroll={HandleScroll}
      >
        <div className='container-lg'>
          <h2 className='pageTitle'>{companyId === 0 ? TXTS.LOCMERCADO_ADD_TITLE : TXTS.LOCMERCADO_EDIT_TITLE}</h2>
        </div>
        
        <form className='container container-lg'>

          <div className='col col-12 mt-4 mb-0 mt-md-0 mb-md-3'>
            <h2 className='pageTitle sub'>Empresa:</h2>
          </div>

          <div className='row justify-content-start'>

            <div className='col-12 col-md-3 mt-4 mt-md-0 mb-4 mb-md-0'>
              <div className='col col-12 mb-4'>
                <Input 
                    name='color'
                    placeholder='Cor Principal:'
                    type='color'
                    required={false}
                    value={companyValues.color}
                    onChange={handleCompanyValues}
                    useLoading={true}
                />
              </div>
              <UserLogo 
                company={true}
                id={companyId}
                logo={userLogo}
                name={companyValues.razaoSocial_nomeCompleto}
                color={companyValues.color}
                onTempLogo={(temp) => {setTempLogo(temp)}}
                onChangeCl={(cL) => {setCompanyValues({
                  ...companyValues,
                  color: cL,
                })}}
              />
            </div>

            <div className={`col-12 col-md-9 order-md-first mx-0 px-0`}>

              <div className='row mx-0 px-0'>
                <div className='col col-12 col-md-3 mt-4 mt-md-0'>
                  <Select 
                    placeholder='Tipo Pessoa:'
                    data={companyTpessoaOpt}
                    value={companyValues.type_pessoa}
                    useLoading={true}
                    onClick={(selValue) => {setCompanyValues({
                      ...companyValues,
                      type_pessoa: selValue,
                    })}}
                  />
                </div>

                <div className='col col-12 col-md-4 mt-4 mt-md-0'>
                  <Input 
                      name='cnpj_cpf'
                      placeholder={companyValues.type_pessoa === 2 ? 'CNPJ:' : 'CPF:'}
                      type='number'
                      required={true}
                      mask={companyValues.type_pessoa === 2 ? '99.999.999/9999-99' : '999.999.999-99'}
                      value={companyValues.cnpj_cpf}
                      onChange={handleCompanyValues}
                      onBlur={()=>{handleCnpjCpf(companyValues)}}
                      useLoading={true}
                  />
                </div>

                <div className='col col-12 col-md-4 mt-4 mt-md-0'>
                  <Input 
                      name='ie_rg'
                      placeholder={companyValues.type_pessoa === 2 ? 'Inscrição Estadual:' : 'RG:'}
                      type='number'
                      required={false}
                      mask={companyValues.type_pessoa === 2 ? '999.999.999.999' : ''}
                      value={companyValues.ie_rg}
                      onChange={handleCompanyValues}
                      useLoading={true}
                  />
                </div>

                <div className='col col-12 col-md-6 mt-4'>
                  <Input 
                      name='razaoSocial_nomeCompleto'
                      placeholder={companyValues.type_pessoa === 2 ? 'Razão Social:' : 'Nome:'}
                      type='text'
                      required={true}
                      value={companyValues.razaoSocial_nomeCompleto}
                      onChange={handleCompanyValues}
                      useLoading={true}
                  />
                </div>

                <div className='col col-12 col-md-6 mt-4'>
                  <Input 
                      name='nomeFantasia_apelido'
                      placeholder={companyValues.type_pessoa === 2 ? 'Nome Fantasia:' : 'Apelido:'}
                      type='text'
                      required={false}
                      value={companyValues.nomeFantasia_apelido}
                      onChange={handleCompanyValues}
                      useLoading={true}
                  />
                </div>

              </div>

            </div>
            

            <div className='row justify-content-center mx-0 px-0'>

              <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                <Btn 
                    value='Cancelar'
                    type='button'
                    btnType='danger'
                    onClick={handleCancelCompany}
                    useLoading={true}
                />
              </div>

            </div> 
            
          </div>

          <hr></hr>

          <div className='col col-12 mt-4 mb-0 mt-md-0 mb-md-3'>
            <h2 className='pageTitle sub'>Endereço:</h2>
          </div>

          <div className='row justify-content-start'>

            <div className='col col-6 col-md-2 mt-4 mt-md-0'>
              <Input 
                  name='cep'
                  placeholder='CEP:'
                  type='number'
                  required={true}
                  value={enderecoValues.cep}
                  mask='99.999-999'
                  onChange={handleEndercoValues}
                  onBlur={handleConsultaCep}
                  useLoading={true}
              />
            </div>
            
            {ufs &&
              <div className='col col-6 col-md-2 col-xl-1 mt-4 mt-md-0'>
                <Select 
                  placeholder='UF:'
                  data={ufs}
                  value={enderecoValues.estado_id}
                  useLoading={true}
                  required={true}
                  onClick={(selValue) => {handleSetUF(selValue)}}
                />
              </div>
            }    

            {cidades &&
              <div className='col col-12 col-md-3 col-xxl-2 mt-4 mt-md-0'>
                <Select 
                  placeholder='Cidade:'
                  data={cidades}
                  disabled={enderecoValues.estado_id === 0 ? true : false}
                  value={enderecoValues.cidade_id}
                  useLoading={true}
                  required={true}
                  onClick={(selValue) => {handleSetCidade(selValue)}}
                />
              </div>
            }        
            
            <div className='col col-12 col-md-3 col-xxl-3 mt-4 mt-md-0'>
              <Input 
                  name='logradouro'
                  placeholder='Logradouro:'
                  type='text'
                  required={true}
                  value={enderecoValues.logradouro}
                  onChange={handleEndercoValues}
                  useLoading={true}
              />
            </div>

            <div className='col col-6 col-md-2 col-xl-1 mt-4 mt-md-0'>
              <Input 
                  name='numero'
                  placeholder='Número:'
                  type='number'
                  required={false}
                  value={enderecoValues.numero}
                  onChange={handleEndercoValues}
                  useLoading={true}
              />
            </div>

            <div className='col col-6 col-md-2 col-xxl-1 mt-4 mt-xl-0'>
              <Input 
                  name='complemento'
                  placeholder='Comp:'
                  type='text'
                  required={false}
                  value={enderecoValues.complemento}
                  onChange={handleEndercoValues}
                  useLoading={true}
              />
            </div>

            <div className='col col-12 col-md-3 col-xxl-2 mt-4 mt-xxl-0'>
              <Input 
                  name='bairro'
                  placeholder='Bairro:'
                  type='text'
                  required={true}
                  value={enderecoValues.bairro}
                  onChange={handleEndercoValues}
                  useLoading={true}
              />
            </div>

            <div className='row justify-content-center mx-0 px-0'>
              
              <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                <Btn 
                    value='Cancelar'
                    type='button'
                    btnType='danger'
                    onClick={handleCancelEndereco}
                    useLoading={true}
                />
              </div>

            </div>

          </div>

          <hr></hr>

          <div className='row justify-content-center mb-4'>

            <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                <Btn 
                    value='Voltar'
                    type='button'
                    btnType='primary'
                    onClick={() => {navigate('/')}}
                    useLoading={true}
                />
            </div>

            <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
              <Btn 
                  value={companyId === 0 ? 'Cadastrar' : 'Salvar'}
                  type='submit'
                  btnType='success'
                  disabled={disabledSave}
                  onClick={(e)=>{handleUpdate(e)}}
                  useLoading={true}
              />
            </div>

          </div>
     
        </form>
        <div className='logoBotton'>
            <img src={LOGO_AUDIT} alt="AUDIT" className='auditLayout'/> 
        </div>
      </motion.section>
    );
}

export default AddLocadoraMercado;