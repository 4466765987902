import ReactDOMServer from 'react-dom/server';
import Btn from "../Forms/Btn";
import * as F from '../../util/Functions';

function Print({
  type,
  nomeVerDe = '',
  titleToPrint = 'Imprimir',
  disablePrint,
  dataPrint,
  indicadoresLocShow1,
  indicadoresLocShow2,
  indicadoresLocShow3,
  lastLocPadraoTop1,
  lastLocPadraoTop2,
  lastLocPadraoTop3,
  selTop1, selTop2, selTop3,
  selectedTop1, selectedTop2, selectedTop3,
  logoPrint1, logoPrint2, logoPrint3,
  indicadoresRefShow,
  useValue = true,

}) {

    const openPrintWindow = async () => {

        let content;

        switch (type) {
            case 'bal':
            content = (
                
                dataPrint.map((item, key) => (
                    <div key={key} style={{marginBottom: '2em'}}>
                        <div className='card text-bg-light mb-3'>
                            <div className="card-header">{item.mes_ano}</div>
                            <div className="card-body lh-1" style={{ hyphens: 'auto' }}>
                            {!F.isEmpty(item.campos) &&
                                Object.keys(item.campos).map((key) => (
                                <div key={key} className='row align-items-center my-0 mx-0 py-0 px-0 overflow-hidden'>
                                    <div className='col-5 col-md-8 my-1 mx-0 py-0 px-0 text-start'>
                                    <b>{item.campos[key]['titulo']}</b>
                                    </div>
                                    <div className='col-7 col-md-4 my-1 mx-0 py-0 px-0 text-end'>
                                    R$ {item.campos[key]['value']}
                                    </div>
                                    <hr className='m-0'></hr>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))
                
            );
            break;
            case 'dres':
            content = (
                
                dataPrint.map((item, key) => (
                    <div key={key} style={{marginBottom: '2em'}}>
                        <div className='card text-bg-light mb-3'>
                            <div className="card-header">{item.mes_ano}</div>
                            <div className="card-body lh-1" style={{ hyphens: 'auto' }}>
                            {!F.isEmpty(item.campos) &&
                                Object.keys(item.campos).map((key) => (
                                <div key={key} className='row align-items-center my-0 mx-0 py-0 px-0 overflow-hidden'>
                                    <div className='col-5 col-md-8 my-1 mx-0 py-0 px-0 text-start'>
                                    <b>{item.campos[key]['titulo']}</b>
                                    </div>
                                    <div className='col-7 col-md-4 my-1 mx-0 py-0 px-0 text-end'>
                                    R$ {item.campos[key]['value']}
                                    </div>
                                    <hr className='m-0'></hr>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))
                
            );
            break;
            case 'frotas':
            content = (
                
                dataPrint.map((item, key) => (
                    <div key={key} style={{marginBottom: '2em'}}>
                        <div className='card text-bg-light mb-3'>
                            <div className="card-header">{item.mesAno}</div>
                            <div className="card-body lh-1" style={{ hyphens: 'auto' }}>
            
                            <div className='row align-items-center my-0 mx-0 py-0 px-0 overflow-hidden'>
                                <div className='col-5 col-md-8 my-1 mx-0 py-0 px-0 text-start'>
                                <b>Ocupação no Período:</b>
                                </div>
                                <div className='col-7 col-md-4 my-1 mx-0 py-0 px-0 text-end'>
                                {item.ocupacao}%
                                </div>
                                <hr className='m-0'></hr>
                            </div>
            
                            <div className='row align-items-center my-0 mx-0 py-0 px-0 overflow-hidden'>
                                <div className='col-5 col-md-8 my-1 mx-0 py-0 px-0 text-start'>
                                <b>Frota Operacional:</b>
                                </div>
                                <div className='col-7 col-md-4 my-1 mx-0 py-0 px-0 text-end'>
                                {item.frota_operacional}
                                </div>
                                <hr className='m-0'></hr>
                            </div>
            
                            <div className='row align-items-center my-0 mx-0 py-0 px-0 overflow-hidden'>
                                <div className='col-5 col-md-8 my-1 mx-0 py-0 px-0 text-start'>
                                <b>Receita de Locação:</b>
                                </div>
                                <div className='col-7 col-md-4 my-1 mx-0 py-0 px-0 text-end'>
                                R$ {item.valorDre}
                                </div>
                                <hr className='m-0'></hr>
                            </div>

                            <div className='row align-items-center my-0 mx-0 py-0 px-0 overflow-hidden'>
                                <div className='col-5 col-md-8 my-1 mx-0 py-0 px-0 text-start'>
                                <b>Valor Frota NF:</b>
                                </div>
                                <div className='col-7 col-md-4 my-1 mx-0 py-0 px-0 text-end'>
                                R$ {item.valorNF}
                                </div>
                                <hr className='m-0'></hr>
                            </div>
            
                            <div className='row align-items-center my-0 mx-0 py-0 px-0 overflow-hidden'>
                                <div className='col-5 col-md-8 my-1 mx-0 py-0 px-0 text-start'>
                                <b>Ticket Médio:</b>
                                </div>
                                <div className='col-7 col-md-4 my-1 mx-0 py-0 px-0 text-end'>
                                R$ {item.ticketMedio}
                                </div>
                                <hr className='m-0'></hr>
                            </div>
                            </div>
                        </div>
                    </div>
                ))
                
            );
            break;
            case 'comparativos':
            
                content = (

                    <>
                        <div className='indicadoresContainer topo'>

                            <div className='indicadoresTopo'>

                            <span style={{'--bgimg': `url(${(selectedTop1 && selTop1.find(option => option.id === selectedTop1)?.logo)})`}}>
                                {typeof selectedTop1 === 'number' && selectedTop1 !== 0 &&
                                    <i>{lastLocPadraoTop1}</i>
                                }
                            </span>

                            <span style={{'--bgimg': `url(${(selectedTop2 && selTop2.find(option => option.id === selectedTop2)?.logo)})`}}>
                                {typeof selectedTop2 === 'number' && selectedTop2 !== 0 &&
                                    <i>{lastLocPadraoTop2}</i>
                                }
                            </span>

                            <span style={{'--bgimg': `url(${(selectedTop3 && selTop3.find(option => option.id === selectedTop3)?.logo)})`}}>
                                {typeof selectedTop3 === 'number' && selectedTop3 !== 0 &&
                                    <i>{lastLocPadraoTop3}</i>
                                }
                            </span>

                            </div>

                        </div>

                        <div className='indicadoresContainer'>


                            {dataPrint.map( (item ) => {

                                let col1 = '0%';
                                let col2 = '0%';
                                let col3 = '0%';

                                const findLocRef = (indicadoresRefShow.find(obj => obj.id === item.id));
                                const indLocRef = findLocRef ? findLocRef.porcentagem :  '0%';

                                if (selectedTop1 === 'CLIENTE') {
                                    col1 = item.porcentagem;
                                } else if (selectedTop1 === 'IDR') {
                                    col1 = indLocRef;
                                } else {
                                    const findLocPadrao = indicadoresLocShow1.find(obj => obj.id === item.id);
                                    col1 = findLocPadrao ? findLocPadrao.porcentagem : '0%';
                                }

                                if (selectedTop2 === 'CLIENTE') {
                                    col2 = item.porcentagem;
                                } else if (selectedTop2 === 'IDR') {
                                    col2 = indLocRef;
                                } else {
                                    const findLocPadrao = indicadoresLocShow2.find(obj => obj.id === item.id);
                                    col2 = findLocPadrao ? findLocPadrao.porcentagem : '0%';
                                }

                                if (selectedTop3 === 'CLIENTE') {
                                    col3 = item.porcentagem;
                                } else if (selectedTop3 === 'IDR') {
                                    col3 = indLocRef;
                                } else {
                                    const findLocPadrao = indicadoresLocShow3.find(obj => obj.id === item.id);
                                    col3 = findLocPadrao ? findLocPadrao.porcentagem : '0%';
                                }
                                
                                return(
                                    <div className='indicadorItem' key={item.id}>
                                    <div className='indicadorNome'>{item.nome}</div>
                                    <div className='indicadoresTopo'>

                                        <b>{col1}</b>
                                        <b>{col2}</b>
                                        <b>{col3}</b>                      
                                        
                                    </div>
                                    </div>
                                )
                    
                                })
                            }

                        </div>
                    </>
                    
                );
            break;
            default:
                content = (<h2>Erro ao carregar o conteúdo para impressão.</h2>);
            break;
        }

        const printWindow = window.open('', '_blank');

        if (printWindow) {
            try{

                const bootstrap = await fetch('https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css');
                const bootstrapCSS = await bootstrap.text();

                const contentHTML = ReactDOMServer.renderToStaticMarkup(content);

                if(type === 'comparativos'){

                    try{
                        const promises = [
                            loadImage(logoPrint1),
                            loadImage(logoPrint2),
                            loadImage(logoPrint3)
                        ];
                        await Promise.all(promises);
                    }catch{

                    }
                    
                }

                printWindow.document.write(`
                    <html>
                    <head>
                        <title>${titleToPrint}</title>
                        <style>${bootstrapCSS}</style>
                        <style>

                        .printContainer {
                            display: none;
                        }

                        .indicadoresContainer {
                            width: 95%;
                            margin: auto;
                        }
                        
                        .indicadoresContainer.topo {
                            background: #ffffff;                            
                        }
                        
                        .indicadoresContainer .indicadorItem {
                            height: auto;
                            page-break-inside: avoid;
                            border-bottom: 4px white groove;
                        }
                        
                        .indicadoresContainer .indicadorNome {
                            display: flex;
                            padding: .2em;
                            font-size: 1rem;
                            width: 100%;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                        }
                        
                        .indicadoresContainer .indicadoresTopo {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            align-items: center;
                            justify-content: space-around;
                            padding: .2em 0;
                        }
                        
                        .indicadoresContainer .indicadoresTopo span, .indicadoresContainer .indicadoresTopo b {
                            display: flex;
                            position: relative;
                            padding: .3em;
                            flex-basis: 30%;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            font-size: 1rem;
                            text-align: center;
                            color: white;
                            font-weight: 900;
                        }
                        
                        .indicadoresContainer .indicadoresTopo span {
                            height: 70px;
                            width: 70px;
                            flex-basis: auto;
                            background: white var(--bgimg);
                            background-size: 90%;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-origin: content-box;
                            margin-bottom: 1em;
                            color: black;
                        }
                        
                        .indicadoresContainer .indicadoresTopo b{
                            font-size: 1rem;
                            color: black;
                        }
                        
                        .indicadoresContainer .indicadoresTopo b:not(:first-child){
                            border-left: 2px groove white;
                        }
                        
                        
                        .indicadoresContainer .indicadoresTopo span i {
                            position: absolute;
                            bottom: -1em;
                            margin-top: 0;
                            font-size: 12px !important;
                            width: max-content !important;
                        }

                        @media print {
    
                            html, body {
                                page-break-inside: auto;
                            }
    
                            .printContainer {
                                display: block;
                            }
    
                            .card {
                                page-break-inside: avoid;
                            }
                        }
                        
                        </style>
                    </head>
                    <body>
                        <div class='printContainer'>
                            ${contentHTML}
                        </div>
                    </body>
                    </html>
                `);

                printWindow.document.close();

                printWindow.onload = () => {
                    printWindow.focus();
                    printWindow.print();
                    printWindow.close();
                };

            }catch (error) {
                printWindow.document.write('<h2>Erro ao carregar o conteúdo para impressão.</h2>');
                printWindow.close();
            }
        }
    };

    const loadImage = (src) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = (error) => reject(error);
            img.src = src;
        });
    };

  return (
    <Btn 
        title={nomeVerDe !== '' ? `${titleToPrint} ${nomeVerDe}` : titleToPrint}                     
        type='button'
        btnType='warning'
        disabled={disablePrint}
        {...(useValue && { value: titleToPrint })}
        iconLeft='faPrint'
        onClick={openPrintWindow}
        useLoading={true}
    />
  );
}

export default Print;