import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenSquare, faEye } from '@fortawesome/free-solid-svg-icons';
import Btn from '../Forms/Btn';
import * as F from '../../util/Functions';
import * as TXTS from '../../util/Texts';
import { AlertasContext } from '../../contexts/AlertasContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { SiteContext } from '../../contexts/SiteContext';
import { GlobalFiltersContext } from '../../contexts/GlobalFiltersContext';
import * as SUBUS from '../../services/SubUser.service';


function SubUsersList({
    usuarios = false,
    canAdd = false,
}){

    const navigate = useNavigate();

    const {

        setAlertShow,
        setAlertType,
        setAlertBtnCancel,
        setAlertMsg,
        setAlertOnOk,
        setAlertBtnOk

    } = useContext(AlertasContext);

    const { setErrorCon} = useContext(SiteContext);

    const { setIsLoading } = useContext(LoaderContext);

    const { subUsers, setSubUsers } = useContext(UserContext);

    const { filterSubUsers, setFilterSubUsers } = useContext(GlobalFiltersContext);

    const subUsersOrder = useMemo(() => {
        
        if(!usuarios){
            return F.SortArrayByObjKey(subUsers, filterSubUsers.orderBy, filterSubUsers.orderDir);
        }else{
            return F.SortArrayByObjKey(usuarios, filterSubUsers.orderBy, filterSubUsers.orderDir);
        }

    }, [usuarios, subUsers, filterSubUsers.orderBy, filterSubUsers.orderDir]);

    const handleOrder = (column) => {

        setFilterSubUsers({
            ...filterSubUsers,
            orderBy: column,
            orderDir: filterSubUsers.orderDir === 'ASC' ? 'DESC' : 'ASC'
        })

    }

    const handleDoRemove = async (Id, key) => {

        setIsLoading('show');

        setAlertBtnCancel(false);

        try{

            const result = await SUBUS.DeleteSubUser(Id);

            if(result.data){

                if (result.status === 200) {

                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    setAlertType('success');
                    setAlertBtnOk(false);  

                }else{

                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    setAlertType('error');
                    setAlertBtnOk(false);
                }

                if(result.status === 200 || result.status === 404){
                    const copyUsers = subUsers;

                    copyUsers.splice(key, 1);

                    setSubUsers(copyUsers);
                }
                
            }else{
                
                setAlertShow(true);
                setAlertMsg(TXTS.ERRO_LOGIN_2);
                setAlertType('error');
                setAlertBtnOk(false);
            
            }

        }catch{

            setAlertShow(true);
            setAlertMsg(TXTS.ERRO_LOGIN_3);
            setAlertType('error');
            setAlertBtnOk(false);
            setErrorCon(true);
  
        }

        setIsLoading('hide');
        
    }

    const handleRemove = (Id, name, email, key) => {
      
        setAlertShow(true);
        setAlertType('warning');
        setAlertBtnCancel(true);
        setAlertOnOk(() => () => {handleDoRemove(Id, key)});
        setAlertMsg(`Remover o subusuário:<br>${name} <br>${email} ?`);

    }

    return(

        <>
            <div className='col col-12 mt-4 mb-0 mt-md-0 mb-md-3'>
                <h2 className='pageTitle sub'>Subusuários:</h2>
            </div>


            {canAdd &&
                <div className='row justify-content-center mb-4 mt-2 mt-md-0'>
                    <div className='col-12 col-md-auto'>
                        <Btn 
                        title='Adicionar Subusuário'
                        value='Adicionar Subusuário'                     
                        type='button'
                        btnType='primary'
                        iconLeft='faFolderPlus'
                        onClick={() => {navigate('users/add')}}
                        useLoading={true}
                        />
                    </div>
                </div>
            }

            <ul className='list-unstyled mt-2 mt-md-0 mb-2 p-1'>
                <li className='row me-0 ms-0 rounded-1 d-none d-md-flex bg-primary text-white mb-2 align-items-center py-1'>
                    <span title='Ordenar' onClick={() => {handleOrder('name')}} className={`col-12 pointer text-md-center ${canAdd ? 'col-md-3' : 'col-md-6'} h-100`}>Nome</span>
                    <span title='Ordenar' onClick={() => {handleOrder('email')}} className={`col-12 pointer text-md-center ${canAdd ? 'col-md-4' : 'col-md-6'}`}>E-mail</span>
                    {canAdd &&
                        <>
                            <span title='Ordenar' onClick={() => {handleOrder('permission')}} className='col-12 pointer text-md-center col-md-2'>Permissões</span>
                            <span className='col-12 text-md-center col-md-3'>Ações</span>
                        </>
                    }
                </li>
                {subUsersOrder.length > 0 &&
                    
                    <>

                    {subUsersOrder.map( (item, key) => {
                            return(
                                
                                <li key={key} className={`row me-0 ms-0 p-1 rounded-1 mb-4 mb-md-2 border border-primary align-items-center ${key%2 === 0 ? 'bg-light': 'bg-white'}`}>
                                    
                                    <span className={`col-12 text-md-center ${canAdd ? 'col-md-3' : 'col-md-6'} h-100`}>
                                        <i title='Ordenar' onClick={() => {handleOrder('name')}}  className='d-md-none me-2'>Nome:</i>
                                        {item.name}
                                    </span>

                                    <span className={`col-12 text-md-center ${canAdd ? 'col-md-4' : 'col-md-6'}`}>
                                        <i title='Ordenar' onClick={() => {handleOrder('email')}}  className='d-md-none me-2'>E-mail:</i>
                                        {item.email}
                                    </span>

                                    {canAdd &&
                                        <>
                                            <span className='col-12 text-md-center col-md-2'>
                                                <i title='Ordenar' onClick={() => {handleOrder('permission')}} className='d-md-none me-2'>Permissões:</i>
                                                {item.permission === 1 &&
                                                <FontAwesomeIcon icon={faPenSquare} title='Editar' className='me-2'/>
                                                }
                                                <FontAwesomeIcon icon={faEye} title='Visualizar'/>
                                            </span>

                                            <span className='col-12 text-md-center col-md-3'>
                                                <div className='row justify-content-center my-3 my-md-2'>

                                                    <div className='col-5 col-md-2 p-1 mx-2 my-0 py-0'>
                                                        <Btn 
                                                            title='Editar'
                                                            type='button'
                                                            btnType='primary'
                                                            iconRight='faPenToSquare'
                                                            onClick={() => {navigate(`users/edit/${item.id}`)}}
                                                            useLoading={true}
                                                        />
                                                    </div>

                                                    <div className='col-5 col-md-2 p-1 mx-2 my-0 py-0'>
                                                        <Btn 
                                                            title='Remover'
                                                            type='button'
                                                            btnType='danger'
                                                            iconRight='faTrashCan'
                                                            onClick={() => {handleRemove(item.id, item.name, item.email, key)}}
                                                            useLoading={true}
                                                        />
                                                    </div>

                                                </div>
                                            </span>
                                        </>
                                    }

                                </li>
                                
                            )
                        })
                    }
                    
                    </>
                    
                }
            </ul>
        </>

    )
}

export default SubUsersList;