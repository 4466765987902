import { instance } from "./Default.service";

export const GetBalancos = async (data) => {

    return await instance.post('getBalancos', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const AddBalanco = async (data) => {

    return await instance.post('addBalanco', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const EditBalanco = async (data) => {

    return await instance.post('editBalanco', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const DeleteBal = async (id = 0, companyId = 0) => {

    return await instance.get(`deleteBal/${id}/${companyId}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const GetFields = async (id = 0, companyId = 0) => {

    return await instance.get(`campos/balancos/${id}/${companyId}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const UpdateFields = async (data) => {

    return await instance.post('updateFields/balancos', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}