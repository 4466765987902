import { instance } from "./Default.service";

export const GetPlanos = async () => {

    return await instance.get('getPlanos')
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const GetPlano = async (id) => {

    return await instance.get(`planos/getPlano/${id}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const DeletePlano = async (id) => {

    return await instance.get(`planos/deletePlano/${id}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const AddPlano = async (data) => {

    return await instance.post('planos/addPlano', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}
