import React, { createContext, useState, useContext, useMemo } from "react";
import * as CS from '../services/Company.service';
import * as ES from '../services/Enderecos.service';
import * as TXTS from '../util/Texts';
import { LoaderContext } from "./LoaderContext";
import { AlertasContext } from "./AlertasContext";
import { SiteContext } from "./SiteContext";
import * as F from '../util/Functions';

export const CompanyContext =  createContext();

function CompanyProvider ({children}) {

    const [companyName, setCompanyName]                 = useState('');
    const [companyNameFantasia, setCompanyNameFantasia] = useState('');
    const [companyType, setCompanyType]                 = useState('');
    const [companyCnpjCpf, setCompanyCnpjCpf]           = useState('');
    const [companyIeRg, setCompanyIeRg]                 = useState('');
    const [companyTpessoa, setCompanyTpessoa]           = useState(2);
    const [companyColor, setCompanyColor]               = useState('#313131');
    const [companyPrimeFatura, setCompanyPrimeFatura]   = useState(F.MesAno());
    const [footerCompanyColor, setFooterCompanyColor]   = useState('#4FACFE');
    const [backColor, setBackColor]                     = useState('');


    const companyTpessoaOpt = [
       
        {
            label: 'Física',
            id: 1
        },

        {
            label: 'Jurídica',
            id: 2
        },
    ];   

    const [companyEndereco, setCompanyEndereco]        = useState({
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        estado_id: 0,
        cidade_id: 0,
    });

    const [unsavedEndereco, setUnsavedEndereco]         = useState({
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        estado_id: 0,
        cidade_id: 0,
    });

    const requiredCompanyValues = [
        'razaoSocial_nomeCompleto',
        'cnpj_cpf',
        'primeiro_faturamento'
    ];

    const [unsavedCompanyValues, setUnsavedCompanyValues] = useState({

        razaoSocial_nomeCompleto: '',
        nomeFantasia_apelido: '',
        type: '',
        cnpj_cpf: '',
        ie_rg: '',
        type_pessoa: '',
        color: '',
        primeiro_faturamento: ''
    
    });

    const { setIsLoading } = useContext(LoaderContext);
    
    const {

        setAlertShow,
        setAlertBtnOk,
        setAlertType,
        setAlertMsg,
        setAlertStyle,
        setToastPosition

    } = useContext(AlertasContext);

    const { setErrorCon, setTextColor } = useContext(SiteContext);

    useMemo(() => {
        setFooterCompanyColor(companyColor);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyColor]);

    const handleSetCompany = (

        companyName,
        companyNameFantasia,
        companyType,
        companyCnpjCpf,
        companyIeRg,
        companyTpessoa,
        companyColor,
        companyPrimeFatura,
        companyEndereco,

    ) => {

        setCompanyName(companyName ?? '');
        setCompanyNameFantasia(companyNameFantasia ?? '');
        setCompanyType(companyType ?? '');
        setCompanyCnpjCpf(companyCnpjCpf ?? '');
        setCompanyIeRg(companyIeRg ?? '');
        setCompanyTpessoa(companyTpessoa ?? '');
        setCompanyPrimeFatura(companyPrimeFatura ?? F.MesAno());

        if(companyColor){
            setCompanyColor(companyColor);
            setBackColor(companyColor);
            setFooterCompanyColor(companyColor);
            const textColor = F.TextColorByBg(companyColor);
            setTextColor(textColor);
        }else{
            setFooterCompanyColor('#4FACFE');
        }

        if(companyEndereco){

            setCompanyEndereco({
                cep: companyEndereco.cep ?? '',
                logradouro: companyEndereco.logradouro ?? '',
                numero: companyEndereco.numero ?? '',
                complemento: companyEndereco.complemento ?? '',
                bairro: companyEndereco.bairro ?? '',
                estado_id: companyEndereco.estado_id ?? 0,
                cidade_id: companyEndereco.cidade_id ?? 0,
            });

        }

    }

    const handleClearCompany = () => {
        setCompanyName('');
        setCompanyNameFantasia('');
        setCompanyType('');
        setCompanyCnpjCpf('');
        setCompanyIeRg('');
        setCompanyTpessoa(2);
        setCompanyColor('#313131');
        setCompanyPrimeFatura(F.MesAno());
        setFooterCompanyColor('#4FACFE');
        setCompanyEndereco({
            cep: '',
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            estado_id: 0,
            cidade_id: 0,
        });

        setTextColor('rgb(255,255,255)');
    }

    const handleCompanyUpdate = async (e, companyValues) => {      
        if(companyValues.razaoSocial_nomeCompleto === '' || companyValues.cnpj_cpf === '')
        return;

        e.preventDefault();

        setIsLoading('show');

        try{
        
            const result =  await CS.UpdateCompany(companyValues);

            if(result.data){

                if (result.status === 200) {
                    
                    const company = result.data.company;
                    const newCompany = result.data.newCompany;

                    setAlertShow(true);
                    setAlertMsg(newCompany ? TXTS.COMPANY_CREATE_SUCESSO : TXTS.COMPANY_UPDATE_SUCESSO);
                    setAlertType('success');
                    setAlertBtnOk(false);

                    handleSetCompany(
                        company.razaoSocial_nomeCompleto,
                        company.nomeFantasia_apelido,
                        company.type,
                        company.cnpj_cpf,
                        company.ie_rg,
                        company.type_pessoa,
                        company.color,
                        company.primeiro_faturamento
                    );
                    

                }else{

                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    setAlertType('error');
                    setAlertBtnOk(false);
                }

            }else{
                
                setAlertShow(true);
                setAlertMsg(TXTS.ERRO_LOGIN_2);
                setAlertType('error');
                setAlertBtnOk(false);
            
            }

        }catch{

            setAlertShow(true);
            setAlertMsg(TXTS.ERRO_LOGIN_3);
            setAlertType('error');
            setAlertBtnOk(false);
            setErrorCon(true);
            
        }

        setIsLoading('hide');
    };

    const handleEnderecoUpdate = async (e, enderecoValues) => {     

        if(enderecoValues.cep === ''
        || enderecoValues.estado_id === 0
        || enderecoValues.cidade_id === 0
        || enderecoValues.logradouro === ''
        || enderecoValues.bairro === ''){
        return;
    }

        e.preventDefault();

        setIsLoading('show');

        try{
        
            const result =  await ES.UpdateEndereco(enderecoValues);

            if(result.data){

                if (result.status === 200) {
                    
                    const endereco = result.data.endereco;
                    const newEndereco = result.data.newEndereco;

                    setAlertShow(true);
                    setAlertMsg(newEndereco ? TXTS.ENDERECO_CREATE_SUCESSO : TXTS.ENDERECO_UPDATE_SUCESSO);
                    setAlertType('success');
                    setAlertBtnOk(false);

                    setCompanyEndereco({
                        cep: endereco.cep ?? '',
                        logradouro: endereco.logradouro ?? '',
                        numero: endereco.numero ?? '',
                        complemento: endereco.complemento ?? '',
                        bairro: endereco.bairro ?? '',
                        estado_id: endereco.estado_id ?? 0,
                        cidade_id: endereco.cidade_id ?? 0,
                    });
                    

                }else{

                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    setAlertType('error');
                    setAlertBtnOk(false);
                }

            }else{
                
                setAlertShow(true);
                setAlertMsg(TXTS.ERRO_LOGIN_2);
                setAlertType('error');
                setAlertBtnOk(false);
            
            }

        }catch{

            setAlertShow(true);
            setAlertMsg(TXTS.ERRO_LOGIN_3);
            setAlertType('error');
            setAlertBtnOk(false);
            setErrorCon(true);
            
        }

        setIsLoading('hide');
        
    };


    const handleCnpjCpf = async (companyValues) => {

        const length = companyValues.type_pessoa === 1 ? 11 : 14;

        const msgCaracteres = companyValues.type_pessoa === 1 ? TXTS.CPF_CARACTERES : TXTS.CNPJ_CARACTERES;

        const msgObrigatorio = companyValues.type_pessoa === 1 ? TXTS.CPF_OBRIGATORIO : TXTS.CNPJ_OBRIGATORIO;

        if(companyValues.cnpj_cpf.length !== length || companyValues.cnpj_cpf.length === 0){
            setAlertShow(true);
            setAlertBtnOk(false);
            setAlertType('warning');
            setAlertStyle(2);
            setToastPosition('top-right');
            setAlertMsg(companyValues.cnpj_cpf.length === 0 ? msgObrigatorio : msgCaracteres);
            return;
        }

        const data = {
            cnpj_cpf: companyValues.cnpj_cpf,
            type_pessoa: companyValues.type_pessoa
        }
        setIsLoading('show');

        try{

            const result =  await CS.CheckCnpjCpf(data);
            

            if(result.data && result.status === 200){

                setUnsavedCompanyValues({
                    ...unsavedCompanyValues,
                    razaoSocial_nomeCompleto: result.data.dados.razaoSocial_nomeCompleto ?? ''
                });

                if(result.data.endereco !== null){
                    
                    setUnsavedEndereco({
                            ...unsavedEndereco,
                            cep: result.data.endereco.cep ?? '',
                            logradouro: result.data.endereco.logradouro ?? '',
                            bairro: result.data.endereco.bairro ?? '',
                            estado_id: result.data.endereco.estado_id ?? 0,
                            cidade_id: result.data.endereco.cidade_id ?? 0,
                        })
                }
            }else{
                setAlertShow(true);
                setAlertBtnOk(false);
                setAlertType('error');
                setAlertStyle(2);
                setToastPosition('top-right');
                setAlertMsg(result.data.msg);
            }
            
        }catch{
           
        }

        setIsLoading('hide');
    }

    return (
        <CompanyContext.Provider value={{ 
            companyName, setCompanyName,
            companyNameFantasia, setCompanyNameFantasia,
            companyType, setCompanyType,
            companyCnpjCpf, setCompanyCnpjCpf,
            companyIeRg, setCompanyIeRg,
            companyTpessoa, setCompanyTpessoa,
            companyPrimeFatura, setCompanyPrimeFatura,
            companyEndereco, setCompanyEndereco,
            companyTpessoaOpt, handleCompanyUpdate,
            companyColor, setCompanyColor, footerCompanyColor, setFooterCompanyColor, backColor,
            requiredCompanyValues, handleCnpjCpf, unsavedCompanyValues,
            handleSetCompany, handleClearCompany, unsavedEndereco, handleEnderecoUpdate
            }}>
            {children}
        </CompanyContext.Provider>
    )
}

export default CompanyProvider;