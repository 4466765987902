import { useContext } from 'react';
import './index.css';
import { STATISTICS_ICON } from '../../util/Urls';
import { SiteContext } from '../../contexts/SiteContext';
import UserLogo from '../User/UserLogo';
import { UserContext } from '../../contexts/UserContext';
import LogoIDR from '../LogoIDR';

function Logos() {

  const { showLogos } = useContext(SiteContext);
  const { isLogged, userType, isPromoAccess } = useContext(UserContext);

  return (
    <div className={`logos ${showLogos}`}>
        {isLogged && userType === 2 && !isPromoAccess &&
          <UserLogo estilo={'header right'}/>
        }

        {isLogged && (userType === 1 || isPromoAccess) &&
            <img src={STATISTICS_ICON} alt="AUDIT" className='audit'/>  
        }

        {!isLogged &&
            <img src={STATISTICS_ICON} alt="AUDIT" className='audit'/>  
        }
        
        <LogoIDR 
          className={`auditIDR ${isLogged ? 'loggedIDR' : ''}`}
          alt='IDR'
        />
    </div>
  );
}

export default Logos;