import React from "react";
import AlertasProvider from "./AlertasContext";
import CompanyProvider from "./CompanyContext";
import GlobalFiltersProvider from "./GlobalFiltersContext";
import LoaderProvider from "./LoaderContext";
import SiteProvider from "./SiteContext";
import UserProvider from "./UserContext";


const AppContextProvider = ({children}) => {
    return (
        <>
            <LoaderProvider>
                <GlobalFiltersProvider>
                    <AlertasProvider>
                        <SiteProvider>
                            <CompanyProvider>
                                <UserProvider>    
                                    {children}
                                </UserProvider>
                            </CompanyProvider>
                        </SiteProvider>
                    </AlertasProvider>
                </GlobalFiltersProvider>
            </LoaderProvider>
        </>
    )
}

export default AppContextProvider;