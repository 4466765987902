import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { animacao } from '../../util/AnimatedDiv'
import Input from '../../components/Forms/Input';
import Btn from '../../components/Forms/Btn';
import Select from '../../components/Forms/Select';
import Switch from '../../components/Forms/Switch';
import { LoaderContext } from '../../contexts/LoaderContext';
import { SiteContext } from '../../contexts/SiteContext';
import { AlertasContext } from '../../contexts/AlertasContext';
import { UserContext } from '../../contexts/UserContext';
import * as SUBUS from '../../services/SubUser.service';
import * as TXTS from '../../util/Texts';
import * as F from '../../util/Functions';
import { LOGO_AUDIT } from '../../util/Urls';

function EditSubUser() {
  
  const { id }                            = useParams();

  const [userId, setUserId]               = useState(isNaN(id) || Number(id) === 0 ? 0 : id);
  
  const navigate                          = useNavigate();

  const [disabledSave, setDisabledSave]   = useState(true);

  const { setIsLoading }                  = useContext(LoaderContext);

  const { 

    isLogged,
    userType,
    userTypes,
    subUsers,
    setSubUsers

  } = useContext(UserContext);

  const { 
    
    HandleScroll,
    setDocTitle,
    setErrorCon,
    canLoad
  
  } = useContext(SiteContext);

  const {

    setAlertShow,
    setAlertMsg,
    setAlertType,
    setAlertBtnOk,
    setAlertBtnCancel,
    setAlertBtnOkTxt,
    setAlertOnOk,
    setAlertOnClose,
    handleCloseAlert
  
  } = useContext(AlertasContext);

  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userTypeL, setUserTypeL] = useState(2);
  const [userPermission, setUserPermission] = useState(false);

  const [senha, setSenha] = useState(userId === 0 ? F.GenPassword(10) : '');

  const [iconCopy, setIconCopy] = useState('faCopy');

  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const [showCopyError, setShowCopyError]     = useState(false);

  const [userValues, setUserValues] = useState({

    email: userEmail,
    name: userName,
    type: userTypeL,
    permission: userPermission,
    senha: senha,

  });

  const handleUserValues = (e) => {

    setUserValues({
        ...userValues,
        [e.target.name]: e.target.value,
    });

  };
  
  const handleCancelUser = () => {
        
    setUserValues({

        email: userEmail,
        name: userName,
        type: userTypeL,
        permission: userPermission,
        senha: senha,

    });

  };

  const handleApplyUser = (user) => {

    const permission = user.permission === 1 ? true : false;
    
    setUserEmail(user.email ?? '');
    setUserName(user.name ?? '');
    setUserTypeL(user.type ?? 2);
    setUserPermission(permission);
    setUserId(user.id);
    setSenha('');

    setUserValues({
      ...userValues,
      email: user.email ?? '',
      name: user.name ?? '',
      type: user.type ?? 2,
      permission: permission,
      senha: '',
    });
    
  }

  const handleGetUser = async(id) => {
        
        setIsLoading('show');

        try {
            
            const result = await SUBUS.GetSubUser(id);

            if(result.data){
                
                if (result.status === 200) {
                  
                  if(result.data.user){

                    const user = result.data.user;

                    handleApplyUser(user);

                  }

                }else{
                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    setAlertType('error');
                    setAlertBtnOk(false);
                    setAlertOnClose(() => () => {navigate('/settings')});
                }
            }else{
                
                setAlertShow(true);
                setAlertMsg(TXTS.ERRO_LOGIN_2);
                setAlertType('error');
                setAlertBtnOk(false);
                setAlertOnClose(() => () => {navigate('/settings')});
            
            }
            
        }catch {

            setAlertShow(true);
            setAlertMsg(TXTS.ERRO_LOGIN_3);
            setAlertType('error');
            setAlertBtnOk(false);
            setErrorCon(true);
            setAlertOnClose(() => () => {navigate('/')});
        
        }
        
        setIsLoading('hide');

        
  };

  const handleUpdate = async (e) => {

    if(disabledSave){
      return;
    }

    e.preventDefault();

    setIsLoading('show');
    
    const data = {

        id: userId,
        email: userValues.email.trim(),
        name: userValues.name.trim(),
        senha: userValues.senha.trim(),
        type: userValues.type,
        permission: userValues.permission ? 1 : 2

    }

    try{

      const result = await SUBUS.SaveSubUser(data);

      if(result.data){

        if (result.status === 200) {

            const copySubUsers = subUsers;

            setAlertShow(true);
            setAlertMsg(result.data.msg);
            setAlertType('success');
            setAlertBtnOk(false);

            if(result.data.newUser){
                
                const user = result.data.newUser;
                handleApplyUser(user);

                copySubUsers.push({
                    id: user.id,
                    name: user.name,
                    email: user.email,
                    permission: user.permission
                });

            }else{

                const userInList = F.IndexOfObject(copySubUsers, 'id', userId);

                    if(userInList !== -1){
                        copySubUsers[userInList].name = userValues.name.trim();
                        copySubUsers[userInList].email= userValues.email.trim();
                        copySubUsers[userInList].permission = userValues.permission ? 1 : 2;
                    }

            }

            setSubUsers(copySubUsers);

        }else{
            setAlertShow(true);
            setAlertMsg(result.data.msg);
            setAlertType('error');
            setAlertBtnOk(false);
            
        }

      }else{
                
          setAlertShow(true);
          setAlertMsg(TXTS.ERRO_LOGIN_2);
          setAlertType('error');
          setAlertBtnOk(false);
      
      }

    }catch{

        setAlertShow(true);
        setAlertMsg(TXTS.ERRO_LOGIN_3);
        setAlertType('error');
        setAlertBtnOk(false);
        setErrorCon(true);
        setAlertOnClose(() => () => {navigate('/')});

    }

    setIsLoading('hide');

  }

  const handleUserType = (value) => {

    if(value === userValues.type){
      return;
    }

    const name = userValues.name;

    setAlertShow(true);
    setAlertType('warning');

    setAlertBtnCancel(true);
    setAlertBtnOkTxt('Sim');
    setAlertOnOk(() => () => {

      setUserValues({
        ...userValues,
        type: value,
      });

      handleCloseAlert();

    });

    let msg = '';
    let cr = '';

    if(value === 1 && userValues.type === 2){
      
      msg = 'Conceder permissões de administrador';
      cr = 'a';
      
    }

    if(value === 2 && userValues.type === 1){
      
      msg = 'Remover permissões de administrador';
      cr = 'de';
      
    }

    setAlertMsg(`${msg} ${name !== '' ? `${cr} ${name}` : ''} ?`);

  }

  const handleUserPermission = (onOff) => {
    
    setUserValues({
        ...userValues,
        permission: onOff,
    });

  }

  const handleCopyPass = () => {
    F.CopyTextToClipboard(userValues.senha)
    .then(() => {
      setIconCopy('faCheck');
      setShowCopySuccess(true);
    })
    .catch((err) => {
      setIconCopy('faCircleExclamation');
      setShowCopyError(true);
    });

    setTimeout(() => {
      setIconCopy('faCopy');
      setShowCopySuccess(false);
      setShowCopyError(false);
    }, 2000);
  }
  

  useEffect(() => {

    if(userValues.email === '' || userValues.name === ''){

      setDisabledSave(true);

    }else{

      setDisabledSave(false);

    }

  }, [userValues]);


  useEffect(() => {
    setDocTitle(userId === 0 ? TXTS.SUBUSERS_ADD_TITLE : TXTS.SUBUSERS_EDIT_TITLE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  useEffect(() => {

    if(isLogged && canLoad === 'settings'){

        if(!isNaN(userId) && userId > 0){

            handleGetUser(userId);

        }

        setTimeout(() => {
            setIsLoading('hide');
        }, 500);
      
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [isLogged, canLoad]);

    return (
      <motion.section 
        className="conteudo"

        variants={animacao}
        initial='entrada'
        animate='animacao'
        exit='saida'
        
        onScroll={HandleScroll}
      >
        <div className='container-lg'>
          <h2 className='pageTitle'>{userId === 0 ? TXTS.SUBUSERS_ADD_TITLE : TXTS.SUBUSERS_EDIT_TITLE}</h2>
        </div>
        
        <form className='container container-lg'>
          
          <div className='col col-12 mt-4 mb-0 mt-md-0 mb-md-3'>
            <h2 className='pageTitle sub'>Usuário:</h2>
          </div>

          <div className='row justify-content-center'>
            
            {userType === 1 &&
                <div className='col col-12 col-md-3 col-xl-2 mt-4 mt-md-0'>
                    <Select 
                    placeholder='Tipo:'
                    data={userTypes}
                    search={false}
                    value={userValues.type}
                    useLoading={true}
                    onClick={(selValue) => {handleUserType(selValue)}}
                    />
                </div>
            }
            
            <div className='col col-12 col-md-4 col-xxl-3 mt-4 mt-md-0'>
              <Input 
                  name='name'
                  labelLeft='faIdBadge'
                  placeholder='Nome:'
                  type='text'
                  required={true}
                  value={userValues.name}
                  onChange={handleUserValues}
                  useLoading={true}
              />
            </div>

            <div className='col col-12 col-md-4 col-xxl-3 mt-4 mt-md-0'>
              <Input 
                  name='email'
                  labelLeft='faEnvelope'
                  placeholder='E-mail:'
                  type='email'
                  required={true}
                  value={userValues.email}
                  onChange={handleUserValues}
                  useLoading={true}
              />
            </div>

            <div className='col col-12 col-md-3 col-xl-2 mt-4 mt-xl-0'>
              <Switch
                text='Permitir Editar'
                on={userValues.permission}
                onChange={(onOff) => {handleUserPermission(onOff)}}
                useLoading={true}
              />
            </div>

            <div className='col col-12 col-md-5 col-xxl-3 mt-4 mt-xxl-0 position-relative'>

                {showCopySuccess &&
                  <div className="alert alert-success position-absolute z-2 top-50 start-50 translate-middle" role="alert">
                    Copiado com sucesso!
                  </div>
                }

                {showCopyError &&
                  <div className="alert alert-danger position-absolute z-2 top-50 start-50 translate-middle" role="alert">
                    Erro ao copiar!
                  </div>
                }
                
              <Input 
                name='senha'
                labelLeft='faLock'
                {...(userValues.senha !== '' && { labelRightOnClick: handleCopyPass, labelRight: iconCopy })}
                {...(userId === 0 && { readOnly: true })}
                placeholder={`Senha${userId !== 0 ? ' (Deixe em branco para não alterar)':''}:`}
                type='text'
                required={userId === 0 ? true : false}
                value={userValues.senha}
                onChange={handleUserValues}
                useLoading={true}
              />
            </div>

            <div className='row justify-content-center mx-0 px-0'>

              <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                <Btn 
                    value='Cancelar'
                    type='button'
                    btnType='danger'
                    onClick={handleCancelUser}
                    useLoading={true}
                />
              </div>

            </div>
            
          </div>

          <hr></hr>

          <div className='row justify-content-center mb-4'>

            <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                <Btn 
                    value='Voltar'
                    type='button'
                    btnType='primary'
                    onClick={() => {navigate('/settings')}}
                    useLoading={true}
                />
            </div>

            <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
              <Btn 
                  value={userId === 0 ? 'Cadastrar' : 'Salvar'}
                  type='submit'
                  btnType='success'
                  disabled={disabledSave}
                  onClick={(e)=>{handleUpdate(e)}}
                  useLoading={true}
              />
            </div>

          </div>
     
        </form>
        <div className='logoBotton'>
            <img src={LOGO_AUDIT} alt="AUDIT" className='auditLayout'/> 
        </div>
      </motion.section>
    );
}

export default EditSubUser;