import React, { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faRightFromBracket, faSpinner, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import * as TXTS from '../../../util/Texts';
import './index.css';
// import UserLogo from '../UserLogo';
import Input from '../../Forms/Input';
import Btn from '../../Forms/Btn';
import { SiteContext } from '../../../contexts/SiteContext';
import { UserContext } from '../../../contexts/UserContext';


function LoginArea() {

    const navigate                          = useNavigate(); 

    const [showPass, setShowPass]           = useState(false);

    const loginAreaRef                      = useRef(null);

    const {

        handleLogin,
        isLogged,
        userName,
        userEmail,
        userType,
        Logout,
        handleLocalLogout,
        txtPromoToken,
        statusPromoToken,
        isPromoAccess

    } = useContext(UserContext);

    const [userValues, setUserValues] = useState({
        email: '',
        password: ''
    });

    const handleUserValues = (e) => {
        setUserValues({
            ...userValues,
            [e.target.name]: e.target.value,
        });
    };

    const {

        openLogin,
        setOpenLogin,
        textColor

    } = useContext(SiteContext);

    useOutsideClick(loginAreaRef, openLogin);

    const toogleShowPass = () => {
        setShowPass(showPass ? false : true)
    }

    const handleLogout = () => {      

        if(isLogged){

            Logout();

        }else{

            handleLocalLogout();
            navigate('/')
        }
    }

    function useOutsideClick(ref, openLogin) {
        
        useEffect(() => {
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target) && openLogin === 'loginAreaOpen') {
                setOpenLogin('');
            }
          }
          
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref, openLogin]);
    }

    return (
        <>
            {isLogged &&
                
                    <div className='logado'>
                    {/* {userType === 2 &&
                        <UserLogo estilo={'header'}/>
                    }  */}

                    <div className='txtsClient'>
                        
                        <div className='nomeBts'>
                            {!isPromoAccess &&
                                <p style={{color: textColor}}>{TXTS.LOGIN_AREA_TXT} {userName}</p>
                            }
                            {isPromoAccess &&
                                <p style={{color: textColor}}>Acesso Degustação</p>
                            }
                            <div className={`btsLogado ${userType === 1 ? 'admin' : ''}`} style={{'--textColor': textColor}}>
                                {/* <NavLink to="/settings" title={TXTS.SETTINGS_TITLE}><FontAwesomeIcon icon={faGear} /></NavLink> */}
                                <FontAwesomeIcon title={TXTS.EXIT_TXT} onClick={handleLogout} icon={faRightFromBracket} />
                            </div> 
                        </div>
                        
                        <p style={{color: textColor}}>{!isPromoAccess ? userEmail : 'Seja bem-vindo.'}</p>                    
                        
                    </div>              

                </div> 
            }
            
            {!isLogged &&    
                <form className={`loginArea row justify-content-center justify-content-xl-end ${openLogin} ${isPromoAccess ? 'promoToken' : ''}`} ref={loginAreaRef}>
                    
                    {openLogin === 'loginAreaOpen' &&
                        <FontAwesomeIcon onClick={()=>setOpenLogin('')} icon={faCircleXmark} className='closeLoginAreaBt'/>
                    }
                    
                        {!isPromoAccess && 
                        
                            <>
                                <div className='col col-12 col-md-5 col-xxl-4 mt-4 mt-md-0'>
                                    <Input 
                                        name='email'
                                        labelLeft='faEnvelope'
                                        placeholder='E-mail:'
                                        type='email'
                                        required={true}
                                        value={userValues.email}
                                        onChange={handleUserValues}
                                    />
                                </div>
                                
                                <div className='col col-12 col-md-5 col-xxl-4 mt-4 mt-md-0'>
                                    <Input 
                                        name='password'
                                        labelLeft='faLock'
                                        labelRight={showPass ? 'faEyeSlash' : 'faEye'}
                                        labelRightOnClick={toogleShowPass} 
                                        labelRightTitle={showPass ? 'Ocultar Senha' : 'Mostrar Senha'}
                                        placeholder='Senha:'
                                        type={showPass ? 'text' : 'password'}
                                        required={true}
                                        value={userValues.password}
                                        onChange={handleUserValues}
                                    />
                                </div>
                            </>
                        
                        }

                        {isPromoAccess &&

                            <div className='col col-12 col-md-10 col-xxl-8 mt-4 mt-md-0'>

                                 <h2 className='promoTxts'>
                                    {statusPromoToken === 'loading' &&
                                        <FontAwesomeIcon style={{marginRight: 8, fontSize: 18}} icon={faSpinner} spin/>
                                    }
                                    {statusPromoToken === 'ok' &&
                                        <FontAwesomeIcon style={{marginRight: 8, fontSize: 18}} icon={faCircleCheck} color='green'/>
                                    }
                                    {statusPromoToken === 'error' &&
                                        <FontAwesomeIcon style={{marginRight: 8, fontSize: 18}} icon={faCircleXmark} color='red'/>
                                    }
                                    {txtPromoToken}
                                </h2>
                                
                            </div>

                        }
                        
                        <div className='col col-12 col-md-2 col-xl-auto mt-4 mt-md-0'>
                            <Btn 
                                disabled={(!isPromoAccess) || (isPromoAccess && statusPromoToken === 'ok') ? false : true}
                                value='Entrar'
                                onClick={(e)=>{handleLogin(e, userValues)}}
                                type='submit'
                            />
                        </div>
                </form>
            }
        </>
    );
}

export default LoginArea;