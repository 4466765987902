export const animacao = {
    entrada: {
        scale: 3,
        opacity: 0.5,
    },
    animacao: {
        scale: 1,
        opacity:1,
        transition: {
            duration: 0.6,
            type: 'tween'
        }
    },
    saida: {
        scale: 0,
        opacity: 0,
        transition: {
            duration: 0.5,
            type: 'tween'
        }
    }
};

export const fade = {
    entrada: {
        scale: .3,
        opacity: 0.5,
    },
    animacao: {
        scale: 1,
        opacity:1,
        transition: {
            duration: 0.6,
            type: 'tween'
        }
    },
    saida: {
        scale: .3,
        opacity: 0,
        transition: {
            duration: 0.5,
            type: 'tween'
        }
    }
}

export const slidesAnime = {
    entrada: {
        scale: 3,
        opacity: 0,
    },
    animacao: {
        scale: 1,
        opacity:1,
        transition: {
            duration: 0.6,
            type: 'tween'
        }
    },
    saida: {
        scale: 3,
        opacity: 0,
        transition: {
            duration: 1.2,
            type: 'tween'
        }
    }
}