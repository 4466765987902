import { instance } from "./Default.service";

export const GetFrota = async (data) => {

    return await instance.post('getFrota', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const DeleteFrota = async (id, companyId = 0) => {

    return await instance.get(`deleteFrota/${id}/${companyId}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const GetLocacoes = async (id) => {

    return await instance.get(`getLocacoes/${id}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const EditLocacao = async (data) => {

    return await instance.post('editLocacao', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const DeleteLocacao = async (id) => {

    return await instance.get(`deleteLocacao/${id}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const GetFrotaFields = async (id = 0, companyId = 0) => {

    return await instance.get(`getFrotaFields/${id}/${companyId}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

// export const AddFrota = async (data) => {

//     return await instance.post('addFrota', data)
//                 .then(response => {
//                     return response;
//                 })
//                 .catch(error => {
//                     if(error.response){
//                         return error.response;
//                     }else{
//                         return null;
//                     }
//                 });
// }

export const AddFrota = async (data) => {

    return await instance.post('addFrota', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const EditFrota = async (data) => {

    return await instance.post('editFrota', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const GetModelosByMarca = async (id = 0) => {

    return await instance.get(`getModelosByMarca/${id}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}