import React, { useEffect, useContext, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './index.css';
import Input from '../../components/Forms/Input';
import Btn from '../../components/Forms/Btn';
import CheckBox from '../../components/Forms/CheckBox';
import { motion } from 'framer-motion';
import { fade } from '../../util/AnimatedDiv'
import { LoaderContext } from '../../contexts/LoaderContext';
import { SiteContext } from '../../contexts/SiteContext';
import { AlertasContext } from '../../contexts/AlertasContext';
import { UserContext } from '../../contexts/UserContext';
import * as F from '../../util/Functions';
import * as BL from '../../services/Balancos.service';
import * as DRE from '../../services/Dres.service';
import * as TXTS from '../../util/Texts';
import { LOGO_AUDIT } from '../../util/Urls';


function CamposAdd() {

    const navigate                                  = useNavigate();
    const { id }                                    = useParams();
    const { setIsLoading }                          = useContext(LoaderContext);
    const { isLogged, userType }                    = useContext(UserContext);
    const [disabledAdd, setDisabledAdd]             = useState(true);
    const [values, setValues]                       = useState({});
    const [comments, setComments]                   = useState({});
    const [campos, setCampos]                       = useState({});
    const [unsavedCampos, setUnsavedCampos]         = useState([]);
    const [trashCampos, setTrashCampos]             = useState([]);

    const [cantRemove, setCantRemove]               = useState([]);

    const [ctrlZwhat, setCtrlZwhat]                 = useState([]);
    const [ctrlZadd, setCtrlZadd]                   = useState([]);
    const [ctrlZtoTrash, setCtrlZtoTrash]           = useState([]);
    const [ctrlZfromTrash, setCtrlZfromTrash]       = useState([]);  

    const dragItem = useRef();
    const dragItenId = useRef();
    const dragOverItem = useRef();
    
    const dragStart = (e, position) => {

        dragItem.current = position;
        dragItenId.current = e.target.id;
    };

    const dragEnter = (e, position) => {

        dragOverItem.current = position;

    };

    const drop = (e) => {
        
        const copyUnsavedCampos = [...unsavedCampos];
        const copyTrash = [...trashCampos];
        const copyCtrlZwhat = [...ctrlZwhat];
        
        
        if(dragOverItem.current === 1000){

            const findCantRemove = cantRemove.filter(x => x.id === parseInt(dragItenId.current))

            let nomeCampo = values[dragItenId.current];
            nomeCampo = nomeCampo[nomeCampo.length-1] === ':' ? nomeCampo.slice(0,-1) : nomeCampo;

            if(findCantRemove.length !== 0){

                const textIndicadores = findCantRemove.length > 1 ? 'dos seguintes indicadores' : 'do seguinte indicador'

                let msg = `O campo ${nomeCampo} não pode ser removido pois faz parte ${textIndicadores}:<br>`;
               
                findCantRemove.forEach((item, key) => {
                    
                    msg += `${item.nome} ${item.titulo !== null ? ` - (${item.titulo})` : ''}${parseInt(key+1) === findCantRemove.length ? '.' : ', '}`
          
                });

                setAlertMsg(msg);
                setAlertType('warning');
                setAlertShow(true);
                setAlertBtnOk(false);
            }

            const dragItemContentAtivo = copyUnsavedCampos[dragItem.current];

            if(!copyTrash.find(x => x.id === dragItenId.current) && findCantRemove.length === 0){
                
                if(!isNaN(dragItenId.current) || dragItemContentAtivo['titulo'].trim() !== ''){

                    copyTrash.push(dragItemContentAtivo);

                    setCtrlZtoTrash([...ctrlZtoTrash, dragItemContentAtivo]);

                    const index = F.IndexOfObject(copyUnsavedCampos, 'id', dragItenId.current);

                    copyCtrlZwhat.push({toTrash: index});

                    setCtrlZwhat(copyCtrlZwhat);

                }else{

                    const copyValues = {...values};
                    delete copyValues[dragItenId.current];
                    setValues(copyValues);

                }

                copyUnsavedCampos.splice(dragItem.current, 1);

            }
        }

        else if(dragOverItem.current === 2000){
            
            const dragItemContentAtivo = copyTrash[dragItem.current];

            if(!copyUnsavedCampos.find(x => x.id === dragItenId.current)){
                copyUnsavedCampos.push(dragItemContentAtivo);

                setCtrlZfromTrash([...ctrlZfromTrash, dragItemContentAtivo]);

                const index = F.IndexOfObject(copyTrash, 'id', dragItenId.current);

                copyCtrlZwhat.push({fromTrash: index});

                setCtrlZwhat(copyCtrlZwhat);

                copyTrash.splice(dragItem.current, 1);

            }
        }

        else{

            if(copyUnsavedCampos.find(x => x.id === dragItenId.current)){

                const dragItemContent = copyUnsavedCampos[dragItem.current];
                copyUnsavedCampos.splice(dragItem.current, 1);
                copyUnsavedCampos.splice(dragOverItem.current, 0, dragItemContent);

                copyCtrlZwhat.push({position: {from: dragOverItem.current, to: dragItem.current}});
                
                setCtrlZwhat(copyCtrlZwhat);
                
            }
        }
        
        setTrashCampos(copyTrash);
        setUnsavedCampos(copyUnsavedCampos);
        dragItenId.current = null;
        dragItem.current = null;
        dragOverItem.current = null;
        
    };

    const {
        HandleScroll,
        setDocTitle,
        setErrorCon
    } = useContext(SiteContext);

    const {

        setAlertShow,
        setAlertBtnOk,
        setAlertOnClose,
        setAlertType,
        setAlertMsg,
    
    } = useContext(AlertasContext);

    const handleSetValues = (e) => {
       
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    }

    const handleSetComents = (e) => {
       
        setComments({
            ...comments,
            [e.target.name]: e.target.value,
        });
    }

    const handleRequired = (e, elem) => {
        const copyUnsavedCampos = [...unsavedCampos];
        const item = copyUnsavedCampos.find(x => x.id === elem);

        if(item) {
            item.required = item.required === 1 ? 0 : 1;
            setUnsavedCampos(copyUnsavedCampos);

            const copyCtrlZwhat = [...ctrlZwhat];

            copyCtrlZwhat.push({required: elem});

            setCtrlZwhat(copyCtrlZwhat);
        }
        e.target.blur();
    }

    const handleShowDash = (e, elem) => {
        const copyUnsavedCampos = [...unsavedCampos];
        const item = copyUnsavedCampos.find(x => x.id === elem);

        if(item) {
            item.show_dash = item.show_dash === 1 ? 0 : 1;
            setUnsavedCampos(copyUnsavedCampos);

            const copyCtrlZwhat = [...ctrlZwhat];

            copyCtrlZwhat.push({show_dash: elem});

            setCtrlZwhat(copyCtrlZwhat);
        }
        e.target.blur();
    }

    const handleFieldAdd = (e) => {
        
        const newId = `new${(unsavedCampos.length + trashCampos.length) + 1}`
        

        const objToAdd = {
            id: newId,
            titulo: '',
            required: 1,
            comentario: '',
            ...(id === 'dre' && { show_dash: 0 })
        };        

        setCtrlZadd([...ctrlZadd, objToAdd]);

        setUnsavedCampos([...unsavedCampos, objToAdd]);
        setValues(values => ({ ...values, [newId]: '' }));

        const copyCtrlZwhat = [...ctrlZwhat];

        copyCtrlZwhat.push({add: 0});

        setCtrlZwhat(copyCtrlZwhat);
        
        e.target.blur();

    }

    const handleAdd = async (e, backOnSave = true) => {
        
        e.preventDefault();

        setDisabledAdd(true);

        const data = {
            adicionar: unsavedCampos,
            remover: trashCampos
        };
        
        setIsLoading('show');
      
        try{

            const result = id === 'bal' ? await BL.UpdateFields(data) : await DRE.UpdateFields(data);

            if(result.data){

                if (result.status === 200) {

                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    setAlertType('success');
                    setAlertBtnOk(false);  
                    
                    if(backOnSave){

                        setAlertOnClose(() => () => {navigate(-1)});

                    }else{

                        setAlertOnClose(() => () => {
                            setDisabledAdd(false);
                            setTrashCampos([]);
                            setCtrlZwhat([]);
                            setCtrlZadd([]);
                            setCtrlZtoTrash([]);
                            setCtrlZfromTrash([]);  

                            if(result.data.novosCampos){
                                handleGetFields(id);
                            }
                            
                        });
                        
                    }

                }else{

                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    setAlertType('error');
                    setAlertBtnOk(false);
                    
                }

            }else{
                
                setAlertShow(true);
                setAlertMsg(TXTS.ERRO_LOGIN_2);
                setAlertType('error');
                setAlertBtnOk(false);
            
            }

        }catch{

            setAlertShow(true);
            setAlertMsg(TXTS.ERRO_LOGIN_3);
            setAlertType('error');
            setAlertBtnOk(false);
            setErrorCon(true);
            
        }

        setIsLoading('hide');

    }

    const handleSetFields = (campos) => {

        setCampos(campos);

        const copyCantRemove = [...cantRemove];
        
        campos.forEach((item) => {

            if(item.indicadoresFind.length > 0){
                Object.values(item.indicadoresFind).forEach(val => {
                    const add = {id: item.id, ...val}
                    copyCantRemove.push(add);
                  });
            }
  
          });

          setCantRemove(copyCantRemove);

    }

    const handleGetFields = async (id) => {

        setIsLoading('show');

        try{

            const result = id === 'bal' ? await BL.GetFields(0) : await DRE.GetFields(0);

            handleSetFields(result.data.campos);
            
        }catch{

        }

        setIsLoading('hide');

    }

    const handleCtrlZ = () => {
        
        if(ctrlZwhat.length === 0){
            return;
        }
        
        const what = Object.keys(ctrlZwhat[ctrlZwhat.length-1])[0];
        const whatIndex = Object.values(ctrlZwhat[ctrlZwhat.length-1])[0];
        
        const copyUnsavedCampos = [...unsavedCampos];

        const copyTrash = [...trashCampos];

        if(what === 'add'){         

            const lastCtrlZAdd = ctrlZadd[[ctrlZadd.length-1]].id;
            const itemUsanved = F.IndexOfObject(copyUnsavedCampos, 'id', lastCtrlZAdd);
            
            if(itemUsanved !== -1){
                copyUnsavedCampos.splice(itemUsanved, 1);
                setUnsavedCampos(copyUnsavedCampos);
            }

            const copyCtrlZadd = [...ctrlZadd];
            copyCtrlZadd.pop();
            setCtrlZadd(copyCtrlZadd);

            const copyValues = {...values};
            
            delete copyValues[lastCtrlZAdd];
            setValues(copyValues);          
            
        }

        else if(what === 'toTrash'){

            const copyCtrlZtoTrash = [...ctrlZtoTrash];
            const lastTrash = copyCtrlZtoTrash[copyCtrlZtoTrash.length-1];
            copyCtrlZtoTrash.pop();

            copyUnsavedCampos.splice(whatIndex, 0, lastTrash);

            const itemRemoveFromTrash = F.IndexOfObject(copyTrash, 'id', lastTrash.id);
            copyTrash.splice(itemRemoveFromTrash, 1);

            setUnsavedCampos(copyUnsavedCampos);
            setTrashCampos(copyTrash);
            setCtrlZtoTrash(copyCtrlZtoTrash);
            
        }

        else if(what === 'fromTrash'){

            const copyCtrlZfromTrash = [...ctrlZfromTrash];
            const lastTrash = copyCtrlZfromTrash[copyCtrlZfromTrash.length-1];
            copyCtrlZfromTrash.pop();

            copyTrash.splice(whatIndex, 0, lastTrash);

            const itemBackToTrash = F.IndexOfObject(copyUnsavedCampos, 'id', lastTrash.id);
            copyUnsavedCampos.splice(itemBackToTrash, 1);

            setUnsavedCampos(copyUnsavedCampos);
            setTrashCampos(copyTrash);
            setCtrlZfromTrash(copyCtrlZfromTrash);
            
        }

        else if(what === 'position'){
            const from = Object.values(ctrlZwhat)[0].position.from;
            const to = Object.values(ctrlZwhat)[0].position.to;
            
          
            const temp = copyUnsavedCampos[from];
            copyUnsavedCampos[from] = copyUnsavedCampos[to];
            copyUnsavedCampos[to] = temp;

            setUnsavedCampos(copyUnsavedCampos);
        }

        else if(what === 'required'){
            const item = copyUnsavedCampos.find(x => x.id === whatIndex);
            item.required = item.required === 1 ? 0 : 1;
            setUnsavedCampos(copyUnsavedCampos);
        }

        else if(what === 'show_dash'){
            const item = copyUnsavedCampos.find(x => x.id === whatIndex);
            item.show_dash = item.show_dash === 1 ? 0 : 1;
            setUnsavedCampos(copyUnsavedCampos);
        }
            
        const copyCtrlZwhat = [...ctrlZwhat];
        copyCtrlZwhat.pop();
        setCtrlZwhat(copyCtrlZwhat);
        
    }

    const handleKeyDow = (e) => {

        let charCode = String.fromCharCode(e.which).toLowerCase();

        if((e.ctrlKey || e.metaKey) && charCode === 's') {

            e.preventDefault();

            if(!disabledAdd){
                
                handleAdd(e, false);

            }

        }else if((e.ctrlKey || e.metaKey) && charCode === 'z') {
            
            if(e.target.type){
                return;
            }    
    
            e.preventDefault();
            
            handleCtrlZ();
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDow);
        return () => document.removeEventListener("keydown", handleKeyDow);
    });

    useEffect(() => {

        const copyUnsavedCampos = [];

        for(const key of Object.keys(campos)){

            copyUnsavedCampos.push({

                id: `${campos[key]['id']}`,
                titulo: campos[key]['titulo'],
                required: campos[key]['required'],
                comentario: campos[key]['comentario'],
                ...(id === 'dre' && { show_dash: campos[key]['show_dash'] })
        
            });

            setValues(values => ({ ...values, [campos[key]['id']]: campos[key]['titulo'] }));
            setComments(comments => ({ ...comments, [campos[key]['id']]: campos[key]['comentario'] }));
            
        }  

        setUnsavedCampos(copyUnsavedCampos);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campos]);

    useEffect(() => {
       
        if(unsavedCampos.length > 0){
            for (const [key, value] of Object.entries(values)) {
                
                const findUnsave = (unsavedCampos.find(obj => obj.id === `${key}`));
                if(findUnsave){
                    findUnsave['titulo'] = value;
                }
                
            }
        }

        setDisabledAdd(!F.CheckEmptyObj(values));
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    useEffect(() => {
       
        if(unsavedCampos.length > 0){
            for (const [key, value] of Object.entries(comments)) {
                
                const findUnsave = (unsavedCampos.find(obj => obj.id === `${key}`));
                if(findUnsave){
                    findUnsave['comentario'] = value;
                }
                
            }
        }
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comments]);

    useEffect(() => {

        if(userType === 2){
            navigate('/balancos');
            return;
        }

        setDocTitle(id === 'bal' ? TXTS.BALANCO_CAMPOS_EDIT : TXTS.DRE_CAMPOS_EDIT);
        
        if(isLogged){
            setIsLoading('hide');
            handleGetFields(id);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsLoading, isLogged, userType]);

    return (
      <motion.section 
        className="conteudo"

        variants={fade}
        initial='entrada'
        animate='animacao'
        exit='saida'
        
        onScroll={HandleScroll}
      >

        <div className='container-lg'>
          <h2 className='pageTitle'>{id === 'bal' ? TXTS.BALANCO_CAMPOS_EDIT : TXTS.DRE_CAMPOS_EDIT}</h2>
        </div>

        <div className='container container-lg'>
            <div className='row justify-content-end mx-0 px-0 mb-3'>
                        
                <div className='col col-12 col-md-3 col-xxl-2 mt-4'>
                    <Btn 
                        value='Adicionar Campo'
                        type='button'
                        iconLeft='faFileCirclePlus'
                        onClick={handleFieldAdd}
                        useLoading={true}
                    />
                </div>

            </div>

            <form className='row justify-content-start gy-3 gx-4 mt-1' onDragEnterCapture={(e) => dragEnter(e, 2000)}>
                
                {!F.isEmpty(unsavedCampos) &&

                    unsavedCampos.map( (item, key) => {
                        return(

                            <div key={key} id={item.id}
                                onDragStart={(e) => dragStart(e, key)}
                                onDragEnter={(e) => dragEnter(e, key)}
                                onDragEnd={drop}
                                draggable
                                className='col col-12 col-md-3 col-xxl-2'>
                                    <div className='p-3 border border-light border-2 rounded-2'>
                                        <Input 
                                            title={comments[item.id]}
                                            id={item.id}
                                            name={item.id}
                                            placeholder={values[item.id]}
                                            type='text'
                                            value={values[item.id]}
                                            onChange={handleSetValues}
                                            useLoading={true}
                                        />

                                        <CheckBox 
                                            label='Campo Obrigatório'
                                            onChange={(e) => {handleRequired(e, item.id)}}
                                            useLoading={true}
                                            checked={item.required === 1 ? true : false}
                                        />

                                        {id === 'dre' &&
                                             <CheckBox 
                                             label='Incluir no Extrato'
                                             onChange={(e) => {handleShowDash(e, item.id)}}
                                             useLoading={true}
                                             checked={item.show_dash === 1 ? true : false}
                                         />
                                        }
                                        
                                        <div className='mb-4'></div>
                                        <Input 
                                            name={item.id}
                                            placeholder='Comentário:'
                                            type='textarea'
                                            height={100}
                                            maxLength={100}
                                            required={false}
                                            value={comments[item.id]}
                                            onChange={handleSetComents}
                                            useLoading={true}
                                        />
                                        
                                    </div>
                            </div>
                            
                        )
                    })
                }
                
                <div 
                    onDragEnterCapture={(e) => dragEnter(e, 1000)}
                    className='row justify-content-start gy-3 gx-4 mx-auto mt-5 mb-1 py-4 trashCampos'>

                    <div className='col col-12 mt-0 mb-4' style={{pointerEvents: 'none'}}>
                        <h2 className='pageTitle sub-m'>{TXTS.BAL_DRE_ADD_EDIT_TRASH}</h2>
                    </div>

                    {!F.isEmpty(trashCampos) &&

                        trashCampos.map( (item, key) => {
                            return(

                                <div key={key} id={item.id}
                                    onDragStart={(e) => dragStart(e, key)}
                                    onDragEnter={(e) => dragEnter(e, key)}
                                    onDragEnd={drop}
                                    draggable
                                    className='col col-12 col-md-3 col-xxl-2'>
                                        <div className='p-3 border border-light border-2 rounded-2'>
                                            <Input 
                                                id={item.id}
                                                name={item.id}
                                                placeholder={values[item.id]}
                                                title={comments[item.id]}
                                                type='text'
                                                value={values[item.id]}
                                                onChange={handleSetValues}
                                                useLoading={true}
                                            />
                                        </div>
                                </div>
                                
                            )
                        })
                    }
                </div>

                <div className='row justify-content-center mx-0 px-0 mb-3'>
                    
                    <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                        <Btn 
                            value='Voltar'
                            type='button'
                            btnType='primary'
                            onClick={() => {navigate('/balancos')}}
                            useLoading={true}
                        />
                    </div>

                    <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                        <Btn 
                            value='Salvar'
                            disabled={disabledAdd}
                            type='submit'
                            btnType='success'
                            onClick={handleAdd}
                            useLoading={true}
                        />
                    </div>

                </div>
               
            </form>

            
        </div>
        <div className='logoBotton'>
            <img src={LOGO_AUDIT} alt="AUDIT" className='auditLayout'/> 
        </div>
      </motion.section>
    );
}

export default CamposAdd;