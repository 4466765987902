import './index.css';
import React, { useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import { animacao } from '../../util/AnimatedDiv';
import { Parallax } from '../../util/Functions';
import { SPACE } from '../../util/Urls';
import { LoaderContext } from '../../contexts/LoaderContext';
import * as TXTS from '../../util/Texts';
import { SiteContext } from '../../contexts/SiteContext';


function PageNotFound() {

    const { setIsLoading }  = useContext(LoaderContext);
    const { setDocTitle }   = useContext(SiteContext);
    
    useEffect(() => {

      setDocTitle(TXTS.NOTFOUND_TITLE);

      setTimeout(() => {
        setIsLoading('hide');
      }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsLoading]);

    return (
      <motion.section 
        className="PageNotFound conteudo"

        variants={animacao}
        initial='entrada'
        animate='animacao'
        exit='saida'

        onMouseMove={Parallax}
        onMouseLeave={Parallax}
      >

      <h2 data-value={3} className='paraObj'>404</h2>
      <h4 data-value={4} className='paraObj'>Opps... Página não Encontrada</h4>
      <div data-value={20} className='paraObj notFoundBack' style={{backgroundImage: `url(${SPACE})`}}>

      </div>
      
      </motion.section>
    );
}

export default PageNotFound;