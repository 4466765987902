import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
    name: 'user',
    initialState: {
        isLogged: false,
        us_id: '',
        us_tk: '',
        name: '',
        email: '',
        type: 0,
        company_id: 0,
        logoClient: '',
        hasLogo: false,
        primeiro_acesso: false
        
    },
    reducers: {
        setIsLogged(state, { payload }){
            return { ...state, isLogged: payload }
        },
        setUserId(state, { payload }){
            return { ...state, us_id: payload }
        },
        setUserTk(state, { payload }){
            return { ...state, us_tk: payload }
        },
        setUserName(state, { payload }){
            return { ...state, name: payload }
        },
        setUserEmail(state, { payload }){
            return { ...state, email: payload }
        },
        setUserType(state, { payload }){
            return { ...state, type: payload }
        },
        setUserCompany(state, { payload }){
            return { ...state, company_id: payload }
        },
        setLogoClient(state, { payload }){
            return { ...state, logoClient: payload }
        },
        setHasLogo(state, { payload }){
            return { ...state, hasLogo: payload }
        },
        setPrimeiroAcesso(state, { payload }){
            return { ...state, primeiro_acesso: payload }
        }
    }
});

export const { 
    setIsLogged,  
    setUserId, 
    setUserTk,
    setUserName, 
    setUserEmail,
    setUserType,
    setLogoClient, 
    setHasLogo,
    setUserCompany,
    setPrimeiroAcesso
} = slice.actions

export default slice.reducer