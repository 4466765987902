import './index.css';
import React, { useState, useRef, useEffect, useContext } from 'react';
import { BASE_BACK } from '../../util/Urls';
import { ValidLogo } from '../../util/Functions';
import * as TXTS from '../../util/Texts';
import * as UserService from '../../services/User.service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faRepeat, faPlus } from '@fortawesome/free-solid-svg-icons';
import { AlertasContext } from '../../contexts/AlertasContext';
import { UserContext } from '../../contexts/UserContext';
import { SiteContext } from '../../contexts/SiteContext';

function LogoIDR({

    hoverSlides = false,
    uploadSlides = false,
    className,
    alt

}) {

    const { 
        logoIdr, setLogoIdr,
        hasLogoIdr, setHasLogoIdr 
    } = useContext(SiteContext);

    const { userId }                        = useContext(UserContext);
    const [imgReady, setImgReady]           = useState(false);
    const [selectedFile, setSelectedFile]   = useState(null);
    const fileInputRef                      = useRef();

    const {

        setAlertShow,
        setAlertBtnOk,
        setAlertType,
        setAlertMsg

    } = useContext(AlertasContext);

    const handleIdrLogo = (event) => {

        const validar = ValidLogo(event)
        if(validar !== ''){

            setAlertShow(true);
            setAlertMsg(validar);
            setAlertType('error');
            setAlertBtnOk(false);

            if (fileInputRef) fileInputRef.current.value = null;
            setSelectedFile(null);
            return false;
        }

        setSelectedFile(event.target.files[0]);
    }

    useEffect(() => {
        const handleUpLoad = async () => {
            
            const formData = new FormData();
            formData.append('file', selectedFile);
            
            setImgReady(false);

            try{

                const result = await UserService.ChangeLogo(formData);

                if(result.data){
                    if (result.status === 200) {
                        const logoIdr = result.data.logo ? `${BASE_BACK}${result.data.logo}` : '/img/semlogo.png';
                    
                        const img = new Image();
                        
                        img.onload = () => {
                            
                            setLogoIdr(logoIdr);
                            setHasLogoIdr(true);

                            setTimeout(() => {
                                setImgReady(true);
                            }, 300);
                        }

                        img.onerror = () => {

                            setLogoIdr('/img/semlogo.png');
                            setHasLogoIdr(false);

                        }

                        img.src = logoIdr;

                    }else{

                        setImgReady(true);
                        setAlertShow(true);
                        setAlertMsg(result.data.msg);
                        setAlertType('error');
                        setAlertBtnOk(false);
                    
                    }
                }
                else{

                    setImgReady(true);
                    setAlertShow(true);
                    setAlertMsg(TXTS.IMG_LOAD_ERROR);
                    setAlertType('error');
                    setAlertBtnOk(false);

                }

            }catch{

                setImgReady(true);
                setAlertShow(true);
                setAlertMsg(TXTS.ERRO_LOGIN_3);
                setAlertType('error');
                setAlertBtnOk(false);

            }
            setSelectedFile(null);
        }

        if(selectedFile !== null)
        handleUpLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFile, setAlertBtnOk, setAlertMsg, setAlertShow, setAlertType, setHasLogoIdr, setLogoIdr]);

    useEffect(() => {
        setImgReady(true);
    }, [hasLogoIdr]);

    return(

        <div className={`logoIdr ${hoverSlides ? 'hoverSlides' : ''} ${uploadSlides ? 'uploadSlides' : ''}`}>
            {imgReady ?
                    <>
                        {userId === 1 && !hoverSlides &&
                            <input type='file' id='logoIdr' name='logoIdr' onChange={handleIdrLogo} ref={fileInputRef}/>
                        }

                        <label htmlFor='logoIdr' className='logoIdr' style={{cursor: userId === 1 ? 'pointer' : 'default'}}>
                            {hasLogoIdr &&
                                <img src={logoIdr} alt={alt} className={className}/>
                            }
                            {!hasLogoIdr && userId === 1 &&
                                <img src='/img/semlogo.png' alt={alt} className={className}/>
                            }
                        </label>

                        {userId === 1 && !hoverSlides &&

                            <div className='logoChange'>
                                
                                <FontAwesomeIcon icon={hasLogoIdr ? faRepeat : faPlus}/>
                                {hasLogoIdr ? TXTS.LOGO_IDR_CHANGE : TXTS.LOGO_IDR_ADD}
                        
                            </div>
                            
                        }                        
                    </>
                    :
                    <><FontAwesomeIcon icon={faSpinner} spin color="lightblue"/></>
                }
        
        </div>
       
    )
}

export default LogoIDR;