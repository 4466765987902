import './index.css';
import React, { useEffect, useContext, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { animacao } from '../../util/AnimatedDiv'
import Input from '../../components/Forms/Input';
import Btn from '../../components/Forms/Btn';
import Select from '../../components/Forms/Select';
import CheckBox from '../../components/Forms/CheckBox';
import UserLogo from '../../components/User/UserLogo';
import { LoaderContext } from '../../contexts/LoaderContext';
import { SiteContext } from '../../contexts/SiteContext';
import { AlertasContext } from '../../contexts/AlertasContext';
import { UserContext } from '../../contexts/UserContext';
import { CompanyContext } from '../../contexts/CompanyContext';
import * as ES from '../../services/Enderecos.service';
import * as US from '../../services/User.service';
import * as CS from '../../services/Company.service';
import * as TXTS from '../../util/Texts';
import * as F from '../../util/Functions';
import { LOGO_AUDIT } from '../../util/Urls';
import SubUsersList from '../../components/SubUsers/list';

function EditUser() {
  
  const { id }                            = useParams();

  const [userId, setUserId]               = useState(isNaN(id) || Number(id) === 0 ? 0 : id);
  
  const navigate                          = useNavigate();

  const [firstLoad, setFirstload]         = useState(true);

  const [disabledSave, setDisabledSave]   = useState(true);

  const { setIsLoading }                  = useContext(LoaderContext);

  const { 

    isLogged,
    userType,
    userTypes,
    ufs

  } = useContext(UserContext);

  const { 
    
    HandleScroll,
    setDocTitle,
    setErrorCon,
    canLoad
  
  } = useContext(SiteContext);

  const {

    setAlertShow,
    setAlertMsg,
    setAlertType,
    setAlertBtnOk,
    setAlertBtnCancel,
    setAlertBtnOkTxt,
    setAlertOnOk,
    setAlertOnClose,
    handleCloseAlert,
    setAlertStyle,
    setToastPosition
  
  } = useContext(AlertasContext);

  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userTypeL, setUserTypeL] = useState(2);
  const [userLogo, setUserLogo] = useState(null);
  
  const [senha, setSenha] = useState(userId === 0 ? F.GenPassword(10) : '');

  const [iconCopy, setIconCopy] = useState('faCopy');

  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const [showCopyError, setShowCopyError]     = useState(false);

  const [userValues, setUserValues] = useState({

    email: userEmail,
    name: userName,
    type: userTypeL,
    senha: senha,

  });

  const [subUsers, setSubUsers] = useState([]);

  const handleUserValues = (e) => {

    setUserValues({
        ...userValues,
        [e.target.name]: e.target.value,
    });

  };
  
  const handleCancelUser = () => {
        
    setUserValues({

        email: userEmail,
        name: userName,
        type: userTypeL,
        senha: senha,

    });

    setCompanyValues({
      ...companyValues,
      type: userTypeL === 1 ? 2 : 1
    });

  };

  const {

    companyTpessoaOpt,
    companyColor

  } = useContext(CompanyContext);

  const [companyId, setCompanyId]                     = useState(0);
  const [companyName, setCompanyName]                 = useState('');
  const [companyNameFantasia, setCompanyNameFantasia] = useState('');
  const [companyType, setCompanyType]                 = useState(1);
  const [companyCnpjCpf, setCompanyCnpjCpf]           = useState('');
  const [companyIeRg, setCompanyIeRg]                 = useState('');
  const [companyTpessoa, setCompanyTpessoa]           = useState(2);  
  const [companyColorL, setCompanyColorL]             = useState(companyColor);
  const [companyPrimeFatura, setCompanyPrimeFatura]   = useState(F.MesAno());

  const [segmentos, setSegmentos]                     = useState([]);
  const [selSeg, setSelSeg]                           = useState([]);
  const [newSegmentos, setNewSegmentos]               = useState([]);

  const [tiposLoc, setTiposLoc]                       = useState([]);
  const [selLoc, setSelLoc]                           = useState([]);
  const [newTiposLoc, setNewTiposLoc]                 = useState([]);

  const handleSegmentos = (selValue) => {
    
    const newSelected = F.CheckMultiSel(selValue, selSeg);

    if(!newSelected) {

      return;

    }
    
    setSelSeg(newSelected);

  }

  const handleAddSegmento = (val, id) => {

    const copyNewSegmentos = [...newSegmentos];
    
    copyNewSegmentos.push({
        id: id,
        nome: val
    });
    
    setNewSegmentos(copyNewSegmentos);

  }

  const handleTiposLoc = (selValue) => {
    
    const newSelected = F.CheckMultiSel(selValue, selLoc);

    if(!newSelected) {

      return;

    }

    setSelLoc(newSelected);

  }

  const handleAddTiposLoc = (val, id) => {

    const copyNewTiposLoc = [...newTiposLoc];
    
    copyNewTiposLoc.push({
        id: id,
        nome: val
    });
    
    setNewTiposLoc(copyNewTiposLoc);

  }

  const [companyValues, setCompanyValues] = useState({

    razaoSocial_nomeCompleto: companyName,
    nomeFantasia_apelido: companyNameFantasia,
    type: companyType,
    cnpj_cpf: companyCnpjCpf,
    ie_rg: companyIeRg,
    type_pessoa: companyTpessoa,
    primeiro_faturamento: companyPrimeFatura

  });

  const handleCompanyValues = (e) => {
    
    setCompanyValues({
        ...companyValues,
        [e.target.name]: e.target.value,
    });

  };

  const handleCancelCompany = () => {
       
    setCompanyValues({

      razaoSocial_nomeCompleto: companyName,
      nomeFantasia_apelido: companyNameFantasia,
      type: companyType,
      cnpj_cpf: companyCnpjCpf,
      ie_rg: companyIeRg,
      type_pessoa: companyTpessoa,
      primeiro_faturamento: companyPrimeFatura

    });

  }; 

  const handleCnpjCpf = async () => {

      const length = companyValues.type_pessoa === 1 ? 11 : 14;

      const msgCaracteres = companyValues.type_pessoa === 1 ? TXTS.CPF_CARACTERES : TXTS.CNPJ_CARACTERES;

      const msgObrigatorio = companyValues.type_pessoa === 1 ? TXTS.CPF_OBRIGATORIO : TXTS.CNPJ_OBRIGATORIO;

      if(companyValues.cnpj_cpf.length !== length || companyValues.cnpj_cpf.length === 0){
          setAlertShow(true);
          setAlertBtnOk(false);
          setAlertType('warning');
          setAlertStyle(2);
          setToastPosition('top-right');
          setAlertMsg(companyValues.cnpj_cpf.length === 0 ? msgObrigatorio : msgCaracteres);
          return;
      }

      const data = {
          cnpj_cpf: companyValues.cnpj_cpf,
          type_pessoa: companyValues.type_pessoa,
          id: userId
      }
      setIsLoading('show');

      try{

          const result =  await CS.CheckCnpjCpf(data);
          

          if(result.data && result.status === 200){

              setCompanyValues({
                  ...companyValues,
                  razaoSocial_nomeCompleto: result.data.dados.razaoSocial_nomeCompleto ?? ''
              });

              if(result.data.endereco !== null){
                  
                  setEnderecoValues({
                    ...enderecoValues,
                    cep: result.data.endereco.cep ?? '',
                    logradouro: result.data.endereco.logradouro ?? '',
                    bairro: result.data.endereco.bairro ?? '',
                    estado_id: result.data.endereco.estado_id ?? 0
                  });

                  setCidadeCep(result.data.endereco.cidade_id ?? 0);
              }
          }else{
              setAlertShow(true);
              setAlertBtnOk(false);
              setAlertType('warning');
              setAlertStyle(2);
              setToastPosition('top-right');
              setAlertMsg(result.data.msg);
          }
          
      }catch{
          
      }

      setIsLoading('hide');
  }

  const [enderecoId, setEnderecoId]   = useState(0);
  const [cidadeCep, setCidadeCep]     = useState(0);
  const [cidades, setCidades]         = useState([]);
  const [cep, setCep]                 = useState('');
  const [logradouro, setLogradouro]   = useState('');
  const [numero, setNumero]           = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro]           = useState('');
  const [estadoId, setEstadoId]       = useState(0);
  const [cidadeId, setCidadeId]       = useState(0);

  const [enderecoValues, setEnderecoValues] = useState({

    cep: cep,
    logradouro: logradouro,
    numero: numero,
    complemento: complemento,
    bairro: bairro,
    estado_id: estadoId,
    cidade_id: cidadeId

  });

  const handleEndercoValues = (e) => {

    setEnderecoValues({
        ...enderecoValues,
        [e.target.name]: e.target.value,
    });

  };

  const handleUf = async (value) => {

    setIsLoading('show');
    setCidades([]);
    setEnderecoValues(enderecoValues => ({ ...enderecoValues, cidade_id: 0 }));

    try{

      const result =  await ES.GetCidades(value);
      

      if(result.data && result.status === 200){

        setCidades(result.data.cidades);

      }
      

    }catch{
      
    }

    setIsLoading('hide');
  };

  const handleConsultaCep = async () => {
    const cep = enderecoValues.cep;
    
    if(cep !== '' && cep.length === 8){

      setIsLoading('show');

      try{

        const result =  await ES.consultaCep(cep);
        
        
        if(result.data && result.status === 200 && result.data.endereco !== null){
  
          setEnderecoValues({
            ...enderecoValues,
            logradouro: result.data.endereco.logradouro ?? '',
            estado_id: result.data.endereco.estado_id ?? 0,
            bairro: result.data.endereco.bairro ?? '',
          });

          setCidadeCep(result.data.endereco.cidade_id ?? 0);
            
        }else{

            setEnderecoValues({
              ...enderecoValues,
              logradouro: '',
              estado_id: 0,
              cidade_id: 0,
              bairro: '',
            });

            setCidades([]);
            setCidadeCep(0);

        }
        
  
      }catch{
        
      }

      setIsLoading('hide');
    }
  };

  const handleSetUF = (val) => {

    if(val === 0) {
      return;
    }

    setEnderecoValues({
      ...enderecoValues,
      estado_id: val,
    });

  }  

  const handleSetCidade = (val) => {

    if(val === 0) {
      return;
    }

    setEnderecoValues({
      ...enderecoValues,
      cidade_id: val,
    });

  } 

  const handleCancelEndereco = () => {

    setEnderecoValues({
      ...enderecoValues, 
      cep: cep,
      logradouro: logradouro,
      numero: numero,
      complemento: complemento,
      bairro: bairro,
      estado_id: estadoId

    });

    if(cidadeId === 0){
      setCidades([]);
    }

    if(enderecoValues.cidade_id !== cidadeId){
      
      setEnderecoValues(enderecoValues => ({ ...enderecoValues, cidadeId }));
      
    }
  }; 

  useEffect(() => {
    if(enderecoValues.estado_id !== '' && enderecoValues.estado_id !== 0 && !firstLoad){
      handleUf(enderecoValues.estado_id);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enderecoValues.estado_id]);


  useEffect(() => {

    if(cidadeCep !== 0 && cidades.length > 0){
      setTimeout(() => {
        setEnderecoValues({
          ...enderecoValues,
          cidade_id: cidadeCep,
        });

        setCidadeCep(0);
      }, 100);
    }

    
    if(estadoId !== enderecoValues.estado_id){

      setEnderecoValues(enderecoValues => ({ ...enderecoValues, cidade_id: 0 }));
      
    }else{
      if(cidades.length > 0 && cidadeId !== 0){

        setEnderecoValues(enderecoValues => ({ ...enderecoValues, cidade_id: cidadeId }));

      }
    }
    
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cidades]);

  const assinaturas = [

    {
      label: 'Ativa',
      id: 1
    },

    {
        label: 'Inativa',
        id: 0
    }

  ];

  const [assATivo, setAssATivo]             = useState(1);
  const [valueAssAtivo, setValueAssAtivo]   = useState(1);

  const [valueAssRenova, setValueAssRenova] = useState(1);
  const [assRenova, setAssRenova]           = useState(1);  

  const [datasAss, setDatasAss] = useState({

    ini: F.DiaMesAno(),
    fim: F.DiaMesAno()

  });

  const [valueDatasAss, setValueDatasAss] = useState({

    ini: F.DiaMesAno(),
    fim: F.DiaMesAno()

  });

  const handleApllyDatasAss = (ini, fim) => {


    setDatasAss({

      ini: ini,
      fim: fim

    });

    setValueDatasAss({
      ini: ini,
      fim: fim
    });

  }

  const [planos, setPlanos] = useState([]);

  const [plano, setPlano] = useState(0);
  const [valuePlano, setValuePlano] = useState(0);

  const handleAssRenova = () => {

    setValueAssRenova(valueAssRenova === 1 ? 0 : 1);

  }

  const handleAssIni = (e) => {

    if(e.target.value === ''){
      return;
    }

    const AssFim = F.AddMeses(e.target.value, planos.find(obj => obj.id === valuePlano).duracao);

    setValueDatasAss({
      ...valueDatasAss,
      ini: e.target.value,
      fim: AssFim
    });
    
  }

  const handleSetPlano  = (planoId) => {

    if(planoId === 0){
      return;
    }

    setValuePlano(planoId);

    const AssFim = F.AddMeses(valueDatasAss.ini, planos.find(obj => obj.id === planoId).duracao);

    setValueDatasAss({
      ...valueDatasAss,
      fim: AssFim
    });

  }

  const handleCancelAss = () => {

    setValueAssAtivo(assATivo);
    setValuePlano(plano);

    setValueDatasAss({
      ini: datasAss.ini,
      fim: datasAss.fim
    });

    setValueAssRenova(assRenova);

  }

  const [periodoFim, setPeriodoFim] = useState('');

  const periodoIni = useMemo(() => {
    
    if(valuePlano === 0){
      setPeriodoFim('');
      return '';
    }

    if(valueDatasAss.ini !== valueDatasAss.fim){
      setPeriodoFim(F.DiaMesAnoS(valueDatasAss.fim));
    }else{
      setPeriodoFim('');
    }

    return F.DiaMesAnoS(valueDatasAss.ini);
    
  }, [valueDatasAss, valuePlano]);

  const handleApplyUser = (user) => {

    setUserEmail(user.email ?? '');
    setUserName(user.name ?? '');
    setUserTypeL(user.type ?? 2);
    setUserLogo(user.logo);
    setUserId(user.id);
    setSenha('');

    setUserValues({
      ...userValues,
      email: user.email ?? '',
      name: user.name ?? '',
      type: user.type ?? 2,
      senha: '',
    });

    setAssATivo(user.assAtivo ?? 0);
    setValueAssAtivo(user.assAtivo ?? 0);

    setPlano(user.planoId ?? 0);
    setValuePlano(user.planoId ?? 0);

    setValueAssRenova(user.renovaAuto ?? 0);
    setAssRenova(user.renovaAuto ?? 0);
    
  }

  const handleApplyCompany = (company) => {

    console.log(company.primeiro_faturamento);
    setCompanyName(company.razaoSocial_nomeCompleto ?? '');
    setCompanyNameFantasia(company.nomeFantasia_apelido ?? '');
    setCompanyType(company.type ?? 1);
    setCompanyCnpjCpf(company.cnpj_cpf ?? '');
    setCompanyIeRg(company.ie_rg ?? '');
    setCompanyTpessoa(company.type_pessoa ?? 2);
    setCompanyId(company.id);
    setCompanyPrimeFatura(company.primeiro_faturamento ?? F.MesAno());

    if(company.color){
      setCompanyColorL(company.color);
    }

    setCompanyValues({

      razaoSocial_nomeCompleto: company.razaoSocial_nomeCompleto ?? '',
      nomeFantasia_apelido: company.nomeFantasia_apelido ?? '',
      type: company.type ?? 1,
      cnpj_cpf: company.cnpj_cpf ?? '',
      ie_rg: company.ie_rg ?? '',
      type_pessoa: company.type_pessoa ?? 2,
      primeiro_faturamento: company.primeiro_faturamento ?? F.MesAno()

    });

    if(company.endereco){

      const endereco = company.endereco;

      handleApplyEnd(endereco);

    }

  }

  const handleApplyEnd = (endereco) => {

    setCep(endereco.cep ?? '');
    setLogradouro(endereco.logradouro ?? '');
    setNumero(endereco.numero ?? '');
    setComplemento(endereco.complemento ?? '');
    setBairro(endereco.bairro ?? '');
    setEstadoId(endereco.estado_id ?? 0);
    setCidadeId(endereco.cidade_id ?? 0);

    setEnderecoId(endereco.id);

    setEnderecoValues({

      cep: endereco.cep ?? '',
      logradouro: endereco.logradouro ?? '',
      numero: endereco.numero ?? '',
      complemento: endereco.complemento ?? '',
      bairro: endereco.bairro ?? '',
      estado_id: endereco.estado_id ?? 0,
      cidade_id: endereco.cidade_id ?? 0

    });

  }

  const handleGetUser = async(id) => {
        
        setIsLoading('show');

        try {
            
            const result = await US.GetUser(id);

            if(result.data){
                
                if (result.status === 200) {
                  
                  setCidades(result.data.cidades);

                  setSegmentos(result.data.segmentos);
                  setTiposLoc(result.data.tiposLoc);

                  if(result.data.segmentosCompany){
                    setSelSeg(result.data.segmentosCompany);
                  }

                  if(result.data.tiposLocCompany){
                    setSelLoc(result.data.tiposLocCompany);
                  }

                  handleApllyDatasAss(result.data.assIni, result.data.assFim);

                  if(result.data.user){

                    const user = result.data.user;

                    handleApplyUser(user);

                    setSubUsers(result.data.subUsers);

                    if(result.data.user.company){

                      const company = result.data.user.company;

                      handleApplyCompany(company);
                      
                    }
                  }

                  setPlanos(result.data.planos);

                }else{
                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    setAlertType('error');
                    setAlertBtnOk(false);
                    setAlertOnClose(() => () => {navigate('/users')});
                }
            }else{
                
                setAlertShow(true);
                setAlertMsg(TXTS.ERRO_LOGIN_2);
                setAlertType('error');
                setAlertBtnOk(false);
                setAlertOnClose(() => () => {navigate('/users')});
            
            }
            
        }catch {

            setAlertShow(true);
            setAlertMsg(TXTS.ERRO_LOGIN_3);
            setAlertType('error');
            setAlertBtnOk(false);
            setErrorCon(true);
            setAlertOnClose(() => () => {navigate('/')});
        
        }
        
        setIsLoading('hide');

        setTimeout(() => {
          setFirstload(false);
        }, id === 0 ? 0 : 500);
  };

  const handleUpdate = async (e) => {

    if(disabledSave){
      return;
    }

    e.preventDefault();

    setIsLoading('show');
    
    const data = {

      user: {
        id: userId,
        email: userValues.email.trim(),
        name: userValues.name.trim(),
        senha: userValues.senha.trim(),
        type: userValues.type
      },

      company: {...companyValues, id: companyId},

      endereco: {...enderecoValues, id: enderecoId},

      assinatura: {

        ativo: valueAssAtivo,
        renova: valueAssRenova,
        datas: valueDatasAss

      },

      plano: valuePlano,
      segmentos: selSeg,
      newSegmentos: newSegmentos,
      tiposLoc: selLoc,
      newTiposLoc: newTiposLoc

    }

    try{

      const result = await US.SaveUser(data);

      if(result.data){

        if (result.status === 200) {

          let msg = '';

          if(result.data.erros.length === 0){
            msg = result.data.msg;

          }else{
            const erros = result.data.erros;
            erros.forEach( (item, index) => {
              msg += `${item} ${index < (erros.length - 1) ? '<br>':''}`;
            });
          }

          setAlertShow(true);
          setAlertMsg(msg);
          setAlertType('success');
          setAlertBtnOk(false);

          if(result.data.newUser){
            
            const user = result.data.newUser;
            handleApplyUser(user);

          }

          if(result.data.newCompany){
            
            const company = result.data.newCompany;
            handleApplyCompany(company);

          }

        }else{
            setAlertShow(true);
            setAlertMsg(result.data.msg);
            setAlertType('error');
            setAlertBtnOk(false);
            
        }

      }else{
                
          setAlertShow(true);
          setAlertMsg(TXTS.ERRO_LOGIN_2);
          setAlertType('error');
          setAlertBtnOk(false);
      
      }

    }catch{

        setAlertShow(true);
        setAlertMsg(TXTS.ERRO_LOGIN_3);
        setAlertType('error');
        setAlertBtnOk(false);
        setErrorCon(true);
        setAlertOnClose(() => () => {navigate('/')});

    }

    setIsLoading('hide');

  }

  const handleUserType = (value) => {

    if(value === userValues.type){
      return;
    }

    const name = userValues.name;

    setAlertShow(true);
    setAlertType('warning');

    setAlertBtnCancel(true);
    setAlertBtnOkTxt('Sim');
    setAlertOnOk(() => () => {

      setUserValues({
        ...userValues,
        type: value,
      });

      setCompanyValues({
        ...companyValues,
        type: value === 1 ? 2 : 1
      });

      handleCloseAlert();

    });

    let msg = '';
    let cr = '';

    if(value === 1 && userValues.type === 2){
      
      msg = 'Conceder permissões de administrador';
      cr = 'a';
      
    }

    if(value === 2 && userValues.type === 1){
      
      msg = 'Remover permissões de administrador';
      cr = 'de';
      
    }

    setAlertMsg(`${msg} ${name !== '' ? `${cr} ${name}` : ''} ?`);

  }

  const handleCopyPass = () => {
    F.CopyTextToClipboard(userValues.senha)
    .then(() => {
      setIconCopy('faCheck');
      setShowCopySuccess(true);
    })
    .catch((err) => {
      setIconCopy('faCircleExclamation');
      setShowCopyError(true);
    });

    setTimeout(() => {
      setIconCopy('faCopy');
      setShowCopySuccess(false);
      setShowCopyError(false);
    }, 2000);
  }
  
  useEffect(() => {
    
    const length = companyValues.type_pessoa === 1 ? 11 : 14;

    if(
      userValues.email === ''
      || userValues.name === ''
      || companyValues.cnpj_cpf === ''
      || companyValues.cnpj_cpf.length !== length
      || companyValues.cnpj_cpf.length === 0
      || companyValues.razaoSocial_nomeCompleto === ''
      || enderecoValues.cep === ''
      || enderecoValues.estado_id === 0 || enderecoValues.cidade_id === 0
      || enderecoValues.logradouro === ''
      || enderecoValues.bairro === '' || valuePlano === 0
      || selSeg.length === 0 || selLoc.length === 0
      ){

      setDisabledSave(true);

    }else{

      setDisabledSave(false);

    }

  }, [userValues, companyValues, enderecoValues, valuePlano, selSeg, selLoc]);

  useEffect(() => {
    setDocTitle(userId === 0 ? TXTS.USERS_ADD_TITLE : TXTS.USERS_EDIT_TITLE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  useEffect(() => {

    if(isLogged && canLoad === 'users'){

      if(userType === 1){

        handleGetUser(userId);

        setTimeout(() => {
          setIsLoading('hide');
        }, 500);

      }else{

        navigate(-1);

      }
      
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, canLoad, userType]);



    return (
      <motion.section 
        className="conteudo"

        variants={animacao}
        initial='entrada'
        animate='animacao'
        exit='saida'
        
        onScroll={HandleScroll}
      >
        <div className='container-lg'>
          <h2 className='pageTitle'>{userId === 0 ? TXTS.USERS_ADD_TITLE : TXTS.USERS_EDIT_TITLE}</h2>
        </div>
        
        <form className='container container-lg'>
          
          <div className='col col-12 mt-4 mb-0 mt-md-0 mb-md-3'>
            <h2 className='pageTitle sub'>Usuário:</h2>
          </div>

          <div className='row justify-content-center'>
            
            <div className='col col-12 col-md-3 col-xl-2 mt-4 mt-md-0'>
                <Select 
                  placeholder='Tipo:'
                  data={userTypes}
                  search={false}
                  value={userValues.type}
                  useLoading={true}
                  onClick={(selValue) => {handleUserType(selValue)}}
                />
            </div>
            
            <div className='col col-12 col-md-4 col-xxl-3 mt-4 mt-md-0'>
              <Input 
                  name='name'
                  labelLeft='faIdBadge'
                  placeholder='Nome:'
                  type='text'
                  required={true}
                  value={userValues.name}
                  onChange={handleUserValues}
                  useLoading={true}
              />
            </div>

            <div className='col col-12 col-md-4 col-xxl-3 mt-4 mt-md-0'>
              <Input 
                  name='email'
                  labelLeft='faEnvelope'
                  placeholder='E-mail:'
                  type='email'
                  required={true}
                  value={userValues.email}
                  onChange={handleUserValues}
                  useLoading={true}
              />
            </div>

            <div className='col col-12 col-md-5 col-xxl-3 mt-4 mt-xxl-0 position-relative'>

                {showCopySuccess &&
                  <div className="alert alert-success position-absolute z-2 top-50 start-50 translate-middle" role="alert">
                    Copiado com sucesso!
                  </div>
                }

                {showCopyError &&
                  <div className="alert alert-danger position-absolute z-2 top-50 start-50 translate-middle" role="alert">
                    Erro ao copiar!
                  </div>
                }
                
              <Input 
                name='senha'
                labelLeft='faLock'
                {...(userValues.senha !== '' && { labelRightOnClick: handleCopyPass, labelRight: iconCopy })}
                {...(userId === 0 && { readOnly: true })}
                placeholder={`Senha${userId !== 0 ? ' (Deixe em branco para não alterar)':''}:`}
                type='text'
                required={userId === 0 ? true : false}
                value={userValues.senha}
                onChange={handleUserValues}
                useLoading={true}
              />
            </div>

            <div className='row justify-content-center mx-0 px-0'>

              <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                <Btn 
                    value='Cancelar'
                    type='button'
                    btnType='danger'
                    onClick={handleCancelUser}
                    useLoading={true}
                />
              </div>

            </div>
            
          </div>

          <hr></hr>

          <div className='col col-12 mt-4 mb-0 mt-md-0 mb-md-3'>
            <h2 className='pageTitle sub'>Empresa:</h2>
          </div>

          <div className='row justify-content-start'>

            {companyType !== 2 && userId !== 0 &&
              <div className='col-12 col-md-3 mt-4 mt-md-0 mb-4 mb-md-0'>
                <UserLogo 
                  edit={true}
                  logo={userLogo}
                  name={userName}
                  color={companyColorL}
                />
              </div>
            }

            <div className={`col-12 ${companyType !== 2 && userId !== 0 ? 'col-md-9' : 'col-md-12'} order-md-first mx-0 px-0`}>

              <div className='row mx-0 px-0'>
                <div className='col col-12 col-md-3 mt-4 mt-md-0'>
                  <Select 
                    placeholder='Tipo Pessoa:'
                    data={companyTpessoaOpt}
                    value={companyValues.type_pessoa}
                    useLoading={true}
                    onClick={(selValue) => {setCompanyValues({
                      ...companyValues,
                      type_pessoa: selValue,
                    })}}
                  />
                </div>

                <div className='col col-12 col-md-4 mt-4 mt-md-0'>
                  <Input 
                      name='cnpj_cpf'
                      placeholder={companyValues.type_pessoa === 2 ? 'CNPJ:' : 'CPF:'}
                      type='number'
                      required={true}
                      mask={companyValues.type_pessoa === 2 ? '99.999.999/9999-99' : '999.999.999-99'}
                      value={companyValues.cnpj_cpf}
                      onChange={handleCompanyValues}
                      onBlur={()=>{handleCnpjCpf(companyValues)}}
                      useLoading={true}
                  />
                </div>

                <div className='col col-12 col-md-4 mt-4 mt-md-0'>
                  <Input 
                      name='ie_rg'
                      placeholder={companyValues.type_pessoa === 2 ? 'Inscrição Estadual:' : 'RG:'}
                      type='number'
                      required={false}
                      mask={companyValues.type_pessoa === 2 ? '999.999.999.999' : ''}
                      value={companyValues.ie_rg}
                      onChange={handleCompanyValues}
                      useLoading={true}
                  />
                </div>

                <div className='col col-12 col-md-6 mt-4'>
                  <Input 
                      name='razaoSocial_nomeCompleto'
                      placeholder={companyValues.type_pessoa === 2 ? 'Razão Social:' : 'Nome:'}
                      type='text'
                      required={true}
                      value={companyValues.razaoSocial_nomeCompleto}
                      onChange={handleCompanyValues}
                      useLoading={true}
                  />
                </div>

                <div className='col col-12 col-md-6 mt-4'>
                  <Input 
                      name='nomeFantasia_apelido'
                      placeholder={companyValues.type_pessoa === 2 ? 'Nome Fantasia:' : 'Apelido:'}
                      type='text'
                      required={false}
                      value={companyValues.nomeFantasia_apelido}
                      onChange={handleCompanyValues}
                      useLoading={true}
                  />
                </div>
                
                <div className='col col-12 col-md-3 mt-4'>
                  <Select 
                    placeholder='Segmentos:'
                    data={segmentos}
                    dataSelected={selSeg}
                    showSelected={true}
                    allowAdd={true}
                    handleAdd={(val, id) => {handleAddSegmento(val, id)}}
                    maxLength={100}
                    minLength={2}
                    useLoading={true}
                    required={true}
                    onClick={(selValue) => {handleSegmentos(selValue)}}
                  />
                </div>

                <div className='col col-12 col-md-3 mt-4'>
                  <Select 
                    placeholder='Tipo de Locação:'
                    data={tiposLoc}
                    dataSelected={selLoc}
                    showSelected={true}
                    allowAdd={true}
                    handleAdd={(val, id) => {handleAddTiposLoc(val, id)}}
                    maxLength={100}
                    minLength={2}
                    useLoading={true}
                    required={true}
                    onClick={(selValue) => {handleTiposLoc(selValue)}}
                  />
                </div>

                <div className='col col-12 col-md-4 col-xxl-4 mt-4'>
                  <Input 
                      name='primeiro_faturamento'
                      placeholder='Primeiro Faturamento:'
                      type='month'
                      required={true}
                      value={companyValues.primeiro_faturamento}
                      onChange={handleCompanyValues}
                      useLoading={true}
                    />
                </div>

              </div>

            </div>
            

            <div className='row justify-content-center mx-0 px-0'>

              <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                <Btn 
                    value='Cancelar'
                    type='button'
                    btnType='danger'
                    onClick={handleCancelCompany}
                    useLoading={true}
                />
              </div>

            </div> 
            
          </div>

          <hr></hr>

          <div className='col col-12 mt-4 mb-0 mt-md-0 mb-md-3'>
            <h2 className='pageTitle sub'>Endereço:</h2>
          </div>

          <div className='row justify-content-start'>

            <div className='col col-6 col-md-2 mt-4 mt-md-0'>
              <Input 
                  name='cep'
                  placeholder='CEP:'
                  type='number'
                  required={true}
                  value={enderecoValues.cep}
                  mask='99.999-999'
                  onChange={handleEndercoValues}
                  onBlur={handleConsultaCep}
                  useLoading={true}
              />
            </div>
            
            {ufs &&
              <div className='col col-6 col-md-2 col-xl-1 mt-4 mt-md-0'>
                <Select 
                  placeholder='UF:'
                  data={ufs}
                  value={enderecoValues.estado_id}
                  useLoading={true}
                  required={true}
                  onClick={(selValue) => {handleSetUF(selValue)}}
                />
              </div>
            }    

            {cidades &&
              <div className='col col-12 col-md-3 col-xxl-2 mt-4 mt-md-0'>
                <Select 
                  placeholder='Cidade:'
                  data={cidades}
                  disabled={enderecoValues.estado_id === 0 ? true : false}
                  value={enderecoValues.cidade_id}
                  useLoading={true}
                  required={true}
                  onClick={(selValue) => {handleSetCidade(selValue)}}
                />
              </div>
            }        
            
            <div className='col col-12 col-md-3 col-xxl-3 mt-4 mt-md-0'>
              <Input 
                  name='logradouro'
                  placeholder='Logradouro:'
                  type='text'
                  required={true}
                  value={enderecoValues.logradouro}
                  onChange={handleEndercoValues}
                  useLoading={true}
              />
            </div>

            <div className='col col-6 col-md-2 col-xl-1 mt-4 mt-md-0'>
              <Input 
                  name='numero'
                  placeholder='Número:'
                  type='number'
                  required={false}
                  value={enderecoValues.numero}
                  onChange={handleEndercoValues}
                  useLoading={true}
              />
            </div>

            <div className='col col-6 col-md-2 col-xxl-1 mt-4 mt-xl-0'>
              <Input 
                  name='complemento'
                  placeholder='Comp:'
                  type='text'
                  required={false}
                  value={enderecoValues.complemento}
                  onChange={handleEndercoValues}
                  useLoading={true}
              />
            </div>

            <div className='col col-12 col-md-3 col-xxl-2 mt-4 mt-xxl-0'>
              <Input 
                  name='bairro'
                  placeholder='Bairro:'
                  type='text'
                  required={true}
                  value={enderecoValues.bairro}
                  onChange={handleEndercoValues}
                  useLoading={true}
              />
            </div>

            <div className='row justify-content-center mx-0 px-0'>
              
              <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                <Btn 
                    value='Cancelar'
                    type='button'
                    btnType='danger'
                    onClick={handleCancelEndereco}
                    useLoading={true}
                />
              </div>

            </div>

          </div>

          <hr></hr>

          <div className='col col-12 mt-4 mb-0 mt-md-0 mb-md-3'>
            <h2 className='pageTitle sub'>Assinatura/Plano:</h2>
          </div>

          <div className='row justify-content-center'>

            <div className='col col-6 col-md-3 col-xl-2 mt-4 mt-md-0'>
                <Select 
                  placeholder='Assinatura:'
                  data={assinaturas}
                  value={valueAssAtivo}
                  useLoading={true}
                  search={false}
                  onClick={(selValue) => {setValueAssAtivo(selValue)}}
                />

                <CheckBox 
                    label='Renovação Autoḿatica'
                    onChange={handleAssRenova}
                    useLoading={true}
                    checked={valueAssRenova === 1 ? true : false}
                />
            </div>

            <div className='col col-6 col-md-3 col-xl-2 mt-4 mt-md-0'>
                <Select 
                  placeholder='Plano:'
                  data={planos}
                  useColors={true}
                  value={valuePlano}
                  useLoading={true}
                  required={true}
                  onClick={(selValue) => {handleSetPlano(selValue)}}
                />
            </div>

            <div className='col col-6 col-md-2 mt-4 mt-md-0'>
              <Input 
                name='assIni'
                placeholder='Data Incial:'
                type='date'
                disabled={valuePlano === 0 ? true : false}
                value={valueDatasAss.ini}
                onChange={handleAssIni}
                useLoading={true}
              />
            </div>

            <div className='col col-6 col-md-2 mt-4 mt-md-0'>
              <Input 
                name='assFim'
                placeholder='Data Final (Automático):'
                type='date'
                disabled={valuePlano === 0 ? true : false}
                value={valueDatasAss.fim}
                readOnly={true}
                useLoading={true}
              />
            </div>

            <div className='row justify-content-center mx-0 px-0'>
              
              <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                <Btn 
                    value='Cancelar'
                    type='button'
                    btnType='danger'
                    onClick={handleCancelAss}
                    useLoading={true}
                />
              </div>

            </div>
          </div>

          <hr></hr>

          <div className='row gx-0 justify-content-center justify-content-md-between align-items-center mt-4'>
            
            <p className='rounded m-2 p-2 col col-12 col-md-3 col-xxl-2 text-center bg-info text-dark'>Assinatura <b>{valueAssAtivo === 1 ? 'Ativa' : 'Inativa'}</b></p>

            {valueAssRenova === 1 &&

              <p className='rounded m-2 p-2 col col-12 col-md-3 col-xxl-2 text-center bg-info text-dark'>Renovação Automática</p>

            }

            {valuePlano !== 0 &&

            <p className='rounded m-2 p-2 col col-12 col-md-4 col-xxl-3 justify-content-center align-items-center d-flex flex-row flex-wrap gap-1' style={{background: planos.find(obj => obj.id === valuePlano).bgColor, color: planos.find(obj => obj.id === valuePlano).txtColor}}>Plano <b>{planos.find(obj => obj.id === valuePlano).label}</b> -
              <i> {planos.find(obj => obj.id === valuePlano).duracao}  {planos.find(obj => obj.id === valuePlano).duracao === 1 ? ' mês' : ' meses'}</i> -
              <b> {F.Moeda(planos.find(obj => obj.id === valuePlano).valor)}</b>
            </p>
              
              
            } 

            <p className='rounded m-2 p-2 col col-12 col-md-3 text-center bg-info text-dark'>Período: <b>{periodoIni}</b> {periodoFim !== ''&& <><br></br> a <b>{periodoFim}</b></>}</p>    

          </div>

          <hr></hr>

          <SubUsersList usuarios={subUsers}/>

          <hr></hr>

          <div className='row justify-content-center mb-4'>

            <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                <Btn 
                    value='Voltar'
                    type='button'
                    btnType='primary'
                    onClick={() => {navigate('/users')}}
                    useLoading={true}
                />
            </div>

            <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
              <Btn 
                  value={userId === 0 ? 'Cadastrar' : 'Salvar'}
                  type='submit'
                  btnType='success'
                  disabled={disabledSave}
                  onClick={(e)=>{handleUpdate(e)}}
                  useLoading={true}
              />
            </div>

          </div>
     
        </form>
        <div className='logoBotton'>
            <img src={LOGO_AUDIT} alt="AUDIT" className='auditLayout'/> 
        </div>
      </motion.section>
    );
}

export default EditUser;