import { instance } from "./Default.service";

export const DeleteSubUser = async (id) => {

    return await instance.get(`subusers/deleteSubUser/${id}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const GetSubUser = async (id = 0) => {

    return await instance.get(`subusers/getSubUser/${id}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const SaveSubUser = async (data) => {

    return await instance.post('subusers/saveSubUser', data)
            .then(response => {
                return response;
            })
            .catch(error => {
                if(error.response){
                    return error.response;
                }else{
                    return null;
                }
            });
}