import axios from "axios";
import { BASE_URL, BASE_BACK } from "../util/Urls";


export const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept':  'application/json'
      },
});

export const instanceCsrf = axios.create({
  baseURL: `${BASE_BACK}sanctum/csrf-cookie`,
  withCredentials: true,
});