import React, { createContext, useState, useContext, useEffect } from "react";
import * as F from '../util/Functions';
import * as US from '../services/User.service';
import * as TXTS from '../util/Texts';
import { BASE_BACK } from "../util/Urls";
import { instance } from "../services/Default.service";
import { LoaderContext } from "./LoaderContext";
import { AlertasContext } from "./AlertasContext";
import { SiteContext } from "./SiteContext";
import { CompanyContext } from "./CompanyContext";
import { GlobalFiltersContext } from "./GlobalFiltersContext";
import { useNavigate } from "react-router-dom";


export const UserContext = createContext();

function UserProvider ({children}) {

    const urlParams = new URLSearchParams(window.location.search);
    const promoToken = urlParams.get('tk') ?? false;

    const [isPromoAccess, setIsPromoAccess]         = useState(promoToken ? true : false);
    
    const navigate                                  = useNavigate();

    const [userTk, setUserTk]                       = useState(F.GetToken());
    const [userId, setUserId]                       = useState(F.GetIDUser());
    const [isLogged, setIsLogged]                   = useState(false);
    
    const [firstAccess, setFirstAccess]             = useState(false);
    
    const [userName, setUserName]                   = useState('');
    const [userEmail, setUserEmail]                 = useState('');
    
    const [userType, setUserType]                   = useState(1);
    const [userPermission, setUserPermission]       = useState(2);
    const [userMaster, setUserMaster]               = useState(false);

    const [subUsers, setSubUsers]                   = useState([]);

    const [logoClient, setLogoClient]               = useState('');
    const [hasLogo, setHasLogo]                     = useState(false);
    const [txtPromoToken, setTxtPromoToken]         = useState('Validando acesso Degustação...');
    const [statusPromoToken, setStatusPromoToken]   = useState('loading');
    const [promoUserId, setPromoUserId]             = useState(0);

    const userTypes = [
       
        {
            label: 'Administrador',
            id: 1
        },

        {
            label: 'Usuário',
            id: 2
        },
    ];

    const [ufs, setUfs]                     = useState([]);
    const [estados, setEstados]             = useState([]);
    const [cidades, setCidades]             = useState([]);

    const { setIsLoading }                  = useContext(LoaderContext);

    const requiredUserValues = [
        'email',
        'name'
    ];

    const {

        setAlertShow,
        setAlertBtnOk,
        setAlertType,
        setAlertMsg,
        setAlertChangePass,
        setAlertOnClose,

    } = useContext(AlertasContext);

    const {

        setOpenLogin,
        setErrorCon,
        setDocTitle

    } = useContext(SiteContext);

    const {
        
        handleSetCompany,
        handleClearCompany
    
    } = useContext(CompanyContext);

    const {

        handleResetGlobalFilters

    } = useContext(GlobalFiltersContext);

    const Login = async(userValues = null) => {
        
        setIsLoading('show');

        let userData = '';

        if(userValues && promoUserId === 0){

            userData = {
                email: userValues.email.trim(),
                password: userValues.password.trim(),
                device: F.GetDeviceInfo()
            };

        }

        if(!userValues && promoUserId !== 0){

            userData = {
                device: 'Acesso Degustação',
                promoUserId: promoUserId
            };

        }

        try {
            
            const result = userTk === null ? await US.Login(userData) : await US.LoggedUser();

            if(result.data){
                
                if (result.status === 200) {
                    
                    setOpenLogin('');
                    if(result.data.firstAccess){
                        setFirstAccess(true);
                        setAlertChangePass(true);
                    }

                    setUserId(result.data.userId ?? F.GetIDUser());
                    setUserTk(result.data.userTk ?? F.GetToken());
                    setUserName(result.data.userName);
                    setUserEmail(result.data.userEmail ?? userData.email);
                    setUserType(result.data.userType);
                    setUserMaster(result.data.userMaster);
                    setUserPermission(result.data.userPermission);
                    setSubUsers(result.data.subUsers);
                    setLogoClient(result.data.logoClient ? `${BASE_BACK}${result.data.logoClient}` : '/img/semlogo.png');
                    setHasLogo(result.data.logoClient ? true : false);

                    setUfs(result.data.estados);
                    setEstados(result.data.estadosDesc);
                    
                    const company = result.data.company;

                    const cidades = result.data.cidades;

                    setCidades(cidades);
                    
                    if(company){

                        const endereco = company.endereco;
                        

                        handleSetCompany(
                            company.razaoSocial_nomeCompleto,
                            company.nomeFantasia_apelido,
                            company.type,
                            company.cnpj_cpf,
                            company.ie_rg,
                            company.type_pessoa,
                            company.color,
                            company.primeiro_faturamento,
                            endereco
                        );
                        
                        
                        
                    }else{
                        handleClearCompany();
                        handleResetGlobalFilters();
                    }
                    
                    setIsLogged(true);
                    
                    const storgePromoToken = localStorage.getItem('promoToken');

                    setIsPromoAccess(storgePromoToken || promoUserId !== 0 ? true : false);

                    if(result.data.userTk){
                        F.SetInstanceToken(result.data.userTk);
                        navigate('/');
                    }                    

                    localStorage.setItem('userId', result.data.userId ?? F.GetIDUser());
                    localStorage.setItem('userTk', result.data.userTk ?? F.GetToken());
                    localStorage.setItem('userType', result.data.userType ?? F.GetUserType());

                }else{
                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    setAlertType('error');
                    setAlertBtnOk(false);
                    setAlertOnClose(() => () => {handleFinalLogout()});
                }
            }else{
                
                setAlertShow(true);
                setAlertMsg(TXTS.ERRO_LOGIN_2);
                setAlertType('error');
                setAlertBtnOk(false);
                setAlertOnClose(() => () => {handleFinalLogout()});
            
            }
            
        }catch{

            setAlertShow(true);
            setAlertMsg(TXTS.ERRO_LOGIN_3);
            setAlertType('error');
            setAlertBtnOk(false);
            setErrorCon(true);
            setAlertOnClose(() => () => {handleFinalLogout()});
        
        }
        
        setIsLoading('hide');
    };

    const handleLocalLogout = () => {

        setIsLogged(false);
        setUserTk(null);
        setUserId(null);
        setUserType(0);
        setUserMaster(false);
        setUserPermission(2);
        setSubUsers([]);
        setUserName('');
        setLogoClient('');

        setCidades([]);
        setUfs([]);
        setEstados([]);
        
        handleClearCompany();
        handleResetGlobalFilters();
        setDocTitle('');

    };

    const handleFinalLogout = () => {

        F.DeleteAllCookies();
        const acceptCookies = localStorage.getItem('acceptCookies');
        const comparaSel1 = localStorage.getItem('comparaSel1');
        const comparaSel2 = localStorage.getItem('comparaSel2');
        const comparaSel3 = localStorage.getItem('comparaSel3');

        localStorage.clear();

        if (acceptCookies){
            localStorage.setItem('acceptCookies', acceptCookies);
        }

        if (comparaSel1){
            localStorage.setItem('comparaSel1', comparaSel1);
        }

        if (comparaSel2){
            localStorage.setItem('comparaSel2', comparaSel2);
        }

        if (comparaSel3){
            localStorage.setItem('comparaSel3', comparaSel3);
        }
        
        delete instance.defaults.headers.Authorization;
        setIsLogged(false);
        setUserType(0);
        setUserMaster(false);
        setUserPermission(2);
        setSubUsers([]);
        setUserName('');
        setLogoClient('');
        setUserTk(null);

        setCidades([]);
        setUfs([]);
        setEstados([]);

        handleClearCompany();
        handleResetGlobalFilters();
        setIsPromoAccess(false);
        setPromoUserId(0);

        navigate('/');

    }

    const Logout = async () => {

        setIsLoading('show');

        try{

            const result =  await US.LogOutApi();
            
            if(result.data && result.status === 200){

                handleFinalLogout();
                
            }
            

        }catch{
           
        }
    };

    const UserUpdate = async (userValues) => {

        setIsLoading('show');

        const userData = {
            name: userValues.name.trim(),
            email: userValues.email.trim(),
            type: userValues.type,
        };

        try{
            
            const result = await US.UpdateUser(userData);
            if(result.data){

                if (result.status === 200) {
                    
                    setAlertShow(true);
                    setAlertMsg(TXTS.USER_UPDATE_SUCESSO);
                    setAlertType('success');
                    setAlertBtnOk(false);

                    setUserName(userData.name);
                    setUserEmail(userData.email);
                    

                }else{

                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    setAlertType('error');
                    setAlertBtnOk(false);
                }

            }else{
                
                setAlertShow(true);
                setAlertMsg(TXTS.ERRO_LOGIN_2);
                setAlertType('error');
                setAlertBtnOk(false);
            
            }

        }catch{

            setAlertShow(true);
            setAlertMsg(TXTS.ERRO_LOGIN_3);
            setAlertType('error');
            setAlertBtnOk(false);
            setErrorCon(true);

        }

        setIsLoading('hide');
    };

    const handleUserUpdate = (e, userValues) => {      
        if(userValues.name === '' || userValues.email === '')
        return;

        e.preventDefault();
        UserUpdate(userValues);
    };

    const handleLogin = (e, userValues) => {

        e.preventDefault();
    
        if(userValues.email !== '' && userValues.password !== '' && promoUserId === 0){
            localStorage.removeItem("promoToken");
            Login(userValues);
        }

        if(userValues.email === '' && userValues.password === '' && promoUserId !== 0){
            Login();
        }
        
    };

    const validatePromo = async(promoToken) => {

        try{
            const result = await US.validatePromo({promoToken: promoToken});

            if(result.data){

                if (result.status === 200) {

                    setTimeout(() => {

                        setTxtPromoToken('Acesso validado com sucesso, clique em Entrar');
                        setStatusPromoToken('ok');
                        setPromoUserId(result.data.ok);
                        localStorage.setItem('promoToken', promoToken);

                    }, 2000);

                }else{
                    
                    localStorage.removeItem("promoToken");
                    setIsPromoAccess(false);

                    setTimeout(() => {
                        setTxtPromoToken(result.data.msg ?? 'Erro ao validar acesso.');
                        setStatusPromoToken('error');
                        setPromoUserId(0);
                        setUserTk(null);
                    }, 2000);
                    
                }

            }else{

                localStorage.removeItem("promoToken");
                setIsPromoAccess(false);

                setTimeout(() => {
                    setTxtPromoToken('Erro ao validar acesso.');
                    setStatusPromoToken('error');
                    setPromoUserId(0);
                }, 2000);

            }
        }catch{

            localStorage.removeItem("promoToken");
            setIsPromoAccess(false);

            setTimeout(() => {
                setTxtPromoToken('Erro ao validar acesso.');
                setStatusPromoToken('error');
                setPromoUserId(0);
            }, 2000);

        }

    };
    
    useEffect(()=>{
        if(userTk !== null && !isLogged){
            F.SetInstanceToken(userTk);
            Login();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userTk, isLogged]);

    useEffect(() => {

        if(promoToken && !isLogged){
            console.log('Acesso Degustação.');
            setIsPromoAccess(true);
            validatePromo(promoToken);
        }

        if(promoToken && isLogged){
            handleFinalLogout();
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogged, promoToken]);
    
    return (
        <UserContext.Provider value={{ 
            firstAccess, setFirstAccess,
            isLogged, setIsLogged,
            userName, setUserName,
            userEmail, setUserEmail,
            userTk, setUserTk,
            userId, setUserId,
            userType, setUserType, userTypes,
            userMaster,
            userPermission, setUserPermission,
            subUsers, setSubUsers,
            logoClient, setLogoClient,
            hasLogo, setHasLogo,
            handleLogin, Logout, handleLocalLogout, handleUserUpdate,
            requiredUserValues, ufs, setUfs,
            estados, setEstados,
            cidades, setCidades,
            txtPromoToken, statusPromoToken, promoToken, isPromoAccess}}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider;