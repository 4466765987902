import { instance } from "./Default.service";

export const GetTiposLoc = async () => {

    return await instance.get('getTiposLoc')
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}
