import './index.css';
import React, { useState, useRef, useEffect, useContext } from 'react';
import { BASE_BACK } from '../../../util/Urls';
import { ValidLogo, TextColorByBg } from '../../../util/Functions';
import * as TXTS from '../../../util/Texts';
import * as UserService from '../../../services/User.service';
import * as LocService from '../../../services/LocadorasPadrao.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faRepeat, faPlus } from '@fortawesome/free-solid-svg-icons';
import { AlertasContext } from '../../../contexts/AlertasContext';
import { UserContext } from '../../../contexts/UserContext';
import { CompanyContext } from '../../../contexts/CompanyContext';
import { SiteContext } from '../../../contexts/SiteContext';

function UserLogo({

    estilo,
    edit = false,
    logo = null,
    name = false,
    company = false,
    id = null,
    onChangeCl = false,
    onTempLogo = false,
    color,

}) {

    const [locLogo, setLocLogo]         = useState('/img/semlogo.png');
    const [hasLocLogo, setHasLocLogo]   = useState(false);
    const [colorIn, setColorIn]         = useState(color);
    const [tempLogo, setTempLogo]       = useState(null);

    const { 

        userName,
        logoClient,
        setLogoClient,
        hasLogo,
        setHasLogo,
        userPermission

    } = useContext(UserContext);

    const {

        setAlertShow,
        setAlertBtnOk,
        setAlertType,
        setAlertMsg

    } = useContext(AlertasContext);

    const { setTextColor } = useContext(SiteContext);

    const { companyColor, setCompanyColor } = useContext(CompanyContext);

    const [imgReady, setImgReady]           = useState(false);
    const [selectedFile, setSelectedFile]   = useState(null);
    const fileInputRef                      = useRef();

    const handleUserLogo = (event) => {
    const validar = ValidLogo(event)
        if(validar !== ''){

            setAlertShow(true);
            setAlertMsg(validar);
            setAlertType('error');
            setAlertBtnOk(false);

            if (fileInputRef) fileInputRef.current.value = null;
            setSelectedFile(null);
            return false;
        }

        setSelectedFile(event.target.files[0]);
    }

    useEffect(() => {
        const handleUpLoad = async () => {
            
            const formData = new FormData();
            formData.append('file', selectedFile);

            if(company){
                formData.append('id', id);
                formData.append('tempLogo', tempLogo);                
            }
            
            setImgReady(false);

            try{
                const result = !company ? await UserService.ChangeLogo(formData) : await LocService.ChangeLogo(formData);

                if(result.data){
                    if (result.status === 200) {
                        const logoClient = result.data.logo ? `${BASE_BACK}${result.data.logo}` : '/img/semlogo.png';
                    
                        const img = new Image();
                        
                        img.onload = () => {
                            
                            if(company){

                                setLocLogo(logoClient);
                                setHasLocLogo(true);

                                setTempLogo(id === 0 ? result.data.logo : null);

                                if (typeof onTempLogo === "function") {
                                    onTempLogo(id === 0 ? result.data.logo : null);
                                }
                
                            }else{
                
                                setLogoClient(logoClient);
                                setHasLogo(true);
                
                            }

                            setTimeout(() => {
                                setImgReady(true);
                            }, 300);
                        }

                        img.onerror = () => {

                            if(company){

                                setLocLogo('/img/semlogo.png');
                                setHasLocLogo(false);
                
                            }else{
                
                                setLogoClient('/img/semlogo.png');
                                setHasLogo(false);
                
                            }
                        }

                        img.src = logoClient;

                        if(result.data.corPrincipal){

                            if(company){

                                setColorIn(result.data.corPrincipal);
                                if (typeof onChangeCl === "function") {
                                    onChangeCl(result.data.corPrincipal);
                                }

                            }else{

                                setCompanyColor(result.data.corPrincipal);
                                const textColor = TextColorByBg(result.data.corPrincipal);
                                setTextColor(textColor);
                            }
                        }

                    }else{

                        setImgReady(true);
                        setAlertShow(true);
                        setAlertMsg(result.data.msg);
                        setAlertType('error');
                        setAlertBtnOk(false);
                    
                    }
                }
                else{

                    setImgReady(true);
                    setAlertShow(true);
                    setAlertMsg(TXTS.IMG_LOAD_ERROR);
                    setAlertType('error');
                    setAlertBtnOk(false);

                }

            }catch{

                setImgReady(true);
                setAlertShow(true);
                setAlertMsg(TXTS.ERRO_LOGIN_3);
                setAlertType('error');
                setAlertBtnOk(false);

            }
            setSelectedFile(null);
        }

        if(selectedFile !== null)
        handleUpLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFile, setAlertBtnOk, setAlertMsg, setAlertShow, setAlertType, setHasLogo, setLogoClient]);

    useEffect(() => {
        
        if(company){
            return;
        }

        const img = new Image();
        
        img.onload = () => {
            setTimeout(() => {
                setImgReady(true);
            }, 300);
        }

        img.onerror = () => {

            setLogoClient('/img/semlogo.png');
            setHasLogo(false);
            
        }

       img.src = logoClient;

    }, [logoClient, setHasLogo, setLogoClient, company]);

    useEffect(() => {
        
        if(company && locLogo !== null){

            const img = new Image();
        
            img.onload = () => {
                
                setTimeout(() => {
                    setImgReady(true);
                }, 300);
            }

        img.onerror = () => {

            setLocLogo('/img/semlogo.png');
            setHasLocLogo(false);
            
        }

        img.src = locLogo;

        }

    }, [locLogo, company]);

    useEffect(() => {
        
        if(company && logo !== null){
            setHasLocLogo(true);
            setLocLogo(`${BASE_BACK}${logo}`);
        }
       
    }, [company, logo]);

    useEffect(() => {
        setColorIn(color);
    },[color])

    return (
        <>
        {!edit && !company &&

            <div className={`logoClient ${imgReady ? 'loaded':''} ${estilo}`} style={{'--bgColor': companyColor}}>
                {imgReady ?
                    <>
                        {userPermission === 1 &&
                            <input type='file' id='logoUser' name='logoUser' onChange={handleUserLogo} ref={fileInputRef}/>
                        }
                        
                        <label htmlFor='logoUser' className='labelLogo' style={{cursor: userPermission === 1 ? 'pointer' : 'default'}}>
                            <img src={logoClient} alt={userName} />
                        </label>

                        {userPermission === 1 &&

                            <div className='logoChange'>
                                
                                <FontAwesomeIcon icon={hasLogo ? faRepeat : faPlus}/>
                                {hasLogo ? TXTS.LOGO_CHANGE : TXTS.LOGO_ADD}
                        
                            </div>
                            
                        }
                        
                    </>
                    :
                    <><FontAwesomeIcon icon={faSpinner} spin color="lightblue"/></>
                }
            </div>

        }

        {edit &&

        <div className='logoClient loaded settings' style={{'border': `4px solid ${colorIn}`}}>

            <label htmlFor='logoUser' className='labelLogo' style={{cursor:'default'}}>
                <img 
                    src={`${logo !== null ? `${BASE_BACK}${logo}` : '/img/semlogo.png'}`}
                    alt={name ? name : 'Logo'}
                />
            </label>
            
        </div>
            
        }

        {company &&

            <div className={`logoClient ${imgReady ? 'loaded':''} settings`} style={{'border': `4px solid ${colorIn}`}}>
                {imgReady ?
                    <>
                    
                        {userPermission === 1 &&
                            <input type='file' id='logoUser' name='logoUser' onChange={handleUserLogo} ref={fileInputRef}/>
                        }
                        
                        <label htmlFor='logoUser' className='labelLogo'>
                            <img src={locLogo} alt={name} />
                        </label>

                        {userPermission === 1 &&

                            <div className='logoChange'>
                            
                                <FontAwesomeIcon icon={hasLocLogo ? faRepeat : faPlus}/>
                                {hasLocLogo ? TXTS.LOGO_CHANGE : TXTS.LOGO_ADD}
                        
                            </div>

                        }
                    </>
                    :
                    <><FontAwesomeIcon icon={faSpinner} spin color="lightblue"/></>
                }
            </div>

        }
        
        </>
    );
}
  
export default UserLogo;