import './index.css';
import { useEffect, useState } from 'react';
import { COOKIES_TXT, COOKIES_BTN } from '../../util/Texts';

function Cookies() {

    const [acceptCookies, setAcceptCokies] = useState(localStorage.getItem('acceptCookies'));
    const [showCookies, setShowCookies] = useState('');

    const handleAcceptCookies = () => {
        localStorage.setItem('acceptCookies', true);
        setShowCookies('');
        setTimeout(() => {
            setAcceptCokies(true);
        }, 350);
    }

    useEffect(() => {
        if(!acceptCookies){
            setShowCookies('show');
        }
    }, [acceptCookies]);

    return (
        <>
            {!acceptCookies &&
                <div className={`Cookies row ${showCookies}`}>
                <div className='col col-12 col-md-8'>
                    {COOKIES_TXT}
                </div>
                <div className='col col-12 col-md-2'>
                    <input className='btn success btn_cookies' type="button" onClick={handleAcceptCookies} value={COOKIES_BTN}/>
                </div>
                </div>
            }
        </>
    );
}

export default Cookies;