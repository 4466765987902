import './index.css';
import React, { useState, useEffect } from 'react';
import { slidesAnime } from '../../util/AnimatedDiv';
import { motion, AnimatePresence } from 'framer-motion';
import { BASE_BACK } from '../../util/Urls';
import * as SiteS from '../../services/Site.service';
import LogoIDR from '../LogoIDR';

function SlidesHome({

    showLogo = true,
    rounded = false

}) {

    const [slidesHome, setSlidesHome]   = useState([]);
    const [isReady, setIsReady]         = useState(false);
    const [active, setActive]           = useState(0);

    const getSlidesHome = async() => {

        try {
            
            const result = await SiteS.slidesHome();

            if(result.data){
                
                if (result.status === 200) {
                    
                    setSlidesHome(result.data.slidesHome);
                    setIsReady(result.data.slidesHome.length > 0);

                }
            }else{
                
                setSlidesHome([]);
                setIsReady(false);
            
            }
            
        }catch{

            setSlidesHome([]);
            setIsReady(false);

        }
        
    };


    useEffect(() => {

        if(isReady){

            const slidesTime = setTimeout(() => {
                
                setActive( previous => {
                        
                    if ((previous+1) === slidesHome.length) {
                        return 0;
                    } else {
                        return previous+1;
                    } 
                    
    
                });
            }, (slidesHome[active].time * 1000));
            
            return () => clearTimeout(slidesTime);
        }
        
      }, [isReady, slidesHome, active]);

    useEffect(() => {
        getSlidesHome();
    }, []);
    
    return(
    
    <div className={`slidesHomeContainer ${rounded ? 'rounded' : ''}`}>

        {isReady && showLogo && slidesHome[active].show_logo === 1 &&
        <LogoIDR 
            hoverSlides={true}
        />
        }
        
        <div className='slidesHome'>
            
            {isReady &&
                <AnimatePresence>
                    <motion.img 
                        className='slidesHomeImg'
                        variants={slidesAnime}
                        initial='entrada'
                        animate='animacao'
                        exit='saida'
                        alt='Slide'
                        src={`${BASE_BACK}${slidesHome[active].url}`}
                        key={slidesHome[active].id}
                    />
                </AnimatePresence>
            }       


        </div>
        
    </div>
    )
}

export default SlidesHome;