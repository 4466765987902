/* eslint no-eval: 0 */
import './index.css';
import React, { useEffect, useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { animacao } from '../../util/AnimatedDiv'
import Input from '../../components/Forms/Input';
import Select from '../../components/Forms/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as FA from '@fortawesome/free-solid-svg-icons';
import { LoaderContext } from '../../contexts/LoaderContext';
import { SiteContext } from '../../contexts/SiteContext';
import { UserContext } from '../../contexts/UserContext';
import { CompanyContext } from '../../contexts/CompanyContext';
import * as IN from '../../services/Indicadores.service';
import * as LOCS from '../../services/LocadorasPadrao.service';
import * as SG from '../../services/Segmentos.service';
import * as TL from '../../services/TiposLocacao.service';
import * as ES from '../../services/Enderecos.service';
import * as TXTS from '../../util/Texts';
import { GlobalFiltersContext } from '../../contexts/GlobalFiltersContext';
import * as F from '../../util/Functions';
import { LOGO_AUDIT } from '../../util/Urls';
import Print from '../../components/Print';
import Excel from '../../components/Excel';
import { BASE_BACK } from '../../util/Urls';
import { STATISTICS_ICON } from '../../util/Urls';

function Comparativos() {

  
  const { setIsLoading, cLoading }                    = useContext(LoaderContext);
  const {
    HandleScroll,
    setDocTitle,
    canLoad,
    logoIdr,
    hasLogoIdr
    // textColor
  } = useContext(SiteContext);

  const { 
    isLogged, 
    // userName, 
    estados, 
    logoClient,
    isPromoAccess 
  } = useContext(UserContext);

  const { companyNameFantasia }                       = useContext(CompanyContext);
 
  const [indicadores, setIndicadores]                 = useState([]);
  const [indicadoresShow, setIndicadoresShow]         = useState([]);

  const [indicadoresRef, setIndicadoresRef]           = useState([]);
  const [indicadoresRefShow, setIndicadoresRefShow]   = useState([]);

  const [changeMes, setChangeMes]                     = useState(true);
  const [carregando, setCarregando]                   = useState(false);
  const [carregandoLocPdr, setCarregandoLocPdr]       = useState(false);
  
  const [segmentos, setSegmentos]                     = useState([]);
  const [tiposLoc, setTiposLoc]                       = useState([]);
  const [cidades, setCidades]                         = useState([]);

  const [selTop1, setSelTop1]                         = useState([]);
  const [showSelTop1, setShowSelTop1]                 = useState(false);
  const [selTop2, setSelTop2]                         = useState([]);
  const [showSelTop2, setShowSelTop2]                 = useState(false);
  const [selTop3, setSelTop3]                         = useState([]);
  const [showSelTop3, setShowSelTop3]                 = useState(false);

  const [selectedTop1, setSelectedTop1]               = useState(0);
  const [selectedTop2, setSelectedTop2]               = useState(0);
  const [selectedTop3, setSelectedTop3]               = useState(0);

  const [selectedTop1Ready, setSelectedTop1Ready]     = useState(false);
  const [selectedTop2Ready, setSelectedTop2Ready]     = useState(false);
  const [selectedTop3Ready, setSelectedTop3Ready]     = useState(false);

  const [lastLocPadraoTop1, setLastLocPadraoTop1]     = useState('Não Encontrado');
  const [lastLocPadraoTop2, setLastLocPadraoTop2]     = useState('Não Encontrado');
  const [lastLocPadraoTop3, setLastLocPadraoTop3]     = useState('Não Encontrado');

  const [indicadoresLoc1, setIndicadoresLoc1]         = useState([]);
  const [indicadoresLocShow1, setIndicadoresLocShow1] = useState([]);

  const [indicadoresLoc2, setIndicadoresLoc2]         = useState([]);
  const [indicadoresLocShow2, setIndicadoresLocShow2] = useState([]);

  const [indicadoresLoc3, setIndicadoresLoc3]         = useState([]);
  const [indicadoresLocShow3, setIndicadoresLocShow3] = useState([]);

  const [logoPrint1, setLogoPrint1]           = useState(false);
  const [logoPrint2, setLogoPrint2]           = useState(false);
  const [logoPrint3, setLogoPrint3]           = useState(false);

  const [canLoadLocs, setCanLoadLocs]                 = useState(false);

  const { 

    globalFiltersPeriodoDash, setGlobalFiltersPeriodoDash,
    minMaxMes, setMinMaxMes,
    handleMesAnoIniDash,
    filtersUsers, setFiltersUser

  } = useContext(GlobalFiltersContext);


  const handleGetSegmentos = async () => {
    
    setIsLoading('show');
    
    try{
    
        const result =  await SG.GetSegmentos();

        if(result.data){

            if (result.status === 200) {
              
              setSegmentos(result.data.segmentos);

            }else{
              setSegmentos([]);
            }

        }

    }catch{
      setSegmentos([]);
    }

    setIsLoading('hide');
  }

  const handleSegmentos = (selValue) => {

    const newSelected = F.CheckMultiSelFilter(selValue, filtersUsers.segmentos);

    if(!newSelected) {
      return;
    }

    setFiltersUser({
      ...filtersUsers,
      segmentos: newSelected
    });

    handleGetIndicadoresRef(globalFiltersPeriodoDash.mes_ano_ini);

  }

  const handleGetTiposLoc = async () => {
    
    setIsLoading('show');
    
    try{
    
        const result =  await TL.GetTiposLoc();

        if(result.data){

            if (result.status === 200) {
              
              setTiposLoc(result.data.tiposLoc);

            }else{
              setTiposLoc([]);
            }

        }

    }catch{
      setTiposLoc([]);
    }

    setIsLoading('hide');
  }

  const handleTiposLoc = (selValue) => {

    const newSelected = F.CheckMultiSelFilter(selValue, filtersUsers.tiposLoc);

    if(!newSelected) {
      return;
    }

    setFiltersUser({
      ...filtersUsers,
      tiposLoc: newSelected
    });

    handleGetIndicadoresRef(globalFiltersPeriodoDash.mes_ano_ini);

  }

  const handleSetUF = (selValue) => {
    
    const newSelected = F.CheckMultiSelFilter(selValue, filtersUsers.estados);

    if(!newSelected) {
      return;
    }

    setFiltersUser({
      ...filtersUsers,
      estados: newSelected
    });

    handleGetCidades(true);

  }

  const handleSetCidade = (selValue) => {

    const newSelected = F.CheckMultiSelFilter(selValue, filtersUsers.cidades);

    if(!newSelected) {
      return;
    }

    setFiltersUser({
      ...filtersUsers,
      cidades: newSelected
    });

    handleGetIndicadoresRef(globalFiltersPeriodoDash.mes_ano_ini);

  }

  const handleGetCidades = async (estadoChange = false) => {

    const data = {estados: filtersUsers.estados};    

    try{

      const result =  await ES.GetCidadesAll(data);
      
      if(result.data && result.status === 200){

        const cidadesOk = result.data.cidades;

        setCidades(cidadesOk);

        const filtersOk = [];
        
        filtersUsers.cidades.forEach(value => {
          
          cidadesOk.forEach(element => {

            if (element.id === value) {

              filtersOk.push(value);

            }

          });

        });

        setFiltersUser({
          ...filtersUsers,
          cidades: filtersOk
        });
        
      }else{
        setCidades([]);
        setFiltersUser({
          ...filtersUsers,
          estados: [0],
          cidades: [0]
        });
      }
      

    }catch{
      setFiltersUser({
        ...filtersUsers,
        estados: [0],
        cidades: [0]
      });
    }

    if(estadoChange){
      handleGetIndicadoresRef(globalFiltersPeriodoDash.mes_ano_ini);
    }

  }

  const handleSetLocadora = (locadora, sel) => {

    if(locadora === 0){
      return;
    }

    switch (sel) {
      case 1:
        setShowSelTop1(false);
        
        if(typeof locadora === 'number'){
          handleGetIndicadoresLocPadrao(1, locadora);
        }else{
          setSelectedTop1(locadora);
          setIndicadoresLoc1([]);
          setLastLocPadraoTop1('Não Encontrado');
        }
        break;

      case 2:
        setShowSelTop2(false);
        
        if(typeof locadora === 'number'){
          handleGetIndicadoresLocPadrao(2, locadora);
        }else{
          setSelectedTop2(locadora);
          setIndicadoresLoc2([]);
          setLastLocPadraoTop2('Não Encontrado');
        }
        break;

      case 3:
        setShowSelTop3(false);
        
        if(typeof locadora === 'number'){
          handleGetIndicadoresLocPadrao(3, locadora);
        }else{
          setSelectedTop3(locadora);
          setIndicadoresLoc3([]);
          setLastLocPadraoTop3('Não Encontrado');
        }
        break;

      default:
        break;
    
    }

  }

  const handleMountTopSels = () => {

    const defaultSel = [
      {
        "label": isPromoAccess ? 'Acesso Degustação' : companyNameFantasia,
        "id": 'CLIENTE',
        "color": "#ffffff",
        "logo": isPromoAccess ? STATISTICS_ICON : logoClient
      },
      {
        "label": TXTS.IDR_REFERENCIA_COMPARA,
        "id": 'IDR',
        "color": "#ffffff",
        "logo": hasLogoIdr ? logoIdr : '/img/semlogo.png'
      }

    ];

    setSelTop1(defaultSel);
    setSelTop2(defaultSel);
    setSelTop3(defaultSel);

  }

  const handleGetLocadoras = async () => {
        
    setIsLoading('show');

    try{
    
        const result =  await LOCS.GetLocadoras();

        if(result.data){

            if (result.status === 200) {

              result.data.locadoras.forEach(obj => {
                obj.logo = BASE_BACK + obj.logo;
              });

              setSelTop1([...selTop1, ...result.data.locadoras]);
              setSelTop2([...selTop2, ...result.data.locadoras]);
              setSelTop3([...selTop3, ...result.data.locadoras]);

              const comparaSel1 = localStorage.getItem('comparaSel1');
              setSelectedTop1(comparaSel1 && isNaN(comparaSel1) ? comparaSel1 : 'CLIENTE');

              const comparaSel2 = localStorage.getItem('comparaSel2');
              setSelectedTop2(comparaSel2 && isNaN(comparaSel2) ? comparaSel2 : 'IDR');

              const comparaSel3 = localStorage.getItem('comparaSel3');

              if(comparaSel3 && isNaN(comparaSel3)){
                setSelectedTop3(comparaSel3);
              }

              setSelectedTop1Ready(true);
              setSelectedTop2Ready(true);
              setSelectedTop3Ready(true);

              if(result.data.locadoras.length > 0){
                
                if(!comparaSel3){
                  handleGetIndicadoresLocPadrao(3, result.data.locadoras[0].id);
                }

                if(comparaSel1 && !isNaN(comparaSel1) && result.data.locadoras.find(option => option.id === Number(comparaSel1))){
                  handleGetIndicadoresLocPadrao(1, Number(comparaSel1));
                }

                if(comparaSel2 && !isNaN(comparaSel2) && result.data.locadoras.find(option => option.id === Number(comparaSel2))){
                  handleGetIndicadoresLocPadrao(2, Number(comparaSel2));
                }

                if(comparaSel3 && !isNaN(comparaSel3) && result.data.locadoras.find(option => option.id === Number(comparaSel3))){
                  handleGetIndicadoresLocPadrao(3, Number(comparaSel3));
                }

              }
              
            }

        }

    }catch{

    }

    setIsLoading('hide');
  }

  const handleGetIndicadores = async () => {
    
    console.log('handleGetIndicadores');

    console.log(selectedTop1);
    console.log(selectedTop2);
    console.log(selectedTop3);
    if(!changeMes || carregando){
      return;
    }

    setChangeMes(false);
    setCarregando(true);
    setIsLoading('show');
    
    const data = {compara: 1, ...globalFiltersPeriodoDash};

    try{
    
        const result =  await IN.GetIndicadoresDash(data);

        if(result.data){

            if (result.status === 200) {
              
              setIndicadores(result.data.indicadores);
              setGlobalFiltersPeriodoDash(globalFiltersPeriodoDash => ({ ...globalFiltersPeriodoDash, 
                mes_ano_ini: result.data.mesAnoIniOk.slice(0, -3),
                ultimos_meses: 0
              }));

              setMinMaxMes(minMaxMes => ({ ...minMaxMes, 
                min_mes: result.data.minMes.slice(0, -3),
                max_mes: result.data.maxMes.slice(0, -3)
              }));

              handleGetIndicadoresRef(result.data.mesAnoIniOk.slice(0, -3));

              if(typeof selectedTop1 === 'number' && selectedTop1 !== 0){
                handleGetIndicadoresLocPadrao(1, selectedTop1);
              }

              if(typeof selectedTop2 === 'number' && selectedTop2 !== 0){
                handleGetIndicadoresLocPadrao(2, selectedTop2);
              }

              if(typeof selectedTop3 === 'number' && selectedTop3 !== 0){
                handleGetIndicadoresLocPadrao(3, selectedTop3);
              }

              setTimeout(() => {
                setCanLoadLocs(true);
              }, 500);

              setTimeout(() => {
                setChangeMes(true);
                setCarregando(false);
              }, 1000);

            }else{
              setIndicadores([]);
              setIndicadoresRef([]);
            }

        }

    }catch{
        
    }

    setIsLoading('hide');
  }

  const handleGetIndicadoresLocPadrao = async (sel, id) => {

    if(carregandoLocPdr){
      return;
    }

    setCarregandoLocPdr(true);
    setIsLoading('show');
    
    const data = {
      compara: 1,
      locPadraoId:id, 
      ...globalFiltersPeriodoDash
    };

    console.log(globalFiltersPeriodoDash.mes_ano_ini)

    try{
    
        const result =  await IN.GetIndicadoresDash(data);

        if(result.data){

            if (result.status === 200) {              

              switch (sel) {
                case 1:
                  setIndicadoresLoc1(result.data.indicadores);
                  setSelectedTop1(id);
                  

                  if(result.data.findLast !==  false){
                    setLastLocPadraoTop1(result.data.findLast);
                  }else{
                    setLastLocPadraoTop1('Não Encontrado');
                  }
                  break;
                case 2:
                  setIndicadoresLoc2(result.data.indicadores);
                  setSelectedTop2(id);
                  

                  if(result.data.findLast !==  false){
                    
                    setLastLocPadraoTop2(result.data.findLast);
                  }else{
                    setLastLocPadraoTop2('Não Encontrado');
                  }
                  break;
                case 3:
                  setIndicadoresLoc3(result.data.indicadores);
                  setSelectedTop3(id);
                  

                  if(result.data.findLast !==  false){
                    
                    setLastLocPadraoTop3(result.data.findLast);
                  }else{
                    setLastLocPadraoTop3('Não Encontrado');
                  }
                  break;
              
                default:
                  break;
              }

              setTimeout(() => {
                setCarregandoLocPdr(false);
              }, 1000);

              

            }else{
              switch (sel) {
                case 1:
                  setIndicadoresLoc1([]);
                  setSelectedTop1(0);
                  localStorage.removeItem('comparaSel1');
                  setLastLocPadraoTop1('Não Encontrado');
                  break;
                case 2:
                  setIndicadoresLoc2([]);
                  setSelectedTop2(0);
                  localStorage.removeItem('comparaSel2');
                  setLastLocPadraoTop2('Não Encontrado');
                  break;
                case 3:
                  setIndicadoresLoc3([]);
                  setSelectedTop3(0);
                  localStorage.removeItem('comparaSel3');
                  setLastLocPadraoTop3('Não Encontrado');
                  break;
              
                default:
                  break;
              }
            }

        }

    }catch{

      switch (sel) {
        case 1:
          setIndicadoresLoc1([]);
          setSelectedTop1(0);
          localStorage.removeItem('comparaSel1');
          setLastLocPadraoTop1('Não Encontrado');
          break;
        case 2:
          setIndicadoresLoc2([]);
          setSelectedTop2(0);
          localStorage.removeItem('comparaSel2');
          setLastLocPadraoTop2('Não Encontrado');
          break;
        case 3:
          setIndicadoresLoc3([]);
          setSelectedTop3(0);
          localStorage.removeItem('comparaSel3');
          setLastLocPadraoTop3('Não Encontrado');
          break;
      
        default:
          break;
      }
        
    }

    setIsLoading('hide');
  }

  const handleGetIndicadoresRef = async (mes) => {
    
    const data = {
      mes_ano_ini: mes,
      ultimos_meses: 0,
      compara: 1,
      ref: 1,
      companyId: 0,
      segmentos: filtersUsers.segmentos,
      tiposLoc: filtersUsers.tiposLoc,
      estados: filtersUsers.estados,
      cidades: filtersUsers.cidades
    }

    setIsLoading('show');
    
    try{
    
        const result =  await IN.GetIndicadoresDash(data);

        if(result.data){

            if (result.status === 200) {
              
              setIndicadoresRef(result.data.indicadores);

            }else{
              setIndicadoresRef([]);
            }

        }

    }catch{
        
    }

    setIsLoading('hide');
  }
  
  useEffect(() => {
    
    if(!F.isEmpty(indicadores)){
      
      const indicaresok = [];

      Object.values(indicadores).forEach( (item) => {

        try {
            
          const valor =  eval(item.indValores); 
   
          const fator = item.changeSign === 1 ? '-100' : '100';

          let porcentagem = (eval(valor)*(fator)).toFixed(2);
           
          porcentagem = isFinite(porcentagem) ? porcentagem : 0;
          
          indicaresok.push({
            id: item.id,
            nome: item.nome,
            porcentagem: `${porcentagem.replace('.', ',')}%`
          });

        } catch {

          indicaresok.push({
            id: item.id,
            nome: item.nome,
            porcentagem: `0%`
          });
        }

      });

      setIndicadoresShow(indicaresok);
      
    }else{
      setIndicadoresShow([]);
    }
    
  },[indicadores]);

  useEffect(() => {
    
    if(!F.isEmpty(indicadoresRef)){
      
      const indicaresok = [];

      Object.values(indicadoresRef).forEach( (item) => {

        try {
            
          const valor =  eval(item.indValores); 
   
          const fator = item.changeSign === 1 ? '-100' : '100';

          let porcentagem = (eval(valor)*(fator)).toFixed(2);
           
          porcentagem = isFinite(porcentagem) ? porcentagem : 0;
          
          indicaresok.push({
            id: item.id,
            nome: item.nome,
            porcentagem: `${porcentagem.replace('.', ',')}%`
          });

        } catch {

          indicaresok.push({
            id: item.id,
            nome: item.nome,
            porcentagem: `0%`
          });
        }

      });

      setIndicadoresRefShow(indicaresok);
      
    }else{

      setIndicadoresRefShow([]);
      
    }
    
  },[indicadoresRef]);

  useEffect(() => {

    const indicaresok = [];
    
    if(!F.isEmpty(indicadoresLoc1)){
      

      Object.values(indicadoresLoc1).forEach( (item) => {

        try {
            
          const valor =  eval(item.indValores); 
   
          const fator = item.changeSign === 1 ? '-100' : '100';

          let porcentagem = (eval(valor)*(fator)).toFixed(2);
           
          porcentagem = isFinite(porcentagem) ? porcentagem : 0;
          
          indicaresok.push({
            id: item.id,
            nome: item.nome,
            porcentagem: `${porcentagem.replace('.', ',')}%`
          });

        } catch {

          indicaresok.push({
            id: item.id,
            nome: item.nome,
            porcentagem: `0%`
          });
        }

      });
      
    }

    setIndicadoresLocShow1(indicaresok);
    
  },[indicadoresLoc1]);

  useEffect(() => {

    const indicaresok = [];
    
    if(!F.isEmpty(indicadoresLoc2)){

      Object.values(indicadoresLoc2).forEach( (item) => {

        try {
            
          const valor =  eval(item.indValores); 
   
          const fator = item.changeSign === 1 ? '-100' : '100';

          let porcentagem = (eval(valor)*(fator)).toFixed(2);
           
          porcentagem = isFinite(porcentagem) ? porcentagem : 0;
          
          indicaresok.push({
            id: item.id,
            nome: item.nome,
            porcentagem: `${porcentagem.replace('.', ',')}%`
          });

        } catch {

          indicaresok.push({
            id: item.id,
            nome: item.nome,
            porcentagem: `0%`
          });
        }

      });
      
    }

    setIndicadoresLocShow2(indicaresok);
    
  },[indicadoresLoc2]);

  useEffect(() => {
    
    const indicaresok = [];

    if(!F.isEmpty(indicadoresLoc3)){

      Object.values(indicadoresLoc3).forEach( (item) => {

        try {
            
          const valor =  eval(item.indValores); 
   
          const fator = item.changeSign === 1 ? '-100' : '100';

          let porcentagem = (eval(valor)*(fator)).toFixed(2);
           
          porcentagem = isFinite(porcentagem) ? porcentagem : 0;
          
          indicaresok.push({
            id: item.id,
            nome: item.nome,
            porcentagem: `${porcentagem.replace('.', ',')}%`
          });

        } catch {

          indicaresok.push({
            id: item.id,
            nome: item.nome,
            porcentagem: `0%`
          });
        }

      });
      
    }

    setIndicadoresLocShow3(indicaresok);
    
  },[indicadoresLoc3]);

  useEffect(() => {
    
    if(selectedTop1 !== 0){

      localStorage.setItem('comparaSel1', selectedTop1);
      setLogoPrint1((selectedTop1 && selTop1.find(option => option.id === selectedTop1)?.logo) || STATISTICS_ICON);

    }

  }, [selectedTop1, selTop1, setLogoPrint1]);

  useEffect(() => {
    
    if(selectedTop2 !== 0){

      localStorage.setItem('comparaSel2', selectedTop2);
      setLogoPrint2((selectedTop2 && selTop2.find(option => option.id === selectedTop2)?.logo) || STATISTICS_ICON);

    }

  }, [selectedTop2, selTop2, setLogoPrint2]);

  useEffect(() => {
    
    if(selectedTop3 !== 0){

      localStorage.setItem('comparaSel3', selectedTop3);  
      setLogoPrint3((selectedTop3 && selTop3.find(option => option.id === selectedTop3)?.logo) || STATISTICS_ICON);   

    }

  }, [selectedTop3, selTop3, setLogoPrint3]);


  useEffect(() => {
    if (selectedTop1 !== 0 && selectedTop2 !== 0 && selectedTop3 !== 0) {
      const lists = ['comparaSel1', 'comparaSel2', 'comparaSel3'];
  
      lists.forEach(currentList => {
        const currentListItems = document.querySelectorAll(`#${currentList} li`);
  
        currentListItems.forEach(currentLi => {
          if (!currentLi.classList.contains('selSelected')) {
            const hasSelectedInOtherLists = lists.some(otherList => {
              if (otherList !== currentList) {
                const otherListItems = document.querySelectorAll(`#${otherList} li.selSelected`);
                return Array.from(otherListItems).some(li => li.id === currentLi.id);
              }
              return false;
            });
  
            if (hasSelectedInOtherLists) {
              currentLi.classList.add('disabled');
              currentLi.setAttribute('title', 'Já está selecionado em outra coluna.');
            } else {
              currentLi.classList.remove('disabled');
              currentLi.removeAttribute('title');
            }
          }
        });
      });
    }
  }, [selectedTop1, selectedTop2, selectedTop3]);
  

  useEffect(() => {
    
    if(isLogged && canLoad === 'comparativos'){

      handleGetIndicadores();
      
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFiltersPeriodoDash, isLogged, canLoad]);

  useEffect(() => {

    if(isLogged && canLoad === 'comparativos' && canLoadLocs){
      handleGetLocadoras();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, canLoad, canLoadLocs]);
  
  useEffect(() => {

    if(isLogged && canLoad === 'comparativos' && hasLogoIdr !== null){
      handleMountTopSels();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, canLoad, hasLogoIdr]);

  useEffect(() => {

    if(isLogged && canLoad === 'comparativos'){
      handleGetSegmentos();
      handleGetTiposLoc();
      handleGetCidades();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, canLoad]);

  useEffect(() => {
    
    setDocTitle(TXTS.COMPARATIVOS_TITLE);

    if(isLogged)

    setTimeout(() => {
      setIsLoading('hide');
    }, 500);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading, isLogged]);

    return (
      <motion.section 
        className="conteudo"

        variants={animacao}
        initial='entrada'
        animate='animacao'
        exit='saida'
        
        onScroll={HandleScroll}
      >
        <div className='container-lg'>
          <h2 className='pageTitle'>{TXTS.COMPARATIVOS_TITLE}</h2>
        </div>

        <div className='container container-lg'>

          <div className='row justify-content-center mt-md-2 mb-2'>

            <div className='col col-12 col-md-3 col-xl-2 mt-4 mt-md-0 d-none'>
                <Select 
                  id='comparaSel1'
                  showFT={showSelTop1}
                  data={selTop1}
                  value={selectedTop1}
                  useLoading={true}
                  setShowLocSelect={setShowSelTop1}
                  onClick={(selValue) => {handleSetLocadora(selValue, 1)}}
                />
            </div>

            <div className='col col-12 col-md-3 col-xl-2 mt-4 mt-md-0 d-none'>
                <Select 
                  id='comparaSel2'
                  showFT={showSelTop2}
                  data={selTop2}
                  value={selectedTop2}
                  useLoading={true}
                  setShowLocSelect={setShowSelTop2}
                  onClick={(selValue) => {handleSetLocadora(selValue, 2)}}
                />
            </div>

            <div className='col col-12 col-md-3 col-xl-2 mt-4 mt-md-0 d-none'>
                <Select 
                  id='comparaSel3'
                  showFT={showSelTop3}
                  data={selTop3}
                  value={selectedTop3}
                  useLoading={true}
                  setShowLocSelect={setShowSelTop3}
                  onClick={(selValue) => {handleSetLocadora(selValue, 3)}}
                />
            </div>

            <div className='col col-6 col-md-3 col-xl-2 mt-4 mt-xl-0'>
              <Select 
                placeholder='Segmentos:'
                data={segmentos}
                dataSelected={filtersUsers.segmentos}
                useLoading={true}
                onClick={(selValue) => {handleSegmentos(selValue)}}
              /> 
            </div>

            <div className='col col-6 col-md-3 col-xl-2 mt-4 mt-xl-0'>
              <Select 
                placeholder='Tipo de Locação:'
                data={tiposLoc}
                dataSelected={filtersUsers.tiposLoc}
                useLoading={true}
                onClick={(selValue) => {handleTiposLoc(selValue)}}
              /> 
            </div>

            <div className='col col-12 col-md-3 col-xxl-2 mt-4 mt-xl-0'>
              <Select 
                placeholder='Estados:'
                data={estados}
                dataSelected={filtersUsers.estados}
                useLoading={true}
                onClick={(selValue) => {handleSetUF(selValue)}}
              />
            </div>

            <div className='col col-12 col-md-3 col-xxl-2 mt-4 mt-xl-0'>
              <Select 
                placeholder='Cidades:'
                data={cidades}
                dataSelected={filtersUsers.cidades}
                useLoading={true}
                onClick={(selValue) => {handleSetCidade(selValue)}}
              />
            </div>

          </div>
         

          <hr></hr>

          <div className='row mt-4 mb-0 mt-md-0 mb-md-3 mx-0 align-items-end'>

            <div className='col col-12 col-md-8 col-xxl-5'>
              <div className='row'>
                <h2 className='pageTitle sub col col-12 col-md-6'>
                  Referente:
                </h2>
                <div className='col col-12 col-md-6'>
                  <Input 
                    style={{marginTop:0}}
                    name='mes_ano_ini'
                    type='month'
                    min={minMaxMes.min_mes}
                    max={minMaxMes.max_mes}
                    value={globalFiltersPeriodoDash.mes_ano_ini}
                    onChange={handleMesAnoIniDash}
                    useLoading={true}
                  />
                </div>
                <i>{TXTS.AVISO_REFERENTE_DOIS}</i>
              </div>
              
            </div>

            <div className='col col-12 col-md-4 col-xxl-7 mt-4 mt-md-0'>
            
              <div className='row  justify-content-end'>

                <div className='col col-6 col-md-3 col-xxl-2'>
                    <Print
                      useValue={false}
                      type='comparativos'
                      titleToPrint='Imprimir Comparativos'
                      dataPrint={indicadoresShow}
                      disablePrint={indicadoresShow.length === 0}
                      indicadoresLocShow1={indicadoresLocShow1}
                      indicadoresLocShow2={indicadoresLocShow2}
                      indicadoresLocShow3={indicadoresLocShow3}
                      lastLocPadraoTop1={lastLocPadraoTop1}
                      lastLocPadraoTop2={lastLocPadraoTop2}
                      lastLocPadraoTop3={lastLocPadraoTop3}
                      logoPrint1={logoPrint1}
                      logoPrint2={logoPrint2}
                      logoPrint3={logoPrint3}
                      selTop1={selTop1}
                      selTop2={selTop2}
                      selTop3={selTop3}
                      selectedTop1={selectedTop1}
                      selectedTop2={selectedTop2}
                      selectedTop3={selectedTop3}
                      indicadoresRefShow={indicadoresRefShow}
                    />
                </div>

                <div className='col col-6 col-md-3 col-xxl-2'>
                    <Excel 
                      useValue={false}
                      type='exportComparativo'
                      title='Download Comparativos'
                      data={indicadoresShow}
                      subject='Comparativos'
                      mes={globalFiltersPeriodoDash.mes_ano_ini}
                      disableExport={indicadoresShow.length === 0}
                      indicadoresRefShow={indicadoresRefShow}
                      indicadoresLocShow1={indicadoresLocShow1}
                      indicadoresLocShow2={indicadoresLocShow2}
                      indicadoresLocShow3={indicadoresLocShow3}
                      lastLocPadraoTop1={lastLocPadraoTop1}
                      lastLocPadraoTop2={lastLocPadraoTop2}
                      lastLocPadraoTop3={lastLocPadraoTop3}
                      selTop1={selTop1}
                      selTop2={selTop2}
                      selTop3={selTop3}
                      selectedTop1={selectedTop1}
                      selectedTop2={selectedTop2}
                      selectedTop3={selectedTop3}
                    />
                </div>

              </div>
                
            </div>
          </div>

          <hr></hr>

          {indicadoresShow.length === 0 &&

            <div className="alert alert-warning" role="alert">
              Nenhum resultado com os filtros aplicados.
            </div>

          }

          {indicadoresShow.length > 0 &&

            <>
             <div className='indicadoresContainer topo'>

              <div className='indicadoresTopo'>

                <span style={{'--bgimg': `url(${(selectedTop1 && selTop1.find(option => option.id === selectedTop1)?.logo) || STATISTICS_ICON})`, cursor: 'pointer'}} 
                  title={`${selectedTop1 && selTop1.find(option => option.id === selectedTop1)?.label} - Clique aqui para selecionar`}
                  onClick={() => selectedTop1Ready ? setShowSelTop1(true) : false}>

                    {typeof selectedTop1 === 'number' && selectedTop1 !== 0 &&
                      <i>{lastLocPadraoTop1}</i>
                    }
                    {selectedTop1Ready &&
                      <>
                        <FontAwesomeIcon className='locadoraSelectArrow' icon={FA['faArrowDown']} />
                        <div className='locadoraSelect'></div>
                      </>
                    }
                    {!selectedTop1Ready &&
                      <FontAwesomeIcon icon={FA['faSpinner']} size='4x' spin color="lightblue"/>
                    }
                </span>

                <span style={{'--bgimg': `url(${(selectedTop2 && selTop2.find(option => option.id === selectedTop2)?.logo) || STATISTICS_ICON})`, cursor: 'pointer'}} 
                  title={`${selectedTop2 && selTop2.find(option => option.id === selectedTop2)?.label} - Clique aqui para selecionar`}
                  onClick={() => selectedTop2Ready ? setShowSelTop2(true) : false}>
                    {typeof selectedTop2 === 'number' && selectedTop2 !== 0 &&
                      <i>{lastLocPadraoTop2}</i>
                    }
                    {selectedTop2Ready &&
                      <>
                        <FontAwesomeIcon className='locadoraSelectArrow' icon={FA['faArrowDown']} />
                        <div className='locadoraSelect'></div>
                      </>
                    }
                    {!selectedTop2Ready &&
                      <FontAwesomeIcon icon={FA['faSpinner']} size='4x' spin color="lightblue"/>
                    }
                </span>

                <span style={{'--bgimg': `url(${(selectedTop3 && selTop3.find(option => option.id === selectedTop3)?.logo) || STATISTICS_ICON})`, cursor: 'pointer'}} 
                  title={`${selectedTop3 && selTop3.find(option => option.id === selectedTop3)?.label} - Clique aqui para selecionar`}
                  onClick={() => selectedTop3Ready ? setShowSelTop3(true) : false}>
                    {typeof selectedTop3 === 'number' && selectedTop3 !== 0 &&
                      <i>{lastLocPadraoTop3}</i>
                    }
                    {selectedTop3Ready &&
                      <>
                        <FontAwesomeIcon className='locadoraSelectArrow' icon={FA['faArrowDown']} />
                        <div className='locadoraSelect'></div>
                      </>
                    }
                    {!selectedTop3Ready &&
                      <FontAwesomeIcon icon={FA['faSpinner']} size='4x' spin color="lightblue"/>
                    }
                </span>

              </div>

                {indicadores.length > 1 &&

                  <div className='indicadoresTopo'>

                    <span style={{'--bgimg': `url(${(selectedTop1 && selTop1.find(option => option.id === selectedTop1)?.logo) || STATISTICS_ICON})`, cursor: 'pointer'}} 
                      title={`${selectedTop1 && selTop1.find(option => option.id === selectedTop1)?.label} - Clique aqui para selecionar`}
                      onClick={() => selectedTop1Ready ? setShowSelTop1(true) : false}>

                        {typeof selectedTop1 === 'number' && selectedTop1 !== 0 &&
                          <i>{lastLocPadraoTop1}</i>
                        }
                        {selectedTop1Ready &&
                          <>
                            <FontAwesomeIcon className='locadoraSelectArrow' icon={FA['faArrowDown']} />
                            <div className='locadoraSelect'></div>
                          </>
                        }
                        {!selectedTop1Ready &&
                          <FontAwesomeIcon icon={FA['faSpinner']} size='4x' spin color="lightblue"/>
                        }
                    </span>

                    <span style={{'--bgimg': `url(${(selectedTop2 && selTop2.find(option => option.id === selectedTop2)?.logo) || STATISTICS_ICON})`, cursor: 'pointer'}} 
                      title={`${selectedTop2 && selTop2.find(option => option.id === selectedTop2)?.label} - Clique aqui para selecionar`}
                      onClick={() => selectedTop2Ready ? setShowSelTop2(true) : false}>
                        {typeof selectedTop2 === 'number' && selectedTop2 !== 0 &&
                          <i>{lastLocPadraoTop2}</i>
                        }
                        {selectedTop2Ready &&
                          <>
                            <FontAwesomeIcon className='locadoraSelectArrow' icon={FA['faArrowDown']} />
                            <div className='locadoraSelect'></div>
                          </>
                        }
                        {!selectedTop2Ready &&
                          <FontAwesomeIcon icon={FA['faSpinner']} size='4x' spin color="lightblue"/>
                        }
                    </span>

                    <span style={{'--bgimg': `url(${(selectedTop3 && selTop3.find(option => option.id === selectedTop3)?.logo) || STATISTICS_ICON})`, cursor: 'pointer'}} 
                      title={`${selectedTop3 && selTop3.find(option => option.id === selectedTop3)?.label} - Clique aqui para selecionar`}
                      onClick={() => selectedTop3Ready ? setShowSelTop3(true) : false}>
                        {typeof selectedTop3 === 'number' && selectedTop3 !== 0 &&
                          <i>{lastLocPadraoTop3}</i>
                        }
                        {selectedTop3Ready &&
                          <>
                            <FontAwesomeIcon className='locadoraSelectArrow' icon={FA['faArrowDown']} />
                            <div className='locadoraSelect'></div>
                          </>
                        }
                        {!selectedTop3Ready &&
                          <FontAwesomeIcon icon={FA['faSpinner']} size='4x' spin color="lightblue"/>
                        }
                    </span>

                  </div>

                }

              </div>
              <div className='indicadoresContainer mb-5'>
              
                {indicadoresShow.map( (item ) => {

                  let col1 = '0%';
                  let col2 = '0%';
                  let col3 = '0%';

                  const findLocRef = (indicadoresRefShow.find(obj => obj.id === item.id));
                  const indLocRef = findLocRef ? findLocRef.porcentagem :  '0%';

                  if (selectedTop1 === 'CLIENTE') {
                    col1 = item.porcentagem;
                  } else if (selectedTop1 === 'IDR') {
                    col1 = indLocRef;
                  } else {
                    const findLocPadrao = indicadoresLocShow1.find(obj => obj.id === item.id);
                    col1 = findLocPadrao ? findLocPadrao.porcentagem : '0%';
                  }

                  if (selectedTop2 === 'CLIENTE') {
                    col2 = item.porcentagem;
                  } else if (selectedTop2 === 'IDR') {
                    col2 = indLocRef;
                  } else {
                    const findLocPadrao = indicadoresLocShow2.find(obj => obj.id === item.id);
                    col2 = findLocPadrao ? findLocPadrao.porcentagem : '0%';
                  }

                  if (selectedTop3 === 'CLIENTE') {
                    col3 = item.porcentagem;
                  } else if (selectedTop3 === 'IDR') {
                    col3 = indLocRef;
                  } else {
                    const findLocPadrao = indicadoresLocShow3.find(obj => obj.id === item.id);
                    col3 = findLocPadrao ? findLocPadrao.porcentagem : '0%';
                  }
                  
                  return(
                    <div className={`indicadorItem ${cLoading ? 'Cloading' : ''}`} key={item.id}>
                      <div className='indicadorNome'>{item.nome}</div>
                      <div className='indicadoresTopo'>

                        <b>{col1}</b>
                        <b>{col2}</b>
                        <b>{col3}</b>                      
                        
                      </div>
                    </div>
                  )
      
                })
                }
      
              </div>
            </>
           
          }
          
          <hr></hr>
          
        
        </div>

        <div className='logoBotton'>
            <img src={LOGO_AUDIT} alt="AUDIT" className='auditLayout'/> 
        </div>
        
      </motion.section>
    );
}

export default Comparativos;