import { useRef, useContext, useEffect } from 'react';
import './index.css';
import { motion } from 'framer-motion';
import { LoaderContext } from '../../../contexts/LoaderContext';

function Switch ({
    text = false,
    on = false,
    onChange = false,
    useLoading = false,
    onColor = '#0fd850',
    offColor = '#d80f0f',
    ballColor = 'white'
}){

    const { cLoading } = useContext(LoaderContext);

    const ballRef = useRef(null);
    const ballPositionX = useRef(null);
    const sliderRef = useRef(null);


    const handleDragStart = (e, info) => {

        e.stopPropagation();

        ballPositionX.current = info.point.x;

    }

    const handleDragEnd = (e, info) => {

        const diff = (info.point.x - ballPositionX.current);

        const setOnOff = diff > 0 ? true : false;

        if (typeof onChange === "function"  && setOnOff !== on) { 

            onChange(setOnOff);
        
        }else{

            ballRef.current.style.transform = setOnOff ? 
                'translateX(24px) translateY(0px) translateZ(0px)' : 'none';

        }

    }

    const handleClick = () => {

        console.log('clicou');
        if (typeof onChange === "function") { 

            onChange(!on);
        
        }
    }

    useEffect(() => {

        ballRef.current.style.transform = on ? 
            'translateX(24px) translateY(0px) translateZ(0px)' : 'none';

        sliderRef.current.style.backgroundColor = on ? onColor : offColor;
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [on]);

    

    return(

        <div className={`switch_container ${cLoading && useLoading ? 'Cloading' :  ''}`}>
            {text &&
            <h6>{text}</h6>
            }
            <div className='switch' onContextMenu={(e) => e.preventDefault()}>
                
                <span ref={sliderRef} onClick={handleClick} className='slider' style={{'--onColor':onColor, '--offColor':offColor, '--ballColor':ballColor}}>
                    <motion.div 
                        ref={ballRef}
                        className='ballSlider'
                        drag='x'
                        dragConstraints={{
                            left: 0,
                            right: 24,
                        }}

                        dragTransition={{ bounceStiffness: 0, bounceDamping: 5 }}

                        onDragStart={(e, info) => {handleDragStart(e, info)}}
                        onDragEnd={(e, info) => {handleDragEnd(e, info)}}
                        
                        
                    >
                    </motion.div>
                </span>
            </div>
        </div>
        
    )
}

export default Switch;