import AnimatedRoutes from '../AnimatedRoutes';
import './index.css';


//COMPONENTES
import Loader from '../Loader';
import Header from '../Header';
import Footer from '../Footer';
import Cookies from '../Cookies';
import Alertas from '../Alertas';


function Layout() {

    return (
        <>
            <Loader />
            <Alertas />
            <Cookies />
            <div className='Layout'>
                <Header />
                <AnimatedRoutes />
                <Footer />
            </div>
        </>
    );
}

export default Layout;