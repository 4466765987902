import { useContext } from 'react';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as FA from '@fortawesome/free-solid-svg-icons';
import InputMask from 'react-input-mask';
import { LoaderContext } from '../../../contexts/LoaderContext';
import { MoedaInput } from '../../../util/Functions';


function Input({
    labelLeft = false,
    labelLeftOnClick = false,
    labelLeftTitle = false,
    labelRight = false,
    labelRightOnClick = false,
    labelRightTitle = false,
    placeholder = false,
    title = false,
    min = false,
    max = false,
    step = false,
    useNumber = false,
    type = 'text',
    height = false,
    maxLength = false,
    minLength = false,
    required = false,
    readOnly = false,
    disabled = false,
    style = false,
    value,
    onChange = false,
    onBlur = false,
    onFocus = false,
    onClick =  false,
    id = false,
    name = false,
    mask = false,
    currency = false,
    allowNegative = true,
    useLoading = false
}) {

    const { cLoading } = useContext(LoaderContext);
    
    const checkMask = (str) => {

        const maskLetter = /[a-zA-Z]/g;
        
        if(maskLetter.test(mask)){
            return str;
        }

        return str.replace(/[^0-9]/g, '');
    }

    const handleOnChange = (e) => {
        
        if(type === 'number' && !currency && isNaN(mask ? checkMask(e.target.value) : e.target.value)){
            return false;
        }
        
        onChange({
            ...e,
            target: {
                ...e.target, 
                name,
                value: mask ? checkMask(e.target.value) : e.target.value,
            }
        })
    }

    const handleOnChangeCurrency = (e) => {
        
        onChange({
            ...e,
            target: {
                ...e.target, 
                name,
                value: MoedaInput(e.target.value, allowNegative),
            }
        })
        
    }

    return (
                
        <div className={`inputContainer ${cLoading && useLoading ? 'Cloading' :  ''} ${disabled ? 'disabled' : ''}`}
        {...(style && { style: style })}
        {...(onClick && { onClick: onClick })}
        >
            
            {labelLeft &&
                <div className={`inputLabel Left ${labelLeftOnClick ? 'pointer' : ''} ${type === 'textarea' ? 'textArea' : ''}`}
                    {...(labelLeftTitle && { title: labelLeftTitle })}
                    {...(labelLeftOnClick && { onClick: labelLeftOnClick })} 
                >
                <FontAwesomeIcon icon={FA[labelLeft]} />
                </div>
            }
            
            {!currency && type !== 'textarea' &&

                <InputMask
                    title={!title ? `${placeholder} ${value !== placeholder ? value : ''}` : title}
                    value={value}
                    type={type === 'number' && !useNumber ? 'text' : type}
                    className={`${labelLeft ? 'labelLeft' : ''} ${labelRight ? 'labelRight' : ''}`}
                    {...(style && { style: style })}
                    {...(id && { id: id })}
                    {...(name && { name: name })}             
                    {...(required && { required: required })}
                    {...(readOnly && { readOnly: readOnly })}
                    {...(disabled && { disabled: disabled })}
                    {...(min && { min: min })}       
                    {...(max && { max: max })}
                    {...(step && { step: step })}
                    {...(maxLength && { maxLength: maxLength })} 
                    {...(minLength && { minLength: minLength })}          
                    {...(type === 'number' && { pattern: '[0-9]*', inputMode: 'numeric' })}
                    {...(onChange && { onChange: handleOnChange })}
                    {...(onBlur && { onBlur: onBlur })}
                    {...(onFocus && { onFocus: onFocus })}
                    {...(onClick && { onClick: onClick })}
                    {...(mask && { mask: mask })}
                />
            }

            {currency && type !== 'textarea' &&

                <input
                    title={!title ? `${placeholder} R$${value}` : title}
                    value={value}  
                    type='text'
                    className={`${labelLeft ? 'labelLeft' : ''} ${labelRight ? 'labelRight' : ''}`}
                    inputMode='decimal'
                    {...(id && { id: id })}
                    {...(name && { name: name })} 
                    {...(required && { required: required })}
                    {...(onChange && { onChange: handleOnChangeCurrency })}
                    {...(onBlur && { onBlur: onBlur })}
                    {...(onFocus && { onFocus: onFocus })}
                    {...(onClick && { onClick: onClick })}
                />
            }

            {type === 'textarea' &&

                <textarea 
                    value={value}
                    placeholder=' '
                    {...(title && { title: title })}
                    maxLength={maxLength ? maxLength : 100}
                    className={`${labelLeft ? 'labelLeft' : ''} ${labelRight ? 'labelRight' : ''}`}
                    style={{height: height ? height : 80}}
                    {...(id && { id: id })}
                    {...(name && { name: name })} 
                    {...(required && { required: required })}
                    {...(onChange && { onChange: onChange })}
                    {...(onBlur && { onBlur: onBlur })}
                    {...(onFocus && { onFocus: onFocus })}
                    {...(onClick && { onClick: onClick })}
                />
            }            

            {placeholder &&
                <label>{required ? '*':''}{placeholder}</label>
            }

            {labelRight &&
                <div className={`inputLabel Right ${labelRightOnClick ? 'pointer' : ''} ${type === 'textarea' ? 'textArea' : ''}`}
                    {...(labelRightTitle && { title: labelRightTitle })}
                    {...(labelRightOnClick && { onClick: labelRightOnClick })}    
                >
                <FontAwesomeIcon icon={FA[labelRight]} />
                </div>
            }
        </div>
    );
}

export default Input;