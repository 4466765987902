import { instance } from "./Default.service";

export const Login = async (data) => {
    
    return await instance.post('auth/login', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const LogOutApi = async () => {

    return await instance.get('auth/logout')
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const LoggedUser = async () => {

    return await instance.get('loggedUser')
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const ChangePass = async (data) => {

    return await instance.post('changePass', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}


export const ChangeLogo = async (data) => {

    return await instance.post('changeLogo', data, {
        headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => {
                return response;
            })
            .catch(error => {
                if(error.response){
                    return error.response;
                }else{
                    return null;
                }
            });
}

export const UpdateUser = async (data) => {
    
    return await instance.post('userUpdate', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const UpdateUserType = async (data) => {
    
    return await instance.post('updateUserType', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const GetUsers = async (data) => {

    return await instance.post('users/getUsers', data)
            .then(response => {
                return response;
            })
            .catch(error => {
                if(error.response){
                    return error.response;
                }else{
                    return null;
                }
            });
}

export const GetUser = async (id = 0) => {

    return await instance.get(`users/getUser/${id}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const SaveUser = async (data) => {

    return await instance.post('users/saveUser', data)
            .then(response => {
                return response;
            })
            .catch(error => {
                if(error.response){
                    return error.response;
                }else{
                    return null;
                }
            });
}

export const DeleteUser = async (id) => {

    return await instance.get(`users/deleteUser/${id}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const createPromoLink = async (data) => {
    
    return await instance.post('createPromoLink', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const validatePromo = async (data) => {
    
    return await instance.post('validatePromo', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const deletePromoLink = async (id) => {

    return await instance.get(`deletePromoLink/${id}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}