import './index.css';
import React, { useEffect, useContext, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from '../../components/Forms/Select';
import Input from '../../components/Forms/Input';
import SingleChartCircle from '../../components/SingleChartCircle';
import { motion } from 'framer-motion';
import { animacao } from '../../util/AnimatedDiv'
import { SiteContext } from '../../contexts/SiteContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { UserContext } from '../../contexts/UserContext';
import { AlertasContext } from '../../contexts/AlertasContext';
import { GlobalFiltersContext } from '../../contexts/GlobalFiltersContext';
import Pagination from '../../components/Pagination';
import * as BL from '../../services/Balancos.service';
import * as DRE from '../../services/Dres.service';
import * as FR from '../../services/Frota.service';
import * as C from '../../services/Company.service';
import * as LOCS from '../../services/LocadorasPadrao.service';
import * as TXTS from '../../util/Texts';
import * as F from '../../util/Functions';
import Btn from '../../components/Forms/Btn';
import { LOGO_AUDIT } from '../../util/Urls';
import Print from '../../components/Print';
import Excel from '../../components/Excel';

function Balancos() {

    const navigate                                    = useNavigate();

    const top                                         = useRef();

    const [titleToPrint, setTitleToPrint]             = useState('Imprimir Balanços');
    const [disablePrint, setDisablePrint]             = useState(true);
    const [typePrint, setTypePrint]                   = useState('bal');
    const [dataPrint, setDataPrint]                   = useState([]);
    const [titleExcel, setTitleExcel]                 = useState('Download Balanços');
    const [subjectExcel, setSubjectExcel]             = useState('Balanços');
    const [columnsExcel, setColumnsExcel]             = useState(['id', 'titulo', 'value']);
    const [columnsTitlesExcel, setColumnsTitlesExcel] = useState(['id', 'Título', 'Valor']);

    const { setIsLoading }                            = useContext(LoaderContext);
    const {
      HandleScroll,
      setErrorCon,
      setDocTitle,
      canLoad
    } = useContext(SiteContext);

    const {
      isLogged,
      userType,
      userPermission,
      isPromoAccess
    } = useContext(UserContext);

    const [balancos, setBalancos]                     = useState([]);
    const [dres, setDres]                             = useState([]);
    const [frotas, setFrotas]                         = useState([]);

    const [totalPagesBal, setTotalPagesBal]           = useState(0);
    const [totalPagesDre, setTotalPagesDre]           = useState(0);
    const [totalPagesFrota, setTotalPagesFrota]       = useState(0);

    const [companies, setCompanies]                   = useState([]);
    const [locadoras, setLocadoras]                   = useState([]);

    const [changeMes, setChangeMes]                   = useState(true);
    const [carregando, setCarregando]                 = useState(false);

    const { 

      globalFiltersPeriodo, setGlobalFiltersPeriodo,
      minMaxMes, setMinMaxMes,
      globalFiltersBal, setGlobalFiltersBal,
      globalFiltersDre, setGlobalFiltersDre,
      globalFiltersFrota, setGlobalFiltersFrota,
      ultimosMesesSelect,
      handleUltimosMeses,
      handleMesAnoIni,
      handleMesAnoFim,
      activeTab,
      setActiveTab,
      periodo,
      verBalDeEmpresa, handleVerBalDeEmpresa,
      verBalDeLocadora, handleVerBalDeLocadora,
      nomeLocadoraParaoBal, nomeVerDeEmpresa
      
    } = useContext(GlobalFiltersContext);


    const nomeVerDe = useMemo(() => {

      return nomeLocadoraParaoBal !== '' ? nomeLocadoraParaoBal : nomeVerDeEmpresa;

    }, [nomeLocadoraParaoBal, nomeVerDeEmpresa]);

    const idVerDe = useMemo(() => {

      return verBalDeLocadora !== 0 ? verBalDeLocadora : verBalDeEmpresa;

    }, [verBalDeLocadora, verBalDeEmpresa]);

    const filtersBal = useMemo(() => {
    
      return {...globalFiltersBal, ...globalFiltersPeriodo};
      
    }, [globalFiltersBal, globalFiltersPeriodo]);

    const filtersDre = useMemo(() => {
    
      return {...globalFiltersDre, ...globalFiltersPeriodo};
      
    }, [globalFiltersDre, globalFiltersPeriodo]);

    const filtersFrota = useMemo(() => {
    
      return {...globalFiltersFrota, ...globalFiltersPeriodo};
      
    }, [globalFiltersFrota, globalFiltersPeriodo]);

    const {

      setAlertShow,
      setAlertBtnOk,
      setAlertBtnCancel,
      setAlertOnOk,
      setAlertType,
      setAlertMsg,
  
    } = useContext(AlertasContext);

    const handlePagination = (page, qual) => {

      if(qual === 'bal'){

        setGlobalFiltersPeriodo({
          ...globalFiltersPeriodo,
          pageBal: page
        });

      }

      if(qual === 'dre'){

        setGlobalFiltersPeriodo({
          ...globalFiltersPeriodo,
          pageDre: page
        });

      }

      if(qual === 'frota'){

        setGlobalFiltersPeriodo({
          ...globalFiltersPeriodo,
          pageFrota: page
        });

      }

      top.current.scrollIntoView(({ behavior: "smooth", block: "start" }));

    }

    const handleOrder = (col, qual) => {

      if(qual === 'bal'){

        setGlobalFiltersBal({
          ...globalFiltersBal,
          page: 1,
          orderBy: col,
          orderDir: globalFiltersBal.orderDir === 'ASC' ? 'DESC' : 'ASC'
        });

      }

      if(qual === 'dre'){

        setGlobalFiltersDre({
          ...globalFiltersDre,
          page: 1,
          orderBy: col,
          orderDir: globalFiltersDre.orderDir === 'ASC' ? 'DESC' : 'ASC'
        });

      }

      if(qual === 'frota'){

        setGlobalFiltersFrota({
          ...globalFiltersFrota,
          page: 1,
          orderBy: col,
          orderDir: globalFiltersFrota.orderDir === 'ASC' ? 'DESC' : 'ASC'
        });

      }

    }

    const handleGetBalancos = async () => {

      if(!changeMes || carregando){
        return;
      }

      setChangeMes(false);
      setCarregando(true);
      setIsLoading('show');
      
      try{

          const result =  await BL.GetBalancos(filtersBal);

          if(result.data){

              if (result.status === 200) {

                setBalancos(result.data.balancos);
                setTotalPagesBal(result.data.last_page);
                
                setGlobalFiltersPeriodo(globalFiltersPeriodo => ({ ...globalFiltersPeriodo, 
                  mes_ano_ini: result.data.mesAnoIniOk.slice(0, -3),
                  mes_ano_fim: result.data.mesAnoFimOk.slice(0, -3)
                }));

                setMinMaxMes(minMaxMes => ({ ...minMaxMes, 
                  min_mes: result.data.minMes.slice(0, -3),
                  max_mes: result.data.maxMes.slice(0, -3)
                }));
                
                setTimeout(() => {
                  setChangeMes(true);
                  setCarregando(false);
                }, 1000);

              }else{

                setBalancos([]);
                setTotalPagesBal(0);
                  
              }

          }else{
              
            setBalancos([]);
            setTotalPagesBal(0);
          
          }

      }catch{

          setAlertShow(true);
          setAlertMsg(TXTS.ERRO_LOGIN_3);
          setAlertType('error');
          setAlertBtnOk(false);
          setErrorCon(true);
          
      }

      setIsLoading('hide');
    }

    const handleGetDres = async () => {

      if(!changeMes || carregando){
        return;
      }

      setIsLoading('show');
      
      try{
      
          const result =  await DRE.GetDres(filtersDre);

          if(result.data){

              if (result.status === 200) {
                
                setDres(result.data.dres);
                setTotalPagesDre(result.data.last_page); 

              }else{

                setDres([]);
                setTotalPagesDre(0); 

              }

          }else{
              
            setDres([]);
            setTotalPagesDre(0); 
          
          }

      }catch{

          setAlertShow(true);
          setAlertMsg(TXTS.ERRO_LOGIN_3);
          setAlertType('error');
          setAlertBtnOk(false);
          setErrorCon(true);
          
      }

      setIsLoading('hide');
    }


    const handleGetFrota = async () => {
      
      if(!changeMes || carregando){
        return;
      }

      setIsLoading('show');
      
      try{
      
        const result =  await FR.GetFrota(filtersFrota);

          if(result.data){

              if (result.status === 200) {
                
                setFrotas(result.data.frota);
                setTotalPagesFrota(result.data.last_page);  

              }else{

                setFrotas([]);
                setTotalPagesFrota(0);

              }

          }else{
              
              setAlertShow(true);
              setAlertMsg(TXTS.ERRO_LOGIN_2);
              setAlertType('error');
              setAlertBtnOk(false);
          
          }

      }catch{

        setFrotas([]);
        setTotalPagesFrota(0);
          
      }

      setIsLoading('hide');
    }

    const handleDoRemove = async (Id, qual) => {

      setIsLoading('show');

      try{

        let result = '';

        switch (qual) {
          case 'bal':
            result = await BL.DeleteBal(Id, idVerDe);
            break;

          case 'dre':
            result = await DRE.DeleteDre(Id, idVerDe);
            break;
          
          case 'frota':
            result = await FR.DeleteFrota(Id, idVerDe);
            break;
        
          default:
            break;
        }

        setAlertBtnCancel(false);

        if(result.data){

          if (result.status === 200) {

            setAlertShow(true);
            setAlertMsg(`${result.data.msg} <br> (${nomeVerDe})`);
            setAlertType('success');
            setAlertBtnOk(false);  

            if(qual === 'bal'){

              handleGetBalancos();

            }
            else if(qual === 'dre'){

              handleGetDres();

            }else{

              handleGetFrota();

            }
          }else{

              setAlertShow(true);
              setAlertMsg(result.data.msg);
              setAlertType('error');
              setAlertBtnOk(false);
          }

        }else{
                
            setAlertShow(true);
            setAlertMsg(TXTS.ERRO_LOGIN_2);
            setAlertType('error');
            setAlertBtnOk(false);
        
        }

      }catch{

          setAlertShow(true);
          setAlertMsg(TXTS.ERRO_LOGIN_3);
          setAlertType('error');
          setAlertBtnOk(false);
          setErrorCon(true);

      }

      setIsLoading('hide');
    }

    const handleRemove = (Id, MesAno, qual) => {
      
      let txtQual = '';

      switch (qual) {
        case 'bal':
          txtQual = 'o Balanço';
          break;

        case 'dre':
          txtQual = 'o DRE';
          break;
         
        case 'frota':
          txtQual = 'a Frota';
          break;
      
        default:
          break;
      }

      setAlertShow(true);
      setAlertType('warning');
      setAlertBtnCancel(true);
      setAlertOnOk(() => () => {handleDoRemove(Id, qual)});
      setAlertMsg(`Remover ${txtQual} de ${MesAno} ${nomeVerDe !== '' ? `(${nomeVerDe})` : ''} ?`);

    }

    const handleGetCompanyList = async () => {
    
      setIsLoading('show');
      
      try{
      
          const result =  await C.GetCompanyList();
  
          if(result.data){
  
              if (result.status === 200) {
                
                setCompanies(result.data.empresas);
  
              }else{
                setCompanies([]);
              }
  
          }
  
      }catch{
        setCompanies([]);
      }
  
      setIsLoading('hide');
    }

    const handleGetLocadoras = async () => {
      
      setIsLoading('show');
      
      try{
      
          const result =  await LOCS.GetLocadoras();
  
          if(result.data){
  
              if (result.status === 200) {
                
                const loc = [
                  {
                    label: 'Selecione:',
                    id: 0
                  },
                  
                ];

                Object.values(result.data.locadoras).forEach( (item) => {

                  loc.push(
                    {
                      label: item.label,
                      id: item.id
                    }
                  )

                });

                setLocadoras(loc); 
                
                
              }
  
          }
  
      }catch{
          
      }
  
      setIsLoading('hide');
    }

    useEffect(() => {
      if(isLogged && canLoad === 'balancos'){
        handleGetBalancos();
      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtersBal, isLogged, canLoad]);

    useEffect(() => {
      if(isLogged && canLoad === 'balancos'){
        handleGetDres();
      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtersDre, isLogged, canLoad]);

    useEffect(() => {
      if(isLogged && canLoad === 'balancos'){
        handleGetFrota();
      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtersFrota, isLogged, canLoad]);

    useEffect(() => {
      function configurePrintData(activeTab) {
        switch (activeTab) {
          case 1:
            setTitleToPrint('Imprimir Balanços');
            setTitleExcel('Download Balanços');
            setSubjectExcel('Balanços');
            setDisablePrint(balancos.length === 0);
            setTypePrint('bal');
            setDataPrint(balancos);
            setColumnsExcel(['id', 'titulo', 'value']);
            setColumnsTitlesExcel(['id', 'Título', 'Valor']);
            break;
          case 2:
            setTitleToPrint('Imprimir DRES');
            setTitleExcel('Download DRES');
            setSubjectExcel('DRES');
            setDisablePrint(dres.length === 0);
            setTypePrint('dres');
            setDataPrint(dres);
            setColumnsExcel(['id', 'titulo', 'value']);
            setColumnsTitlesExcel(['id', 'Título', 'Valor']);
            break;
          case 3:
            setTitleToPrint('Imprimir Frota');
            setTitleExcel('Download Frota');
            setSubjectExcel('Frotas');
            setDisablePrint(frotas.length === 0);
            setTypePrint('frotas');
            setDataPrint(frotas);
            setColumnsExcel(['id', 'frota_operacional', 'valorDre', 'valorNF', 'ticketMedio', 'ocupacao']);
            setColumnsTitlesExcel(['id', 'Frota_Operacional', 'Receita_Locação', 'Valor_Frota_NF', 'Ticket_Médio', 'Ocupação']);
            break;
          default:
            setTitleToPrint('Imprimir Balanços');
            setTitleExcel('Download Balanços');
            setSubjectExcel('Balanços');
            setDisablePrint(balancos.length === 0);
            setTypePrint('bal');
            setDataPrint(balancos);
            setColumnsExcel(['id', 'titulo', 'value']);
            setColumnsTitlesExcel(['id', 'Título', 'Valor']);
            break;
        }
      }
  
      configurePrintData(activeTab);
    }, [activeTab, balancos, dres, frotas]);

    useEffect(() => {

        if(isLogged && canLoad === 'balancos'){

          if(userType === 1){
            handleGetLocadoras();
            handleGetCompanyList();
          }
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogged, userType, canLoad]);

    useEffect(() => {
        
      setDocTitle(TXTS.BALANCO_TITLE);

      if(isLogged)

      setTimeout(() => {
        setIsLoading('hide');
      }, 500);
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading, isLogged]);

    return (
      <motion.section 
        className="conteudo"

        variants={animacao}
        initial='entrada'
        animate='animacao'
        exit='saida'
        
        onScroll={HandleScroll}
      >

        <div className='container-lg'>
          <h2 className='pageTitle'>{TXTS.BALANCO_TITLE}</h2>
        </div>

        <div className='container container-lg'>

          <div className='row justify-content-center mt-md-2 mb-2'>

            {userType === 1 &&

              <>
              <div className='col col-12 col-md-3 col-xl-2 mt-4 mt-md-0'>
                <Select 
                  placeholder='Ver de:'
                  disabled={verBalDeLocadora !== 0}
                  data={companies}
                  value={verBalDeEmpresa}
                  useLoading={true}
                  onClick={(selValue, txt) => {handleVerBalDeEmpresa(selValue, txt)}}
                />
              </div>

              <div className='col col-12 col-md-3 col-xl-2 mt-4 mt-md-0'>
                <Select 
                  placeholder='Ver de Locadora:'
                  disabled={verBalDeEmpresa !== 0}
                  data={locadoras}
                  value={verBalDeLocadora}
                  useLoading={true}
                  onClick={(selValue, txt) => {handleVerBalDeLocadora(selValue, txt)}}
                />
              </div>
              </>
              
            }

            <div className='col col-12 col-md-3 col-xl-2 mt-4 mt-md-0'>
                <Select 
                  placeholder='Período:'
                  data={ultimosMesesSelect}
                  value={globalFiltersPeriodo.ultimos_meses}
                  useLoading={true}
                  onClick={(selValue) => {handleUltimosMeses(selValue)}}
                />
            </div>

            <div className='col col-12 col-md-3 col-xxl-2 mt-4 mt-md-0'>
              <Input 
                name='mes_ano_ini'
                placeholder='Início:'
                type='month'
                min={minMaxMes.min_mes}
                max={globalFiltersPeriodo.mes_ano_fim}
                value={globalFiltersPeriodo.mes_ano_ini < minMaxMes.min_mes ? minMaxMes.min_mes : globalFiltersPeriodo.mes_ano_ini}
                onChange={handleMesAnoIni}
                disabled={globalFiltersPeriodo.mes_ano_fim_disabled}
                useLoading={true}
              />
            </div>

            <div className='col col-12 col-md-3 col-xxl-2 mt-4 mt-md-0'>
              <Input 
                name='mes_ano_fim'
                placeholder='Fim:'
                type='month'
                min={globalFiltersPeriodo.mes_ano_ini}
                max={minMaxMes.max_mes}
                value={globalFiltersPeriodo.mes_ano_fim > minMaxMes.max_mes ? minMaxMes.max_mes : globalFiltersPeriodo.mes_ano_fim}
                onChange={handleMesAnoFim}
                disabled={globalFiltersPeriodo.mes_ano_ini_disabled}
                useLoading={true}
              />
            </div>

          </div>

          {/* <div className='row justify-content-center mt-md-2 mb-2'>
            
            <div className='col col-12 col-md-3 mt-4'>
              <Print
                type={typePrint}
                nomeVerDe={nomeVerDe}
                titleToPrint={titleToPrint}
                disablePrint={disablePrint}
                dataPrint={dataPrint}
              />
            </div>

            <div className='col col-12 col-md-3 mt-4'>
              <Excel 
                type='export'
                title={titleExcel}
                data={dataPrint}
                subject={subjectExcel}
                disableExport={disablePrint}
                multi={true}
                columns={columnsExcel}
                columnsTitles={columnsTitlesExcel}
            />
            </div>

            
          </div> */}

          {userType === 1 &&

            <>
              <hr></hr>
              <div className='row justify-content-center mt-md-2 mb-2'>

                <div className='col-12 col-md-4 col-xl-3'>
                  <Btn 
                    title='Editar Campos de Balanço'
                    value='Editar Campos de Balanço'
                    type='button'
                    btnType='primary'
                    iconRight='faPenToSquare'
                    onClick={() => {navigate('campos/bal')}}
                    useLoading={true}
                  />
                </div>

                <div className='col-12 col-md-4 col-xl-3 mt-3 mt-md-0'>
                  <Btn 
                    title='Editar Campos de DRE'
                    value='Editar Campos de DRE'
                    type='button'
                    btnType='primary'
                    iconRight='faPenToSquare'
                    onClick={() => {navigate('campos/dre')}}
                    useLoading={true}
                  />
                </div>

              </div>
            </>
          
          }
          <hr></hr>
          <div className="card mb-4" ref={top}>
            <div className="card-header">
              <div className="nav nav-tabs card-header-tabs" id="nav-tab" role="tablist">
                <button onClick={() => {setActiveTab(1)}} className={`nav-link ${activeTab === 1 ? 'active' : ''}`} data-bs-toggle="tab" data-bs-target="#bal" type="button" role="tab" aria-controls="nav-bal" aria-selected={activeTab === 1 ? true : false}>BALANÇOS</button>
                <button onClick={() => {setActiveTab(2)}} className={`nav-link ${activeTab === 2 ? 'active' : ''}`} data-bs-toggle="tab" data-bs-target="#dre" type="button" role="tab" aria-controls="nav-dre" aria-selected={activeTab === 2 ? true : false}>DRES</button>
                <button onClick={() => {setActiveTab(3)}} className={`nav-link ${activeTab === 3 ? 'active' : ''}`} data-bs-toggle="tab" data-bs-target="#frota" type="button" role="tab" aria-controls="nav-frota" aria-selected={activeTab === 3 ? true : false}>FROTA</button>
              </div>
            </div>
            
            <div className="card-body">

              <div className="tab-content" id="nav-tabContent">

                  <div className='row mt-4 mb-0 mt-md-0 mb-md-3 mx-0 align-items-end'>

                    <div className='col col-12 col-md-8 col-xxl-5'>
                      <h2 className='pageTitle sub'>
                        Período: {periodo}
                        {nomeLocadoraParaoBal !== '' &&
                        <> - ({nomeLocadoraParaoBal})</>
                        }

                        {nomeVerDeEmpresa !== '' &&
                        <> - ({nomeVerDeEmpresa})</>
                        }
                      </h2>
                    </div>

                    <div className='col col-12 col-md-4 col-xxl-7 mt-4 mt-md-0'>
                      <div className='row  justify-content-end'>

                        <div className={`col-${userPermission === 1 ? '4' : '6'} col-md-3 col-xxl-2`}>
                            <Print
                              useValue={false}
                              type={typePrint}
                              nomeVerDe={nomeVerDe}
                              titleToPrint={titleToPrint}
                              disablePrint={disablePrint}
                              dataPrint={dataPrint}
                            />
                        </div>

                        <div className={`col-${userPermission === 1 ? '4' : '6'} col-md-3 col-xxl-2`}>
                          <Excel 
                              useValue={false}
                              type='export'
                              title={titleExcel}
                              data={dataPrint}
                              subject={subjectExcel}
                              disableExport={disablePrint}
                              multi={true}
                              columns={columnsExcel}
                              columnsTitles={columnsTitlesExcel}
                          />
                        </div>

                        {userPermission === 1 && !isPromoAccess &&
                          
                          <div className='col-4 col-md-3 col-xxl-2'>

                              {activeTab === 1 &&
                                <Btn 
                                  title={nomeVerDe !== '' ? `Adicionar Balanço ${nomeVerDe}` : 'Adicionar Balanço'}
                                  {...(nomeVerDe !== '' && { value: nomeVerDe })}                        
                                  type='button'
                                  // disabled={verBalDeEmpresa !== 0}
                                  btnType='primary'
                                  iconLeft='faFolderPlus'
                                  onClick={() => {navigate('bal')}}
                                  useLoading={true}
                                />
                              }

                              {activeTab === 2 &&
                                <Btn 
                                  title={nomeVerDe !== '' ? `Adicionar DRE ${nomeVerDe}` : 'Adicionar DRE'}
                                  {...(nomeVerDe !== '' && { value: nomeVerDe })}
                                  type='button'
                                  // disabled={verBalDeEmpresa !== 0}
                                  btnType='primary'
                                  iconRight='faFolderPlus'
                                  onClick={() => {navigate('dre')}}
                                  useLoading={true}
                                />
                              }

                              {activeTab === 3 &&
                                <Btn 
                                  title={nomeVerDe !== '' ? `Adicionar Frota ${nomeVerDe}` : 'Adicionar Frota'}
                                  {...(nomeVerDe !== '' && { value: nomeVerDe })}                        
                                  type='button'
                                  // disabled={verBalDeEmpresa !== 0}
                                  btnType='primary'
                                  iconLeft='faFolderPlus'
                                  onClick={() => {navigate('frota')}}
                                  useLoading={true}
                                />
                              }
                              
                          </div>

                        }

                      </div>
                    </div>

                  </div>
                  <hr></hr>
                  <div className={`tab-pane fade mt-5 ${activeTab === 1 ? 'show active' : ''}`} id="bal" role="tabpanel">

                    {balancos.length > 0 &&
                      <>
                      <div className={`row ${balancos.length > 1 ? 'justify-content-start' : 'justify-content-center'} g-4`}>

                      {balancos.map( (item, key) => {
                        return(
                          <div key={key} className='col-12 col-md-6 col-xxl-4'>
                            <div className='card text-bg-light mb-3'>
                              <div className="card-header pointer text-end" title='Ordenar' onClick={()=>{handleOrder('mes_ano', 'bal')}}>{item.mes_ano}</div>
                                <div className="card-body lh-1" style={{hyphens: 'auto'}}>

                                  {!F.isEmpty(item.campos) &&

                                    Object.keys(item.campos).map((key) => {
                                        return(

                                              <div key={key} className='row align-items-center my-0 mx-0 py-0 px-0 overflow-hidden'>

                                                <div className='col-5 col-md-8 my-1 mx-0 py-0 px-0 text-start'>
                                                  <b className='pointer' title='Ordenar' onClick={()=>{handleOrder(item.campos[key]['id'], 'bal')}}>{item.campos[key]['titulo']}</b>
                                                </div>

                                                <div className='col-7 col-md-4 my-1 mx-0 py-0 px-0 text-end'>
                                                  R$ {item.campos[key]['value']}
                                                </div>

                                                <hr className='my-2 mx-0'></hr>
                                              
                                              </div>
                                                                                    
                                        )
                                    })
                                  }          
                                  
                                  {userPermission === 1 && !isPromoAccess &&
                                    
                                    <div className='row justify-content-center justify-content-md-end my-0 my-0'>
                                      <div className='col-5 col-md-2 p-1 m-2'>
                                        <Btn 
                                          title='Editar'
                                          type='button'
                                          btnType='primary'
                                          iconRight='faPenToSquare'
                                          onClick={() => {navigate(`bal/${item.id}`)}}
                                          useLoading={true}
                                        />
                                      </div>

                                      <div className='col-5 col-md-2 p-1 m-2'>
                                        <Btn 
                                          title='Remover'
                                          type='button'
                                          btnType='danger'
                                          iconRight='faTrashCan'
                                          onClick={() => {handleRemove(item.id, item.mes_ano, 'bal')}}
                                          useLoading={true}
                                        />
                                      </div>
                                    </div>

                                  }                                  
                                  
                                </div>
                            </div>
                          </div>
                        )
                        })
                      }

                      </div>

                      <Pagination 
                        currentPage={globalFiltersPeriodo.pageBal}
                        totalPage={totalPagesBal}
                        onClick={(setPage)=>{handlePagination(setPage, 'bal')}}
                      />
                      </>
                    }

                    {balancos.length === 0 &&
                      <div className="alert alert-warning" role="alert">
                        Nenhum resultado com os filtros aplicados.
                      </div>
                    }

                  </div>

                  <div className={`tab-pane fade mt-5 ${activeTab === 2 ? 'show active' : ''}`} id="dre" role="tabpanel">

                    {dres.length > 0 &&

                      <>
                        <div className={`row ${dres.length > 1 ? 'justify-content-start' : 'justify-content-center'} g-4`}>

                        {dres.map( (item, key) => {
                          return(
                            <div key={key} className='col-12 col-md-6 col-xxl-4'>
                              <div className='card text-bg-light mb-3'>
                                <div className="card-header pointer text-end" title='Ordenar' onClick={()=>{handleOrder('mes_ano', 'dre')}}>{item.mes_ano}</div>
                                  <div className="card-body lh-1" style={{hyphens: 'auto'}}>

                                    {!F.isEmpty(item.campos) &&

                                      Object.keys(item.campos).map((key) => {
                                          return(

                                                <div key={key} className='row align-items-center my-0 mx-0 py-0 px-0 overflow-hidden'>

                                                  <div className='col-5 col-md-8 my-1 mx-0 py-0 px-0 text-start'>
                                                    <b className='pointer' title='Ordenar' onClick={()=>{handleOrder(item.campos[key]['id'], 'dre')}}>{item.campos[key]['titulo']}</b>
                                                  </div>

                                                  <div className='col-7 col-md-4 my-1 mx-0 py-0 px-0 text-end'>
                                                    R$ {item.campos[key]['value']}
                                                  </div>

                                                  <hr className='my-2 mx-0'></hr>
                                                
                                                </div>
                                                                                    
                                          )
                                      })
                                    }
                                    
                                    {userPermission === 1 && !isPromoAccess &&
                                      
                                      <div className='row justify-content-center justify-content-md-end my-0 my-0'>
                                        <div className='col-5 col-md-2 p-1 m-2'>
                                          <Btn 
                                            title='Editar'
                                            type='button'
                                            btnType='primary'
                                            iconRight='faPenToSquare'
                                            onClick={() => {navigate(`dre/${item.id}`)}}
                                            useLoading={true}
                                          />
                                        </div>

                                        <div className='col-5 col-md-2 p-1 m-2'>
                                          <Btn 
                                            title='Remover'
                                            type='button'
                                            btnType='danger'
                                            iconRight='faTrashCan'
                                            onClick={() => {handleRemove(item.id, item.mes_ano, 'dre')}}
                                            useLoading={true}
                                          />
                                        </div>
                                      </div>

                                    }
                                            
                                  </div>
                              </div>
                            </div>
                          )
                          })
                        }

                        </div>

                        <Pagination 
                          currentPage={globalFiltersPeriodo.pageDre}
                          totalPage={totalPagesDre}
                          onClick={(setPage)=>{handlePagination(setPage, 'dre')}}
                        />
                      </>

                    }

                    {dres.length === 0 &&
                      <div className="alert alert-warning" role="alert">
                        Nenhum resultado com os filtros aplicados.
                      </div>
                    }
                    
                  </div>

                  <div className={`tab-pane fade my-5 ${activeTab === 3 ? 'show active' : ''}`} id="frota" role="tabpanel">

                    {frotas.length > 0 &&
                      <>
                      <div className={`row ${frotas.length > 1 ? 'justify-content-start' : 'justify-content-center'} g-4`}>

                      {frotas.map( (item, key) => {
                        return(
                          <div key={key} className='col-12 col-md-6 col-xxl-4'>
                            <div className='card text-bg-light mb-3'>
                              <div className="card-header pointer text-end" title='Ordenar' onClick={()=>{handleOrder('mes_ano', 'frota')}}>{item.mesAno}</div>
                                <div className="card-body lh-1" style={{hyphens: 'auto'}}>
                                
                                <div className='row align-items-center my-0 mx-0 py-0 px-0 overflow-hidden'>

                                  <div className='col-12 my-1 mx-0 py-0 px-0 text-center mb-3'>
                                    <b className='pointer' title='Ordenar' onClick={()=>{handleOrder('ocupacao', 'frota')}}>Ocupação no Período:</b>
                                  </div>

                                  {activeTab === 3 &&
                                    <div className='col-12 my-1 mx-0 py-0 px-0 text-start'>
                                      <SingleChartCircle 
                                        percent={item.ocupacao}
                                        showOver={true}
                                        strokeColor='#ccc'
                                        progressColor='rgb(0,168,28)'
                                      /> 
                                    </div>
                                  }
                                  
                                  
                                  <hr className='my-2 mx-0'></hr>
                                
                                </div>

                                <div className='row align-items-center my-0 mx-0 py-0 px-0 overflow-hidden'>

                                  <div className='col-5 col-md-8 my-1 mx-0 py-0 px-0 text-start'>
                                    <b className='pointer' title='Ordenar' onClick={()=>{handleOrder('frota_operacional', 'frota')}}>Frota Operacional:</b>
                                  </div>

                                  <div className='col-7 col-md-4 my-1 mx-0 py-0 px-0 text-end'>
                                    {item.frota_operacional}
                                  </div>

                                  <hr className='my-2 mx-0'></hr>
                                
                                </div>

                                <div className='row align-items-center my-0 mx-0 py-0 px-0 overflow-hidden'>

                                  <div className='col-5 col-md-8 my-1 mx-0 py-0 px-0 text-start'>
                                    <b>Receita de Locação:</b>
                                  </div>

                                  <div className='col-7 col-md-4 my-1 mx-0 py-0 px-0 text-end'>
                                    R$ {item.valorDre}
                                  </div>

                                  <hr className='my-2 mx-0'></hr>
                                
                                </div>

                                <div className='row align-items-center my-0 mx-0 py-0 px-0 overflow-hidden'>

                                  <div className='col-5 col-md-8 my-1 mx-0 py-0 px-0 text-start'>
                                    <b className='pointer' title='Ordenar' onClick={()=>{handleOrder('valor_frota_nf', 'frota')}}>Valor Frota NF:</b>
                                  </div>

                                  <div className='col-7 col-md-4 my-1 mx-0 py-0 px-0 text-end'>
                                    R$ {item.valorNF}
                                  </div>

                                  <hr className='my-2 mx-0'></hr>
                                
                                </div>

                                <div className='row align-items-center my-0 mx-0 py-0 px-0 overflow-hidden'>

                                  <div className='col-5 col-md-8 my-1 mx-0 py-0 px-0 text-start'>
                                    <b className='pointer' title='Ordenar' onClick={()=>{handleOrder('ticket_medio', 'frota')}}>Ticket Médio:</b>
                                  </div>

                                  <div className='col-7 col-md-4 my-1 mx-0 py-0 px-0 text-end'>
                                    R$ {item.ticketMedio}
                                  </div>

                                  <hr className='my-2 mx-0'></hr>
                                
                                </div>

                                {/* <div className='row align-items-center my-0 mx-0 py-0 px-0 overflow-hidden'>

                                  <div className='col-5 col-md-8 my-1 mx-0 py-0 px-0 text-start'>
                                    <b>Média Diária  - ({item.diasMes} dias):</b>
                                  </div>

                                  <div className='col-7 col-md-4 my-1 mx-0 py-0 px-0 text-end'>
                                    R$ {item.receitaDiariaMes}
                                  </div>

                                  <hr className='my-2 mx-0'></hr>
                                
                                </div> */}
                                          
                                {userPermission === 1 && !isPromoAccess &&

                                  <div className='row justify-content-center justify-content-md-end my-0 my-0'>
                                    <div className='col-5 col-md-2 p-1 m-2'>
                                      <Btn 
                                        title='Editar'
                                        type='button'
                                        btnType='primary'
                                        iconRight='faPenToSquare'
                                        onClick={() => {navigate(`frota/${item.id}`)}}
                                        useLoading={true}
                                      />
                                    </div>

                                    <div className='col-5 col-md-2 p-1 m-2'>
                                      <Btn 
                                        title='Remover'
                                        type='button'
                                        btnType='danger'
                                        iconRight='faTrashCan'
                                        onClick={() => {handleRemove(item.id, item.mesAno, 'frota')}}
                                        useLoading={true}
                                      />
                                    </div>
                                  </div>
                                }
                                    
                                </div>
                            </div>
                          </div>
                        )
                        })
                      }

                      </div>

                      <Pagination 
                        currentPage={globalFiltersPeriodo.pageFrota}
                        totalPage={totalPagesFrota}
                        onClick={(setPage)=>{handlePagination(setPage, 'frota')}}
                      />
                      </>
                    }

                    {balancos.length === 0 &&
                      <div className="alert alert-warning" role="alert">
                        Nenhum resultado com os filtros aplicados.
                      </div>
                    }

                  </div>

              </div>

            </div>
            
          </div>
        
        </div>

        <div className='logoBotton'>
            <img src={LOGO_AUDIT} alt="AUDIT" className='auditLayout'/> 
        </div>
            
      </motion.section>    
    );
}

export default Balancos;