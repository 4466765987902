import { instance } from "./Default.service";

export const CheckCnpjCpf = async (data) => {

    return await instance.post('functions/checkCnpjCpf', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const UpdateCompany = async (data) => {

    return await instance.post('updateCompany', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const GetCompanyList = async () => {

    return await instance.get('getCompanyList')
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}