import React, { useContext } from 'react';
import './index.css';
import LogoLoader from './logo';
import { SITE_TITLE } from '../../util/Texts';
import { LoaderContext } from '../../contexts/LoaderContext';
import { SiteContext } from '../../contexts/SiteContext';


function Loader() {

  const {

    isLoading,
    loadClassName,

  } = useContext(LoaderContext);

  const { 
    logoIdr,
    hasLogoIdr 
  } = useContext(SiteContext);
  
  return (
    <div className={`Loader ${isLoading} ${loadClassName}`}>

      {hasLogoIdr &&
        <img src={logoIdr} alt={SITE_TITLE}/>
      }
        <LogoLoader />
    </div>
  );
}

export default Loader;