import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCircleInfo, faTriangleExclamation, faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import './index.css';
import Btn from '../Forms/Btn';
import ChangePass from '../Forms/ChangePass';
import { AlertasContext } from '../../contexts/AlertasContext';

function Alertas() {
    
    const {
        
        show,
        btnClose,
        btnOk,
        btnOkTxt,
        btnOkType,
        btnCancel,
        btnCancelTxt,
        btnCancelType,
        closeOutClick,
        type,
        alertStyle,
        toastPostion,
        msgOk,
        changePass,
        alertTitle,
        handleCloseAlert,
        handleOKAlert,
        handleCancelAlert,
        htmlItem

    } = useContext(AlertasContext);
    
    return (
        
        <div className={`alertArea ${alertStyle === 1 ? 'normal' : 'toasty'} ${show ? 'show' : 'hide'} ${toastPostion}`} onClick={closeOutClick ? handleCloseAlert : undefined}>
            <div className='alerta' {...(alertStyle === 1 && { onClick: (e) => e.stopPropagation() })}>
                
                {alertStyle === 1 &&
                    <p className={`alertTitle ${type}`}>
                        {alertTitle}
                        {btnClose &&
                            <FontAwesomeIcon onClick={handleCloseAlert} className='closeAlert' icon={faCircleXmark} />
                        }
                    </p>
                }
                
                {alertStyle === 2 &&
                    <>
                        <FontAwesomeIcon onClick={handleCloseAlert} className='closeAlert' icon={faCircleXmark} />
                        <div className={`toastAlertIcon ${type}`}>
                        {type === 'primary' &&
                            <FontAwesomeIcon icon={faCircleInfo} className='toastAlertIconInfo'/>                    
                        }
                        {type === 'warning' &&
                            <FontAwesomeIcon icon={faTriangleExclamation} className='toastAlertIconInfo'/>                    
                        }
                        {type === 'success' &&
                            <FontAwesomeIcon icon={faCircleCheck} className='toastAlertIconInfo'/>                    
                        }
                        {type === 'error' &&
                            <FontAwesomeIcon icon={faCircleExclamation} className='toastAlertIconInfo'/>                    
                        }
                        </div>
                    </>
                }

                {msgOk.length > 0 &&

                    <div className='alertMsg'>
                                    
                        {msgOk.map( (item, key) => {
                            return(
                            <p key={key}>{item}</p>
                            )
                        })}
                        
                    </div>
                }
                
                
                {(btnOk || btnCancel) && alertStyle === 1 &&
                    <form className='row formAlert'>
                        {changePass &&
                            <ChangePass />
                        }
                            <div className='row mb-4 justify-content-center'>
                                
                                {btnCancel &&
                                
                                    <div className='col col-12 col-lg-5 mt-3 mt-md-0'>
                                        <Btn 
                                        btnType={btnCancelType}
                                        value={btnCancelTxt}
                                        onClick={handleCancelAlert}
                                        type='button'
                                        />
                                    </div>

                                }

                                {btnOk &&

                                    <div className='col col-12 col-lg-5 mt-3 mt-md-0'>
                                        <Btn 
                                            btnType={btnOkType}
                                            value={btnOkTxt}
                                            onClick={handleOKAlert}
                                            type='submit'
                                            />
                                    </div>
                                    
                                }
                            </div>
                    </form>
                }

                {alertStyle === 1 && htmlItem && (
                    <div className='alertHtmlItem'>{htmlItem}</div>
                )}
                
            </div>
            
        </div>
        
    );
}

export default Alertas;