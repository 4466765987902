import './index.css';
import React, { useEffect, useContext, useState, useMemo } from 'react';
import Select from '../../components/Forms/Select';
import Input from '../../components/Forms/Input';
import { motion } from 'framer-motion';
import { animacao } from '../../util/AnimatedDiv'
import { SiteContext } from '../../contexts/SiteContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { UserContext } from '../../contexts/UserContext';
import { AlertasContext } from '../../contexts/AlertasContext';
import { GlobalFiltersContext } from '../../contexts/GlobalFiltersContext';
import { Chart } from "react-google-charts";
import * as BL from '../../services/Balancos.service';
import * as DRE from '../../services/Dres.service';
import * as FR from '../../services/Frota.service';
import * as C from '../../services/Company.service';
import * as LOCS from '../../services/LocadorasPadrao.service';
import * as TXTS from '../../util/Texts';
import * as F from '../../util/Functions';
import { LOGO_AUDIT } from '../../util/Urls';

function Graficos() {

    const { setIsLoading }                               = useContext(LoaderContext);
    const {
      HandleScroll,
      setErrorCon,
      setDocTitle,
      canLoad
    } = useContext(SiteContext);

    const { isLogged, userType }            = useContext(UserContext);

    const [balancos, setBalancos]           = useState([]);
    const [dres, setDres]                   = useState([]);
    const [frotas, setFrotas]               = useState([]);

    const [changeMes, setChangeMes] = useState(true);
    const [carregando, setCarregando] = useState(false);

    const [companies, setCompanies] = useState([]);
    const [locadoras, setLocadoras] = useState([]);

    const { 

      globalFiltersPeriodo, setGlobalFiltersPeriodo,
      periodo, minMaxMes, setMinMaxMes,
      ultimosMesesSelect,
      handleUltimosMeses,
      handleMesAnoIni,
      handleMesAnoFim,
      activeTab,
      setActiveTab,
      verBalDeEmpresa, handleVerBalDeEmpresa,
      verBalDeLocadora, handleVerBalDeLocadora,
      nomeLocadoraParaoBal, nomeVerDeEmpresa
      
    } = useContext(GlobalFiltersContext);

    const filters = useMemo(() => {
      
      const localFilters = {

        orderBy: 'mes_ano',
        orderDir: 'ASC',
        paginate: 0,
        graficos: true
  
      };
    
      return {...localFilters, ...globalFiltersPeriodo};
      
    }, [globalFiltersPeriodo]);

    const {

      setAlertShow,
      setAlertBtnOk,
      setAlertType,
      setAlertMsg,
  
    } = useContext(AlertasContext);

    const handleGetBalancos = async () => {
        
      if(!changeMes || carregando){
        return;
      }

      setChangeMes(false);
      setCarregando(true);
      setIsLoading('show');
      
      try{

          const result =  await BL.GetBalancos(filters);

          if(result.data){

              if (result.status === 200) {

                if(result.data.balancos.length > 0){

                  let titulos = [
                      'Mês/Ano'
                  ];

                  for (const value of Object.values(result.data.balancos[0].campos)) {
                    titulos.push(value['titulo'].replace(/:\s*$/, ''));
                  } 

                  let data = [
                    titulos,
                  ];
                  
                  result.data.balancos.forEach((item) => {

                    let infos = [
                      item.mes_ano,
                    ];

                    for (const value of Object.values(item.campos)) {
                      infos.push(parseFloat(F.Decimal(value['value'])));
                    } 

                    data.push(infos);
                    
                  });

                  setBalancos(data);
               

                }else{

                  setBalancos([]);

                }

                setGlobalFiltersPeriodo(globalFiltersPeriodo => ({ ...globalFiltersPeriodo, 
                  mes_ano_ini: result.data.mesAnoIniOk.slice(0, -3),
                  mes_ano_fim: result.data.mesAnoFimOk.slice(0, -3)
                }));

                setMinMaxMes(minMaxMes => ({ ...minMaxMes, 
                  min_mes: result.data.minMes.slice(0, -3),
                  max_mes: result.data.maxMes.slice(0, -3)
                }));

                setTimeout(() => {
                  setChangeMes(true);
                  setCarregando(false);
                }, 1000);

              }else{

                setBalancos([]);

              }

          }else{
              
              setBalancos([]);
          
          }

      }catch{

          setAlertShow(true);
          setAlertMsg(TXTS.ERRO_LOGIN_3);
          setAlertType('error');
          setAlertBtnOk(false);
          setErrorCon(true);
          
      }

      setIsLoading('hide');
    }

    const handleGetDres = async () => {
        
      if(!changeMes || carregando){
        return;
      }

      setIsLoading('show');
      
      try{
      
          const result =  await DRE.GetDres(filters);

          if(result.data){

            if (result.status === 200) {

              if(result.data.dres.length > 0){

                let titulos = [
                    'Mês/Ano'
                ];

                for (const value of Object.values(result.data.dres[0].campos)) {
                  titulos.push(value['titulo'].replace(/:\s*$/, ''));
                } 

                let data = [
                  titulos,
                ];
                
                result.data.dres.forEach((item) => {

                  let infos = [
                    item.mes_ano,
                  ];

                  for (const value of Object.values(item.campos)) {
                    infos.push(parseFloat(F.Decimal(value['value'])));
                  } 

                  data.push(infos);
                  
                });

                setDres(data);                 

              }else{

                setDres([]);

              }

            }else{

              setDres([]);

              }

          }else{
              
              setAlertShow(true);
              setAlertMsg(TXTS.ERRO_LOGIN_2);
              setAlertType('error');
              setAlertBtnOk(false);
          
          }

      }catch{

          setAlertShow(true);
          setAlertMsg(TXTS.ERRO_LOGIN_3);
          setAlertType('error');
          setAlertBtnOk(false);
          setErrorCon(true);
          
      }

      setIsLoading('hide');
    }

    const handleGetFrota = async () => {
      
      if(!changeMes || carregando){
        return;
      }

      setIsLoading('show');
      
      try{
      
        const result =  await FR.GetFrota(filters);

          if(result.data){

              if (result.status === 200) {
                
                if(result.data.frota.length > 0){

                  const frota = result.data.frota;

                  let titulos = [
                      'Mês/Ano',
                      // 'Frota',
                      // 'Frota Operacional',
                      'Ocupação',
                      'Receita de Locação',
                      'Ticket Médio'
                  ];
  
                  let data = [
                    titulos,
                  ];

                  Object.values(frota).forEach( (item) => {
                    
                    data.push([
                      item.mesAno,
                      // item.frota,
                      // item.frota_operacional,
                      `${item.ocupacao}%`,
                      parseFloat(F.Decimal(item.valorDre)),
                      parseFloat(F.Decimal(item.ticketMedio))
                    ]);

                  });
                  
                  setFrotas(data);
  
                }else{
  
                  setFrotas([]);
  
                }

              }else{

                setFrotas([]);

              }

          }else{
              
            setFrotas([]);
          
          }

      }catch{

        setAlertShow(true);
        setAlertMsg(TXTS.ERRO_LOGIN_3);
        setAlertType('error');
        setAlertBtnOk(false);
        setErrorCon(true);
          
      }

      setIsLoading('hide');
    }
    
    const handleGetCompanyList = async () => {
    
      setIsLoading('show');
      
      try{
      
          const result =  await C.GetCompanyList();
  
          if(result.data){
  
              if (result.status === 200) {
                
                setCompanies(result.data.empresas);
  
              }else{
                setCompanies([]);
              }
  
          }
  
      }catch{
        setCompanies([]);
      }
  
      setIsLoading('hide');
    }

    const handleGetLocadoras = async () => {
      
      setIsLoading('show');
      
      try{
      
          const result =  await LOCS.GetLocadoras();
  
          if(result.data){
  
              if (result.status === 200) {
                
                const loc = [
                  {
                    label: 'Selecione:',
                    id: 0
                  },
                  
                ];

                Object.values(result.data.locadoras).forEach( (item) => {

                  loc.push(
                    {
                      label: item.label,
                      id: item.id
                    }
                  )

                });

                setLocadoras(loc); 
                
                
              }
  
          }
  
      }catch{
          
      }
  
      setIsLoading('hide');
    }

    useEffect(() => {
      
      if(isLogged && canLoad === 'graficos'){
        handleGetBalancos();
        handleGetDres();  
        handleGetFrota();
      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, isLogged, canLoad]);

    useEffect(() => {
        

      if(isLogged && canLoad === 'graficos'){

        if(userType === 1){
          handleGetLocadoras();
          handleGetCompanyList();
        }
      }
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogged, userType, canLoad]);

    useEffect(() => {
    
        if(isLogged)

        setDocTitle(TXTS.GRAFICOS_TITLE);

        setTimeout(() => {
            setIsLoading('hide');
        }, 500);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsLoading, isLogged]);

    return (
    <motion.section 
        className="conteudo"

        variants={animacao}
        initial='entrada'
        animate='animacao'
        exit='saida'
        
        onScroll={HandleScroll}
      >

        <div className='container-lg'>
          <h2 className='pageTitle'>{TXTS.GRAFICOS_TITLE}</h2>
        </div>

        <div className='container container-lg'>

          <div className='row justify-content-center mt-md-2 mb-5'>

          {userType === 1 &&

              <>
              <div className='col col-12 col-md-3 col-xl-2 mt-4 mt-md-0'>
                <Select 
                  placeholder='Ver de:'
                  disabled={verBalDeLocadora !== 0}
                  data={companies}
                  value={verBalDeEmpresa}
                  useLoading={true}
                  onClick={(selValue, txt) => {handleVerBalDeEmpresa(selValue, txt)}}
                />
              </div>

              <div className='col col-12 col-md-3 col-xl-2 mt-4 mt-md-0'>
                <Select 
                  placeholder='Ver de Locadora:'
                  disabled={verBalDeEmpresa !== 0}
                  data={locadoras}
                  value={verBalDeLocadora}
                  useLoading={true}
                  onClick={(selValue, txt) => {handleVerBalDeLocadora(selValue, txt)}}
                />
              </div>
              </>
              
            }

            <div className='col col-12 col-md-3 col-xl-2 mt-4 mt-md-0'>
                <Select 
                  placeholder='Período:'
                  data={ultimosMesesSelect}
                  value={globalFiltersPeriodo.ultimos_meses}
                  useLoading={true}
                  onClick={(selValue) => {handleUltimosMeses(selValue)}}
                />
            </div>

            <div className='col col-12 col-md-3 col-xxl-2 mt-4 mt-md-0'>
              <Input 
                name='mes_ano_ini'
                placeholder='Início:'
                type='month'
                min={minMaxMes.min_mes}
                max={globalFiltersPeriodo.mes_ano_fim}
                value={globalFiltersPeriodo.mes_ano_ini < minMaxMes.min_mes ? minMaxMes.min_mes : globalFiltersPeriodo.mes_ano_ini}
                onChange={handleMesAnoIni}
                disabled={globalFiltersPeriodo.mes_ano_fim_disabled}
                useLoading={true}
              />
            </div>

            <div className='col col-12 col-md-3 col-xxl-2 mt-4 mt-md-0'>
              <Input 
                name='mes_ano_fim'
                placeholder='Fim:'
                type='month'
                min={globalFiltersPeriodo.mes_ano_ini}
                max={minMaxMes.max_mes}
                value={globalFiltersPeriodo.mes_ano_fim > minMaxMes.max_mes ? minMaxMes.max_mes : globalFiltersPeriodo.mes_ano_fim}
                onChange={handleMesAnoFim}
                disabled={globalFiltersPeriodo.mes_ano_ini_disabled}
                useLoading={true}
              />
            </div>

          </div>

          <hr></hr>

          <div className="card mb-4">
            <div className="card-header">
              <div className="nav nav-tabs card-header-tabs" id="nav-tab" role="tablist">
                <button onClick={() => {setActiveTab(1)}} className={`nav-link ${activeTab === 1 ? 'active' : ''}`} data-bs-toggle="tab" data-bs-target="#bal" type="button" role="tab" aria-controls="nav-bal" aria-selected={activeTab === 1 ? true : false}>BALANÇOS</button>
                <button onClick={() => {setActiveTab(2)}} className={`nav-link ${activeTab === 2 ? 'active' : ''}`} data-bs-toggle="tab" data-bs-target="#dre" type="button" role="tab" aria-controls="nav-dre" aria-selected={activeTab === 2 ? true : false}>DRES</button>
                <button onClick={() => {setActiveTab(3)}} className={`nav-link ${activeTab === 3 ? 'active' : ''}`} data-bs-toggle="tab" data-bs-target="#frota" type="button" role="tab" aria-controls="nav-frota" aria-selected={activeTab === 3 ? true : false}>FROTA</button>
              </div>
            </div>
            
            <div className="card-body">

              <div className='col col-12 mt-4 mb-3'>
                <h2 className='pageTitle sub'>
                  Período: {periodo}
                  {nomeLocadoraParaoBal !== '' &&
                  <> - ({nomeLocadoraParaoBal})</>
                  }

                  {nomeVerDeEmpresa !== '' &&
                  <> - ({nomeVerDeEmpresa})</>
                  }
                </h2>
              </div>

              <div className="tab-content" id="nav-tabContent">

                <div className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`} id="bal" role="tabpanel" style={{minHeight: 400}}>

                  {balancos.length > 0 && activeTab === 1 &&
                    <div className='row justify-content-around p-0'>

                      <Chart
                        chartType='Bar'
                        width="100%"
                        height="500px"
                        data={balancos}
                        options={{
                          tooltip: {textStyle:  {fontSize: 14, bold: true}},
                          curveType: "function",
                          legend: { position: "right",
                          textStyle : {
                            fontSize: 12
                          } },
                          hAxis: {
                            textPosition : 'out',
                            textStyle : {
                              fontSize: 12
                            }
                          }
                        }}
                        chartLanguage='pt_BR'
                        
                      />

                    </div>
                  }

                  {balancos.length === 0 &&
                    <div className="alert alert-warning" role="alert">
                      Nenhum resultado com os filtros aplicados.
                    </div>
                  }

                </div>

                <div className={`tab-pane fade ${activeTab === 2 ? 'show active' : ''}`} id="dre" role="tabpanel" style={{minHeight: 400}}> 

                {dres.length > 0 && activeTab === 2 &&
                   <div className='row justify-content-around p-4'>

                      <Chart
                        // chartType={dres.length < 3 ? 'ColumnChart' : 'Line'}
                        chartType='Bar'
                        width="100%"
                        height="500px"
                        data={dres}
                        options={{
                          tooltip: {textStyle:  {fontSize: 14, bold: true}},
                          curveType: "function",
                          legend: { position: "right",
                          textStyle : {
                            fontSize: 12
                          } },
                          hAxis: {
                            textPosition : 'out',
                            textStyle : {
                              fontSize: 12
                            }
                          }
                        }}
                        chartLanguage='pt_BR'
                      />

                   </div>
                  }

                  {dres.length === 0 &&
                    <div className="alert alert-warning" role="alert">
                      Nenhum resultado com os filtros aplicados.
                    </div>
                  }
                  
                </div>

                <div className={`tab-pane fade ${activeTab === 3 ? 'show active' : ''}`} id="frota" role="tabpanel" style={{minHeight: 400}}> 

                {frotas.length > 0 && activeTab === 3 &&
                   <div className='row justify-content-around p-4'>

                      <Chart
                        chartType='Bar'
                        width="100%"
                        height="500px"
                        data={frotas}
                        options={{
                          tooltip: {textStyle:  {fontSize: 14, bold: true}},
                          curveType: "function",
                          legend: { position: "right",
                          textStyle : {
                            fontSize: 12
                          } },
                          hAxis: {
                            textPosition : 'out',
                            textStyle : {
                              fontSize: 12
                            }
                          }
                        }}
                        chartLanguage='pt_BR'
                      />

                   </div>
                  }

                  {frotas.length === 0 &&
                    <div className="alert alert-warning" role="alert">
                      Nenhum resultado com os filtros aplicados.
                    </div>
                  }
                  
                </div>


              </div>

            </div>
            
          </div>
        
        </div>

        <div className='logoBotton'>
            <img src={LOGO_AUDIT} alt="AUDIT" className='auditLayout'/> 
        </div>

      </motion.section>
    );
}

export default Graficos;