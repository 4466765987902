import React, { createContext, useEffect, useState, useMemo } from "react";
import { MesAno, MesAnoPeriodo, TextColorByBg } from "../util/Functions";

export const GlobalFiltersContext =  createContext();


function GlobalFiltersProvider ({children}) {

    const [activeTab, setActiveTab] = useState(1);

    const [periodo, setPeriodo] = useState('Últimos 3 meses');

    const [periodoDash, setPeriodoDash] = useState('');

    const [locadoraPadrao, setLocadoraPadrao] = useState('');

    const [nomeLocadoraPadrao, setNomeLocadoraPadrao] = useState('');

    const [logoLocadoraPadrao, setLogoLocadoraPadrao] = useState('');

    const [bgLocadoraPadrao, setBgLocadoraPadrao] = useState('#000000');

    const [verBalDeEmpresa, setVerBalDeEmpresa] = useState(0);

    const [nomeVerDeEmpresa, setNomeVerDeEmpresa] = useState('');

    const [verBalDeLocadora, setVerBalDeLocadora] = useState(0);

    const [nomeLocadoraParaoBal, setNomeLocadoraParaoBal] = useState('');

    const [globalFiltersPeriodo, setGlobalFiltersPeriodo] = useState({

        mes_ano_ini: MesAno(),
        mes_ano_fim: MesAno(),
        ultimos_meses: 3,
        mes_ano_ini_disabled: true,
        mes_ano_fim_disabled: true,
        pageBal: 1,
        pageDre: 1,
        pageFrota: 1,
        companyId: 0

    });

    const [globalFiltersPeriodoDash, setGlobalFiltersPeriodoDash] = useState({

        mes_ano_ini: MesAno(),
        ultimos_meses: 1,
        companyId: 0

    });

    const [minMaxMes, setMinMaxMes] = useState({
        min_mes: MesAno(),
        max_mes: MesAno(),
    });

    const [globalFiltersBal, setGlobalFiltersBal] = useState({

        orderBy: 'mes_ano',
        orderDir: 'ASC',
        paginate: 10
  
    });

    const globalFiltersBalDash = {

        orderBy: 'mes_ano',
        orderDir: 'ASC',
        page: 1,
        paginate: 0
  
    };
  
    const [globalFiltersDre, setGlobalFiltersDre] = useState({
  
        orderBy: 'mes_ano',
        orderDir: 'ASC',
        paginate: 10
  
    });

    const globalFiltersDreDash = {
  
        orderBy: 'mes_ano',
        orderDir: 'ASC',
        page: 1,
        paginate: 0
  
    };

    const [globalFiltersFrota, setGlobalFiltersFrota] = useState({

        orderBy: 'mes_ano',
        orderDir: 'ASC',
        paginate: 10

    });

    const ultimosMesesSelect = [

        {
            label: 'Período:',
            id: 0
        },

        {
            label: 'Último Mês',
            id: 1
        },
       
        {
            label: '3 Meses',
            id: 3
        },

        {
            label: '6 Meses',
            id: 6
        },

        {
            label: '9 Meses',
            id: 9
        },

        {
            label: '12 Meses',
            id: 12
        },

    ];   

    const textColorLocPadrao = useMemo(() => {
  
        return TextColorByBg(bgLocadoraPadrao);
        
    }, [bgLocadoraPadrao]);

    const handleVerBalDeEmpresa = (value, txt) => {
        
        setNomeVerDeEmpresa(value !== 0 ? txt : '')
        setVerBalDeEmpresa(value); 
        setGlobalFiltersPeriodo({
            ...globalFiltersPeriodo,
            companyId: value
        });       
        
    }

    const handleVerBalDeLocadora = (value, txt) => {

        setNomeLocadoraParaoBal(value !== 0 ? txt : '');
        setVerBalDeLocadora(value); 
        setGlobalFiltersPeriodo({
            ...globalFiltersPeriodo,
            companyId: value
        });       
        
    }

    const handleUltimosMeses = (value) => {

        setGlobalFiltersPeriodo({
            ...globalFiltersPeriodo,
            ultimos_meses: value,
            mes_ano_ini_disabled: value === 0 ? false : true,
            mes_ano_fim_disabled: value === 0 ? false : true,
            pageBal: 1,
            pageDre: 1,
            pageFrota: 1,
        });       
        
    }

    const handleMesAnoIni = (e) => {
        
        const value = e.target.value === '' ? minMaxMes.min_mes : e.target.value;

        setGlobalFiltersPeriodo({
            ...globalFiltersPeriodo,
            mes_ano_ini: value,
            pageBal: 1,
            pageDre: 1,
            pageFrota: 1,
        });

    }

    const handleMesAnoFim = (e) => {
        
        const value = e.target.value === '' ? minMaxMes.max_mes : e.target.value;

        setGlobalFiltersPeriodo({
            ...globalFiltersPeriodo,
            mes_ano_fim: value,
            pageBal: 1,
            pageDre: 1,
            pageFrota: 1,
        });

    }

    const handleMesAnoIniDash = (e) => {
        
        const value = e.target.value === '' ? minMaxMes.min_mes : e.target.value;

        setGlobalFiltersPeriodoDash({
            ...globalFiltersPeriodoDash,
            mes_ano_ini: value
        });

    }

    const [filtersUsers, setFiltersUser] = useState({
        
        orderBy: 'name',
        orderDir: 'ASC',
        page: 1,
        paginate: 10,
        assAtivo: 2,
        planoId: 0,
        tipoPessoa: 0,
        tipoUser: 0,
        segmentos: [0],
        tiposLoc: [0],
        estados: [0],
        cidades: [0],
        mes_ano_ini: MesAno(),
        mes_ano_fim: MesAno(),
        ultimos_meses: 3,
        mes_ano_ini_disabled: true,
        mes_ano_fim_disabled: true,
        name: '',
        email: '',
        reload: false

    });

    const [filterSubUsers, setFilterSubUsers] = useState({
        orderBy: 'name',
        orderDir: 'ASC'
    });

    const handleUltimosMesesUsers = (value) => {

        setFiltersUser({
            ...filtersUsers,
            ultimos_meses: value,
            mes_ano_ini_disabled: value === 0 ? false : true,
            mes_ano_fim_disabled: value === 0 ? false : true,
        });       
        
    }

    const handleMesAnoIniUsers = (e) => {
        
        const value = e.target.value;

        if(value === ''){
            return;
        }

        setFiltersUser({
            ...filtersUsers,
            mes_ano_ini: value
        });

    }

    const handleMesAnoFimUsers = (e) => {
        
        const value = e.target.value;

        if(value === ''){
            return;
        }

        setFiltersUser({
            ...filtersUsers,
            mes_ano_fim: value
        });

    }

    const [filtersUserValues, setFiltersUserValues] = useState({

        name: '',
        email: ''

    });

    const handleFiltersUsersValues = (e) => {

        setFiltersUserValues({
            ...filtersUserValues,
            [e.target.name]: e.target.value,
        });

    }

    const handleFiltersUsersPlanoId = (value) => {
        
        
        setFiltersUser({
            ...filtersUsers,
            planoId: value,
            reload: true
        });

    }

    const handleFiltersUsersAssAtivo = (value) => {
        
        setFiltersUser({
            ...filtersUsers,
            assAtivo: value,
            reload: true
        });

    }

    const handleFiltersUsersTipoPessoa = (value) => {
        
        setFiltersUser({
            ...filtersUsers,
            tipoPessoa: value,
            reload: true
        });

    }

    const handleFiltersUsersTipoUser = (value) => {
        
        setFiltersUser({
            ...filtersUsers,
            tipoUser: value,
            reload: true
        });

    }

    const handleResetGlobalFilters = () => {

        setGlobalFiltersPeriodo({

            mes_ano_ini: MesAno(),
            mes_ano_fim: MesAno(),
            ultimos_meses: 3,
            mes_ano_ini_disabled: true,
            mes_ano_fim_disabled: true,
            pageBal: 1,
            pageDre: 1,
            pageFrota: 1,
            companyId: 0
    
        });

        setGlobalFiltersPeriodoDash({

            mes_ano_ini: MesAno(),
            ultimos_meses: 1,
            companyId: 0
    
        });

        setMinMaxMes({
            min_mes: MesAno(),
            max_mes: MesAno(),
        });

        setActiveTab(1);

        setPeriodo('Últimos 3 meses');

        setPeriodoDash('');

        setLocadoraPadrao('');

        setBgLocadoraPadrao('#000000');

        setNomeLocadoraPadrao('');

        setLogoLocadoraPadrao('');

        setVerBalDeEmpresa(0);

        setVerBalDeLocadora(0);

        setGlobalFiltersBal({

            orderBy: 'mes_ano',
            orderDir: 'ASC',
            paginate: 10
      
        });
    
        setGlobalFiltersDre({
      
            orderBy: 'mes_ano',
            orderDir: 'ASC',
            paginate: 10
      
        });
    
        setGlobalFiltersFrota({
    
            orderBy: 'mes_ano',
            orderDir: 'ASC',
            paginate: 10,
            dash: 0
    
        });

        setNomeLocadoraParaoBal('');
        setNomeVerDeEmpresa('');

        setFiltersUser({

            orderBy: 'name',
            orderDir: 'ASC',
            page: 1,
            paginate: 10,
            assAtivo: 2,
            planoId: 0,
            tipoPessoa: 0,
            tipoUser: 0,
            segmentos: [0],
            tiposLoc: [0],
            estados: [0],
            cidades: [0],
            mes_ano_ini: MesAno(),
            mes_ano_fim: MesAno(),
            ultimos_meses: 3,
            mes_ano_ini_disabled: true,
            mes_ano_fim_disabled: true,
            name: '',
            email: '',
            reload: false,

        });

        setFilterSubUsers({

            orderBy: 'name',
            orderDir: 'ASC'

        });

        setFiltersUserValues({

            name: '',
            email: ''

        })
    }

    useEffect(() => {

        if(globalFiltersPeriodo.ultimos_meses !== 0 && globalFiltersPeriodo.ultimos_meses !== 1){
            
            setPeriodo(`Últimos ${globalFiltersPeriodo.ultimos_meses} meses`);

        }else{

            const ini = globalFiltersPeriodo.mes_ano_ini < minMaxMes.min_mes ? minMaxMes.min_mes : globalFiltersPeriodo.mes_ano_ini;
            const fim = globalFiltersPeriodo.mes_ano_fim > minMaxMes.max_mes ? minMaxMes.max_mes : globalFiltersPeriodo.mes_ano_fim;
            
            setPeriodo(MesAnoPeriodo(ini, fim));
        }       

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFiltersPeriodo]);

    useEffect(() => {

        const ini = globalFiltersPeriodoDash.mes_ano_ini < minMaxMes.min_mes ? minMaxMes.min_mes : globalFiltersPeriodoDash.mes_ano_ini;
                        
        setPeriodoDash(MesAnoPeriodo(ini, ini));     

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFiltersPeriodoDash]);

    return (
        <GlobalFiltersContext.Provider value={{ 
            ultimosMesesSelect, globalFiltersPeriodo, setGlobalFiltersPeriodo,
            globalFiltersPeriodoDash, setGlobalFiltersPeriodoDash,
            minMaxMes, setMinMaxMes,
            globalFiltersBal, setGlobalFiltersBal,
            globalFiltersDre, setGlobalFiltersDre,
            globalFiltersBalDash, globalFiltersDreDash,
            globalFiltersFrota, setGlobalFiltersFrota,
            handleUltimosMeses, handleMesAnoIni, handleMesAnoFim, handleMesAnoIniDash,
            activeTab, setActiveTab,
            periodo, setPeriodo,
            periodoDash, setPeriodoDash,
            locadoraPadrao, setLocadoraPadrao,
            nomeLocadoraPadrao, setNomeLocadoraPadrao,
            bgLocadoraPadrao, setBgLocadoraPadrao,
            logoLocadoraPadrao, setLogoLocadoraPadrao,
            textColorLocPadrao,
            verBalDeEmpresa, handleVerBalDeEmpresa,
            verBalDeLocadora, handleVerBalDeLocadora,
            nomeLocadoraParaoBal, nomeVerDeEmpresa,
            handleResetGlobalFilters,
            filtersUsers, setFiltersUser,
            filterSubUsers, setFilterSubUsers,
            filtersUserValues, setFiltersUserValues, handleFiltersUsersValues,
            handleFiltersUsersPlanoId, handleFiltersUsersAssAtivo, handleFiltersUsersTipoPessoa,
            handleFiltersUsersTipoUser, handleUltimosMesesUsers, handleMesAnoIniUsers, handleMesAnoFimUsers
            }}>
            {children}
        </GlobalFiltersContext.Provider>
    )
}

export default GlobalFiltersProvider;