const LogoLoader = () => {
    return (
      
        <svg className='loaderLogo' width="1054px" height="100px" viewBox="0 0 1054 100">
            <g>
                <circle className='circle1' fill="black" cx="459" cy="70" r="49"/>
                <circle className='circle2' fill="#D01519" cx="582" cy="70" r="49"/>
                <circle className='circle3' fill="#60A731" cx="705" cy="70" r="49"/>
                <circle className='circle4' fill="#CEA052" cx="828" cy="70" r="49"/>
            </g>
        </svg>
      
    );
  }
  
  export default LogoLoader;