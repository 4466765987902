import { deviceType, browserName, osName, mobileVendor, mobileModel } from 'react-device-detect';
import { instance } from '../services/Default.service';

import * as TXTS from './Texts';

export const SetInstanceToken = (token) => {
    instance.defaults.headers.Authorization = `Bearer ${token}`;
}

export const GetIDUser = ()  => {
    return Number(localStorage.getItem('userId')) ?? null;
}
 
export const GetToken = ()  => {
    return localStorage.getItem('userTk') ?? null;
}

export const GetUserType = ()  => {
    return Number(localStorage.getItem('userType')) ?? null;
}

export const GetLocalLogged = () => {
    if(GetIDUser() !== null && GetToken() !== null){
        return true;
    }else{
        return false;
    }
}

export const GetDeviceInfo = () => {
    switch (deviceType) {
        case 'browser':
            return `${browserName} em ${osName}`;
        case 'mobile':
        case 'tablet':
            return `${mobileVendor} ${mobileModel} em ${osName}`;
        default:
            return 'Navegador não identificado';
    }
}

export const Parallax = (event) => {
    document.querySelectorAll('.paraObj').forEach(function(move){
        const moveValue = move.getAttribute('data-value');
        const x = (event.clientX * moveValue) / 250;
        const y = (event.clientY * moveValue / 250);
        move.style.transform = `translateX(${x}px) translateY(${y}px)`;
    });
}

export const ValidLogo = (event, logo = true) => {
    
    const validSize = logo ? 1000000 : 2000000;
    const file = event.target.files;
    let msg = '';

    if (file && file[0]) {console.log(file[0].size);
        const fileTypes = ['jpg', 'jpeg', 'png'];
        const extension = file[0].name.split('.').pop().toLowerCase();
        const isValidExt = fileTypes.indexOf(extension) > -1;
        const size = file[0].size;
        const isValidSize = size <= validSize ? true : false;

        if(!isValidExt){
            msg = TXTS.IMG_FORMAT_INVALID;
        }

        if(!isValidSize){
            msg = TXTS.IMG_SIZE_INVALID;
        }
    }else{
        msg = TXTS.IMG_SIZE_NOSELECT;
    }
    return msg;
}

export const GetCookie = (cookieName) => {
    let cookie = {};
    document.cookie.split(';').forEach(function(el) {
      let [key,value] = el.split('=');
      cookie[key.trim()] = value;
    })
    return cookie[cookieName];
}

export const DeleteAllCookies = () => {
    document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    return true;
}

export const CheckEmptyObj = (obj) => {

    return Object.values(obj).every(o => o !== '');

}

export const CheckEmptyArray = (array) => {
    let check = true;
        array.forEach(element => {
            if(element === '')
            check = false;
        });
    return check;
}

export const CheckDiffArray = (required = false, array) => {
    
    if(required && !CheckEmptyArray(array)){
        return false;
    }else{
        for(var i = 0; i < array.length - 1; i++) {
            if(array[i] === array[i+1]) {
                return false;
            }
        }
        return true;
    }
}

export const hasNullObjArray = (array) => {
    let check = false;
    array.forEach((value) => {
        Object.values(value).forEach(val => {
            if(val === null)
            check = true;
        });
    });

    return check;
}

export const RemoveAccent = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const Slugfy = (string) => {
    let slug = string;
    slug = slug.toString()
    .normalize('NFD')                   
    .replace(/[\u0300-\u036f]/g, '')   
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '')
    .replace(/\s+/g, '_');

    return slug;
}

export const CreateUF = (ufs) => {
    
    const estados = [
        {
            label: 'UF',
            id: 0
        }
    ];
    for (const key in ufs) {
        estados.push(
            {
                label: ufs[key],
                id: parseInt(key)
            },
        )
    };
    return estados;
}

export const DiaMesAno = () => {

    const Hoje = new Date();

    let year = Hoje.toLocaleString("default", { year: "numeric" });
    let month = Hoje.toLocaleString("default", { month: "2-digit" });
    let day = Hoje.toLocaleString("default", { day: "2-digit" });

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}

const meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
];

export const MesAno = () => {
    const Hoje = new Date();
    return `${Hoje.getFullYear()}-${("0" + (Hoje.getMonth() + 1)).slice(-2)}`;
}

export const MesAnoGr = (mesAno) => {

    let mesAnoOk = `${meses[parseInt(mesAno.slice(-2)-1)]}/${mesAno.substring(0, 4)}`;
    return mesAnoOk;

}

export const MesAnoPeriodo = (ini, fim) => {

    const mesIni = meses[parseInt(ini.slice(-2)-1)];
    const anoIni = ini.substring(0, 4);
    
    let mesAnoTitulo = `${mesIni}/${anoIni}`;

    if(fim !== ini){
        
        const mesFim = meses[parseInt(fim.slice(-2)-1)];
        const anoFim = fim.substring(0, 4);

        mesAnoTitulo += ` - ${mesFim}/${anoFim}`;
    }

    return mesAnoTitulo;

}

export const DiaMesAnoPeriodo = (ini, fim) => {

    const diaIni = ini.slice(-2);
    const mesIni = meses[parseInt(ini.substring(5, 7)-1)];
    const anoIni = ini.substring(0, 4);

    let DiaMesAnoTitulo = `${diaIni} de ${mesIni} de ${anoIni}`;

    if(fim !== ini){
        
        const diaFim = fim.slice(-2);
        const mesFim = meses[parseInt(fim.substring(5, 7)-1)];
        const anoFim = fim.substring(0, 4);

        DiaMesAnoTitulo += ` - ${diaFim} de ${mesFim} de ${anoFim}`;
    }

    return DiaMesAnoTitulo;

}

export const DiaMesAnoS = (data) => {

    const dia = data.slice(-2);
    const mes = meses[parseInt(data.substring(5, 7)-1)];
    const ano = data.substring(0, 4);

    let DiaMesAnoSTitulo = `${dia} de ${mes} de ${ano}`;

    return DiaMesAnoSTitulo;

}

export const AddMeses = (date, months) => {
    
    const monthsAdd = months ? months : 1;
    const dateCopy = new Date(date);
    dateCopy.setMonth(dateCopy.getMonth() + monthsAdd);
    dateCopy.setDate(dateCopy.getDate() + 1);

    let year = dateCopy.toLocaleString("default", { year: "numeric" });
    let month = dateCopy.toLocaleString("default", { month: "2-digit" });
    let day = dateCopy.toLocaleString("default", { day: "2-digit" });

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}


export const MoedaInput = (value, allowNegative = true) => {

    const neg = value.startsWith('-');

    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{2})$/, '$1,$2');
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

    if(neg && allowNegative) value = '-'.concat(value);

    return value;
}

export const Moeda = (value, currency = true) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
}).format(value).replace(`${!currency ? 'R$' : ''}`, '').trim().replace(/\s/g,'');


export const Decimal = (numero) => {
    
    try{
        if(numero !== ''){

            numero = numero.split('.').join('').replace(',', '.');
            
        }else{
    
            numero = 0;
            
        }
    }catch{
        numero = 0;
    }
    

    return numero;
}

export const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
}

export const IsValReady = (obj1, obj2) => {
    return Object.keys(obj1).length === (Object.keys(obj2).length -1);
}

export const GroupBy = (collection, property) => {
    let i = 0, val, index,
        values = [], result = [];
    for (; i < collection.length; i++) {
        val = collection[i][property];
        index = values.indexOf(val);
        if (index > -1)
            result[index].push(collection[i]);
        else {
            values.push(val);
            result.push([collection[i]]);
        }
    }
    return result;
}

export const GetLastDay = (data) => {
    const dataok = data.replace(/-0+/g, '-');
    const lastDayOfMonth = new Date(new Date(dataok).getFullYear(), new Date(dataok).getMonth() + 1, 0);
    return lastDayOfMonth.getDate();
}

export const IndexOfObject = (array, key, id) => {

    return array.findIndex(object => {
        return object[key] === id;   
    });
}

export const SortArrayByObjKey = (array, key, direction) => {

    if(direction === 'ASC'){

        return array.sort((a,b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0));

    }else{

        return array.sort((a,b) => (a[key] < b[key]) ? 1 : ((b[key] < a[key]) ? -1 : 0));

    }

}

export const KeyExists = (obj, key) => {
    return key in obj;
}

const HexToRgb = (hex) => {
    
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : null;
}

export const TextColorByBg = (bgcolor) => {
    
    const modo = 1;

    let { r, g, b } = HexToRgb(bgcolor);
    let targetColor = `rgb(${r},${g},${b})`;
    let colors = targetColor.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

    let R = colors[1];
    let G = colors[2];
    let B = colors[3];

    
    //somente preto e branco
    if(modo === 1){

        const bright = Math.round(((parseInt(R) * 299) +
        (parseInt(G) * 587) +
        (parseInt(B) * 114)) / 1000);

        return (bright > 125) ? 'black' : 'white';
    }
    
    
    //todas as cores
    if(modo === 2){
        let brightness = 1;

        let ir = Math.floor((255 - R) * brightness);
        let ig = Math.floor((255 - G) * brightness);
        let ib = Math.floor((255 - B) * brightness);

        return `rgb(${ir}, ${ig}, ${ib})`;
    }
    
}

export const GenPassword = (passwordLength) => {

    const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let password = '';

    for (var i = 0; i <= passwordLength; i++) {
    var randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber +1);
    }
    return password;
}

export const CopyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
}

export const CheckMultiSelFilter = (val, array) => {

    if(val === 0 && array.length === 1 && array[0] === 0){ return false; }
  
    const newSelected = [];

    if(val !== 0){

    const index = array.indexOf(val);

    if (index > -1) {

        array.splice(index, 1);

        if(array.length === 0){ array.push(0); }

    }else{

        const indexZero = array.indexOf(0);

        if (indexZero > -1) { array.splice(indexZero, 1); }

        array.push(val);

    }

    }else{

        array.length = 0;
        array.push(0);

    }

    array.forEach(item => { newSelected.push(item); });

    return newSelected;

} 

export const CheckMultiSel = (val, array, allowZero = false) => {
    
    const newSelected = [];

    const index = array.indexOf(val);

    if (index > -1) {

        if(array.length === 1 && !allowZero){
            return;
        }
        
        array.splice(index, 1);

    }else{

        array.push(val);

    }

    array.forEach(item =>{
        newSelected.push(item);
    });

    return newSelected;

}