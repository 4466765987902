import React, { useEffect, useContext, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';
import * as SiteS from '../../services/Site.service';
import Btn from '../../components/Forms/Btn';
import CheckBox from '../../components/Forms/CheckBox';
import Input from '../../components/Forms/Input';
import { motion } from 'framer-motion';
import { fade } from '../../util/AnimatedDiv'
import { LoaderContext } from '../../contexts/LoaderContext';
import { SiteContext } from '../../contexts/SiteContext';
import { AlertasContext } from '../../contexts/AlertasContext';
import { UserContext } from '../../contexts/UserContext';
import * as TXTS from '../../util/Texts';
import { LOGO_AUDIT, BASE_BACK } from '../../util/Urls';
import * as F from '../../util/Functions';
import UploadSlide from '../../components/SlidesHome/upload';


function SlidesHomeEdit(){

    const navigate                          = useNavigate();
    const { setIsLoading }                  = useContext(LoaderContext);
    const { isLogged, userType }            = useContext(UserContext);
    const [slidesHome, setSlidesHome]       = useState({});
    const [unsavedCampos, setUnsavedCampos] = useState([]);
    const [trashCampos, setTrashCampos]     = useState([]);
    const [disabledAdd, setDisabledAdd]     = useState(true);

    const {
        HandleScroll,
        setDocTitle,
        setErrorCon
    } = useContext(SiteContext);

    const {

        setAlertShow,
        setAlertBtnOk,
        setAlertOnClose,
        setAlertType,
        setAlertMsg,
    
    } = useContext(AlertasContext);

    const dragItem = useRef();
    const dragItenId = useRef();
    const dragOverItem = useRef();
    
    const dragStart = (e, position) => {

        dragItem.current = position;
        dragItenId.current = e.target.id;
    
    };

    const dragEnter = (e, position) => {

        dragOverItem.current = position;

    };

    const drop = (e) => {
        
        const copyUnsavedCampos = [...unsavedCampos];
        const copyTrash = [...trashCampos];
              
        if(dragOverItem.current === 1000){

            const dragItemContentAtivo = copyUnsavedCampos[dragItem.current];

            if(!copyTrash.find(x => x.id === dragItenId.current)){
                
                if(!isNaN(dragItenId.current) || dragItemContentAtivo['url'] !== false){
                    copyTrash.push(dragItemContentAtivo);
                }

                copyUnsavedCampos.splice(dragItem.current, 1);

            }
        }

        else if(dragOverItem.current === 2000){
            
            const dragItemContentAtivo = copyTrash[dragItem.current];

            if(!copyUnsavedCampos.find(x => x.id === dragItenId.current)){
                copyUnsavedCampos.push(dragItemContentAtivo);
                copyTrash.splice(dragItem.current, 1);
            }
        }

        else{

            if(copyUnsavedCampos.find(x => x.id === dragItenId.current)){

                const dragItemContent = copyUnsavedCampos[dragItem.current];
                copyUnsavedCampos.splice(dragItem.current, 1);
                copyUnsavedCampos.splice(dragOverItem.current, 0, dragItemContent);
                
            }
        }
        
        setTrashCampos(copyTrash);
        setUnsavedCampos(copyUnsavedCampos);
        dragItenId.current = null;
        dragItem.current = null;
        dragOverItem.current = null;
        
    };

    const getSlidesHome = async() => {

        setIsLoading('show');

        try {
            
            const result = await SiteS.slidesHome();

            if(result.data){
                
                if (result.status === 200) {
                    
                    setSlidesHome(result.data.slidesHome);

                }
            }else{
                
                setSlidesHome([]);
            
            }
            
        }catch{

            setSlidesHome([]);

        }

        setIsLoading('hide');
        
    };

    const handleSlideAdd = (e) => {
        
        const newId = `new${(unsavedCampos.length + trashCampos.length) + 1}`;

        const objToAdd = {
            url: false,
            id: newId,
            show_logo: 1,
            time: 2
        };
        
        setUnsavedCampos([...unsavedCampos, objToAdd]);

        e.target.blur();

    };

    const handleShowLogo = (e, elem) => {
        const copyUnsavedCampos = [...unsavedCampos];
        const item = copyUnsavedCampos.find(x => x.id === elem);

        if(item) {

            item.show_logo = item.show_logo === 1 ? 0 : 1;
            setUnsavedCampos(copyUnsavedCampos);

        }
        e.target.blur();
    }

    const handleTime = (e, elem) => {
        
        if(e.target.value === '0'){
            return;
        }

        const copyUnsavedCampos = [...unsavedCampos];
        const item = copyUnsavedCampos.find(x => x.id === elem);

        if(item) {

            item.time = e.target.value;
            setUnsavedCampos(copyUnsavedCampos);

        }
    }

    const handleSetUrl = (elem, url) => {

        if(url !== false){
            const copyUnsavedCampos = [...unsavedCampos];
            const item = copyUnsavedCampos.find(x => x.id === elem);
            if(item) {

                item.url = url;
                setUnsavedCampos(copyUnsavedCampos);
    
            }
        }

    }

    const handleAdd = async (e) => {
        
        e.preventDefault();

        setDisabledAdd(true);

        const data = {
            adicionar: unsavedCampos,
            remover: trashCampos
        };

        setIsLoading('show');
      
        try{

            const result = await SiteS.UpdateSlides(data);

            if(result.data){

                if (result.status === 200) {

                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    setAlertType('success');
                    setAlertBtnOk(false);  
                    
                    setAlertOnClose(() => () => {navigate('/')});

                }else{

                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    setAlertType('error');
                    setAlertBtnOk(false);
                    
                }

            }else{
                
                setAlertShow(true);
                setAlertMsg(TXTS.ERRO_LOGIN_2);
                setAlertType('error');
                setAlertBtnOk(false);
            
            }

        }catch{

            setAlertShow(true);
            setAlertMsg(TXTS.ERRO_LOGIN_3);
            setAlertType('error');
            setAlertBtnOk(false);
            setErrorCon(true);
            
        }

        setIsLoading('hide');

    }

    useEffect(() => {

        if(userType === 2){
            navigate('/');
            return;
        }

        setDocTitle(TXTS.SLIDESHOME_EDIT_TITLE);
        
        if(isLogged){
            setIsLoading('hide');
            getSlidesHome();
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsLoading, isLogged, userType]);

    useEffect(() => {
        const item = unsavedCampos.find(x => x.url === false);
        setDisabledAdd(item ? true : false);
    }, [unsavedCampos]);

    useEffect(() => {

        const copyUnsavedCampos = [];

        for(const key of Object.keys(slidesHome)){

            copyUnsavedCampos.push({

                id: `${slidesHome[key]['id']}`,
                url: slidesHome[key]['url'],
                show_logo: slidesHome[key]['show_logo'],
                time: slidesHome[key]['time']
        
            });
            
        }  

        setUnsavedCampos(copyUnsavedCampos);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slidesHome]);

    return(
        <motion.section 
        className="conteudo"

        variants={fade}
        initial='entrada'
        animate='animacao'
        exit='saida'
        
        onScroll={HandleScroll}
      >
        <div className='container-lg'>
          <h2 className='pageTitle'>{TXTS.SLIDESHOME_EDIT_TITLE}</h2>
        </div>

        <div className='container container-lg'>

            <div className='row justify-content-end mx-0 px-0 mb-3'>
                        
                <div className='col col-12 col-md-3 col-xxl-2 mt-4'>
                    <Btn 
                        value='Adicionar Slide'
                        type='button'
                        iconLeft='faFileCirclePlus'
                        onClick={handleSlideAdd}
                        useLoading={true}
                    />
                </div>

            </div>

            <div className='row justify-content-start gy-3 gx-4 mt-1' onDragEnterCapture={(e) => dragEnter(e, 2000)}>

                {!F.isEmpty(unsavedCampos) &&
                    unsavedCampos.map( (item, key) => {    
                        return(
                            <div key={key} id={item.id}
                            onDragStart={(e) => dragStart(e, key)}
                            onDragEnter={(e) => dragEnter(e, key)}
                            onDragEnd={drop}
                            draggable
                            className='col col-12 col-md-3 col-xxl-2'>
                                <div className='p-3 border border-light border-2 rounded-2'>
                                    
                                    <UploadSlide
                                    url={item.url}
                                    refe={item.id}
                                    showLogo={item.show_logo === 1 ? true : false}
                                    onChange={(setUrl)=>{handleSetUrl(item.id, setUrl)}}/>

                                    <CheckBox 
                                        label='Mostrar Logo IDR'
                                        onChange={(e) => {handleShowLogo(e, item.id)}}
                                        useLoading={true}
                                        checked={item.show_logo === 1 ? true : false}
                                    />

                                    <div className='col col-12 mt-4'>
                                        <Input 
                                            name='time'
                                            placeholder='Tempo:'
                                            required={true}
                                            useNumber={true}
                                            type='number'
                                            min='2'
                                            max='60'
                                            value={item.time}
                                            onChange={(e) => {handleTime(e, item.id)}}
                                            useLoading={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                <div 
                onDragEnterCapture={(e) => dragEnter(e, 1000)}
                className='row justify-content-start gy-3 gx-4 mx-auto mt-5 mb-1 py-4 trashSlides'>

                <div className='col col-12 mt-0 mb-4' style={{pointerEvents: 'none'}}>
                    <h2 className='pageTitle sub-m'>{TXTS.BAL_DRE_ADD_EDIT_TRASH}</h2>
                </div>

                {!F.isEmpty(trashCampos) &&

                    trashCampos.map( (item, key) => {
                        return(

                            <div key={key} id={item.id}
                            onDragStart={(e) => dragStart(e, key)}
                            onDragEnter={(e) => dragEnter(e, key)}
                            onDragEnd={drop}
                            draggable
                            className='col col-12 col-md-3 col-xxl-2'>
                                <div className='p-3 border border-light border-2 rounded-2'>
                                    
                                    <img draggable={false} src={item.url ? `${BASE_BACK}${item.url}` : '/img/semimagem.png'} alt='Slide' className='rounded slideEditImg'/>
                                </div>
                            </div>
                            
                        )
                    })
                }
            </div>

                <div className='row justify-content-center mx-0 px-0 mb-3'>
                    
                    <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                        <Btn 
                            value='Voltar'
                            type='button'
                            btnType='primary'
                            onClick={() => {navigate('/')}}
                            useLoading={true}
                        />
                    </div>

                    <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                        <Btn 
                            value='Salvar'
                            disabled={disabledAdd}
                            type='submit'
                            btnType='success'
                            onClick={handleAdd}
                            useLoading={true}
                        />
                    </div>

                </div>
            
            </div>

        </div>

        <div className='logoBotton'>
            <img src={LOGO_AUDIT} alt="AUDIT" className='auditLayout'/> 
        </div>

      </motion.section>
    )

}

export default SlidesHomeEdit;