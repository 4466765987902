import { useEffect, useMemo, useRef, useState } from 'react';
import './index.css';

function SingleChartCircle({
    
    percent = 0,
    progressColor = 'rgb(10, 127, 194)',
    strokeColor = '#777',
    textColor = false,
    showOver = false

}) {
    
    const lastWidth = useRef(null);
    const [medidas, setMedidas] = useState({width: 130, height: 130});
    const [firstLoad, setFirstLoad] = useState(true);

    const percentIn = percent > 0 ? percent : 0; 

    let fator = (percentIn/100);
    fator = fator % 1 !== 0 || fator === 0 ? parseInt(fator+1) : fator;

    useEffect(() => {
        if(showOver)
        if(lastWidth.current !== null){
            
            let timeout = 0;

            if(firstLoad){
                timeout = 800;
            }

            setTimeout(() => {
                let elem = lastWidth.current.getBoundingClientRect();
                setMedidas({width: elem.width, height: elem.height});
                setFirstLoad(false);
            }, timeout);
            
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [percentIn])
    

    const circles = useMemo(() => {

        
        const percents = [];

        if(showOver){
            
            for (let i = 1; i < fator; i++) {

                percents.push(100);
            
            }
    
            percents.push((percentIn - (100*(fator-1))));
            
        }else{
            percents.push(percentIn);
        }
        
        
        const circlesPrint = percents.map((per, key) => {
            const opacity = 1 - (0.1*key);
            let scale = key === 0 ? 1 : `1.${(16+key)*key}`;
            let useThisWidth = false;

            if(fator <= 5){
                useThisWidth = (key+1) === percents.length ? true : false;
            }else{
                useThisWidth = (key+1) === 5 ? true : false;
            }

            return(
                <svg className='circleSvg' 
                    key={key}
                    style={{transform: `scale(${scale})`}}
                    ref={useThisWidth ? lastWidth : null }
                    >
                    
                    <circle cx={60} cy={60} r={60} style={{
                        '--strokeColor': key === 0 ? strokeColor : 'none'
                        }}></circle>
                    <circle cx={60} cy={60} r={60} style={{
                        '--percent': per, 
                        '--color': progressColor,
                        opacity: opacity
                    }}></circle>
                </svg>
            );
        });

        return circlesPrint;
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progressColor, strokeColor, fator, percentIn]);

    return (
        <div className='cardCircle'>
            
            <div className='percentCicle' style={{
                width: (medidas.width),
                height: (medidas.height)
            }}>
               
                {circles}

                <div className='numberCircle'>
                    <h2 style={{
                        '--textColor':textColor ? textColor : progressColor,
                        fontSize: percent > 100 && percent % 1 !== 0 ? 27 : 35                        
                        }}>{percent}<span>%</span></h2>
                </div>
            </div>

        </div>
    );
  }
  
export default SingleChartCircle;