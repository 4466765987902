import { instance } from "./Default.service";

export const GetSegmentos = async () => {

    return await instance.get('getSegmentos')
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}
