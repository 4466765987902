/* eslint no-eval: 0 */
import './index.css';
import React, { useEffect, useContext, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { animacao } from '../../util/AnimatedDiv'
import { LoaderContext } from '../../contexts/LoaderContext';
import { SiteContext } from '../../contexts/SiteContext';
import { UserContext } from '../../contexts/UserContext';
import { GlobalFiltersContext } from '../../contexts/GlobalFiltersContext';
import { AlertasContext } from '../../contexts/AlertasContext';
import * as F from '../../util/Functions';
import InputMask from 'react-input-mask';
import Select from '../../components/Forms/Select';
import Input from '../../components/Forms/Input';
import Btn from '../../components/Forms/Btn';
import Pagination from '../../components/Pagination';
import * as TXTS from '../../util/Texts';
import { LOGO_AUDIT, BASE_BACK } from '../../util/Urls';
import * as US from '../../services/User.service';
import * as PL from '../../services/Planos.service';
import * as SG from '../../services/Segmentos.service';
import * as TL from '../../services/TiposLocacao.service';
import * as ES from '../../services/Enderecos.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

function Users() {

  const navigate = useNavigate();
  const top = useRef();
  const [totalPages, setTotalPages] = useState(0);

  const [secondLoad, setSecondLoad] = useState(false);

  const { setIsLoading, cLoading } = useContext(LoaderContext);

  const {

    HandleScroll,
    setDocTitle,
    canLoad,
    setErrorCon

  } = useContext(SiteContext);

  const { isLogged, userType, userId, estados } = useContext(UserContext);

  const { 
    
    filtersUsers, setFiltersUser,
    filtersUserValues, setFiltersUserValues,
    handleFiltersUsersValues,
    handleFiltersUsersPlanoId,
    handleFiltersUsersAssAtivo,
    handleFiltersUsersTipoPessoa,
    handleFiltersUsersTipoUser

  } = useContext(GlobalFiltersContext);

  const assAtivo = [

    {
      label: 'Todos',
      id: 2
    },

    {
      label: 'Ativa',
      id: 1
    },

    {
      label: 'Inativa',
      id: 0
    },

  ];

  const tipoPessoa = [

    {
      label: 'Todos',
      id: 0
    },
       
    {
        label: 'Física',
        id: 1
    },

    {
        label: 'Jurídica',
        id: 2
    },

  ];  

  const tipoUser = [

    {
      label: 'Todos',
      id: 0
    },
       
    {
        label: 'Admin',
        id: 1
    },

    {
        label: 'Usuário',
        id: 2
    },

  ]

  const [planos, setPlanos]       = useState([]);

  const [segmentos, setSegmentos] = useState([]);

  const handleSegmentos = (selValue) => {

    const newSelected = F.CheckMultiSelFilter(selValue, filtersUsers.segmentos);

    if(!newSelected) {
      return;
    }

    setFiltersUser({
      ...filtersUsers,
      segmentos: newSelected,
      reload: true
    });

  }

  const [tiposLoc, setTiposLoc]   = useState([]);

  const handleTiposLoc = (selValue) => {

    const newSelected = F.CheckMultiSelFilter(selValue, filtersUsers.tiposLoc);

    if(!newSelected) {
      return;
    }

    setFiltersUser({
      ...filtersUsers,
      tiposLoc: newSelected,
      reload: true
    });

  }

  const handleSetUF = (selValue) => {
    
    const newSelected = F.CheckMultiSelFilter(selValue, filtersUsers.estados);

    if(!newSelected) {
      return;
    }

    setFiltersUser({
      ...filtersUsers,
      estados: newSelected,
      reload: false
    });

  }

  const handleSetCidade = (selValue) => {

    const newSelected = F.CheckMultiSelFilter(selValue, filtersUsers.cidades);

    if(!newSelected) {
      return;
    }

    setFiltersUser({
      ...filtersUsers,
      cidades: newSelected,
      reload: true
    });

  }

  const [cidades, setCidades] = useState([]);

  const handleGetCidades = async () => {

    const data = {estados: filtersUsers.estados};    
     
    try{

      const result =  await ES.GetCidadesAll(data);
      
      if(result.data && result.status === 200){

        const cidadesOk = result.data.cidades;

        setCidades(cidadesOk);
     
        const filtersOk = [];
        
        filtersUsers.cidades.forEach(value => {
          
          cidadesOk.forEach(element => {

            if (element.id === value) {

              filtersOk.push(value);

            }

          });

        });

        setFiltersUser({
          ...filtersUsers,
          cidades: filtersOk,
          reload: true
        });
        
      }else{
        setCidades([]);
        setFiltersUser({
          ...filtersUsers,
          estados: [0],
          cidades: [0],
          reload: true
        });
      }
      

    }catch{
      setFiltersUser({
        ...filtersUsers,
        estados: [0],
        cidades: [0],
        reload: false
      });
    }

  }

  const {

    setAlertShow,
    setAlertMsg,
    setAlertType,
    setAlertBtnOk,
    setAlertBtnOkTxt,
    setAlertBtnOkType,
    setAlertBtnCancel,
    setAlertBtnCancelTxt,
    setAlertBtnCancelType,
    setAlertOnOk,
    setAlertOnCancel,
    setAlertHtmlItem,
    setAlertCloseOnCancel,
    setAlertTitleCustom

  } = useContext(AlertasContext);

  const [users, setUsers] = useState([]);
  
  const handleGetUsers = async () => {

    setIsLoading('show');
    setSecondLoad(true);
    
    try{

        const result =  await US.GetUsers(filtersUsers);

        if(result.data){

            if (result.status === 200) {

             setUsers(result.data.users);
             setTotalPages(result.data.last_page);
              
            }else{

              setUsers([]);
                
            }

        }else{
          
          setUsers([]);
        
        }

    }catch{

        setAlertShow(true);
        setAlertMsg(TXTS.ERRO_LOGIN_3);
        setAlertType('error');
        setAlertBtnOk(false);
        setErrorCon(true);
        
    }

    setIsLoading('hide');
    setSecondLoad(false);
  }

  const handleGetPlanos = async () => {
    
    setIsLoading('show');
    
    try{
    
        const result =  await PL.GetPlanos();

        if(result.data){

            if (result.status === 200) {
              
              setPlanos(result.data.planos);

            }else{
              setPlanos([]);
            }

        }

    }catch{
      setPlanos([]);
    }

    setIsLoading('hide');
  }

  const handleGetSegmentos = async () => {
    
    setIsLoading('show');
    
    try{
    
        const result =  await SG.GetSegmentos();

        if(result.data){

            if (result.status === 200) {
              
              setSegmentos(result.data.segmentos);

            }else{
              setSegmentos([]);
            }

        }

    }catch{
      setSegmentos([]);
    }

    setIsLoading('hide');
  }

  const handleGetTiposLoc = async () => {
    
    setIsLoading('show');
    
    try{
    
        const result =  await TL.GetTiposLoc();

        if(result.data){

            if (result.status === 200) {
              
              setTiposLoc(result.data.tiposLoc);

            }else{
              setTiposLoc([]);
            }

        }

    }catch{
      setTiposLoc([]);
    }

    setIsLoading('hide');
  }

  const handleApllyFilter = (qual, tipo) => {
    
    if(tipo === 'set'){

      setFiltersUser({
        ...filtersUsers,
        [qual]: filtersUserValues[qual],
        reload: true
      });

    }else{

      setFiltersUser({
        ...filtersUsers,
        [qual]: '',
        reload: true
      });

      setFiltersUserValues(
        {
          ...filtersUserValues,
          [qual]: ''
        }
      )

    }
  
  }

  const handlePagination = (page) => {

    setFiltersUser({
      ...filtersUsers,
      page: page,
      reload: true
    });

    top.current.scrollIntoView(({ behavior: "smooth", block: "start" }));

  }

  const [orderDir, setOrderDir] = useState('ASC');

  const handleOrder = (col) => {

    if(users.length === 1){
      return;
    }
    
    setFiltersUser(filtersUsers => ({ ...filtersUsers, page: 1, orderBy:  col, orderDir: orderDir, reload: true}));
    setOrderDir(orderDir === 'ASC' ? 'DESC' : 'ASC');
  }


  const handleDoRemove = async (Id) => {

    setIsLoading('show');

    try{

      const result =  await US.DeleteUser(Id);

      setAlertBtnCancel(false);

      if(result.data){

        if (result.status === 200) {

          setAlertShow(true);
          setAlertMsg(result.data.msg);
          setAlertType('success');
          setAlertBtnOk(false);  
          handleGetUsers();

        }else{

            setAlertShow(true);
            setAlertMsg(result.data.msg);
            setAlertType('error');
            setAlertBtnOk(false);
        }

      }else{
              
          setAlertShow(true);
          setAlertMsg(TXTS.ERRO_LOGIN_2);
          setAlertType('error');
          setAlertBtnOk(false);
      
      }

    }catch{

        setAlertShow(true);
        setAlertMsg(TXTS.ERRO_LOGIN_3);
        setAlertType('error');
        setAlertBtnOk(false);
        setErrorCon(true);

    }

    setIsLoading('hide');
  }

  const handleRemove = (Id, Nome, NomeEmpresa) => {

    setAlertShow(true);
    setAlertType('warning');
    setAlertBtnCancel(true);
    setAlertOnOk(() => () => {handleDoRemove(Id)});
    setAlertMsg(`Remover usuário ${Nome}, <br> ${NomeEmpresa} ?`);

  }

  const handleCopyLink = (PromoLink) => {
    F.CopyTextToClipboard(PromoLink)
    .then(() => {
      setAlertHtmlItem(
        <div>
            <FontAwesomeIcon icon={faCheck} color='green' /> Copiado com sucesso!
        </div>
      );
    })
    .catch((err) => {
      setAlertHtmlItem(
        <div>
            <FontAwesomeIcon icon={faCircleExclamation} color='red' /> Erro ao copiar
        </div>
      );
    });

    setTimeout(() => {
      setAlertHtmlItem(null);
    }, 2000);
  }

  const handleCreateLink = async (Id, Nome) => {

    setIsLoading('show');
    
    try {

      const result =  await US.createPromoLink({user_id: Id});

      if(result.data){

        if (result.status === 200) {

          setUsers(users.map(user => {
            if (user.id === Id) {
                return { ...user, promoLink: result.data.promoLink, promoLinkId: result.data.promoLinkId };
            }
            return user;
          }));

          setAlertHtmlItem(
            <div>
                <FontAwesomeIcon icon={faCheck} color='green' /> Link criado com sucesso!
            </div>
          );

          setAlertMsg(`Copie ou remova o Link Desgustação para ${Nome}`);
          setAlertBtnCancel(true);
          setAlertOnCancel(() => () => {handleRemoveLink(Id, result.data.promoLinkId, Nome)});
          setAlertOnOk(() => () => {handleCopyLink(result.data.promoLink)});
          setAlertBtnOkTxt('COPIAR LINK');
          setAlertBtnOkType('success');

        }else{

          setAlertHtmlItem(
            <div>
                <FontAwesomeIcon icon={faCircleExclamation} color='red' /> {result.data.msg ?? 'Erro ao criar link'}
            </div>
          );

        }

      }else{

        setAlertHtmlItem(
          <div>
              <FontAwesomeIcon icon={faCircleExclamation} color='red' /> Erro ao criar link
          </div>
        );

      }
      
    } catch {

      setAlertHtmlItem(
        <div>
            <FontAwesomeIcon icon={faCircleExclamation} color='red' /> Erro ao criar link
        </div>
      );

    }finally{

      setIsLoading('hide');
      setTimeout(() => {
        setAlertHtmlItem(null);
      }, 2000);
    }
    
  }

  const handleDoRemoveLink = async (Id, IdPromoLink) => {

    setIsLoading('show');

    try {

      const result =  await US.deletePromoLink(IdPromoLink);

      if(result.data){

        if (result.status === 200) {

          setUsers(users.map(user => {
            if (user.id === Id) {
                return { ...user, promoLink: null, promoLinkId: null };
            }
            return user;
          }));

          setAlertTitleCustom('');

          setAlertType('success');

          setAlertHtmlItem(null);

          setAlertMsg('Link removido com sucesso!');

          setAlertOnCancel(false);
          setAlertBtnCancel(false);
          

        }else{

          setAlertHtmlItem(
            <div>
                <FontAwesomeIcon icon={faCircleExclamation} color='red' /> {result.data.msg ?? 'Erro ao remover link'}
            </div>
          );

        }

      }else{

        setAlertHtmlItem(
          <div>
              <FontAwesomeIcon icon={faCircleExclamation} color='red' /> Erro ao remover link
          </div>
        );

      }
      
    } catch {

      setAlertHtmlItem(
        <div>
            <FontAwesomeIcon icon={faCircleExclamation} color='red' /> Erro ao remover link
        </div>
      );
      
    }finally{

      setIsLoading('hide');

    }

  }
  
  const handleRemoveLink = (Id, IdPromoLink, Nome) => {
    setAlertMsg(`Tem certeza que deseja remover o Link Degustação de ${Nome}?`);
    setAlertType('error');
    setAlertTitleCustom('REMOVER LINK?');
    setAlertBtnOk(false);
    setAlertOnCancel(() => () => {handleDoRemoveLink(Id, IdPromoLink)});
  }

  const handlePromoLink = (Id, Nome, IdPromoLink, PromoLink) => {

    const msg = IdPromoLink ? `Copie ou remova o Link Desgustação para ${Nome}` : `Criar Link Degustação para ${Nome}`;
    setAlertShow(true);
    setAlertType('primary');
    setAlertBtnCancel(IdPromoLink ? true : false);
    setAlertBtnCancelType('danger');
    setAlertBtnCancelTxt('REMOVER LINK');
    setAlertOnCancel(() => () => {handleRemoveLink(Id, IdPromoLink, Nome)});
    setAlertBtnOkTxt(IdPromoLink ? 'COPIAR LINK' : 'CRIAR LINK');
    setAlertBtnOkType(IdPromoLink ? 'success' : 'primary');
    setAlertOnOk(() => () => {IdPromoLink ? handleCopyLink(PromoLink) : handleCreateLink(Id, Nome)});
    setAlertMsg(msg);
    setAlertCloseOnCancel(false);
    setAlertTitleCustom('LINK DESGUSTAÇÃO');

  }

  
  useEffect(() => {

    if(filtersUsers.reload === true && canLoad === 'users' && isLogged === true && secondLoad === false){
      handleGetUsers();
      
    }

     // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [filtersUsers, canLoad, isLogged]);

  useEffect(() => {

    if(isLogged){
      handleGetCidades();
    }

     // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [filtersUsers.estados, isLogged]);

  useEffect(() => {
    if(isLogged && canLoad === 'users'){

      if(userType === 1){

        handleGetPlanos();
        handleGetSegmentos();
        handleGetTiposLoc();

      }else{

        navigate(-1);

      }
      
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, canLoad, userType]);

  useEffect(() => {
  
    setDocTitle(TXTS.USERS_TITLE);

    if(isLogged)
      setTimeout(() => {
        setIsLoading('hide');
      }, 500);
    
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading, isLogged]);

    return (
      <motion.section 
        className="conteudo"

        variants={animacao}
        initial='entrada'
        animate='animacao'
        exit='saida'
        
        onScroll={HandleScroll}
      >
        <div className='container-lg'>
          <h2 className='pageTitle'>{TXTS.USERS_TITLE}</h2>
        </div>

        <div className='container container-lg'>
          
          <div className='row justify-content-center mt-md-2'>

            <div className='col col-6 col-md-3 col-xl-2 mt-4 mt-md-0'>
                <Select 
                  placeholder='Assinatura:'
                  data={assAtivo}
                  value={filtersUsers.assAtivo}
                  useLoading={true}
                  search={false}
                  onClick={(selValue) => {handleFiltersUsersAssAtivo(selValue)}}
                />
            </div>

            <div className='col col-6 col-md-3 col-xl-2 mt-4 mt-md-0'>
                <Select 
                  placeholder='Plano:'
                  data={planos}
                  value={filtersUsers.planoId}
                  useLoading={true}
                  useColors={true}
                  onClick={(selValue) => {handleFiltersUsersPlanoId(selValue)}}
                />
            </div>

            <div className='col col-6 col-md-3 col-xl-2 mt-4 mt-md-0'>
                <Select 
                  placeholder='Tipo Pessoa:'
                  data={tipoPessoa}
                  value={filtersUsers.tipoPessoa}
                  useLoading={true}
                  search={false}
                  onClick={(selValue) => {handleFiltersUsersTipoPessoa(selValue)}}
                />
            </div>

            <div className='col col-6 col-md-3 col-xl-2 mt-4 mt-md-0'>
                <Select 
                  placeholder='Tipo Usuário:'
                  data={tipoUser}
                  value={filtersUsers.tipoUser}
                  useLoading={true}
                  search={false}
                  onClick={(selValue) => {handleFiltersUsersTipoUser(selValue)}}
                />
            </div>

            <div className='col col-6 col-md-3 col-xl-2 mt-4 mt-xl-0'>
              <Select 
                placeholder='Segmentos:'
                data={segmentos}
                dataSelected={filtersUsers.segmentos}
                useLoading={true}
                onClick={(selValue) => {handleSegmentos(selValue)}}
              /> 
            </div>

            <div className='col col-6 col-md-3 col-xl-2 mt-4 mt-xl-0'>
              <Select 
                placeholder='Tipo de Locação:'
                data={tiposLoc}
                dataSelected={filtersUsers.tiposLoc}
                useLoading={true}
                onClick={(selValue) => {handleTiposLoc(selValue)}}
              /> 
            </div>

            <div className='col col-12 col-md-3 col-xxl-2 mt-4'>
              <Select 
                placeholder='Estados:'
                data={estados}
                dataSelected={filtersUsers.estados}
                useLoading={true}
                onClick={(selValue) => {handleSetUF(selValue)}}
              />
            </div>

            <div className='col col-12 col-md-3 col-xxl-2 mt-4'>
              <Select 
                placeholder='Cidades:'
                data={cidades}
                dataSelected={filtersUsers.cidades}
                useLoading={true}
                onClick={(selValue) => {handleSetCidade(selValue)}}
              />
            </div>

          </div>

          <div className='row justify-content-center mb-5'>
          
            <div className='col col-12 col-md-4 mt-4'>
              <Input 
                  name='name'
                  placeholder='Nome:'
                  type='text'
                  value={filtersUserValues.name}
                  onChange={handleFiltersUsersValues}
                  useLoading={true}
              />
            </div>

            <div className='col col-6 col-md-1 col-xxl-1 mt-4'>
              <Btn 
                  style={{marginTop: '.5em', padding: '.6em'}}
                  type='button'
                  btnType='success'
                  iconRight='faCheck'
                  onClick={() => {handleApllyFilter('name', 'set')}}
                  useLoading={true}
              />
            </div> 

            <div className='col col-6 col-md-1 col-xxl-1 mt-4'>
              <Btn 
                  style={{marginTop: '.5em', padding: '.6em'}}
                  type='button'
                  btnType='danger'
                  iconRight='faDeleteLeft'
                  onClick={() => {handleApllyFilter('name', 'clear')}}
                  useLoading={true}
              />
            </div>  

            <hr className='d-block g-0 mt-4 d-md-none'></hr>

            <div className='col col-12 col-md-4 mt-0 mt-md-4'>
              <Input 
                  name='email'
                  placeholder='Email:'
                  type='email'
                  value={filtersUserValues.email}
                  onChange={handleFiltersUsersValues}
                  useLoading={true}
              />
            </div>

            <div className='col col-6 col-md-1 col-xxl-1 mt-4'>
              <Btn 
                  style={{marginTop: '.5em', padding: '.6em'}}
                  type='button'
                  btnType='success'
                  iconRight='faCheck'
                  onClick={() => {handleApllyFilter('email', 'set')}}
                  useLoading={true}
              />
            </div> 

            <div className='col col-6 col-md-1 col-xxl-1 mt-4'>
              <Btn 
                  style={{marginTop: '.5em', padding: '.6em'}}
                  type='button'
                  btnType='danger'
                  iconRight='faDeleteLeft'
                  onClick={() => {handleApllyFilter('email', 'clear')}}
                  useLoading={true}
              />
            </div>  

          </div>

          <hr></hr>
          <div className='row justify-content-center mt-md-2 mb-2'>

            <div className='col-12 col-md-4 col-xl-3'>
              <Btn 
                title='Adicionar Usuário'
                value='Adicionar Usuário'
                type='button'
                btnType='primary'
                iconRight='faUserPlus'
                onClick={() => {navigate('add')}}
                useLoading={true}
              />
            </div>

          </div>
          <hr ref={top}></hr>

          {users.length === 0 &&
            <div className="alert alert-warning" role="alert">
              Nenhum resultado com os filtros aplicados.
            </div>
          }

          {users.length > 0 &&

            <div className='row row-cols-1 row-cols-md-3 row-cols-xxl-4 justify-content-start mb-3 mt-5 mx-auto'>

              {users.map( (item, key) => {
                return(
                  item.id !== userId &&
                  <div key={key} className='userCards'
                  style={{
                    '--borderColor': `${item.corEmpresa !== null ? item.corEmpresa : '#ccc'}`,
                    '--img': `url(${BASE_BACK}${item.logo})`
                  }}
                  >

                    <div className={`userCardsIn ${cLoading ? 'Cloading' :  ''}`}>

                      {item.assAtivo !== null && item.type !== 1 &&
                        <div className={`usersAssAtivo text-white pointer ${item.assAtivo === 1 ? 'bg-success' : 'bg-danger'}`}
                        title='Ordenar' onClick={()=>{handleOrder('assAtivo')}}
                        >
                          {item.assAtivo === 1 &&
                            <>Assinatura Ativa</>
                          }
                          {item.assAtivo === 0 &&
                            <>Assinatura Inativa</>
                          }
                        </div>
                      }

                      {item.assAtivo === null && item.type !== 1 &&
                        <div className='usersAssAtivo bg-warning pointer'
                        title='Ordenar' onClick={()=>{handleOrder('assAtivo')}}
                        >
                          Sem Assinatura
                        </div>
                      }
                      
                      {item.planoId !== null && item.type !== 1 &&

                        <div title={`Plano ${item.planoNome} - ${item.planoDuracao} Meses`} className='usersPlano pointer' style={{
                          color: item.planoTxt !== null ? item.planoTxt : '#000',
                          background: item.planoBg !== null ? item.planoBg : '#fff'
                          }}
                          onClick={()=>{handleOrder('planoNome')}}
                          >
                          Plano: {item.planoNome}
                        </div>

                      }

                      {item.planoId === null && item.type !== 1 &&

                        <div title='Sem Plano' className='usersPlano' style={{
                          color: '#000',
                          background: '#fff'
                          }}>
                          Sem Plano
                        </div>

                      }

                      <h2 title='Ordenar' onClick={()=>{handleOrder('name')}} className='mx-2 mt-3 mb-2 pageTitle sub-m pointer'>{item.name}</h2>
                      
                      {item.nomeEmpresa !== null &&

                        <p>{item.nomeEmpresa}</p>

                      }

                      {item.docEmpresa !== null &&
                        
                        <div className='docEmpresa' title='Ordenar' onClick={()=>{handleOrder('docEmpresa')}}>
                        <p>{item.tipoPessoa === 2 ? 'CNPJ:':'CPF:'}</p>
                        <InputMask value={item.docEmpresa}
                          className='usersDocInput'
                          readOnly={true}
                          mask={item.tipoPessoa === 2 ? '99.999.999/9999-99' : '999.999.999-99'}
                          type='text'
                        />
                        </div>
                      }

                      {item.email !== null &&

                        <p title='Ordenar' onClick={()=>{handleOrder('email')}} style={{marginTop: '-.8em'}} className='pointer'>{item.email}</p>

                      }

                      {item.type !== 1 && item.logo !== null &&

                      <div className='usersLogo'></div>

                      }
                      
                      <div className='row justify-content-center justify-content-md-center my-0 my-0 usersBts'
                      style={{width: item.logo === null || item.type === 1 ? '100%' : ''}}
                      >
                        <div className='col-auto col-md-3 p-1 m-2'>
                          <Btn 
                            title='Editar'
                            type='button'
                            btnType='primary'
                            iconRight='faPenToSquare'
                            onClick={() => {item.id === userId ? navigate('/settings') : navigate(`edit/${item.id}`)}}
                            useLoading={true}
                          />
                        </div>
                        {item.type !== 1 &&
                          <div className='col-auto col-md-3 p-1 m-2'>
                            <Btn 
                              title='Remover'
                              type='button'
                              btnType='danger'
                              iconRight='faTrashCan'
                              onClick={() => {handleRemove(item.id, item.name, item.nomeEmpresa)}}
                              useLoading={true}
                            />
                          </div>
                        }

                        {item.type !== 1 && item.assAtivo === 1 &&
                          <div className='col-auto col-md-3 p-1 m-2'>
                            <Btn 
                              title={item.promoLinkId ? 'Ver Link Degustação' : 'Criar Link Degustação'}
                              type='button'
                              btnType={item.promoLinkId ? 'success' : 'warning'}
                              iconRight='faLink'
                              onClick={() => {handlePromoLink(item.id, item.name, item.promoLinkId, item.promoLink)}}
                              useLoading={true}
                            />
                          </div>
                        }
                        
                      </div>

                    </div>
                    
                  </div>
                )
                })
              }

            </div>

          }
          
          <Pagination 
            currentPage={filtersUsers.page}
            totalPage={totalPages}
            onClick={(setPage)=>{handlePagination(setPage)}}
          />

        </div>

        <div className='logoBotton'>
            <img src={LOGO_AUDIT} alt="AUDIT" className='auditLayout'/> 
        </div> 
        
      </motion.section>
    );
}

export default Users;