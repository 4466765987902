import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../../components/Forms/Input';
import Btn from '../../components/Forms/Btn';
import { motion } from 'framer-motion';
import { fade } from '../../util/AnimatedDiv'
import { LoaderContext } from '../../contexts/LoaderContext';
import { SiteContext } from '../../contexts/SiteContext';
import { AlertasContext } from '../../contexts/AlertasContext';
import { UserContext } from '../../contexts/UserContext';
import * as PL from '../../services/Planos.service';
import * as TXTS from '../../util/Texts';
import { LOGO_AUDIT } from '../../util/Urls';

function PlanoAdd() {

    const navigate                          = useNavigate();
    const { id }                            = useParams();
    const { setIsLoading }                  = useContext(LoaderContext);
    const { isLogged }                      = useContext(UserContext);
    const [add, setAdd]                     = useState(true);

    const {
        HandleScroll,
        setDocTitle,
        setErrorCon
    } = useContext(SiteContext);

    const {

        setAlertShow,
        setAlertBtnOk,
        setAlertBtnOkTxt,
        setAlertBtnCancel,
        setAlertOnOk,
        setAlertOnClose,
        handleCloseAlert,
        setAlertType,
        setAlertMsg,
    
    } = useContext(AlertasContext);

    const [disabledAdd, setDisabledAdd] = useState(true);

    const [values, setValues] = useState(
        { 
            nome: '',
            valor: '',
            duracao: 1, 
            bgColor: '#ffffff',
            txtColor: '#000000'
        }
    );

    const reqValues = ['nome', 'valor', 'durcao'];

    const handleSetValues = (e) => {
        
        setValues({
            ...values,
            [e.target.name]: e.target.value
        });

    }

    const handleAdd = async (e) => {
        
        e.preventDefault();

        const data = {

            id: add ? 0 : id,
            nome: values.nome,
            valor: values.valor,
            duracao: values.duracao, 
            bgColor: values.bgColor,
            txtColor: values.txtColor
            
        }
        
        setIsLoading('show');
      
        try{

            const result = await PL.AddPlano(data);

            if(result.data){

                if (result.status === 200) {

                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    setAlertOnClose(() => () => {navigate(-1)});
                    setAlertType('success');
                    setAlertBtnOk(false);  

                }else{

                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    if(result.data.plano){
                        
                        setAlertType('warning');
                        setAlertBtnCancel(true);
                        setAlertBtnOkTxt('Copiar Dados');
                        setAlertOnOk(() => () => {handleSetFields(result.data.plano); handleCloseAlert()});

                    }else{

                        setAlertType('error');
                        setAlertBtnOk(false);
                    }
                    
                }

            }else{
                
                setAlertShow(true);
                setAlertMsg(TXTS.ERRO_LOGIN_2);
                setAlertType('error');
                setAlertBtnOk(false);
            
            }

        }catch{

            setAlertShow(true);
            setAlertMsg(TXTS.ERRO_LOGIN_3);
            setAlertType('error');
            setAlertBtnOk(false);
            setErrorCon(true);
            
        }

        setIsLoading('hide');

    }

    const handleSetFields = (plano) => {
       
        setValues({
            nome: plano.nome,
            valor: plano.valor,
            duracao: plano.duracao, 
            bgColor: plano.bgColor,
            txtColor: plano.txtColor
        });
    }

    const handleGetPlano = async (id) => {

        setIsLoading('show');

        try{

            const result =  await PL.GetPlano(id);
            
            if (result.status === 200) {
                handleSetFields(result.data.plano);
            }else{
                navigate('/');
            }
            
        }catch{

        }

        setIsLoading('hide');

    }

    useEffect(() => {
        
        let err = 0;
    
        reqValues.forEach((item) => {

          if(values[item] === ''){ err += 1;}

        });
    
        if(err === 0){
            setDisabledAdd(false);
        }else{
            setDisabledAdd(true);
        }
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);  
    
    useEffect(() => {
        if(!add){
            handleGetPlano(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [add]);
    
    useEffect(() => {

        setDocTitle(isNaN(id) || id === '0' ? TXTS.PLANO_ADD_TITLE : TXTS.PLANO_EDIT_TITLE);
        setAdd(isNaN(id) || id === '0');
        
        if(isLogged){
            setIsLoading('hide');

            
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsLoading, isLogged]);

    return (
      <motion.section 
        className="conteudo"

        variants={fade}
        initial='entrada'
        animate='animacao'
        exit='saida'
        
        onScroll={HandleScroll}
      >

        <div className='container-lg'>

            <div className='row gx-0 align-items-center gap-2'>
                <h2 className='pageTitle'>
                    {add ? TXTS.PLANO_ADD_TITLE : TXTS.PLANO_EDIT_TITLE}
                </h2>
                <span style={{
                        background: values.bgColor, 
                        color: values.txtColor,
                        fontWeight: 'bold'
                    }}
                    className='d-flex w-auto p-2 rounded mb-2'>
                        {values.nome}
                </span>
            </div>
           
        </div>

        <div className='container container-lg'>

            <form className='row justify-content-center gy-3 gx-4 mt-1'>

                <div className='col col-12 col-md-3 col-xxl-2 mt-md-0'>
                    <Input 
                        name='nome'
                        placeholder='Nome:'
                        required={true}
                        type='text'
                        value={values.nome}
                        onChange={handleSetValues}
                        useLoading={true}
                    />
                </div>

                <div className='col col-6 col-md-2 col-xxl-2 mt-4 mt-md-0'>
                    <Input 
                        name='valor'
                        labelLeft='faDollarSign'
                        placeholder='Valor:'
                        type='number'
                        currency={true}
                        required={true}
                        value={values.valor}
                        onChange={handleSetValues}
                        useLoading={true}
                    />
                </div>

                <div className='col col-6 col-md-2 col-xxl-2 mt-4 mt-md-0'>
                    <Input 
                        name='duracao'
                        placeholder='Duração (Meses):'
                        required={true}
                        useNumber={true}
                        type='number'
                        min='1'
                        value={values.duracao}
                        onChange={handleSetValues}
                        useLoading={true}
                    />
                </div>

                <div className='col col-6 col-md-2 mb-4 mt-4 mt-md-1'>
                  <Input 
                    name='bgColor'
                    placeholder='Cor Fundo:'
                    type='color'
                    required={false}
                    value={values.bgColor}
                    onChange={handleSetValues}
                    useLoading={true}
                  />
                </div>

                <div className='col col-6 col-md-2 mb-4 mt-4 mt-md-1'>
                  <Input 
                    name='txtColor'
                    placeholder='Cor Texto:'
                    type='color'
                    required={false}
                    value={values.txtColor}
                    onChange={handleSetValues}
                    useLoading={true}
                  />
                </div>

                <div className='row justify-content-center mx-0 px-0'>
                    
                    <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                        <Btn 
                            value='Cancelar'
                            type='button'
                            btnType='danger'
                            onClick={() => {navigate('/')}}
                            useLoading={true}
                        />
                    </div>

                    <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                        <Btn 
                            value={add ?  'Adicionar' : 'Salvar'}
                            disabled={disabledAdd}
                            type='submit'
                            btnType='success'
                            onClick={handleAdd}
                            useLoading={true}
                        />
                    </div>

                </div>
               
            </form>
        </div>
        <div className='logoBotton'>
            <img src={LOGO_AUDIT} alt="AUDIT" className='auditLayout'/> 
        </div>
      </motion.section>
    );
}

export default PlanoAdd;