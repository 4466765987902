import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../../components/Forms/Input';
import Btn from '../../components/Forms/Btn';
import { motion } from 'framer-motion';
import { fade } from '../../util/AnimatedDiv'
import { LoaderContext } from '../../contexts/LoaderContext';
import { SiteContext } from '../../contexts/SiteContext';
import { AlertasContext } from '../../contexts/AlertasContext';
import { UserContext } from '../../contexts/UserContext';
import { GlobalFiltersContext } from '../../contexts/GlobalFiltersContext';
import * as F from '../../util/Functions';
import * as FR from '../../services/Frota.service';
import * as TXTS from '../../util/Texts';
import { LOGO_AUDIT } from '../../util/Urls';
import Excel from '../../components/Excel';

function FrotaAdd() {

    const navigate                          = useNavigate();
    const { id }                            = useParams();
    const { setIsLoading }                  = useContext(LoaderContext);
    const { isLogged }                      = useContext(UserContext);


    const columns                           = ['frota_operacional', 'valor_frota_nf', 'ticket_medio', 'ocupacao'];
    const columnsTitles                     = ['Frota_Operacional', 'Valor_Frota_NF', 'Ticket_Médio', 'Ocupação'];

    const { 
        nomeVerDeEmpresa,
        nomeLocadoraParaoBal,
        globalFiltersPeriodo
    } = useContext(GlobalFiltersContext);

    const {
        HandleScroll,
        setDocTitle,
        setErrorCon
    } = useContext(SiteContext);

    const {

        setAlertShow,
        setAlertBtnOk,
        setAlertBtnOkTxt,
        setAlertBtnCancel,
        setAlertOnOk,
        setAlertOnClose,
        handleCloseAlert,
        setAlertType,
        setAlertMsg,
    
    } = useContext(AlertasContext);

    const [disabledAdd, setDisabledAdd] = useState(true);

    const [values, setValues] = useState(
        { 
            mes_ano: F.MesAno(),
            frota_operacional: 1,
            valor_frota_nf: '',
            ticket_medio: '',
            ocupacao: 0
        }
        );

    const reqValues = ['mes_ano', 'frota_operacional', 'ocupacao', 'ticket_medio', 'valor_frota_nf'];
        
    const handleMesAno = (e) => {
        
        const value = e.target.value === '' ? F.MesAno() : e.target.value;

        setValues({
            ...values,
            mes_ano: value,
        });

    }

    const handleSetValues = (e) => {
        
        if(e.target.value === '0'){
            return;
        }

        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    }

    const handleExcel = (excel) => {
       

        if(excel.length === 1){

            const frotaOp = excel[0].Frota_Operacional;
            const ValorFNF = excel[0].Valor_Frota_NF;
            const ticketM = excel[0].Ticket_Médio;
            const ocupa = excel[0].Ocupação;


            if(!isNaN(frotaOp) && frotaOp > 0){
                setValues({
                    ...values,
                    frota_operacional: frotaOp,
                });
            }

            setValues({
                ...values,
                valor_frota_nf: F.Moeda(isNaN(ValorFNF) ? F.Decimal(ValorFNF) : ValorFNF, false),
                ticket_medio: F.Moeda(isNaN(ticketM) ? F.Decimal(ticketM) : ticketM, false),
                ocupacao: ocupa
            });

            

        }
        
    }

    const handleAdd = async (e) => {
        
        e.preventDefault();

        const data = {
            mes_ano: values.mes_ano,
            frota_operacional: values.frota_operacional,
            ocupacao: values.ocupacao,
            ticket_medio: values.ticket_medio,
            valor_frota_nf: values.valor_frota_nf,
            id: isNaN(id) ? 0 : id,
            companyId: globalFiltersPeriodo.companyId
        }
        
        setIsLoading('show');
      
        try{

            const result = isNaN(id) ? await FR.AddFrota(data) : await FR.EditFrota(data);

            if(result.data){

                if (result.status === 200) {

                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    setAlertOnClose(() => () => {navigate(-1)});
                    setAlertType('success');
                    setAlertBtnOk(false);  

                }else{

                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    if(result.data.frota){

                        setAlertType('warning');
                        setAlertBtnCancel(true);
                        setAlertBtnOkTxt('Copiar Dados');
                        setAlertOnOk(() => () => {handleSetFields(result.data); handleCloseAlert()});

                    }else{

                        setAlertType('error');
                        setAlertBtnOk(false);
                    }
                    
                }

            }else{
                
                setAlertShow(true);
                setAlertMsg(TXTS.ERRO_LOGIN_2);
                setAlertType('error');
                setAlertBtnOk(false);
            
            }

        }catch{

            setAlertShow(true);
            setAlertMsg(TXTS.ERRO_LOGIN_3);
            setAlertType('error');
            setAlertBtnOk(false);
            setErrorCon(true);
            
        }

        setIsLoading('hide');

    }

    const handleSetFields = (data) => {

        setValues({
            ...values,
            mes_ano: data.mes_ano,
            frota_operacional: data.frota_operacional,
            ocupacao: data.ocupacao,
            ticket_medio: data.ticket_medio,
            valor_frota_nf: data.valor_frota_nf
        });
    }

    const handleGetFields = async (id) => {

        setIsLoading('show');

        try{

            const result =  await FR.GetFrotaFields(id, globalFiltersPeriodo.companyId);
            
            if (result.status === 200) {
                handleSetFields(result.data);
            }else{
                navigate('/balancos');
            }
            
        }catch{

        }

        setIsLoading('hide');

    }

    useEffect(() => {
        
        let err = 0;
    
        reqValues.forEach((item) => {

            if((item === 'ticket_medio' || item === 'valor_frota_nf') && values[item] === '0,00'){ err += 1;}
            else if(values[item] === ''){ err += 1;}

        });
    
        if(err === 0){
            setDisabledAdd(false);
        }else{
            setDisabledAdd(true);
        }
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);  
    
    
    useEffect(() => {
        setDocTitle(isNaN(id) ? TXTS.FROTA_ADD_TITLE : TXTS.FROTA_EDIT_TITLE);
        
        if(isLogged){
            setIsLoading('hide');
            handleGetFields(isNaN(id) ? 0 : id);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsLoading, isLogged]);

    return (
      <motion.section 
        className="conteudo"

        variants={fade}
        initial='entrada'
        animate='animacao'
        exit='saida'
        
        onScroll={HandleScroll}
      >

        <div className='container-lg'>
            <h2 className='pageTitle'>
                {isNaN(id) ? TXTS.FROTA_ADD_TITLE : TXTS.FROTA_EDIT_TITLE}
                {nomeLocadoraParaoBal !== '' ? ` - (${nomeLocadoraParaoBal})` : ''}
                {nomeVerDeEmpresa !== '' ? ` - (${nomeVerDeEmpresa})` : ''}
            </h2>
        </div>

        <div className='container container-lg'>

            <div className='row justify-content-center mx-0 px-0'>
                <div className='col col-12 col-md-3'>
                    <Excel 
                        type='import'
                        title='Importar Excel'
                        onReady={(excel) => {handleExcel(excel)}}
                    />
                </div>

                <div className='col col-12 col-md-3 mt-4 mt-md-0'>
                    <Excel 
                        type='export'
                        title={isNaN(id) ?  'Download Modelo' : 'Download Frota'}
                        data={values}
                        mes={values.mes_ano}
                        subject='Frota'
                        columns={columns}
                        columnsTitles={columnsTitles}
                    />
                </div>
            </div>
            
            <hr></hr>

            <form className='row justify-content-center gy-3 gx-4 mt-1'>
                
                <div className='col col-12 col-md-3 col-xxl-2'>
                    <Input 
                        name='mes_ano'
                        placeholder='Referente:'
                        required={true}
                        type='month'
                        value={values.mes_ano}
                        onChange={handleMesAno}
                        useLoading={true}
                    />
                </div>

                {/* <div className='col col-6 col-md-2 col-xxl-2'>
                    <Input 
                        name='frota'
                        placeholder='Frota:'
                        required={true}
                        useNumber={true}
                        type='number'
                        min='1'
                        value={values.frota}
                        onChange={handleSetValues}
                        useLoading={true}
                    />
                </div> */}

                <div className='col col-6 col-md-2 col-xxl-2'>
                    <Input 
                        name='frota_operacional'
                        placeholder='Frota Operacional:'
                        required={true}
                        useNumber={true}
                        type='number'
                        min='1'
                        value={values.frota_operacional}
                        onChange={handleSetValues}
                        useLoading={true}
                    />
                </div>

                <div className='col col-6 col-md-3 col-xxl-2'>
                    <Input 
                        name='valor_frota_nf'
                        labelLeft='faDollarSign'
                        placeholder='Valor Frota NF:'
                        type='number'
                        currency={true}
                        required={true}
                        value={values.valor_frota_nf}
                        onChange={handleSetValues}
                        useLoading={true}
                    />
                </div>

                <div className='col col-6 col-md-2 col-xxl-2'>
                    <Input 
                        name='ticket_medio'
                        labelLeft='faDollarSign'
                        placeholder='Ticket Médio:'
                        type='number'
                        currency={true}
                        required={true}
                        value={values.ticket_medio}
                        onChange={handleSetValues}
                        useLoading={true}
                    />
                </div>

                <div className='col col-6 col-md-2 col-xxl-2'>
                    <Input 
                        name='ocupacao'
                        placeholder='Ocupação(%):'
                        required={true}
                        useNumber={true}
                        type='number'
                        min='0'
                        max='100'
                        step='.01'
                        value={values.ocupacao}
                        onChange={handleSetValues}
                        useLoading={true}
                    />
                </div>

                <div className='row justify-content-center mx-0 px-0'>
                    
                    <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                        <Btn 
                            value='Cancelar'
                            type='button'
                            btnType='danger'
                            onClick={() => {navigate('/balancos')}}
                            useLoading={true}
                        />
                    </div>

                    <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                        <Btn 
                            value={isNaN(id) ?  'Adicionar' : 'Salvar'}
                            disabled={disabledAdd}
                            type='submit'
                            btnType='success'
                            onClick={handleAdd}
                            useLoading={true}
                        />
                    </div>

                </div>
               
            </form>
        </div>
        <div className='logoBotton'>
            <img src={LOGO_AUDIT} alt="AUDIT" className='auditLayout'/> 
        </div>
      </motion.section>
    );
}

export default FrotaAdd;