import { instance } from "./Default.service";

export const GetDashAdmin = async (data) => {

    return await instance.post('getDashAdmin', data)
            .then(response => {
                return response;
            })
            .catch(error => {
                if(error.response){
                    return error.response;
                }else{
                    return null;
                }
            });
}