import { instance } from "./Default.service";

export const GetBtns = async () => {

    return await instance.get('indicadores/getCampos')
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const AddIndicador = async (data) => {

    return await instance.post('indicadores/addIndicador', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const EditIndicador = async (data) => {

    return await instance.post('indicadores/editIndicador', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const ChangeOrder = async (data) => {

    return await instance.post('indicadores/changeOrder', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const GetIndicador = async (id) => {

    return await instance.get(`indicadores/getIndicador/${id}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const GetIndicadores = async () => {

    return await instance.get('indicadores/getIndicadores')
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const DeleteIndicador = async (id) => {

    return await instance.get(`indicadores/deleteIndicador/${id}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}


export const GetIndicadoresDash = async (data) => {

    return await instance.post('indicadores/getIndicadoresDash', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}