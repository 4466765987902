import './index.css';

function Pagination({
    currentPage = 0,
    totalPage = 0,
    backButton = true,
    nextButton = true,
    onClick = false,

}) {

    const handleSetPage = (e, setPage) =>{

        e.preventDefault();
        
        if (typeof onClick === "function") {
        onClick(setPage);
        }
    }

    const pagesBtn = [];

    for (let key = 1; key <= totalPage; key++) {
        pagesBtn.push( <li key={key} className={`page-item ${key === currentPage ? 'active' : ''}`}><a className='page-link' href='/#' onClick={(e) => handleSetPage(e, key)}>{key}</a></li>);
    }
  
    return (
        <ul className='pagination flex-wrap justify-content-center mt-4'>
            {backButton &&
                <li className={`page-item ${currentPage > 1 ? '' : 'disabled'}`}>
                    <a className='page-link' href='/#' aria-label='Anterior' onClick={(e) => handleSetPage(e, (currentPage-1))}>
                        <span aria-hidden='true'>&laquo;</span>
                    </a>
                </li>
            }
           
            {pagesBtn}
            
            {nextButton &&
                <li className={`page-item ${currentPage < totalPage ? '' : 'disabled'}`}>
                    <a className='page-link' href='/#' aria-label='Próximo' onClick={(e) => handleSetPage(e, (currentPage+1))}>
                        <span aria-hidden='true'>&raquo;</span>
                    </a>
                </li>
            }
          </ul>
    );
  }
  
export default Pagination;


