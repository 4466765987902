import { useContext } from 'react';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as FA from '@fortawesome/free-solid-svg-icons';
import { LoaderContext } from '../../../contexts/LoaderContext';


function Btn({
    value = false,
    type = 'button',
    title = false,
    btnType = 'primary',
    style = false,
    iconLeft = false,
    iconRight =  false,
    disabled = false,
    onClick = false,
    useLoading = false
}) {
    
    const { cLoading } = useContext(LoaderContext);

    return (
        <button
        {...(title && { title: title })}
        className={`btn ${btnType} ${cLoading && useLoading ? 'Cloading' :  ''}`}
        type={type}
        {...(onClick && { onClick: onClick })}
        {...(disabled && { disabled: disabled })}
        {...(style && { style: style })}
        >

            {iconLeft &&
                <FontAwesomeIcon icon={FA[iconLeft]} />
            }

            {value &&
                value
            }

            {iconRight &&
                <FontAwesomeIcon icon={FA[iconRight]} />
            }

        </button>
    );
}

export default Btn;