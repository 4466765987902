/* eslint no-eval: 0 */
import './index.css';
import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { animacao } from '../../util/AnimatedDiv'
import Btn from '../../components/Forms/Btn';
import Select from '../../components/Forms/Select';
import Input from '../../components/Forms/Input';
import { Chart } from "react-google-charts";
import { LoaderContext } from '../../contexts/LoaderContext';
import { SiteContext } from '../../contexts/SiteContext';
import { UserContext } from '../../contexts/UserContext';
import { AlertasContext } from '../../contexts/AlertasContext';
import { GlobalFiltersContext } from '../../contexts/GlobalFiltersContext';
import * as TXTS from '../../util/Texts';
import * as F from '../../util/Functions';
import { LOGO_AUDIT } from '../../util/Urls';
import { BASE_BACK } from '../../util/Urls';
import * as PL from '../../services/Planos.service';
import * as LOCS from '../../services/LocadorasPadrao.service';
import * as ES from '../../services/Enderecos.service';
import * as SG from '../../services/Segmentos.service';
import * as TL from '../../services/TiposLocacao.service';
import * as DA from '../../services/DashAdmin.service';
import SlidesHome from '../../components/SlidesHome';
function HomeAdmin() {

  const navigate                    = useNavigate();
  const { setIsLoading, cLoading }  = useContext(LoaderContext);
  const [secondLoad, setSecondLoad] = useState(false);

  const {
    HandleScroll,
    setDocTitle,
    canLoad,
    setErrorCon
  } = useContext(SiteContext);

  const { isLogged, userType, estados } = useContext(UserContext);

  const {

    setAlertShow,
    setAlertBtnOk,
    setAlertBtnCancel,
    setAlertOnOk,
    setAlertType,
    setAlertMsg,

  } = useContext(AlertasContext);

  const { 
    
    filtersUsers, setFiltersUser,
    handleFiltersUsersPlanoId,
    handleFiltersUsersAssAtivo,
    ultimosMesesSelect,
    handleUltimosMesesUsers,
    handleMesAnoIniUsers,
    handleMesAnoFimUsers

  } = useContext(GlobalFiltersContext);
  
  const [planos, setPlanos]       = useState([]);
  const [locadoras, setLocadoras] = useState([]);
  const [segmentos, setSegmentos] = useState([]);

  const handleGetSegmentos = async () => {
    
    setIsLoading('show');
    
    try{
    
        const result =  await SG.GetSegmentos();

        if(result.data){

            if (result.status === 200) {
              
              setSegmentos(result.data.segmentos);

            }else{
              setSegmentos([]);
            }

        }

    }catch{
      setSegmentos([]);
    }

    setIsLoading('hide');
  }

  const handleGetTiposLoc = async () => {
    
    setIsLoading('show');
    
    try{
    
        const result =  await TL.GetTiposLoc();

        if(result.data){

            if (result.status === 200) {
              
              setTiposLoc(result.data.tiposLoc);

            }else{
              setTiposLoc([]);
            }

        }

    }catch{
      setTiposLoc([]);
    }

    setIsLoading('hide');
  }

  const handleSetUF = (selValue) => {
    
    const newSelected = F.CheckMultiSelFilter(selValue, filtersUsers.estados);

    if(!newSelected) {
      return;
    }

    setFiltersUser({
      ...filtersUsers,
      estados: newSelected,
      reload: false
    });

  }

  const handleSetCidade = (selValue) => {

    const newSelected = F.CheckMultiSelFilter(selValue, filtersUsers.cidades);

    if(!newSelected) {
      return;
    }

    setFiltersUser({
      ...filtersUsers,
      cidades: newSelected,
      reload: true
    });

  }

  const [cidades, setCidades] = useState([]);

  const handleGetCidades = async () => {

    const data = {estados: filtersUsers.estados};    

    try{

      const result =  await ES.GetCidadesAll(data);
      
      if(result.data && result.status === 200){

        const cidadesOk = result.data.cidades;

        setCidades(cidadesOk);

        const filtersOk = [];
        
        filtersUsers.cidades.forEach(value => {
          
          cidadesOk.forEach(element => {

            if (element.id === value) {

              filtersOk.push(value);

            }

          });

        });

        setFiltersUser({
          ...filtersUsers,
          cidades: filtersOk,
          reload: true
        });
        
      }else{
        setCidades([]);
        setFiltersUser({
          ...filtersUsers,
          estados: [0],
          cidades: [0],
          reload: true
        });
      }
      

    }catch{
      setFiltersUser({
        ...filtersUsers,
        estados: [0],
        cidades: [0],
        reload: false
      });
    }

  }

  const assAtivo = [

    {
      label: 'Todos',
      id: 2
    },

    {
      label: 'Ativa',
      id: 1
    },

    {
      label: 'Inativa',
      id: 0
    },

  ]; 

  const handleSegmentos = (selValue) => {

    const newSelected = F.CheckMultiSelFilter(selValue, filtersUsers.segmentos);

    if(!newSelected) {
      return;
    }

    setFiltersUser({
      ...filtersUsers,
      segmentos: newSelected,
      reload: true
    });

  }

  const [tiposLoc, setTiposLoc]   = useState([]);

  const handleTiposLoc = (selValue) => {

    const newSelected = F.CheckMultiSelFilter(selValue, filtersUsers.tiposLoc);

    if(!newSelected) {
      return;
    }

    setFiltersUser({
      ...filtersUsers,
      tiposLoc: newSelected,
      reload: true
    });

  }

  const handleGetLocadoras = async () => {
        
    setIsLoading('show');
    
    try{
    
        const result =  await LOCS.GetLocadoras();

        if(result.data){

            if (result.status === 200) {
              
              setLocadoras(result.data.locadoras);
              
            }

        }

    }catch{
        
    }

    setIsLoading('hide');
  }
  const handleGetPlanos = async () => {
    
    setIsLoading('show');
    
    try{
    
        const result =  await PL.GetPlanos();

        if(result.data){

            if (result.status === 200) {
              
              setPlanos(result.data.planos);

            }else{
              setPlanos([]);
            }

        }

    }catch{
      setPlanos([]);
    }

    setIsLoading('hide');
  }

  const [assChart, setAssChart]       = useState([]);
  const [assMesChart, setAssMesChart] = useState([]);

  const [planosChart, setPlanosChart]               = useState([]);
  const [planosMesChart, setPlanosMesChart]         = useState([]);
  const [planosVMesChart, setPlanosVMesChart]       = useState([]);
  const [planosChartSlices, setPlanosChartSlices]   = useState({});
  const [planosChartsColors, setPlanosChartsColors] = useState([]);
  const [planosLegends, setPlanosLegends]           = useState([]);

  const [usersEstadosChart, setUsersEstadosChart]         = useState([]);
  const [usersEstadosMesChart, setUsersEstadosMesChart]   = useState([]);
  const [usersEstadosVMesChart, setUsersEstadosVMesChart] = useState([]);
  const [colorsUfs, setColorsUfsIn]                       = useState([]);
  
  const handleGetDashAdmin = async () => {

    setIsLoading('show');
    setSecondLoad(true);

    try{
    
        const result =  await DA.GetDashAdmin(filtersUsers);

        if(result.data){

            if (result.status === 200) {
              
              const assinaturas = result.data.assinaturas;
              const assinaturasMesAno = result.data.assinaturasMesAno;
              const planosQtd = result.data.planosQtd;
              const planosSlices = result.data.planosSlices;
              const planosColors = result.data.planosColors;
              const planosLegends = result.data.planosLegends;
              const planosMesQtd = result.data.planosMesQtd;
              const planosMesValor = result.data.planosMesValor;
              const estadosQtd = result.data.estadosQtd;
              const estadosColors = result.data.estadosColors;
              const estadosMesQtd = result.data.estadosMesQtd;
              const estadosMesValor = result.data.estadosMesValor;

              setAssChart(assinaturas);
              setAssMesChart(assinaturasMesAno);
              setPlanosChart(planosQtd);
              setPlanosChartSlices(planosSlices);
              setPlanosChartsColors(planosColors);
              setPlanosLegends(planosLegends);
              setPlanosMesChart(planosMesQtd);
              setPlanosVMesChart(planosMesValor);
              setUsersEstadosChart(estadosQtd);
              setColorsUfsIn(estadosColors);
              setUsersEstadosMesChart(estadosMesQtd);
              setUsersEstadosVMesChart(estadosMesValor);

            }else{
        
              setAssChart([]);
              setAssMesChart([]);
              setPlanosChart([]);
              setPlanosChartSlices({});
              setPlanosChartsColors([]);
              setPlanosLegends([]);
              setPlanosMesChart([]);
              setPlanosVMesChart([]);
              setUsersEstadosChart([]);
              setColorsUfsIn([]);
              setUsersEstadosMesChart([]);
              setUsersEstadosVMesChart([]);

            }

        }

    }catch{
      
      setAssChart([]);
      setAssMesChart([]);
      setPlanosChart([]);
      setPlanosChartSlices({});
      setPlanosChartsColors([]);
      setPlanosLegends([]);
      setPlanosMesChart([]);
      setPlanosVMesChart([]);
      setUsersEstadosChart([]);
      setColorsUfsIn([]);
      setUsersEstadosMesChart([]);
      setUsersEstadosVMesChart([]);

    }

    setIsLoading('hide');
    setSecondLoad(false);
  }

  const handleDoRemove = async (Id, qual) => {

    setIsLoading('show');

    let url;

    if(qual === 'plano') {
      url = PL.DeletePlano(Id);
    }

    if(qual === 'locMercado') {
      url = LOCS.DeleteLocadora(Id);
    }

    try{

      const result =  await url;

      setAlertBtnCancel(false);

      if(result.data){

        if (result.status === 200) {

          setAlertShow(true);
          setAlertMsg(result.data.msg);
          setAlertType('success');
          setAlertBtnOk(false);  

          if(qual === 'plano') {
            handleGetPlanos();
          }
      
          if(qual === 'locMercado') {
            handleGetLocadoras();
          }

        }else{

            setAlertShow(true);
            setAlertMsg(result.data.msg);
            setAlertType('error');
            setAlertBtnOk(false);
        }

      }else{
              
          setAlertShow(true);
          setAlertMsg(TXTS.ERRO_LOGIN_2);
          setAlertType('error');
          setAlertBtnOk(false);
      
      }

    }catch{

        setAlertShow(true);
        setAlertMsg(TXTS.ERRO_LOGIN_3);
        setAlertType('error');
        setAlertBtnOk(false);
        setErrorCon(true);

    }

    setIsLoading('hide');
  }

  const handleRemove = (Id, titulo, qual) => {
    
    let msg;

    if(qual === 'plano') {
      msg = `Remover o Plano: ${titulo}?`
    }

    if(qual === 'locMercado') {
      msg = `Remover a Locadora Mercado: ${titulo}?`
    }
    
    setAlertShow(true);
    setAlertType('warning');
    setAlertBtnCancel(true);
    setAlertOnOk(() => () => {handleDoRemove(Id, qual)});
    setAlertMsg(msg);

  }
  
  useEffect(() => {
  
    if(filtersUsers.reload === true && canLoad === '' && isLogged === true && secondLoad === false){
      handleGetDashAdmin();
      
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersUsers, canLoad, isLogged]);

  useEffect(() => {

    if(isLogged){
      handleGetCidades();
    }

     // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [filtersUsers.estados, isLogged]);

  useEffect(() => {
    if(isLogged && canLoad === ''){

      if(userType === 1){

        handleGetPlanos();
        handleGetLocadoras();
        handleGetSegmentos();
        handleGetTiposLoc();
      }
      
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, canLoad, userType]);

  useEffect(() => {
    
    setDocTitle(TXTS.DASHBOARD_TITLE_ADM);

    if(isLogged)

    setTimeout(() => {
      setIsLoading('hide');
    }, 500);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading, isLogged]);

    return (
      <motion.section 
        className="conteudo"

        variants={animacao}
        initial='entrada'
        animate='animacao'
        exit='saida'
        
        onScroll={HandleScroll}
      >
        <div className='container-lg'>
          <h2 className='pageTitle'>{TXTS.DASHBOARD_TITLE_ADM}</h2>
        </div>

        <div className='container container-lg'>
          
          <div className='row row-cols-1 row-cols-md-2 gy-3 gx-3 justify-content-start mb-3 mx-auto'>
            <div className='col col-12 col-md-8'>
              <div className='row justify-content-center mt-md-2'>

                <div className='col col-6 col-md-3 mt-4 mt-md-0'>
                    <Select 
                      placeholder='Assinatura:'
                      data={assAtivo}
                      value={filtersUsers.assAtivo}
                      useLoading={true}
                      search={false}
                      onClick={(selValue) => {handleFiltersUsersAssAtivo(selValue)}}
                    />
                </div>

                <div className='col col-6 col-md-3 mt-4 mt-md-0'>
                    <Select 
                      placeholder='Plano:'
                      data={planos}
                      value={filtersUsers.planoId}
                      useLoading={true}
                      useColors={true}
                      onClick={(selValue) => {handleFiltersUsersPlanoId(selValue)}}
                    />
                </div>

                <div className='col col-6 col-md-4 mt-4 mt-md-0'>
                  <Select 
                    placeholder='Segmentos:'
                    data={segmentos}
                    dataSelected={filtersUsers.segmentos}
                    useLoading={true}
                    onClick={(selValue) => {handleSegmentos(selValue)}}
                  /> 
                </div>

                <div className='col col-6 col-md-4 mt-4'>
                  <Select 
                    placeholder='Tipo de Locação:'
                    data={tiposLoc}
                    dataSelected={filtersUsers.tiposLoc}
                    useLoading={true}
                    onClick={(selValue) => {handleTiposLoc(selValue)}}
                  /> 
                </div>

                <div className='col col-12 col-md-4 mt-4'>
                  <Select 
                    placeholder='Estados:'
                    data={estados}
                    dataSelected={filtersUsers.estados}
                    useLoading={true}
                    onClick={(selValue) => {handleSetUF(selValue)}}
                  />
                </div>

                <div className='col col-12 col-md-4 mt-4'>
                  <Select 
                    placeholder='Cidades:'
                    data={cidades}
                    dataSelected={filtersUsers.cidades}
                    useLoading={true}
                    onClick={(selValue) => {handleSetCidade(selValue)}}
                  />
                </div>

                <div className='col col-12 col-md-4 col-xxl-3 mt-4'>
                  <Select 
                    placeholder='Período:'
                    data={ultimosMesesSelect}
                    value={filtersUsers.ultimos_meses}
                    useLoading={true}
                    onClick={(selValue) => {handleUltimosMesesUsers(selValue)}}
                  />
                </div>

                <div className='col col-12 col-md-4 col-xxl-3 mt-4'>
                  <Input 
                    name='mes_ano_ini'
                    placeholder='Início:'
                    type='month'
                    max={filtersUsers.mes_ano_fim}
                    value={filtersUsers.mes_ano_ini}
                    onChange={handleMesAnoIniUsers}
                    disabled={filtersUsers.mes_ano_fim_disabled}
                    useLoading={true}
                  />
                </div>

                <div className='col col-12 col-md-4 col-xxl-3 mt-4'>
                  <Input 
                    name='mes_ano_fim'
                    placeholder='Fim:'
                    type='month'
                    min={filtersUsers.mes_ano_ini}
                    value={filtersUsers.mes_ano_fim}
                    onChange={handleMesAnoFimUsers}
                    disabled={filtersUsers.mes_ano_ini_disabled}
                    useLoading={true}
                  />
                </div>

              </div>
              
              <hr className='my-4'></hr>

              {assChart.length > 0 && assMesChart.length > 0 &&
                <>
                  <div className='card border-light position-relative p-0 gx-0 gap-3 mb-4 homeAdminCard'>
                    <div className='card-header text-dark bg-light'>
                      Assinaturas
                    </div>
                    <div className='row'>
                      <div className='col col-12 col-md-5 pt-4 px-1 pb-1' style={{height: '260px'}}>
                        <Chart
                          chartType="PieChart"
                          data={assChart}
                          options={{
                            legend: { position: 'bottom', textStyle:  {fontSize: 12, bold: true}}, 
                            backgroundColor: { fill:'transparent' },
                            tooltip: {textStyle:  {fontSize: 14, bold: true}},
                            chartArea: {bottom: 50, width: '100%', height: '100%'},
                            colors: ['#00E820', '#E83100'],
                            pieSliceTextStyle: {
                              color: 'black',
                              fontSize: 14,
                              bold: true
                            }
                          }}
                          width={'100%'}
                          height={'100%'}
                          chartLanguage='pt_BR'
                        />
                      </div>

                      <div className='col col-12 col-md-7 pt-4 px-3 pb-2' style={{height: '260px'}}>
                        <Chart
                          chartType="ColumnChart"
                          width="100%"
                          height="100%"
                          data={assMesChart}
                          options={{
                            legend: { position: 'none'}, 
                            backgroundColor: { fill:'transparent' },
                            tooltip: {textStyle:  {fontSize: 14, bold: true}},
                            chartArea: {left: 30, right: 15, bottom: 30, top: 5},
                            colors: ['#00E820', '#E83100'],
                            bar: {
                              groupWidth: assMesChart.length < 4 ? 30 : 'auto'
                            },
                            vAxis: {
                              format: '0'
                            }
                          }}
                          chartLanguage='pt_BR'
                        />
                      </div>
                    </div>
                    

                  </div>
                  <hr className='my-4'></hr>
                </>
              }

              {planosChart.length > 0 && planosMesChart.length > 0 &&
                <>
                  <div className='card border-light position-relative p-0 gx-0 gap-3 mb-4 homeAdminCard'>
                    <div className='card-header text-dark bg-light'>
                      Usuários/Planos
                    </div>
                    <div className='chartLegends'>
                    {planosLegends.map( (item, key) => {
                        
                        return(
                          <legend key={key} style={{'--bgColor':`${item.bgColor}`, '--txtColor':`${item.txtColor}`}}>{item.label}</legend>
                        )
                      })
                    }
                    </div>
                    <div className='row'>
                      <div className='col col-12 col-md-5 pt-4 px-1 pb-1' style={{height: '260px'}}>
                        <Chart
                          chartType="PieChart"
                          data={planosChart}
                          options={{
                            legend: { position: 'none' }, 
                            backgroundColor: { fill:'transparent' },
                            tooltip: {textStyle:  {fontSize: 14, bold: true}},
                            chartArea: {bottom: 0, width: '100%', height: '100%'},
                            pieSliceTextStyle: {
                              fontSize: 14,
                              bold: true
                            },
                            slices: planosChartSlices,
                          }}
                          width={'100%'}
                          height={'100%'}
                          chartLanguage='pt_BR'
                        />
                      </div>

                      <div className='col col-12 col-md-7 pt-4 px-3 pb-2' style={{height: '260px'}}>
                        <Chart
                          chartType="ColumnChart"
                          width="100%"
                          height="100%"
                          data={planosMesChart}
                          options={{
                            legend: { position: 'none'}, 
                            backgroundColor: { fill:'transparent' },
                            tooltip: {textStyle:  {fontSize: 14, bold: true}},
                            chartArea: {left: 30, right: 15, bottom: 30, top: 5},
                            colors: planosChartsColors,
                            bar: {
                              groupWidth: planosMesChart.length < 4 ? 50 : 'auto'
                            },
                            vAxis: {
                              format: '0'
                            }
                          }}
                          chartLanguage='pt_BR'
                        />
                      </div>

                      <div className='col col-12 pt-4 px-3 pb-2' style={{height: '260px'}}>
                        <Chart
                          chartType='Bar'
                          width="100%"
                          height="100%"
                          data={planosVMesChart}
                          options={{
                            legend: { position: 'none'}, 
                            backgroundColor: { fill:'transparent' },
                            tooltip: {textStyle:  {fontSize: 14, bold: true}},
                            chartArea: {left: 100, right: 15, bottom: 40, top: 5},
                            colors: planosChartsColors
                           
                          }}
                          chartLanguage='pt_BR'
                        />
                      </div>
                    </div>
                    

                  </div>
                  <hr className='my-4'></hr>
                </>
                
              }

              {usersEstadosChart.length > 0 && usersEstadosMesChart &&
                <>
                  
                      
                    <div className='card border-light p-0 gx-0 gap-3 mb-4 position-relative homeAdminCard'>
                      <div className='card-header text-dark bg-light'>
                        Usuários/Estados
                      </div>
                      <div className='row'>
                        <div className='col col-12 pt-4 px-3 pb-2' style={{height: '260px'}}>
                          <Chart
                            chartType="BarChart"
                            width="100%"
                            height="100%"
                            data={usersEstadosChart}
                            options={{
                              legend: { position: 'none'}, 
                              backgroundColor: { fill:'transparent' },
                              tooltip: {textStyle:  {fontSize: 14, bold: true}},
                              chartArea: {left: 30, right: 15, bottom: 20, top: 5},
                              bar: { groupWidth: "95%" },
                              hAxis: {
                                format: '0'
                              }
                            }}
                            chartLanguage='pt_BR'
                          />
                        </div>
                        
                        <div className='col col-12 pt-4 px-3 pb-2' style={{height: '260px'}}>
                          <Chart
                            chartType="ColumnChart"
                            width="100%"
                            height="100%"
                            data={usersEstadosMesChart}
                            options={{
                              legend: { position: 'none'}, 
                              backgroundColor: { fill:'transparent' },
                              tooltip: {textStyle:  {fontSize: 14, bold: true}},
                              chartArea: {left: 30, right: 15, bottom: 30, top: 10},
                              colors: colorsUfs,
                              bar: {
                                groupWidth: 'auto'
                              },
                              vAxis: {
                                format: '0'
                              }
                            }}
                            chartLanguage='pt_BR'
                          />
                        </div>

                        <div className='col col-12 pt-4 px-3 pb-2' style={{height: '260px'}}>
                          <Chart
                            chartType='Bar'
                            width="100%"
                            height="100%"
                            data={usersEstadosVMesChart}
                            options={{
                              legend: { position: 'none'}, 
                              backgroundColor: { fill:'transparent' },
                              tooltip: {textStyle:  {fontSize: 14, bold: true}},
                              chartArea: {left: 100, right: 15, bottom: 40, top: 5},
                              colors: colorsUfs
                            
                            }}
                            chartLanguage='pt_BR'
                          />
                        </div>
                      </div>
                      
                    </div>
                  
                  <hr className='my-4'></hr>
                  
                </>
              }          
              
            </div>
              
            <div className='col col-12 col-md-4'>
              
              <div className='col-12 card border-light homeAdminCard pb-1' style={{height:'320px'}}>

                <div className='card-header text-dark bg-light row gx-0 align-items-center'>
                  <p className='col-8 col-md-9 col-xxl-10 my-2 fs-5'>Planos</p>
                  <div className='col-4 col-md-3 col-xxl-2 my-2'>
                    <Btn 
                      title='Adicionar Plano'
                      type='button'
                      btnType='success'
                      iconRight='faFileCirclePlus'
                      onClick={() => {navigate('/planos/add')}}
                      useLoading={true}
                    />
                  </div>
                
                </div>

                <div className='card-body mh-100 overflow-auto' style={{width: '98%'}}>

                  <ul className='list-group list-group-flush'>
                    {planos.length > 1 &&
                    <>
                      {planos.map( (item, key) => {
                        
                          return(
                            key > 0 &&
                            
                            <li key={key} className={`planosCard my-2 rounded row gx-0 p-2 align-items-center ${cLoading ? 'Cloading' : ''}`}
                              style={{background: `${item.bgColor ?? ''}`, color: `${item.txtColor ?? ''}`}}
                            >
                              <div className='col-12 col-md-6 ms-2 ms-md-3'>
                                <b className='m-0 fs-5'>{item.label}</b>
                                <p className='m-0 fs-6'>{item.duracao} {item.duracao === 1 ? 'mês' : 'meses'}</p>
                                <p className='m-0 fs-6'>{F.Moeda(item.valor)}</p>
                              </div>

                              <div className='col-10 col-md-5 col-xxl-3 mx-auto'>
                                <div className='row my-3 my-md-0'>
                                  <div className='col col-6'>
                                    <Btn 
                                      title='Editar'
                                      type='button'
                                      btnType='primary'
                                      iconRight='faPenToSquare'
                                      onClick={() => {navigate(`/planos/edit/${item.id}`)}}
                                      useLoading={true}
                                    />
                                  </div>

                                  <div className='col col-6'>
                                  <Btn 
                                    title='Remover'
                                    type='button'
                                    btnType='danger'
                                    iconRight='faTrashCan'
                                    onClick={() => {handleRemove(item.id, `${item.label}`, 'plano')}}
                                    useLoading={true}
                                  />
                                  </div>
                                </div>
                                
                              </div>
                              
                            </li>
                          )
                        })
                      }
                    </>
                    }
                  </ul>

                </div>

              </div>

              <hr className='my-4'></hr>

              <div className='col-12 card border-light homeAdminCard pb-1' style={{height:'320px'}}>

                <div className='card-header text-dark bg-light row gx-0 align-items-center'>
                  <p className='col-8 col-md-9 col-xxl-10 my-2 fs-5'>Locadoras Mercado</p>
                  <div className='col-4 col-md-3 col-xxl-2 my-2'>
                    <Btn 
                      title='Adicionar Locadora Mercado'
                      type='button'
                      btnType='success'
                      iconRight='faFileCirclePlus'
                      onClick={() => {navigate('/locadoraMercado/add')}}
                      useLoading={true}
                    />
                  </div>
                
                </div>

                <div className='card-body mh-100 overflow-auto' style={{width: '98%'}}>

                  <ul className='list-group list-group-flush'>
                    {locadoras.length > 0 &&
                    <>
                      {locadoras.map( (item, key) => {
                        
                          return(
                                                          
                            <li key={key} className={`planosCard my-2 rounded row gx-0 p-2 align-items-center ${cLoading ? 'Cloading' : ''}`}
                              style={{background: item.color, color: F.TextColorByBg(item.color)}}
                            >
                              <div className='col-12 col-md-6 d-flex flex-column justify-content-center align-items-center'>
                                <b className='m-0 fs-5'>{item.label}</b>
                                {item.logo &&
                                  <img src={`${BASE_BACK}${item.logo}`} className='my-1' style={{objectFit: 'contain', width:'80%'}} alt={item.label} />
                                }
                              </div>

                              <div className='col-10 col-md-5 col-xxl-3 mx-auto'>
                                <div className='row my-3 my-md-0'>
                                  <div className='col col-6'>
                                    <Btn 
                                      title='Editar'
                                      type='button'
                                      btnType='primary'
                                      iconRight='faPenToSquare'
                                      onClick={() => {navigate(`/locadoraMercado/edit/${item.id}`)}}
                                      useLoading={true}
                                    />
                                  </div>

                                  <div className='col col-6'>
                                  <Btn 
                                    title='Remover'
                                    type='button'
                                    btnType='danger'
                                    iconRight='faTrashCan'
                                    onClick={() => {handleRemove(item.id, `${item.label}`, 'locMercado')}}
                                    useLoading={true}
                                  />
                                  </div>
                                </div>
                                
                              </div>
                              
                            </li>
                          )
                        })
                      }
                    </>
                    }
                  </ul>

                </div>

              </div>
              
              <hr className='my-4'></hr>

              <div className='col-12 card border-light homeAdminCard pb-1' style={{height:'320px'}}>
                
                <div className='card-header text-dark bg-light row gx-0 align-items-center'>
                  <p className='col-8 col-md-9 col-xxl-10 my-2 fs-5'>Slides Home</p>
                  <div className='col-4 col-md-3 col-xxl-2 my-2'>
                    <Btn 
                      title='Editar Slides'
                      type='button'
                      btnType='primary'
                      iconRight='faPenToSquare'
                      onClick={() => {navigate('/slidesHome')}}
                      useLoading={true}
                    />
                  </div>
                
                </div>

                <div className='card-body'>
                    <SlidesHome
                      showLogo={false}
                      rounded={true}
                    />
                  </div>
              </div>

            </div>
            
          </div>
        
        </div>

        <div className='logoBotton'>
            <img src={LOGO_AUDIT} alt="AUDIT" className='auditLayout'/> 
        </div> 
        
      </motion.section>
    );
}

export default HomeAdmin;