const env = 1;

let LBASE_URL = '';
let LBASE_BACK = '';

if(env === 1) {
    
    LBASE_URL = 'https://api.idrrental.com.br/api';
    LBASE_BACK = 'https://api.idrrental.com.br/';
}

if(env === 2) {
    
    LBASE_URL = 'http://192.168.15.15:8000/api/';
    LBASE_BACK = 'http://192.168.15.15:8000/';
}

if(env === 3) {
    
    LBASE_URL = 'http://localhost:8000/api/';
    LBASE_BACK = 'http://localhost:8000/';
}

export const BASE_URL   = LBASE_URL;
export const BASE_BACK  = LBASE_BACK;

export const LOGO_AUDIT = '/img/logo.svg';
export const LOGO_IDR = '/img/logo_idr_01.svg';
export const SPACE = '/img/space.png';
export const STATISTICS_ICON = '/img/statistics.svg';