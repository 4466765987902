import React, { createContext, useState, useEffect } from "react";
import { BASE_BACK } from "../util/Urls";
import * as SiteS from "../services/Site.service";
export const SiteContext =  createContext();

function SiteProvider ({children}) {

    const [openLogin, setOpenLogin]         = useState('');
    const [showFooter, setShowFooter]       = useState('');
    const [showLogos, setShowLogos]         = useState('');
    const [errorCon, setErrorCon]           = useState(false);
    const [docTitle, setDocTitle]           = useState('');
    const [canLoad, setCanLoad]             = useState('home');
    const [textColor, setTextColor]         = useState('rgb(255,255,255)');
    const [logoIdr, setLogoIdr]             = useState(null);
    const [hasLogoIdr, setHasLogoIdr]       = useState(null);

    const HandleScroll = (e) => {
        
        const diffFooter = parseInt(window.innerHeight*20/100);
        const diffLogos = parseInt(window.innerHeight*8/100);
    
        if( e.currentTarget.scrollTop > (e.currentTarget.scrollHeight - e.currentTarget.offsetHeight - diffFooter))
        {
            setShowFooter('show');
        }else{
            setShowFooter('');
        }
    
        if(e.currentTarget.scrollTop > diffLogos){
            setShowLogos('hide');
        }else{
            setShowLogos('');
        }
    };  


    const IdrLogo = async() => {

        try {
            
            const result = await SiteS.GetIdrLogo();

            if(result.data){
                
                if (result.status === 200) {
                    
                    setLogoIdr(result.data.logoIdr ? `${BASE_BACK}${result.data.logoIdr}` : null);
                    setHasLogoIdr(result.data.logoIdr ? true : false);

                }
            }else{
                
                setLogoIdr(null);
                setHasLogoIdr(false);
            
            }
            
        }catch{

            setLogoIdr(null);
            setHasLogoIdr(false);
        
        }
        
    };
    
    useEffect(() => {
        IdrLogo();
    }, []);

    return (
        <SiteContext.Provider value={{ 
            openLogin, setOpenLogin,
            showFooter, setShowFooter, 
            showLogos, setShowLogos,
            errorCon, setErrorCon,
            textColor, setTextColor,
            canLoad, setCanLoad,
            HandleScroll,
            docTitle, setDocTitle,
            logoIdr, setLogoIdr,
            hasLogoIdr, setHasLogoIdr
            }}>
            {children}
        </SiteContext.Provider>
    )
}

export default SiteProvider;