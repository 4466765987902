import { useState, useEffect, useMemo, useRef } from 'react';
import ReactDOM  from 'react-dom';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as FA from '@fortawesome/free-solid-svg-icons';
import Input from '../Input';
import * as F from '../../../util/Functions';
import Btn from '../Btn';

function Select({

    data,
    dataSelected = false,
    showSelected = false,
    allowZero = false,
    placeholder,
    value,
    search = true,
    onClick = false,
    useLoading = false,
    disabled = false,
    readOnly = false,
    allowAdd = false, 
    handleAdd = false,
    maxLength = false,
    minLength = 0,
    required = false,
    useColors = false,
    showFT = false,
    setShowLocSelect = null,
    id = null

}) {
    
    const [show, setShow] = useState(false);

    const [allOptions, setAllOptions]   = useState([]);
    
    const [txtSearch, setTxtSearch]     = useState('');

    const [disabledL, setDisabledL]     = useState(true);

    const selectedRef                   = useRef(null);

    const handleClose = () => {
        setShow(false);
        
        if (typeof setShowLocSelect === "function") {
            setShowLocSelect(false);
        }
    }

    const handleAddIn = () => {

        const newId = (allOptions.length + 1);

        const copyAllOptions = [...allOptions];

        copyAllOptions.push({label: txtSearch, id: newId});
        
        let copyFirst = false;

        const first = F.IndexOfObject(copyAllOptions, 'id', 0);
        
        if(first !== -1){

            copyFirst = copyAllOptions[first];
            copyAllOptions.splice(first, 1);

        }

        F.SortArrayByObjKey(copyAllOptions, 'label', 'ASC');

        const allOk = copyFirst ? [copyFirst, ...copyAllOptions] : copyAllOptions;

        setAllOptions(allOk);

        setTxtSearch('');
        
        if (typeof handleAdd === "function") { 

            handleAdd(txtSearch, newId);
        
        }
    }

    const filteredName = useMemo(() => {
        
        try{
            if(allOptions.length > 0){
                const lowerTxtSearch = F.RemoveAccent(txtSearch.toLowerCase().trim());
                return allOptions
                .filter((opt) => F.RemoveAccent(opt.label.toLowerCase().trim()).includes(lowerTxtSearch));
            }else{
                return [];
            }
        }catch{
            return [];
        }
        
        
    }, [txtSearch, allOptions]);


    const selectTxt = useMemo(() => {

        try{

            if(dataSelected){
                
                if(dataSelected.length === 0){
                    return 'Selecione';
                }
    
                if(dataSelected.length === 1){

                    return allOptions.filter((opt) => opt.id === dataSelected[0])[0].label;
                }
    
                if(dataSelected.length >1){
                    return `${dataSelected.length} Selecionados`;
                }

            }else{

                if(allOptions.length > 0 && value !== ''){
                    return allOptions.filter((opt) => opt.id === value)[0].label;
                }else{
                    return 'Selecione';
                }
            }

        }catch{
            return 'Selecione';
        }
    
    }, [value, allOptions, dataSelected]);


    const selectedColor = useMemo(() => {
        
        let bgColor = false;
        let txtColor =  false;

        try{
            if(useColors){

                if(dataSelected){

                    if(dataSelected.length > 0){

                        bgColor =  allOptions.filter((opt) => opt.id === dataSelected[0])[0].bgColor;
                        txtColor = allOptions.filter((opt) => opt.id === dataSelected[0])[0].txtColor;

                    }
                    
                }else{

                    if(allOptions.length > 0 && value !== ''){
                        
                        bgColor =  allOptions.filter((opt) => opt.id === value)[0].bgColor;
                        txtColor = allOptions.filter((opt) => opt.id === value)[0].txtColor;
                    }

                }

            }
            
        }catch{
            
        }

        return {bgColor: bgColor, txtColor: txtColor}
        
    }, [useColors, allOptions, dataSelected, value]);


    const handleSelValue = (selValue, txt, classList = false) =>{

        if(classList && classList.contains('disabled')){
            return;
        }

        if (typeof onClick === "function") {
            onClick(selValue, txt);
        }

        if(!dataSelected){
            setShow(false);
        }
        
    }

    const handleShow = () => {

        if(disabled || readOnly){
            return;
        }
        
        setShow(true);
    }

    useEffect(() => {

        setShow(showFT);    
        
    }, [showFT]);

    

    useEffect(() => {

        if(show && selectedRef.current !== null){
            selectedRef.current.scrollIntoView();
        }

        if(!show){
            setTxtSearch('');
        }        
        
    }, [show, selectedRef]);

    

    useEffect(() => {
        
        if(data.length > 0){
            setAllOptions(data);
            setDisabledL(disabled);
        }else{
            setDisabledL(true);
            setAllOptions([]);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, disabled]);

    return (
        <>
        
         <Input 
            style={{cursor: readOnly ? 'text' : 'pointer', background: `${selectedColor.bgColor ?? ''}`, color: `${selectedColor.txtColor ?? ''}`}}
            labelRight='faArrowDown'
            placeholder={placeholder}
            readOnly={true}
            required={required}
            disabled={disabledL}
            value={selectTxt}
            onClick={handleShow}
            useLoading={useLoading}
        />

        {showSelected && dataSelected && dataSelected.length > 0 && allOptions.length > 0 &&
            <div className='selectedTags'>
                {
                    dataSelected.map( (item, key) => {
                        const label = allOptions.filter((opt) => opt.id === item)[0].label;
                        return(
                            <span key={key}>
                                <i>{label}</i>
                                {(allowZero || dataSelected.length > 1) &&
                                    <FontAwesomeIcon 
                                        title={`Remover ${label}`}
                                        icon={FA.faCircleXmark} 
                                        className='selectedTagsRem'
                                        onClick={() => handleSelValue(item)}
                                    />
                                }                                
                            </span>
                        )
                    })
                }
            </div>
        }
        
        {ReactDOM.createPortal(
            <div className={`selOptsArea ${show ? 'show':''}`}>
                <div className='selOpts'>
                    <FontAwesomeIcon onClick={handleClose} icon={FA.faCircleXmark} className='closeSelectBt'/>
                    
                    {search &&
                        <div className='selOptsSearch'>
                            <Input 
                                placeholder={allowAdd ? 'Digite aqui para procurar ou adicionar...' : 'Digite aqui para procurar...'}
                                value={txtSearch}
                                {...(maxLength && { maxLength: maxLength })} 
                                {...(minLength && { minLength: minLength })} 
                                labelLeft='faMagnifyingGlass'
                                onChange={(e)=>{setTxtSearch(e.target.value)}}
                            />

                            {filteredName.length === 0 && allowAdd && txtSearch.length >= minLength &&
                                <Btn 
                                    title={`Adicionar ${txtSearch}`}
                                    value='Adicionar'
                                    type='button'
                                    btnType='success'
                                    onClick={handleAddIn}
                                />
                            }
                        </div>
                    }                    
                    
                    <ul className='selOptsList' {...(id && { id: id })}>
                    {
                        
                        filteredName.length > 0 &&
                        filteredName.map( (item, key) => {
                            return(
                                <li id={`${item.id}`}
                                    style={{background: `${(useColors && item.bgColor )?? ''}`, color: `${(useColors && item.txtColor) ?? ''}`}}
                                    onClick={(event) => handleSelValue(item.id, item.label, event.target.classList)}
                                    key={key} 
                                    className={`${item.id === value || (dataSelected && dataSelected.includes(item.id)) ? 'selSelected' : ''}`}
                                    ref={item.id === value || (dataSelected && dataSelected.includes(item.id)) ? selectedRef : null}
                                
                                >
                                    {item.label}
                                </li>
                            )
                        })
                    }
                    </ul>
                </div>
            </div>, document.getElementById('selectContainner')
        )}
        </>        
    );
}

export default Select;