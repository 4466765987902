import React, { createContext, useState, useEffect, useCallback } from "react";
import { useLocation } from 'react-router-dom';
import * as TXTS from '../util/Texts';

export const AlertasContext =  createContext();


function AlertasProvider ({children}) {
    
    const location                                  = useLocation();
    const [show, setAlertShow]                      = useState(false);
    const [btnClose, setAlertBtnClose]              = useState(true);
    const [btnOk, setAlertBtnOk]                    = useState(true);
    const [btnOkTxt, setAlertBtnOkTxt]              = useState('OK');
    const [btnOkType, setAlertBtnOkType]            = useState('primary');
    const [btnCancel, setAlertBtnCancel]            = useState(false);
    const [btnCancelTxt, setAlertBtnCancelTxt]      = useState('CANCELAR');
    const [btnCancelType, setAlertBtnCancelType]    = useState('primary');
    const [closeOutClick, setAlertCloseOutClick]    = useState(true);
    const [type, setAlertType]                      = useState('error');
    const [alertStyle, setAlertStyle]               = useState(1);
    const [toastPostion, setToastPosition]          = useState('center');
    const [msg, setAlertMsg]                        = useState('');
    const [msgOk, setMsgOk]                         = useState([]);
    const [onClose, setAlertOnClose]                = useState(false);
    const [onOk, setAlertOnOk]                      = useState(false);
    const [onCancel, setAlertOnCancel]              = useState(false);
    const [closeOnCancel, setAlertCloseOnCancel]    = useState(true);
    const [changePass, setAlertChangePass]          = useState(false);
    const [alertTitle, setAlertTitle]               = useState('');
    const [alertTitleCustom, setAlertTitleCustom]   = useState('');
    const [htmlItem, setAlertHtmlItem]              = useState(null);
    
    const [changePassIputs, setValuesInput]         = useState({
        oldPassPassword: '',
        newPassword: '',
        checkNewPassword: ''
    });

    useEffect(() => {

        if(msg === '' || msg === undefined || msg === null){
            setMsgOk('');
            return;
        }

        let m = msg;
        m = m.split('<br>'); 

        setMsgOk(m);        

    }, [msg]);

    const handleInputChange = (e) => {
        setValuesInput({
        ...changePassIputs,
        [e.target.name]: e.target.value,
        });
    }
    
    const handleOKAlert = (e) => {
        
        if(changePass){
            if(changePassIputs.oldPassPassword === '' 
                || changePassIputs.newPassword === ''
                || changePassIputs.checkNewPassword === ''){
                return;
            }
        }
    
        e.preventDefault();

        if (typeof onOk === "function") { 
            !changePass ? 
            onOk() : 
            onOk(changePassIputs);
                
        }
    }
    
    const handleCancelAlert = () => {
        
        if (typeof onCancel === "function") { 
            
            onCancel();
        }

        if(closeOnCancel){
            handleCloseAlert();
        }
    }

    const handleSetTitle = useCallback(() => {

        if(alertTitleCustom === ''){

            switch(type) {
                case 'success':
                    setAlertTitle(TXTS.ALERT_TITLE_SUCCESS);
                    break;
                case 'warning':
                    setAlertTitle(TXTS.ALERT_TITLE_WARNING);
                    break;
                case 'error':
                    setAlertTitle(TXTS.ALERT_TITLE_ERROR);
                    break;
                default:
                    setAlertTitle(TXTS.ALERT_TITLE_DEFAULT);
                    break;
            }

        }else{
            setAlertTitle(alertTitleCustom);
        }
        
    }, [type, alertTitleCustom]);

    const handleResetAlert = () => {
        
        setAlertBtnClose(true);
        setAlertBtnOk(true);
        setAlertBtnOkTxt('OK');
        setAlertBtnOkType('primary');
        setAlertBtnCancel(false);
        setAlertBtnCancelTxt('CANCELAR');
        setAlertBtnCancelType('primary');
        setAlertCloseOutClick(true);
        setAlertType('primary');
        setAlertStyle(1);
        setToastPosition('center');
        setAlertMsg('');
        setMsgOk([]);
        setAlertOnClose(false);
        setAlertOnOk(false);
        setAlertOnCancel(false);
        setAlertChangePass(false);
        setAlertTitle('');
        setAlertHtmlItem(null);
        setAlertCloseOnCancel(true);
        setAlertTitleCustom('');

        setValuesInput({
            oldPassPassword: '',
            newPassword: '',
            checkNewPassword: ''
        });
    }

    const handleCloseAlert = useCallback(() => {
        
        if (typeof onClose === "function") { 
            
            onClose();
        }

        setAlertShow(false);
        setTimeout(() => {
            handleResetAlert();
        }, 200);
    }, [onClose]);


    useEffect(() => {
        
        handleSetTitle();
        setAlertCloseOutClick(btnClose);
        
    }, [show, handleSetTitle, btnClose]);

    useEffect(() => {
    
        if(show){
            handleCloseAlert();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [location]);

    
    return (
        <AlertasContext.Provider value={{ 
            show, setAlertShow, 
            btnClose, setAlertBtnClose, 
            btnOk, setAlertBtnOk,
            btnOkTxt, setAlertBtnOkTxt,
            btnOkType, setAlertBtnOkType,
            btnCancel, setAlertBtnCancel,
            btnCancelTxt, setAlertBtnCancelTxt,
            btnCancelType, setAlertBtnCancelType,
            closeOutClick, setAlertCloseOutClick,
            type, setAlertType,
            alertStyle, setAlertStyle,
            toastPostion, setToastPosition,
            msgOk, setAlertMsg,
            onClose, setAlertOnClose,
            onOk, setAlertOnOk,
            onCancel, setAlertOnCancel,
            changePass, setAlertChangePass, changePassIputs,
            alertTitle, handleCloseAlert, handleOKAlert, handleCancelAlert, handleInputChange,
            htmlItem, setAlertHtmlItem,
            closeOnCancel, setAlertCloseOnCancel, setAlertTitle, setAlertTitleCustom
            }}>
            {children}
        </AlertasContext.Provider>
    )
}

export default AlertasProvider;