import { useContext } from 'react';
import './index.css';
import { LoaderContext } from '../../../contexts/LoaderContext';


function CheckBox({
    type = 'checkbox',
    label = '',
    checked = false,
    disabled = false,
    onChange = false,
    useLoading = false,
    style = false,
    name = false,
    id = false
}) {
    
    const { cLoading } = useContext(LoaderContext);

    return (

        <label className={`checkbox ${cLoading && useLoading ? 'Cloading' :  ''} mt-2`} {...(style && { style: style })}>
            <input            
            type={type}
            {...(id && { id: id })}
            {...(name && { name: name })}
            {...(onChange && { onChange: onChange })}
            {...(disabled && { disabled: disabled })}
            checked={checked}
        />
        <p>{label}</p>
        
        </label>
    );
}

export default CheckBox;