import React, { createContext, useState, useEffect } from "react";

export const LoaderContext =  createContext();

function LoaderProvider ({children}) {

    const [firstLoad, setFirstLoad]         = useState(true);
    const [loadClassName, setLoadClassName] = useState('firstLoad');
    const [isLoading, setIsLoading]         = useState('show');
    const [cLoading, setCLoading]           = useState(true);

    useEffect(() => {
        
        if(firstLoad){
            setTimeout(() => {
                setCLoading(false);
            }, 1000);
            
        }
        
    }, [firstLoad]);

    return (
        <LoaderContext.Provider value={{ 
            firstLoad, setFirstLoad, 
            loadClassName, setLoadClassName, 
            isLoading, setIsLoading,
            cLoading, setCLoading}}>
            {children}
        </LoaderContext.Provider>
    )
}

export default LoaderProvider;