/* eslint no-eval: 0 */
import React, { useEffect, useContext, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as FA from '@fortawesome/free-solid-svg-icons';
import './index.css';
import Input from '../../components/Forms/Input';
import Btn from '../../components/Forms/Btn';
import CheckBox from '../../components/Forms/CheckBox';
import { motion } from 'framer-motion';
import { fade } from '../../util/AnimatedDiv'
import { LoaderContext } from '../../contexts/LoaderContext';
import { SiteContext } from '../../contexts/SiteContext';
import { AlertasContext } from '../../contexts/AlertasContext';
import { UserContext } from '../../contexts/UserContext';
import * as F from '../../util/Functions';
import * as IN from '../../services/Indicadores.service';
import * as TXTS from '../../util/Texts';


function MontarIndicador() {

    const navigate                          = useNavigate();
    const { id }                            = useParams();
    const [idOk, setIdOk]                   = useState(id);
    const { setIsLoading, cLoading }        = useContext(LoaderContext);
    const { isLogged, userType }            = useContext(UserContext);
    const [btnsBal, setBtnsBal]             = useState([]);
    const [balSN, setBalSN]                 = useState(true);             
    const [btnsDre, setBtnsDre]             = useState([]); 
    const [dreSN, setDreSN]                 = useState(true);
    const [disabledAdd, setDisabledAdd]     = useState(true);
    const [disabledEqual, setDisabledEqual] = useState(true);
    const [disabledOp, setDisabledOp]       = useState(true);
    const [disabledVal, setDisabledVal]     = useState(false);
    const [disabledClear, setDisabledClear] = useState(true);
    const [formula, setFormula]             = useState([]);
    const [formulaShow, setFormulaShow]     = useState([]);
    const [formulaCalc, setFormulaCalc]     = useState([]);
    const [formulaResult, setFormulaResult] = useState('0');
    const [porcentagem, setPorcentagem]     = useState(0);
    const [canChangeSign, setCanChangeSign] = useState(false);
    const [changeSign, setChangeSign]       = useState(false);
    const [visorLoad, setVisorLoad]         = useState(true);
    const [ctrlZ, setCtrlZ]                 = useState([]);
    const [canCtrlZ, setCanCtrlZ]           = useState(false);
    const [ctrlZClear, setCtrlZClear]       = useState(false);
    const [ctrlZChangeSign, setCtrlZChangeSign] = useState(false);
    const visorNum                          = useRef();
    const btsBlRef                          = useRef([]);
    const btsDreRef                         = useRef([]);
    const btOperaMenos                      = useRef();
    const btOperaMais                       = useRef();
    const btOperaDiv                        = useRef();
    const btOperaMult                       = useRef();
    const btOpenPare                        = useRef();
    const btClosePare                       = useRef();

    const [values, setValues]               = useState({
        nome: '',
        titulo: '',
        descricao: '',
        show_dash: 1,
        show_compara: 1
    });

    const reqValues = [
        'nome',
    ];

    const {
        HandleScroll,
        setDocTitle,
        setErrorCon
    } = useContext(SiteContext);

    const {

        setAlertShow,
        setAlertBtnOk,
        setAlertType,
        setAlertMsg,
        setAlertOnClose,
        setAlertBtnOkTxt,
        setAlertOnOk,
        setAlertBtnCancel,
        handleCloseAlert
    
    } = useContext(AlertasContext);

    const handleAdd = async (e, backOnSave = true) => {
        
        e.preventDefault();
        
        setDisabledAdd(true);

        const data = {
            formula: formula,
            changeSign: changeSign,
            nome: values.nome.trim(),
            titulo: values.titulo.trim(),
            descricao: values.descricao,
            show_dash: values.show_dash,
            show_compara: values.show_compara,
            id: isNaN(idOk) ? 0 : idOk
        }
        
        setIsLoading('show');
      
        try{

            const result = isNaN(idOk) ? await IN.AddIndicador(data) : await IN.EditIndicador(data);

            if(result.data){

                if (result.status === 200) {

                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    setAlertType('success');
                    setAlertBtnOk(false);  
                    
                    if(backOnSave){

                        setAlertOnClose(() => () => {navigate(-1)});

                    }else{

                        setAlertOnClose(() => () => {
                            setDisabledAdd(false);
                            if(result.data.idNovo){
                                setIdOk(result.data.idNovo);
                                setDocTitle(TXTS.INDICADORES_EDIT_TITLE);
                            }
                        });
                        
                    }

                }else{

                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    if(result.data.formula){

                        setAlertType('warning');
                        setAlertBtnCancel(true);
                        setAlertBtnOkTxt('Copiar Fórmula');
                        setAlertOnOk(() => () => {handleSetFields(result.data.formula, (result.data.changeSign === 1), 1000); handleCloseAlert()});

                    }else{

                        setAlertType('error');
                        setAlertBtnOk(false);
                    }
                    
                }

            }else{
                
                setAlertShow(true);
                setAlertMsg(TXTS.ERRO_LOGIN_2);
                setAlertType('error');
                setAlertBtnOk(false);
            
            }

        }catch{

            setAlertShow(true);
            setAlertMsg(TXTS.ERRO_LOGIN_3);
            setAlertType('error');
            setAlertBtnOk(false);
            setErrorCon(true);
            
        }

        setIsLoading('hide');

    }

    const handleSetFields = async (formulaGet, changeSignGet, delay = 0, showLoading = true) => {
        
        if(showLoading){
            setIsLoading('show');
            setVisorLoad(true);
        }

        handleClearFormula(); 

        setFormula(formulaGet);

        const total = Object.values(formulaGet).length -1;
        
        const copyFormulaShow = [];
        const copyFormulaCalc = [...formulaCalc];

        let result = '';
        
        for(const [keys, values] of Object.entries(formulaGet)){
            
            for (const [key, value] of Object.entries(values)) {

                let tp;
                let valueOK = value;
                let title = null;

                if(key === 'operador'){

                    if(value === '(' || value === ')'){
                        tp = 'parenteses';
                    }else{
                        tp = 'operador';
                    }

                    copyFormulaCalc.push(value);
                    result += ` ${value}`;
                    
                }else{

                    let valor;
                    
                    if(key === 'bal_id'){
                        tp = 'btnBal'
                        valueOK = btsBlRef.current[value].getAttribute('short');
                        title = btsBlRef.current[value].getAttribute('title');  
                        valor = btsBlRef.current[value].getAttribute('value');
                     }
     
                     if(key === 'dre_id'){
                         tp = 'btnDre';
                         valueOK = btsDreRef.current[value].getAttribute('short');
                         title = btsDreRef.current[value].getAttribute('title');
                         valor = btsDreRef.current[value].getAttribute('value');
                     }

                     copyFormulaCalc.push(valor);
                     result += ` ${valor}`;
                }

                copyFormulaShow.push({
                    tp: tp,
                    title: title,
                    value: valueOK
                });
                
                if(total === parseInt(keys)){
                    setChangeSign(changeSignGet);
                    setFormulaShow(copyFormulaShow);
                    setFormulaCalc(copyFormulaCalc);
                    
                    if(result !== ''){
                        setFormulaResult(result);
                        handlePorcentagem(result, delay, changeSignGet);                        
                    }
                    
                    if(key === 'operador'){
                        if(value === ')'){
                            setDisabledVal(true);
                            setDisabledOp(false);
                        }else{
                            setDisabledVal(false);
                            setDisabledOp(true);
                        }
                    }else{
                        setDisabledVal(true);
                        setDisabledOp(false);
                    }

                    setTimeout(() => {
                        setIsLoading('hide');
                        setVisorLoad(false);
                    }, 1000);
                    
                }
               
            }
            
        }       
    }

    const handleSetValues = (e) => {
       
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    }

    const handleSetCheck = (e) => {
        
            setValues({
                ...values,
                [e.target.name]: e.target.checked ? 1 : 0,
            });
    }

    const handleGetBtns = async () => {

        setIsLoading('show');

        try{

            const result =  await IN.GetBtns();
            setBtnsBal(result.data.camposBal);
            setBtnsDre(result.data.camposDre);

            if(!isNaN(idOk)){
                handleGetIndicador();
            }else{
                setIsLoading('hide');
                setTimeout(() => {
                    setVisorLoad(false);
                }, 1000);
            }
        }

        catch{
            navigate(-1);
        }

    }

    const handleFormula = (e) => {
        
        setPorcentagem(0);
        setCanCtrlZ(true);

        const value = e.target.getAttribute('value');
        const copyFormula = [...formula];    
        const copyFormulaShow = [...formulaShow];
        
        let tp;

        if(value === '(' || value === ')'){

            tp = 'parenteses';

            copyFormula.push({
                operador: value
            });

            copyFormulaShow.push({
                tp: tp,
                value: value
            });

            if(value === '(') {
                setDisabledOp(true);
            }

            setFormulaCalc([...formulaCalc, value]);

        }else{

            if(isNaN(value)){
                
                if(disabledOp){
                    return;
                }

                tp = 'operador';
    
                copyFormula.push({
                    operador: value
                });
    
                copyFormulaShow.push({
                    tp: tp,
                    value: value
                });
    
                setDisabledVal(false);
                setDisabledOp(true);
                setFormulaCalc([...formulaCalc, value]);
    
            }
            
            if(!isNaN(value)){
    
                if(disabledVal){
                    return;
                }

                const shortName = e.target.getAttribute('short');
                const title = e.target.getAttribute('title');
                const bal_id = e.target.getAttribute('bal_id');
                const dre_id = e.target.getAttribute('dre_id');
    
                if(bal_id){
                    
                    tp = 'btnBal';
    
                    copyFormula.push({
                        bal_id: parseInt(bal_id)
                    });
                }
    
                if(dre_id){
    
                    tp = 'btnDre';
    
                    copyFormula.push({
                        dre_id: parseInt(dre_id)
                    });
    
                }
    
                copyFormulaShow.push({
                    tp: tp,
                    title: title,
                    value: shortName
                });
    
                setDisabledVal(true);
                setDisabledOp(false);
                setFormulaCalc([...formulaCalc, parseFloat(value)]);
            }

        }
        
        setCtrlZ([...ctrlZ, value]);
        setFormula(copyFormula);
        setFormulaShow(copyFormulaShow);
        setCanChangeSign(false);

        if(formulaResult === '0'){

            setFormulaResult(value);
            setPorcentagem(0);

        }else{

            const result = `${formulaResult} ${value}`;
            setFormulaResult(result);

            if(formula.length > 0){
                handlePorcentagem(result);
            }
        }

    }

    const handlePorcentagem = (value, delay = 0, cgs = changeSign) => {

        try {
            
            eval(value); 
     
            const fator = cgs ? '-100' : '100';

            let porcentagem = (eval(value)*(fator)).toFixed(2);
            //TODO INFINITO PEGAR X CASAS DECIMAIS    
            porcentagem = isFinite(porcentagem) ? porcentagem : 0;
            
            setTimeout(() => {
                setPorcentagem(porcentagem);
                setCanChangeSign(true);
            }, delay);
            

        } catch {
            
        }

    }

    useEffect(() => {

        visorNum.current.scrollTo(visorNum.current.offsetWidth*2,0);

    }, [formulaResult, porcentagem]);


    useEffect(() => {
        
        setDisabledClear(formula.length === 0);
        setDisabledEqual(formula.length === 0);

    }, [formula]);

    const handleChangeSign = () => {
        
        if(porcentagem){

            setCanCtrlZ(true);
            setCtrlZChangeSign(true);

            if((porcentagem < 0)){
                
                setChangeSign(true);
                setPorcentagem(Math.abs(porcentagem));
                
            }else{
                
                setChangeSign(false);
                setPorcentagem(-Math.abs(porcentagem));
                
            }
        }
    }

    const handleBack = (ctrlZhandle = true) => {

        setCanCtrlZ(true);

        setPorcentagem(0);
        const copyFormula = [...formula];    
        const copyFormulaShow = [...formulaShow]; 
        const copyFormulaCalc = [...formulaCalc];

        if(ctrlZhandle){
            
            const lastToCtrlZ = copyFormulaCalc[[copyFormulaCalc.length-1]];
            setCtrlZ([...ctrlZ, lastToCtrlZ]);
        }

        if(copyFormulaCalc.length > 1){

            const last = copyFormulaCalc[[copyFormulaCalc.length-1]];

            if(last === '(' || last === ')'){

                const beforePare = copyFormulaCalc[[copyFormulaCalc.length-2]];

                if(isNaN(beforePare)){
                    setDisabledOp(true);
                    setDisabledVal(false);
                }else{
                    setDisabledOp(false);
                    setDisabledVal(true);
                }
            }else{

                if(isNaN(last)){
                    setDisabledOp(false);
                    setDisabledVal(true);
                }else{
                    setDisabledOp(true);
                    setDisabledVal(false);
                }

            }
            
        }else{
            setDisabledOp(true);
            setDisabledVal(false);
        }

        setFormulaResult();

        let newFormulaResult = '';

        copyFormula.pop(); 
        copyFormulaShow.pop(); 
        copyFormulaCalc.pop();  
        
        copyFormulaCalc.forEach((value) => {
            newFormulaResult += ` ${value} `;
        });
        
        setFormulaResult(newFormulaResult);
        handlePorcentagem(newFormulaResult);
        setFormula(copyFormula);
        setFormulaShow(copyFormulaShow);
        setFormulaCalc(copyFormulaCalc);
        
    }

    const handleClearFormula = () => {

        
        if(disabledClear){
            return;
        }
        
        setCtrlZ(formula);
        setCanCtrlZ(true);
        setCtrlZClear(true);
        setDisabledOp(true);
        setDisabledVal(false);
        setFormula([]);
        setFormulaShow([]);
        setFormulaCalc([]);
        setFormulaResult('0');
        setPorcentagem(0);
        setChangeSign(changeSign);
        
    }

    const checkRequired = () => {

        let err = 0;
    
        reqValues.forEach((item) => {

          if(values[item] === ''){ err += 1;}

        });
    
        if(err === 0){
            setDisabledAdd(false);
        }else{
            setDisabledAdd(true);
        }  

    }

    const handleGetIndicador = async () => {
        
        setIsLoading('show');

        try{

            const result = await IN.GetIndicador(idOk);

            if (result.status === 200) {

                if(F.hasNullObjArray(result.data.formula)){

                    setIsLoading('hide');
                    setAlertShow(true);
                    setAlertMsg('Foram encontrados erros na fórmula! <br> Por favor, remova o Indicador e crie-o novamente!');
                    setAlertType('error');
                    setAlertBtnOk(false);  
                    setAlertOnClose(() => () => {navigate(-1)});

                    return false;
                }

                handleSetFields(result.data.formula, (result.data.changeSign === 1), 1000);

                setValues({
                    ...values,
                    nome: result.data.nome,
                    titulo: result.data.titulo,
                    descricao: result.data.descricao,
                    show_dash: result.data.show_dash,
                    show_compara: result.data.show_compara
                });

            }else{
                navigate(-1);
            }

        }catch{
            navigate(-1);
        }

    }

    const handleCtrlZ = () => {

        if(ctrlZChangeSign){
            handleChangeSign();
            setCtrlZChangeSign(false);
        }

        const copyCtrlZ = [...ctrlZ];
        
        if(copyCtrlZ.length === 0){
            setCanCtrlZ(false);
            return;
        }

        if(ctrlZClear){

            handleSetFields(copyCtrlZ, changeSign, 0, false);
            setCtrlZClear(false);
            setCtrlZ([]);
            return;
        }

        const lastCtrlz = `${ctrlZ[[ctrlZ.length-1]]}`;
        const lastFormulaCalc = `${formulaCalc[[formulaCalc.length-1]]}`;

        if(!lastFormulaCalc || lastFormulaCalc !== lastCtrlz){           

            if(isNaN(lastCtrlz)){
                
                if(lastCtrlz === '-'){
                    btOperaMenos.current.click();
                }

                if(lastCtrlz === '+'){
                    btOperaMais.current.click();
                }

                if(lastCtrlz === '/'){
                    btOperaDiv.current.click();
                }

                if(lastCtrlz === '*'){
                    btOperaMult.current.click();
                }
                
                if(lastCtrlz === '('){
                    btOpenPare.current.click();
                } 

                if(lastCtrlz === '('){
                    btClosePare.current.click();
                }

            }else{

                let find = false;

                btsBlRef.current.forEach((item) => {
                    if(item.getAttribute('value') === lastCtrlz){
                        item.click();
                        find = true;
                    }
                });

                if(!find){
                    btsDreRef.current.forEach((item) => {
                        if(item.getAttribute('value') === lastCtrlz){
                            item.click();
                            find = true;
                        }
                    });
                }
            }
            
        }else{
            handleBack(false);
        }

        copyCtrlZ.pop();
        setCtrlZ(copyCtrlZ);

    }

    const handleKeyDow = (e) => {

        let charCode = String.fromCharCode(e.which).toLowerCase();

        if((e.ctrlKey || e.metaKey) && charCode === 's') {

            e.preventDefault();

            if(!disabledAdd){
                
                handleAdd(e, false);

            }

        }else if((e.ctrlKey || e.metaKey) && charCode === 'z') {

            if(e.target.type){
                return;
            }    
    
            e.preventDefault();

            if(canCtrlZ){
                handleCtrlZ();
            }

        }
    }

    useEffect(() => {

        if(disabledEqual){
            setDisabledAdd(true);
        }else{
            checkRequired();
        }    
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, disabledEqual]);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDow);
        return () => document.removeEventListener("keydown", handleKeyDow);
    });

    useEffect(() => {

        if(userType === 2){
            navigate(-1);
            return;
        }

        setDocTitle(isNaN(idOk) ? TXTS.INDICADORES_ADD_TITLE : TXTS.INDICADORES_EDIT_TITLE);
        
        if(isLogged){
            handleGetBtns();
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsLoading, isLogged]);

    return (
      <motion.section 
        className="conteudo"

        variants={fade}
        initial='entrada'
        animate='animacao'
        exit='saida'
        
        onScroll={HandleScroll}
      >

        <div className='container-lg'>
          <h2 className='pageTitle'>{TXTS.INDICADORES_ADD_TITLE}</h2>
        </div>

        <div className='container container-lg'>
        
            <form className='row justify-content-start gy-3 gx-4 mt-1'>

                <div className='col col-12 col-md-6 order-3 order-md-1'>

                    <div className='container-lg d-none d-md-block'>
                        <h2 className='pageTitle sub'>{TXTS.INDICADORES_CAMPOS_TITLE}</h2>
                    </div>

                    <hr className='d-none d-md-block'></hr>

                    <div className='container-lg'>
                        <h2 className='pageTitle sub-m'>{TXTS.BALANCO_SINGLE_TITLE}</h2><i className='indInfo'>{TXTS.INDICADORES_BALANCOS_INFO}</i>
                    </div>

                    <div className='row justify-content-center mb-3'>

                    <div className='col col-12 col-md-4 d-none d-md-block'>
                        <Btn 
                            value={balSN ? 'Expandir Nomes' : 'Fechar Nomes'}
                            type='button'
                            btnType='success'
                            onClick={() => setBalSN(!balSN)}
                            useLoading={true}
                        />
                    </div>

                    </div>

                    <div className='btnsIndica'>
                        {!F.isEmpty(btnsBal) &&

                            Object.keys(btnsBal).map((key) => {
                                return(

                                    <span 
                                        disabled={disabledVal}
                                        ref={ref => {
                                            btsBlRef.current[btnsBal[key]['bal_id']] = ref
                                        }}
                                        key={btnsBal[key]['bal_id']}
                                        onClick={(e) => handleFormula(e)}
                                        title={btnsBal[key]['titulo']}
                                        short={btnsBal[key]['short_name']}
                                        bal_id={btnsBal[key]['bal_id']}
                                        value={btnsBal[key]['value']}
                                        className={`${cLoading ? 'Cloading' :  ''} btnBal ${disabledVal ? 'disabled' :  ''}`}>
                                            {balSN ? btnsBal[key]['short_name'] : btnsBal[key]['titulo']}
                                    </span>
                                                                            
                                )
                            })

                        }
                    </div>

                    <hr></hr>

                    <div className='container-lg'>
                        <h2 className='pageTitle sub-m'>{TXTS.DRE_SINGLE_TITLE}</h2><i className='indInfo'>{TXTS.INDICADORES_DRES_INFO}</i>
                    </div>

                    <div className='row justify-content-center mb-3'>

                    <div className='col col-12 col-md-4 mt-4 d-none d-md-block'>
                        <Btn 
                            value={dreSN ? 'Expandir Nomes' : 'Fechar Nomes'}
                            type='button'
                            btnType='success'
                            onClick={() => setDreSN(!dreSN)}
                            useLoading={true}
                        />
                    </div>

                    </div>

                    <div className='btnsIndica'>
                        {!F.isEmpty(btnsDre) &&

                            Object.keys(btnsDre).map((key) => {
                                return(

                                    <span 
                                        ref={ref => {
                                            btsDreRef.current[btnsDre[key]['dre_id']] = ref
                                        }}
                                        key={btnsDre[key]['dre_id']}
                                        onClick={(e) => handleFormula(e)}
                                        title={btnsDre[key]['titulo']}
                                        short={btnsDre[key]['short_name']}
                                        dre_id={btnsDre[key]['dre_id']}
                                        value={btnsDre[key]['value']}
                                        className={`${cLoading ? 'Cloading' :  ''} btnDre ${disabledVal ? 'disabled' :  ''}`}>
                                            {dreSN ? btnsDre[key]['short_name'] : btnsDre[key]['titulo']}
                                    </span>
                                                                            
                                )
                            })

                        }
                    </div>

                    <hr></hr>
                    
                    <div className='row'>

                        <div className='col col-12 col-md-6 mt-4'>
                            <Input 
                                name='nome'
                                placeholder='Nome do Indicador:'
                                type='text'
                                required={true}
                                value={values.nome}
                                onChange={handleSetValues}
                                useLoading={true}
                            />
                        </div>

                        <div className='col col-12 col-md-6 mt-4'>
                            <Input 
                                name='titulo'
                                placeholder='Título do Indicador:'
                                type='text'
                                required={true}
                                value={values.titulo}
                                onChange={handleSetValues}
                                useLoading={true}
                            />
                        </div>

                        <div className='col col-12 mt-4 mb-4'>
                            <Input 
                                name='descricao'
                                placeholder='Descrição:'
                                type='textarea'
                                height={100}
                                maxLength={500}
                                required={false}
                                value={values.descricao}
                                onChange={handleSetValues}
                                useLoading={true}
                            />
                        </div>

                        <div className='col col-12 col-md-6 mt-0 mb-4 mb-md-2'>
                            <CheckBox 
                                name='show_dash'
                                style={{justifyContent: 'center', border: '1px solid #ccc', padding: '.5em'}}
                                label='Mostrar no Dashboard'
                                onChange={handleSetCheck}
                                useLoading={true}
                                checked={values.show_dash === 1 ? true : false}
                            />
                        </div>

                        <div className='col col-12 col-md-6 mt-0 mb-4 mb-md-2'>
                            <CheckBox 
                                name='show_compara'
                                style={{justifyContent: 'center', border: '1px solid #ccc', padding: '.5em'}}
                                label='Mostrar nos Comparativos'
                                onChange={handleSetCheck}
                                useLoading={true}
                                checked={values.show_compara === 1 ? true : false}
                            />
                        </div>

                    </div>

                    <hr className='d-md-none'></hr>

                    <div className='row justify-content-center mx-0 px-0 mb-4 d-md-none'>
                        
                        <div className='col col-12 mt-2 px-0'>
                            <Btn 
                                value='Voltar'
                                type='primary'
                                btnType='danger'
                                onClick={() => {navigate(-1)}}
                                useLoading={true}
                            />
                        </div>

                        <div className='col col-12 mt-4 px-0'>
                            <Btn 
                                value={isNaN(idOk) ? 'Adicionar' : 'Salvar'}
                                disabled={disabledAdd}
                                type='submit'
                                btnType='success'
                                onClick={handleAdd}
                                useLoading={true}
                            />
                        </div>

                    </div>  

                </div>
                
                <div className='row px-0 mx-0 gx-0 d-md-none order-2 mt-5'><hr></hr></div>                

                <div className='col col-12 col-md-6 order-1 order-md-3'>

                    <div className='container-lg d-none d-md-block'>
                        <h2 className='pageTitle sub'>{TXTS.INDICADORES_CALCULADORA_TITLE}</h2>
                    </div>

                    <hr className='d-none d-md-block'></hr>

                    <div className='container-lg d-none d-md-block'>
                        <h2 className='pageTitle sub-m'>{TXTS.INDICADORES_FORMULA}</h2><i className='indInfo'>{TXTS.INDICADORES_FORMULA_INFO}</i>
                    </div>

                    <div className='calculadora mt-2'>
                        
                        <div className={`visor ${visorLoad ? 'Cloading' :  ''}`}>

                        {!F.isEmpty(formulaShow) &&

                            Object.keys(formulaShow).map((key) => {
                                return(

                                    <span key={key} title={formulaShow[key]['title']} className={formulaShow[key]['tp']}>
                                        {formulaShow[key]['value']}
                                    </span>
                                                                            
                                )
                            })

                        }

                        </div>

                        <div className={`visor numeros ${visorLoad ? 'Cloading' :  ''}`} ref={visorNum}>

                            <h6>
                                
                                {formulaResult}

                                {porcentagem !== 0 &&
                                    <>
                                        <b> = </b><i>{porcentagem}%</i>
                                    </>
                                }
                            </h6>

                        </div>

                        <div className='btnsIndica justify-content-center mt-3'>
                            
                            <span 
                                disabled={disabledClear}
                                title={disabledClear ? '' : 'Voltar Último'}
                                onClick={handleBack}
                                className={`${cLoading ? 'Cloading' :  ''} ${disabledClear ? 'disabled' :  ''}`}>
                                    <FontAwesomeIcon icon={FA.faRotateLeft} fontSize={22}/>
                            </span>

                            <span 
                                disabled={disabledClear}
                                onClick={handleClearFormula}
                                className={`${cLoading ? 'Cloading' :  ''} ${disabledClear ? 'disabled' :  ''}`}>
                                    LIMPAR
                            </span>

                        </div>

                        <div className='btnsCalculadora mb-3 mt-3'>

                            <span 
                                ref={btOpenPare}
                                value='('
                                onClick={(e) => handleFormula(e)}
                                className={`${cLoading ? 'Cloading' :  ''}`}>
                                    {`(`}
                            </span>

                            <span
                                ref={btClosePare}
                                value=')'
                                onClick={(e) => handleFormula(e)}
                                className={`${cLoading ? 'Cloading' :  ''}`}>
                                    {`)`}
                            </span>

                            <span 
                                disabled={disabledOp}
                                ref={btOperaMenos}
                                value='-'
                                onClick={(e) => handleFormula(e)}
                                className={`${cLoading ? 'Cloading' :  ''} ${disabledOp ? 'disabled' :  ''}`}>
                                    -
                            </span>

                            <span 
                                disabled={disabledOp}
                                ref={btOperaMais}
                                value='+'
                                onClick={(e) => handleFormula(e)}
                                className={`${cLoading ? 'Cloading' :  ''} ${disabledOp ? 'disabled' :  ''}`}>
                                    +
                            </span>

                            <span 
                                disabled={disabledOp}
                                ref={btOperaDiv}
                                value='/'
                                onClick={(e) => handleFormula(e)}
                                className={`${cLoading ? 'Cloading' :  ''} ${disabledOp ? 'disabled' :  ''}`}>
                                    /
                            </span>

                            <span 
                                disabled={disabledOp}
                                ref={btOperaMult}
                                value='*'
                                onClick={(e) => handleFormula(e)}
                                className={`${cLoading ? 'Cloading' :  ''} ${disabledOp ? 'disabled' :  ''}`}>
                                    X
                            </span>

                            <span 
                                disabled={!canChangeSign}
                                onClick={handleChangeSign}
                                className={`${cLoading ? 'Cloading' :  ''} ${!canChangeSign ? 'disabled' :  ''}`}>
                                    +/-
                            </span>

                        </div>
                        
                        <div className='container-lg mx-0 px-0'>

                            <hr className='d-none d-md-block'></hr>

                            <div className='row justify-content-center mx-0 px-0 mb-4 d-none d-md-flex'>
                        
                                <div className='col col-12 col-md-3 col-xxl-2 mt-2 mx-0 px-0 mx-md-3'>
                                    <Btn 
                                        value='Voltar'
                                        type='button'
                                        btnType='primary'
                                        onClick={() => {navigate(-1)}}
                                        useLoading={true}
                                    />
                                </div>

                                <div className='col col-12 col-md-3 col-xxl-2 mt-2 mx-0 px-0 mx-md-3'>
                                    <Btn 
                                        value={isNaN(idOk) ? 'Adicionar' : 'Salvar'}
                                        disabled={disabledAdd}
                                        type='submit'
                                        btnType='success'
                                        onClick={handleAdd}
                                        useLoading={true}
                                    />
                                </div>

                            </div>  

                        </div>
                         
                    </div>             

                </div>
               
            </form>

        </div>

      </motion.section>
    );
}

export default MontarIndicador;