import { instance } from "./Default.service";

export const GetLocadoras = async () => {

    return await instance.get('getLocadoras')
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const GetLocadora = async (id = 0) => {

    return await instance.get(`locMercado/getLocadora/${id}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const UpdateLocadora = async (data) => {

    return await instance.post('locMercado/updateLocadora', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const GetFrotaLocadora = async (data) => {

    return await instance.post('getFrotaLocadora', data)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}

export const ChangeLogo = async (data) => {

    return await instance.post('locMercado/changeLogo', data, {
        headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => {
                return response;
            })
            .catch(error => {
                if(error.response){
                    return error.response;
                }else{
                    return null;
                }
            });
}


export const DeleteLocadora = async (id) => {

    return await instance.get(`locMercado/deleteLocadora/${id}`)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(error.response){
                        return error.response;
                    }else{
                        return null;
                    }
                });
}
