import React, { useRef, useState, useMemo, useEffect, useContext } from "react";
import * as XLSX from 'xlsx';
import Btn from "../Forms/Btn";
import { MesAnoPeriodo } from "../../util/Functions";
import { GlobalFiltersContext } from '../../contexts/GlobalFiltersContext';
import * as F from '../../util/Functions';
import './index.css';

function Excel({

    onReady = false,
    type = 'import',
    title = false,
    label = false,
    data = [],
    mes = false,
    columns = [],
    columnsTitles = [],
    subject = false,
    disableExport = undefined,
    indicadoresRefShow,
    indicadoresLocShow1,
    indicadoresLocShow2,
    indicadoresLocShow3,
    lastLocPadraoTop1,
    lastLocPadraoTop2,
    lastLocPadraoTop3,
    selTop1,
    selTop2,
    selTop3,
    selectedTop1,
    selectedTop2,
    selectedTop3,
    multi = false,
    useValue = true

}) {

    const fileInputRef =  useRef(null);

    const [fileName, setFileName] = useState('');

    const [disabled, setDisabled] = useState(false);

    const [titleIn, setTitleIn] = useState(title ? title : '');

    const { periodo } = useContext(GlobalFiltersContext);

    const labelIn = useMemo(() => {
        
        try{

            if(label){

                return label;

            }else{

                if(title){

                    return title;

                }else{

                    return type === 'import' ? 'Importar' : 'Exportar';

                }

            }

        }catch{

            return 'Sem Título';

        }
        
    }, [title, label, type]);

    const handleImportExport = () => {

        if(type === 'import' && fileInputRef.current !== null){
            fileInputRef.current.click();
            return;
        }

        if(type === 'export' && data.length !== 0){
            handleExportExcel();
        }

        if(type === 'exportComparativo' && data.length !== 0){
            handleExportComparativo();
        }

    }

    const handleExportExcel = () => {

        let nomeP2;

        if(multi){
            nomeP2 = periodo ? periodo.replace('/', '-') : '';
        }else{
            nomeP2 = mes ? MesAnoPeriodo(mes, mes).replace('/', '-') : '';
        }

        nomeP2 = nomeP2 !== '' ? nomeP2 : 'Download';
        
        const nomePlanilha = `${subject !== '' ? `${subject} - `:''}${nomeP2}`;

        const workbook = XLSX.utils.book_new();

        if(multi){

            data.forEach( (item, key) => {

                let dataItem = item.campos ? item.campos : item;

                if(!Array.isArray(dataItem)){
                    dataItem = [dataItem];
                }

                if(!F.isEmpty(dataItem)){
                    
                    let nomeSheet = item.mes_ano ? item.mes_ano.replace('/', '-') : '';

                    if(nomeSheet === '' && item.mesAno){
                        nomeSheet = item.mesAno.replace('/', '-');
                    }

                    nomeSheet = nomeSheet !== '' ? nomeSheet : `Download-${key}`;

                    if(columns.length !== 0){

                        const l = new Set(columns);
            
                        for (let obj of dataItem) {
                            for (let prop of Object.keys(obj)) {
                                if (!l.has(prop)) {
                                    delete obj[prop];
                                }
                            }
                        }
                    }

                    const worksheet = XLSX.utils.json_to_sheet(dataItem);
                    const wscols = [];
                    const wsrows = [{hpx: 15}];
            
                    dataItem.forEach((item, index) => {
                        
                        if(index === 0){
            
                            Object.keys(item).forEach(tl => {
            
                                let max_width = 5;
            
                                try{
            
                                    max_width = dataItem.reduce((w, r) => Math.max(w, r[tl].length), 10);
            
                                }catch{
            
                                }
                                
                                wscols.push({
                                    hidden: tl === 'id',
                                    wch: max_width 
                                });
                                
                            });
            
                        }
            
                        wsrows.push({hpx: 15});
                        
                    });
            
                    worksheet['!cols'] = wscols;
                    worksheet['!rows'] = wsrows;
                    worksheet['!margins'] = {left:0.7, right:0.7, top:0.75,bottom:0.75,header:0.3,footer:0.3};
                    
                    if(columnsTitles.length > 0){
                        XLSX.utils.sheet_add_aoa(worksheet, [columnsTitles], { origin: "A1" });
                    }
            
                    XLSX.utils.book_append_sheet(workbook, worksheet, nomeSheet);

                }

            });

        }else{


            if(!Array.isArray(data)){
                data = [data];
            }
            
            if(columns.length !== 0){

                const l = new Set(columns);
    
                for (let obj of data) {
                    for (let prop of Object.keys(obj)) {
                        if (!l.has(prop)) {
                            delete obj[prop];
                        }
                    }
                }
            }
            
            const worksheet = XLSX.utils.json_to_sheet(data);
    
            const wscols = [];
            const wsrows = [{hpx: 15}];
    
            data.forEach((item, index) => {
                
                if(index === 0){
    
                    Object.keys(item).forEach(tl => {
    
                        let max_width = 5;
    
                        try{
    
                            max_width = data.reduce((w, r) => Math.max(w, r[tl].length), 10);
    
                        }catch{
    
                        }
                        
                        wscols.push({
                            hidden: tl === 'id',
                            wch: max_width 
                        });
                        
                    });
    
                }
    
                wsrows.push({hpx: 15});
                
            });
    
            worksheet['!cols'] = wscols;
            worksheet['!rows'] = wsrows;
            worksheet['!margins'] = {left:0.7, right:0.7, top:0.75,bottom:0.75,header:0.3,footer:0.3};
    
            if(columnsTitles.length > 0){
                XLSX.utils.sheet_add_aoa(worksheet, [columnsTitles], { origin: "A1" });
            }
    
            XLSX.utils.book_append_sheet(workbook, worksheet, nomePlanilha);

        }

        XLSX.writeFile(workbook, `${nomePlanilha}.xlsx`, {
            bookType: 'xlsx', 
            type: 'binary'
        });
        
    }

    const handleExportComparativo = () => {
        
        const nomePlanilha = `${subject !== '' ? `${subject} - ` : ''}${mes ? MesAnoPeriodo(mes, mes).replace('/', '-') : 'Download'}`;
      
        let topCol1 = `${(selectedTop1 && selTop1.find(option => option.id === selectedTop1)?.label)} - ` || '';

        if(typeof selectedTop1 === 'number'){
            topCol1 += lastLocPadraoTop1;
        }

        let topCol2 = `${(selectedTop2 && selTop2.find(option => option.id === selectedTop2)?.label)} - ` || '';

        if(typeof selectedTop2 === 'number'){
            topCol2 += lastLocPadraoTop2;
        }

        let topCol3 = `${(selectedTop3 && selTop2.find(option => option.id === selectedTop3)?.label)} - ` || '';

        if(typeof selectedTop3 === 'number'){
            topCol3 += lastLocPadraoTop3;
        }

        const dataPlanilha = [['Indicador', topCol1, topCol2, topCol3]];

        const wsrows = [{hpx: 15}];

        data.forEach(item => {

            let col1 = '0%';
            let col2 = '0%';
            let col3 = '0%';

            const findLocRef = (indicadoresRefShow.find(obj => obj.id === item.id));
            const indLocRef = findLocRef ? findLocRef.porcentagem :  '0%';

            if (selectedTop1 === 'CLIENTE') {
                col1 = item.porcentagem;
            } else if (selectedTop1 === 'IDR') {
                col1 = indLocRef;
            } else {
                const findLocPadrao = indicadoresLocShow1.find(obj => obj.id === item.id);
                col1 = findLocPadrao ? findLocPadrao.porcentagem : '0%';
            }

            if (selectedTop2 === 'CLIENTE') {
                col2 = item.porcentagem;
            } else if (selectedTop2 === 'IDR') {
                col2 = indLocRef;
            } else {
                const findLocPadrao = indicadoresLocShow2.find(obj => obj.id === item.id);
                col2 = findLocPadrao ? findLocPadrao.porcentagem : '0%';
            }

            if (selectedTop3 === 'CLIENTE') {
                col3 = item.porcentagem;
            } else if (selectedTop3 === 'IDR') {
                col3 = indLocRef;
            } else {
                const findLocPadrao = indicadoresLocShow3.find(obj => obj.id === item.id);
                col3 = findLocPadrao ? findLocPadrao.porcentagem : '0%';
            }

            dataPlanilha.push([item.nome, col1, col2, col3]); 

            wsrows.push({hpx: 15});

        });
      
        const ws = XLSX.utils.aoa_to_sheet(dataPlanilha);
      
        const wscols = dataPlanilha[0].map((col, i) => {
          const maxColWidth = Math.max(...dataPlanilha.map(row => row[i].length));
          return { wch: maxColWidth + 2 };
        });

        ws['!cols'] = wscols;
        ws['!rows'] = wsrows;
        ws['!margins'] = {left:0.7, right:0.7, top:0.75,bottom:0.75,header:0.3,footer:0.3};
      
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, nomePlanilha);
      
        XLSX.writeFile(wb, `${nomePlanilha}.xlsx`, {
          bookType: 'xlsx',
          type: 'binary'
        });
    }

    const readExcel = async (e) => {

        setDisabled(true);
        setTitleIn('Aguarde...');
        const file = e.target.files[0];
        const data = await file.arrayBuffer(file);
        const excelFile = XLSX.read(data);
        const excelSheet = excelFile.Sheets[excelFile.SheetNames[0]];
        const excelJson = XLSX.utils.sheet_to_json(excelSheet);

        setFileName(e.target.files[0].name);

        if (fileInputRef) fileInputRef.current.value = null;

        if (typeof onReady === "function") { 

            onReady(excelJson);
        
        }

        setTimeout(() => {
            setDisabled(disableExport !== undefined ? disableExport : false);
            setTitleIn(titleIn);
        }, 1000);

    }

    useEffect(() => {
        setDisabled(disableExport !== undefined ? disableExport : false);
    }, [disableExport])

    return(
        
        <div className="importExcel">

            {type === 'import' &&
                
                <input 
                    id='fileImport' 
                    ref={fileInputRef} 
                    type="file" 
                    onChange={ (e) => readExcel(e) }
                    accept='application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                />
            
            }

            <Btn 
                btnType={type === 'import' ? 'primary' : 'success'}
                title={titleIn}
                disabled={disabled}
                {...(useValue && { value: fileName === '' ? labelIn : fileName })}
                iconLeft={type === 'import' ? 'faFileImport' : 'faFileExcel'}
                onClick={handleImportExport}
            />
        
        </div>
    );

}

export default Excel;