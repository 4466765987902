import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../../components/Forms/Input';
import Btn from '../../components/Forms/Btn';
import { motion } from 'framer-motion';
import { fade } from '../../util/AnimatedDiv'
import { LoaderContext } from '../../contexts/LoaderContext';
import { SiteContext } from '../../contexts/SiteContext';
import { AlertasContext } from '../../contexts/AlertasContext';
import { UserContext } from '../../contexts/UserContext';
import { GlobalFiltersContext } from '../../contexts/GlobalFiltersContext';
import * as F from '../../util/Functions';
import * as DRE from '../../services/Dres.service';
import * as TXTS from '../../util/Texts';
import { LOGO_AUDIT } from '../../util/Urls';
import Excel from '../../components/Excel';

function DreAdd() {

    const navigate                          = useNavigate();
    const { id }                            = useParams();
    const { setIsLoading }                  = useContext(LoaderContext);
    const { isLogged }                      = useContext(UserContext);

    const { 
        nomeVerDeEmpresa,
        nomeLocadoraParaoBal,
        globalFiltersPeriodo
    } = useContext(GlobalFiltersContext);

    const [campos, setCampos]               = useState({});
    const [unsavedCampos, setUnsavedCampos] = useState({});

    const columns                               = ['id', 'titulo', 'value'];
    const columnsTitles                         = ['id', 'Título', 'Valor'];
    const [camposDownload, setCamposDownload]   = useState([]);

    const {
        HandleScroll,
        setDocTitle,
        setErrorCon
    } = useContext(SiteContext);

    const {

        setAlertShow,
        setAlertBtnOk,
        setAlertBtnOkTxt,
        setAlertBtnCancel,
        setAlertOnOk,
        setAlertOnClose,
        handleCloseAlert,
        setAlertType,
        setAlertMsg,
    
    } = useContext(AlertasContext);

    const [disabledAdd, setDisabledAdd] = useState(true);

    const [values, setValues] = useState({ mes_ano: F.MesAno() });

    const [reqValues, setReqValues] = useState([ 'mes_ano' ]);

    const handleMesAno = (e) => {
        
        const value = e.target.value === '' ? F.MesAno() : e.target.value;

        setValues({
            ...values,
            mes_ano: value,
        });

    }

    const handleSetValues = (e) => {
       
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    }

    const handleExcel = (excel) => {
        
        excel.forEach(item => {
            
            const valor = isNaN(item.Valor) ? F.Decimal(item.Valor) : item.Valor;

            if(F.KeyExists(values, item.id)){
                setValues(values => ({ ...values, [item.id]: F.Moeda(valor, false) }));
            }
            
        });

    }

    const handleAdd = async (e) => {
        
        e.preventDefault();

        const data = {
            campos: unsavedCampos,
            mes_ano: values.mes_ano,
            id: isNaN(id) ? 0 : id,
            companyId: globalFiltersPeriodo.companyId
        }
        
        setIsLoading('show');
      
        try{

            const result = isNaN(id) ? await DRE.AddDre(data) : await DRE.EditDre(data);

            if(result.data){

                if (result.status === 200) {

                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    setAlertOnClose(() => () => {navigate(-1)});
                    setAlertType('success');
                    setAlertBtnOk(false);  

                }else{

                    setAlertShow(true);
                    setAlertMsg(result.data.msg);
                    if(result.data.campos){

                        setAlertType('warning');
                        setAlertBtnCancel(true);
                        setAlertBtnOkTxt('Copiar Dados');
                        setAlertOnOk(() => () => {handleSetFields(result.data.campos, result.data.mes_ano); handleCloseAlert()});

                    }else{

                        setAlertType('error');
                        setAlertBtnOk(false);
                    }
                    
                }

            }else{
                
                setAlertShow(true);
                setAlertMsg(TXTS.ERRO_LOGIN_2);
                setAlertType('error');
                setAlertBtnOk(false);
            
            }

        }catch{

            setAlertShow(true);
            setAlertMsg(TXTS.ERRO_LOGIN_3);
            setAlertType('error');
            setAlertBtnOk(false);
            setErrorCon(true);
            
        }

        setIsLoading('hide');

    }

    const handleSetFields = (campos, mes_ano) => {

        setCampos(campos);
        setValues({
            ...values,
            mes_ano: mes_ano,
        });
    }

    const handleGetFields = async (id) => {

        setIsLoading('show');

        try{

            const result =  await DRE.GetFields(id, globalFiltersPeriodo.companyId);
            
            if (result.status === 200) {
                handleSetFields(result.data.campos, result.data.mes_ano);
            }else{
                navigate('/balancos');
            }
            
        }catch{

        }

        setIsLoading('hide');

    }

    useEffect(() => {
        
        if(!F.isEmpty(camposDownload) && !F.isEmpty(unsavedCampos)){
            
            for (const [key, value] of Object.entries(unsavedCampos)) {
                
                const find = camposDownload.find(x => x.id === Number(key));
                
                if(find){
                    find.value = Number(value);
                }
                
            }

            setCamposDownload(camposDownload);
        }

    }, [camposDownload, unsavedCampos]);

    useEffect(() => {

        if(!F.isEmpty(campos)){
            setCamposDownload(campos);
        }

        for(const key of Object.keys(campos)){
        
            setValues(values => ({ ...values, [campos[key]['id']]: campos[key]['value'] }));
            if(campos[key]['required'] === 1){
                setReqValues(reqValues => [...reqValues, campos[key]['id']]);
            }
        }  

    }, [campos]);

    useEffect(() => {
        
        let err = 0;
    
        reqValues.forEach((item) => {

          if(values[item] === ''){ err += 1;}

        });
    
        if(err === 0){
            setDisabledAdd(false);
        }else{
            setDisabledAdd(true);
        }

        for (const [key, value] of Object.entries(values)) {
            if(key !== 'mes_ano'){
                setUnsavedCampos(unsavedCampos => ({ ...unsavedCampos, [key]: F.Decimal(value) }));
            }
        }
        
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);  
    
    
    useEffect(() => {
        setDocTitle(isNaN(id) ? TXTS.DRE_ADD_TITLE : TXTS.DRE_EDIT_TITLE);
        
        if(isLogged){
            setIsLoading('hide');
            handleGetFields(isNaN(id) ? 0 : id);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsLoading, isLogged]);

    return (
      <motion.section 
        className="conteudo"

        variants={fade}
        initial='entrada'
        animate='animacao'
        exit='saida'
        
        onScroll={HandleScroll}
      >

        <div className='container-lg'>
            <h2 className='pageTitle'>
                {isNaN(id) ? TXTS.DRE_ADD_TITLE : TXTS.DRE_EDIT_TITLE}
                {nomeLocadoraParaoBal !== '' ? ` - (${nomeLocadoraParaoBal})` : ''}
                {nomeVerDeEmpresa !== '' ? ` - (${nomeVerDeEmpresa})` : ''}
            </h2>
        </div>

        <div className='container container-lg'>

            <div className='row justify-content-center mx-0 px-0'>
                <div className='col col-12 col-md-3'>
                    <Excel 
                        type='import'
                        title='Importar Excel'
                        onReady={(excel) => {handleExcel(excel)}}
                    />
                </div>

                <div className='col col-12 col-md-3 mt-4 mt-md-0'>
                    <Excel 
                        type='export'
                        title={isNaN(id) ?  'Download Modelo' : 'Download DRE'}
                        data={camposDownload}
                        mes={values.mes_ano}
                        subject='DRE'
                        columns={columns}
                        columnsTitles={columnsTitles}
                    />
                </div>
            </div>
            
            <hr></hr>

            <b>*Atenção: Preencher com o valor acumulado do exercício até {F.MesAnoGr(values.mes_ano)}</b>

            <hr></hr>

            <form className='row justify-content-start gy-3 gx-4 mt-1'>
                
                <div className='col col-12 col-md-3 col-xxl-2'>
                    <Input 
                        name='mes_ano'
                        placeholder='Referente:'
                        required={true}
                        type='month'
                        value={values.mes_ano}
                        onChange={handleMesAno}
                        useLoading={true}
                    />
                </div>

                {!F.isEmpty(campos) && F.IsValReady(campos, values) &&

                    Object.keys(campos).map((key) => {
                        return(
                            <div key={key} className='col col-12 col-md-3 col-xxl-2'>
                                <Input 
                                    id={campos[key]['id']}
                                    name={campos[key]['id']}
                                    labelLeft='faDollarSign'
                                    placeholder={campos[key]['titulo']}
                                    title={campos[key]['comentario']}
                                    type='number'
                                    currency={true}
                                    required={campos[key]['required'] === 1 ? true : false}
                                    value={values[campos[key]['id']]}
                                    onChange={handleSetValues}
                                    useLoading={true}
                                />
                            </div>
                        )
                    })
                }

                <div className='row justify-content-center mx-0 px-0'>
                    
                    <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                        <Btn 
                            value='Cancelar'
                            type='button'
                            btnType='danger'
                            onClick={() => {navigate('/balancos')}}
                            useLoading={true}
                        />
                    </div>

                    <div className='col col-12 col-md-2 col-xxl-1 mt-4'>
                        <Btn 
                            value={isNaN(id) ?  'Adicionar' : 'Salvar'}
                            disabled={disabledAdd}
                            type='submit'
                            btnType='success'
                            onClick={handleAdd}
                            useLoading={true}
                        />
                    </div>

                </div>
               
            </form>
        </div>

        <div className='logoBotton'>
            <img src={LOGO_AUDIT} alt="AUDIT" className='auditLayout'/> 
        </div>
      </motion.section>
    );
}

export default DreAdd;